import { memo, useMemo } from "react";
import { alpha, Box, SxProps, Typography, Theme } from "@mui/material";

export const AvatarColors = [
  "red",
  "orange",
  "yellow",
  "green",
  "cyan",
  "blue",
  "navy",
  "purple",
  "pink",
] as const;
export type AvatarColor = (typeof AvatarColors)[number];

const DEFAULT_AVATAR_COLORS: AvatarColor[] = [
  "red",
  "orange",
  "yellow",
  "green",
  "cyan",
  "blue",
  "navy",
  "purple",
];

const sizeStyles: Record<string, SxProps<Theme>> = {
  size48px: {
    width: "48px",
    height: "48px",
    margin: `0 12px 0 0`,
  },
  size40px: {
    width: "40px",
    height: "40px",
    margin: `0 12px 0 0`,
  },
  size24px: {
    width: "24px",
    height: "24px",
    margin: `0 12px 0 0`,
  },
};

const variantStyles: Record<string, SxProps<Theme>> = {
  circle: { borderRadius: "50%" },
  square: { borderRadius: "0%" },
};

const letterStyles: Record<string, SxProps<Theme>> = {
  font16px: {
    fontSize: "16px",
    lineHeight: 1.43,
  },
  font14px: {
    fontSize: "1rem",
    lineHeight: 1.43,
  },
  font12px: {
    fontSize: "0.75rem",
    lineHeight: 1.33,
  },
};

const colorStyles: Record<string, SxProps<Theme>> = {
  redColor: {
    border: `1px solid #ffd4cc`,
    backgroundColor: alpha("#ffd4cc", 0.5),
    color: "#f55d3e",
  },
  orangeColor: {
    border: `1px solid #ffe7ba`,
    backgroundColor: alpha("#ffe7ba", 0.5),
    color: "#ffa400",
  },
  yellowColor: {
    border: `1px solid #fff1bc`,
    backgroundColor: alpha("#fff1bc", 0.5),
    color: "#f9c80e",
  },
  greenColor: {
    border: `1px solid #defbed`,
    backgroundColor: alpha("#defbed", 0.5),
    color: "#00cc66",
  },
  cyanColor: {
    border: `1px solid #bff2f3`,
    backgroundColor: alpha("#bff2f3", 0.5),
    color: "#00ced1",
  },
  blueColor: {
    border: `1px solid #b4e3f6`,
    backgroundColor: alpha("#b4e3f6", 0.5),
    color: "#008cd6",
  },
  navyColor: {
    border: `1px solid #bcd3f8`,
    backgroundColor: alpha("#bcd3f8", 0.5),
    color: "#0b4cd9",
  },
  purpleColor: {
    border: `1px solid #e3c9f7`,
    backgroundColor: alpha("#e3c9f7", 0.5),
    color: "#7d2ed7",
  },
  pinkColor: {
    border: `1px solid #ffd2de`,
    backgroundColor: alpha("#ffd2de", 0.5),
    color: "#f7567c",
  },
};

export interface LetterAvatarProps {
  letter?: string;
  color?: "auto" | AvatarColor | number;
  variant?: "circle" | "square";
  size?: "24px" | "40px" | "48px";
  colors?: AvatarColor[];
  className?: string;
}

function LetterAvatar(props: LetterAvatarProps) {
  const {
    className: classNameProp,
    letter,
    color: colorProp,
    variant,
    size,
    colors = DEFAULT_AVATAR_COLORS,
  } = props;

  const firstLetter = useMemo(
    () => letter?.charAt(0).toUpperCase() ?? "",
    [letter]
  );

  const autoColor = useMemo(
    () => firstLetter.charCodeAt(0) % colors.length,
    [colors.length, firstLetter]
  );

  const color = colorProp === "auto" ? autoColor : colorProp;
  const colorStr =
    typeof color === "number" ? colors[color % colors.length] : color;
  const colorClassName = colorStr && (`${colorStr}Color` as const);

  const currentSizeStyle: SxProps<Theme> = {
    ...(size ? sizeStyles[`size${size}`] : {}),
  };

  const currentColorStyle: SxProps<Theme> = {
    ...(colorClassName ? colorStyles[colorClassName] : {}),
  };

  const currentLetterStyle: SxProps<Theme> = {
    width: "100%",
    height: "100%",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 400,
    ...(size === "48px" ? letterStyles.font16px : letterStyles.font16px),
  };

  const currentVariantStyle: SxProps<Theme> = variant
    ? variantStyles[variant]
    : variantStyles["circle"];

  return (
    <Box
      sx={[
        {
          width: "48px",
          height: "48px",
          display: "flex",
        },
        ...(Array.isArray(currentVariantStyle)
          ? currentVariantStyle
          : [currentVariantStyle]),
        ...(Array.isArray(currentSizeStyle)
          ? currentSizeStyle
          : [currentSizeStyle]),
        ...(Array.isArray(currentColorStyle)
          ? currentColorStyle
          : [currentColorStyle]),
      ]}
    >
      <Typography component="div" sx={currentLetterStyle}>
        {firstLetter}
      </Typography>
    </Box>
  );
}

export default memo(LetterAvatar);
