import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";

interface Package {
  info: {
    title: string;
    description: string;
    // tag: string;
    titleNoWrap?: boolean;
    isOuterLink?: boolean;
    url: string;
    src: string;
  };
  total: number;
}

export default function AppCard(props: Package) {
  const { info, total } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToPage = (url: string | undefined, isOuterLink: boolean) => {
    if (!url) return;
    if (isOuterLink) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };
  const theme = useTheme();
  const queryBetweenSm = useMediaQuery(theme.breakpoints.between("sm", "md"));
  return (
    <Box
      sx={{
        boxSizing: "border-box",
        width: total !== 1 && queryBetweenSm ? "50%" : "100%",
        maxWidth: { md: "280px", xs: "auto" },
        minHeight: { md: "200px", xs: "160px" },
        height: { md: "200px", xs: "160px" },
        padding: "20px",
        border: "1px solid #C8C8C8",
        display: "flex",
        flexDirection: "column",
        alignItems: { md: "flex-end", sm: "flex-end", xs: "flex-start" },
        gap: "4px",
        backgroundColor: { sm: "transparent", xs: "#fff" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: " flex-start",
          flexDirection: { sm: "row", xs: "row-reverse" },
          gap: "20px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: { sm: "60px", xs: "54px" },
            maxHeight: { sm: "60px", xs: "54px" },
            borderRadius: "6px",
            overflow: "hidden",
          }}
        >
          <img
            src={info.src as string}
            style={{ width: "100%" }}
            alt={info.title as string}
          />
        </Box>
        <Box
          sx={{
            boxSizing: "border-box",
            width: "100%",
            maxWidth: { md: "160px", sm: "100%", xs: "358px" },
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            padding: "4px 0",
            alignItems: "center",
            height: { md: "132px", xs: "100px" },
          }}
        >
          <Typography
            sx={{
              width: "100%",
              fontWeight: "700",
              fontSize: { sm: "16px", xs: "14px" },
              lineHeight: "24px",
              alignItems: "center",
              color: "#323233",
              "-webkit-line-clamp": {
                xs: "2",
              },
              textOverflow: "ellipsis",
              display: "-webkit-box",
              "-webkit-box-orient": "vertical",
              overflow: "hidden",
            }}
          >
            {info.title}
          </Typography>

          <Typography
            sx={{
              width: "100%",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "16px",
              color: "#89898A",
              maxHeight: "88px",
              overflowX: "hidden",
              "-webkit-line-clamp":
                total !== 1 && queryBetweenSm
                  ? { xs: "3" }
                  : {
                      md: "4",
                      xs: "6",
                    },
              textOverflow: "ellipsis",
              display: "-webkit-box",
              "-webkit-box-orient": "vertical",
              overflow: "hidden",
            }}
          >
            {info.description}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          sx={{
            color: "#008CD6",
            fontWeight: 700,
            fontSize: "16px",
            textTransform: "none",
            padding: "0",
          }}
          onClick={() => {
            goToPage(info.url ?? "", !!info.isOuterLink);
          }}
        >
          <Typography color="#008CD6" fontWeight="700">
            {t("landing-page.common.learn-more")}
          </Typography>

          <ChevronRightIcon style={{ color: "#008CD6" }} />
        </Button>
      </Box>
    </Box>
  );
}
