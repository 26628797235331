import type {
  apps,
  Architectures,
  packages,
  transformative,
} from "../types/architecture";
import { infoTypes } from "../types/architecture";
import fakePicture from "../images/unknownItem.png";
import activityRecognitionPic from "../images/activity_recognition_img.png";
import energyIntensivePic from "../images/energy_intense_optimization_img.png";
import inspectionAIPic from "../images/inspection_ai_img.png";
import phmPic from "../images/phm_img.png";
import smartSchedulingPic from "../images/smart_scheduling_img.png";
import spcPic from "../images/spc_img.png";
import { useTranslation, Trans } from "react-i18next";
import addOnPic from "../../../../images/AddOn.png";
import eManualPic from "../../../../images/eManual.png";
import maintenancePic from "../../../../images/img_service_maintenance.png";
import oeePic from "../../../../images/OEE.png";
import shopfloorPic from "../../../../images/ShopFloor.png";
import trackerPic from "../../../../images/img_service_sc-tracker.png";

const useArchitecturesData = () => {
  const { t } = useTranslation();
  // let starterPackageInfo: packages[] = [
  //   {
  //     title: t("solutions.tpm.machine-inspector"),
  //     description: t(
  //       "solutions.tpm.zero-downtime-machine-doctor-focuses-on-detecting-specific-parameters-of-machines-and-gives-an-appropriate-early-diagnosis-evaluation-to-avoid-unplanned"
  //     ),
  //     src: fakePicture,
  //     tag: "4b",
  //   },
  //   {
  //     title: t("solutions.tpm.quick-response"),
  //     description: t(
  //       "solutions.tpm.warn-and-report-to-the-relevant-personnel-in-case-of-equipment-abnormalities"
  //     ),
  //     src: fakePicture,
  //     tag: "4c",
  //     url: "/starter-packages/quick-response",
  //   },
  //   {
  //     title: t("solutions.tpm.real-time-progress"),
  //     description: t(
  //       "solutions.tpm.notify-the-supervisor-and-manager-immediately-when-equipment-productivity-drops"
  //     ),
  //     src: fakePicture,
  //     tag: "3b",
  //   },
  //   {
  //     title: t("solutions.tpm.work-mentor"),
  //     description: t(
  //       "solutions.tpm.record-recipe-formula-and-work-sop-guiding-on-site-operator-and-providing-standard-formula-and-recipes"
  //     ),
  //     src: fakePicture,
  //     tag: "2b",
  //   },
  // ];
  //
  // let appInfo: apps[] = [
  //   {
  //     title: t("solutions.tpm.andon"),
  //     description: t(
  //       "solutions.tpm.manage-abonormal-event-and-report-to-quickly-return-to-normal-production"
  //     ),
  //     src: addOnPic,
  //     url: t("products.addon-url") as string,
  //     isOuterLink: true,
  //   },
  //   {
  //     title: t("solutions.tpm.emanual"),
  //     description: t(
  //       "solutions.tpm.digital-sop-provides-accurate-product-information-and-fast-updates-which-shortens-the-learning-curve-for-operators"
  //     ),
  //     src: eManualPic,
  //     url: t("products.emanual-url") as string,
  //     isOuterLink: true,
  //   },
  //   {
  //     title: t("solutions.tpm.maintenance"),
  //     description: t(
  //       "solutions.tpm.machine-maintenance-and-repair-strategy-planner-for-production-field"
  //     ),
  //     src: maintenancePic,
  //     url: t("products.maintenance-url") as string,
  //     isOuterLink: true,
  //   },
  //   {
  //     title: t("solutions.tpm.oee"),
  //     description: t(
  //       "solutions.tpm.monitor-and-analysis-machine-and-production-kpi-oee-in-real-time"
  //     ),
  //     src: oeePic,
  //     url: t("products.oee-url") as string,
  //     isOuterLink: true,
  //   },
  //   {
  //     title: (
  //       <Trans i18nKey="solutions.tpm.sc-tracker" components={{ br: <br /> }} />
  //     ),
  //     description: t(
  //       "solutions.tpm.synchronize-work-progress-across-suppliers-for-timely-delivery"
  //     ),
  //     src: trackerPic,
  //     url: "",
  //     isOuterLink: true,
  //   },
  //   {
  //     title: t("solutions.tpm.shop-floor"),
  //     description: t(
  //       "solutions.tpm.production-tracking-scheduling-and-status-reporting"
  //     ),
  //     src: shopfloorPic,
  //     url: t("products.shop-floor-url") as string,
  //     isOuterLink: true,
  //   },
  // ];

  let transformativeInfo: transformative[] = [
    {
      title: t("intelligent-automation.machine-monitoring"),
      description: t(
        "intelligent-automation.identify-production-bottlenecks-and-inefficiencies-with-real-time-machine-data-standardized-real-time-dashboards-reports"
      ),
      src: [fakePicture, fakePicture],
    },
    {
      title: t("intelligent-automation.condition-monitoring"),
      description: t(
        "intelligent-automation.diagnose-issues-with-real-time-status-condition-data"
      ),
      src: [fakePicture, fakePicture],
    },
    {
      title: t("intelligent-automation.process-optimization"),
      description: t("intelligent-automation.analyze-machine-and-process-data"),
      src: [fakePicture, fakePicture],
    },
    {
      title: t("intelligent-automation.equipment-energy-monitoring"),
      description: t(
        "intelligent-automation.utilize-iot-measurement-strategies-to-obtain-accurate-real-time-energy-data"
      ),
      src: [fakePicture, fakePicture],
      titleNoWrap: true,
    },
    {
      title: t("intelligent-automation.build-your-own-applications"),
      description: t(
        "intelligent-automation.build-your-own-operator-ui-and-apps-and-integrate-with-3rd-party-applications"
      ),
      src: [fakePicture, fakePicture],
    },
  ];

  const architectures: Architectures = [
    // {
    //   title: t("solutions.ai.starter_packages"),
    //   description: t(
    //     "solutions.ai.improve_daily_work_by_baselining_performance"
    //   ),
    //   types: infoTypes.packages,
    //   informations: starterPackageInfo,
    // },
    // {
    //   title: t("solutions.ai.optimization_iapps"),
    //   description: t(
    //     "solutions.ai.a_combination_of_datadriven_applications_data"
    //   ),
    //   types: infoTypes.apps,
    //   informations: appInfo,
    // },
    {
      title: t("intelligent-automation.build-your-own-use-cases"),
      description: t(
        "intelligent-automation.continuous-improvement-in-manufacturing-starts-with-intelligent-aio-edge"
      ),
      types: infoTypes.packages,
      informations: transformativeInfo,
    },
  ];

  return architectures;
};
export { useArchitecturesData };
