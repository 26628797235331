import { Partners } from "../../../../components/partners/types/Partners";
import AnewtechPic from "../images/img_partner_Anewtech.png";
import BioEnergyPic from "../images/img_partner_Bio-Energy.png";
import ChurPic from "../images/img_partner_Chur.png";
import EcoPic from "../images/img_partner_ECO.png";
import eAIPic from "../images/img_partner_eAI.png";
import EforelPic from "../images/img_partner_Eforel.png";
import ExpetechPic from "../images/img_partner_Expetech.png";
import GribPic from "../images/img_partner_Grib.png";
import HITIPic from "../images/img_partner_HITI.png";
import HoshimaPic from "../images/img_partner_Hoshima.png";
import HuaPic from "../images/img_partner_Hua Cen.png";
import IBCONPic from "../images/img_partner_IBCON.png";
import IndustrialRevolutionPic from "../images/img_partner_Industrial Revolution.png";
import InternetInitiativePic from "../images/img_partner_Internet Initiative Japan.png";
import ITTSPic from "../images/img_partner_ITTS.png";
import MultitradePic from "../images/img_partner_Multitrade.png";
import NipponRADPic from "../images/img_partner_Nippon RAD.png";
import pfElectronicPic from "../images/img_partner_pf.png";
import Servtech from "../images/img_partner_Servtech.png";
import SmartIDPic from "../images/img_partner_Smart ID.png";
import StreberPic from "../images/img_partner_Streber-Tech.png";
import autoechPic from "../images/img_partner_Autotech_Machinery_JSC.png";
import atecPic from "../images/img_partner_ATEC_C&.png";
import { useTranslation } from "react-i18next";

const usePartnersData = () => {
  const { t } = useTranslation();
  const partners: Partners = [
    {
      name: t("partners.anewtech-systems-pte-ltd"),
      description: t("partners.anewtech-description"),
      region: t("partners.singapore"),
      src: AnewtechPic,
      url: "https://www.anewtech.net/",
      location: "ASEAN",
    },
    {
      name: t("partners.atec-c-and-co-ltd"),
      description: t("partners.atec-description"),
      region: t("partners.south-korea"),
      src: atecPic,
      url: "https://www.ateccn.kr/main/main.html",
      location: "NorthernAsia",
    },
    {
      name: t("partners.autotech-machinery-jsc"),
      description: t("partners.autotec-description"),
      region: t("partners.vietnam"),
      src: autoechPic,
      url: "https://www.auto-tech.vn/home-new/en/",
      location: "ASEAN",
    },
    {
      name: t("partners.bio-energy-technology-sdn-bhd"),
      description: t("partners.bio-description"),
      region: t("partners.malaysia"),
      src: BioEnergyPic,
      url: "https://www.bio-energy.com.my/",
      location: "ASEAN",
    },
    {
      name: t("partners.chur-co-ltd"),
      description: t("partners.chur-co-ltd-description"),
      region: t("partners.taiwan"),
      src: ChurPic,
      url: "https://www.chur.com.tw/",
      location: "NorthernAsia",
    },
    {
      name: t("partners.eai-technologies-inc"),
      description: t("partners.eai-technologies-inc-description"),
      region: t("partners.taiwan"),
      src: eAIPic,
      url: "https://www.eai.com.tw/",
      location: "NorthernAsia",
    },
    {
      name: t("partners.eco-industrial"),
      description: t("partners.eco-automacao-industrial-description"),
      region: t("partners.brazil"),
      src: EcoPic,
      url: "https://www.ecoautomacao.com.br/",
      location: "SouthAmerica",
    },
    {
      name: t("partners.eforel-citra-utama-pt"),
      description: t("partners.eforel-citra-utama-pt-description"),
      region: t("partners.indonesia"),
      src: EforelPic,
      url: "https://eforel.com/",
      location: "ASEAN",
    },
    {
      name: t("partners.expetech-co-ltd"),
      description: t("partners.expetech-co-ltd-description"),
      region: t("partners.taiwan"),
      src: ExpetechPic,
      url: "",
      location: "NorthernAsia",
    },
    {
      name: t("partners.grib-co-ltd"),
      description: t("partners.grib-co-ltd-description"),
      region: t("partners.south-korea"),
      src: GribPic,
      url: "http://grib-iot.com/ko/index.asp",
      location: "NorthernAsia",
    },
    {
      name: t("partners.hiti-industrial-automation"),
      description: t("partners.footwear-hiti-description"),
      region: t("partners.taiwan-vietnam"),
      src: HITIPic,
      url: "https://www.hitifa.com.tw/",
      location: "NorthernAsia",
    },
    {
      name: t("partners.hoshima-international-pte-ltd"),
      description: t("partners.hoshima-international-pte-ltd-description"),
      region: t("partners.vietnam"),
      src: HoshimaPic,
      url: "https://hoshima-int.com/products",
      location: "ASEAN",
    },
    {
      name: t("partners.hua-cen-automation-technology-co-ltd"),
      description: t(
        "partners.hua-cen-automation-technology-co-ltd-description"
      ),
      region: t("partners.taiwan"),
      src: HuaPic,
      url: "http://www.hua-cen.com/en/",
      location: "NorthernAsia",
    },
    {
      name: t("partners.ibcon-company-limited"),
      description: t("partners.ibcon-company-limited-description"),
      region: t("partners.thailand"),
      src: IBCONPic,
      url: "https://ibcon.com/home.php",
      location: "ASEAN",
    },
    {
      name: t("partners.industrial-revolution-co-ltd"),
      description: t("partners.industrial-revolution-co-ltd-description"),
      region: t("partners.thailand"),
      src: IndustrialRevolutionPic,
      url: "https://www.industrialrev.com/",
      location: "ASEAN",
    },
    {
      name: t("partners.information-technology-total-services-itts"),
      description: t(
        "partners.information-technology-total-services-itts-description"
      ),
      region: t("partners.taiwan"),
      src: ITTSPic,
      url: "https://www.itts.com.tw/zh-tw/",
      location: "NorthernAsia",
    },
    {
      name: t("partners.internet-initiative-japan-inc"),
      description: t("partners.internet-initiative-japan-inc-description"),
      region: t("partners.japan"),
      src: InternetInitiativePic,
      url: "https://www.iij.ad.jp/",
      location: "NorthernAsia",
    },
    {
      name: t("partners.multitrade-asiapac-sdn-bhd"),
      description: t("partners.multitrade-asiapac-sdn-bhd-description"),
      region: t("partners.malaysia"),
      src: MultitradePic,
      url: "https://www.multitradepac.com/",
      location: "ASEAN",
    },
    {
      name: t("partners.nippon-rad-inc"),
      description: t("partners.nippon-rad-inc-description"),
      region: t("partners.japan"),
      src: NipponRADPic,
      url: "https://www.nippon-rad.co.jp/",
      location: "NorthernAsia",
    },
    {
      name: t("partners.pf"),
      description: t("partners.pf-description"),
      region: t("partners.poland"),
      src: pfElectronicPic,
      url: "http://www.pf-electronic.pl/o-firmie",
      location: "EasternEurope",
    },
    {
      name: t("partners.servtech-co-ltd"),
      description: t("partners.servtech-description"),
      region: t("partners.taiwan"),
      src: Servtech,
      url: "http://www.servtech.com.tw/",
      location: "NorthernAsia",
    },
    {
      name: t("partners.smart-id-dynamics-s-a"),
      description: t("partners.smart-id-dynamics-s-a-description"),
      region: t("partners.romania"),
      src: SmartIDPic,
      url: "https://smartid.ro/",
      location: "EasternEurope",
    },
    {
      name: t("partners.streber-tech-co-ltd"),
      description: t("partners.streber-tech-description"),
      region: t("partners.taiwan"),
      src: StreberPic,
      url: "http://streber-tech.com/en-index.html",
      location: "NorthernAsia",
    },
  ];

  return partners;
};

export { usePartnersData };
