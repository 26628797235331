import React, { useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  BlackTitleTypography,
  GreyContentTypography,
} from "@/components/ColoredTypography";
import { RoleCard } from "@/components/home";

import { useRolesData } from "@/routes/Home/data/useRolesData";
import { DashedDividingLine } from "@/components/Common";

const HomeRoles = React.memo(() => {
  const { t } = useTranslation();
  const roles = useRolesData();

  const [activeSolutionTitle, setActiveSolutionTitle] = useState(
    roles[0].title
  );

  const handleEnterSolutionCard = (title: string) => {
    setActiveSolutionTitle(title);
  };
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        // maxWidth: { md: "768px", sm: "716px", xs: "328px" },
        boxSizing: "border-box",
        justifyContent: "center",
        alignItems: "center",
        gap: { md: "20px" },
        padding: { md: "40px" },
      }}
    >
      <Box
        display="flex"
        sx={{
          flexDirection: { xs: "column" },
          gap: "20px",
          padding: "0 0 20px",
          maxWidth: { md: "768px", sm: "716px", xs: "328px" },
        }}
      >
        <BlackTitleTypography
          sx={{
            fontSize: { md: "48px", sm: "40px", xs: "24px" },
            display: "flex",
            justifyContent: "center",
          }}
          textAlign={"center"}
        >
          {t("home.who-are-you-title")}
        </BlackTitleTypography>
        <GreyContentTypography
          sx={{
            fontSize: { md: "20px", sm: "16px", xs: "16px" },
          }}
        >
          {t("home.pick-and-choose-the-right-solution-for-your-role")}
        </GreyContentTypography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          boxSizing: "border-box",
          maxWidth: { md: 1180, sm: 716 },
          gap: { md: 0, sm: "20px" },
        }}
      >
        <Box
          display={"flex"}
          sx={{
            gap: { md: 0, sm: "20px", xs: "16px" },
            flexDirection: { sm: "row", xs: "column" },
          }}
        >
          {roles.map((solution) => (
            <Box
              boxSizing={"border-box"}
              onMouseEnter={() => handleEnterSolutionCard(solution.title)}
              sx={{
                height: { md: "240px", sm: "auto", xs: "auto" },
              }}
            >
              <RoleCard
                solution={solution}
                active={solution.title === activeSolutionTitle}
              />
            </Box>
          ))}
        </Box>
        <Box position={"relative"} width="100%" height="32px">
          <DashedDividingLine />
        </Box>
      </Box>
    </Box>
  );
});

export default HomeRoles;
