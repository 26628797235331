import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const WhiteScrollBtn: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
     <path fillRule="evenodd" clipRule="evenodd" d="M9.99997 17.0863L1.70693 8.79309L0.292969 10.2071L9.99997 19.9141L19.707 10.2071L18.2932 8.79309L9.99997 17.0863ZM9.99997 9.08577L1.70693 0.79254L0.292969 2.2065L9.99997 11.9135L19.707 2.2065L18.2932 0.79254L9.99997 9.08577Z" fill="white"/>
    </SvgIcon>
  )
}

export default WhiteScrollBtn

