import { Button, ButtonProps, styled, Box } from "@mui/material";
import { WhiteScrollIcon } from "@/components/Icon";
import { useTranslation } from "react-i18next";

interface IButtonProps {
  text?: string;
}

const ButtonBase = styled(Button)<ButtonProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  color: "#fff",
  "&:hover": {
    cursor: "pointer",
  },
  "&:active": {
    cursor: "pointer",
  },
  padding: 0,
}));

const WhiteScrollButton = (props: ButtonProps & IButtonProps) => {
  const { t } = useTranslation();
  return (
    <ButtonBase {...props}>
      <WhiteScrollIcon />
      {props.text || t('landing-page.common.scroll-text')}
    </ButtonBase>
  );
};

export default WhiteScrollButton;
