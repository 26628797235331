import { useTranslation } from "react-i18next";

const useInfoCardsData = () => {
  const { t } = useTranslation();
  const infoCards = [
    {
      step: "01",
      title: t("food.paper-waste-and-maintenance-issues"),
      lists: [
        {
          content: t("food.paper-waste-and-maintenance-issues-content"),
        },
      ],
    },
    {
      step: "02",
      title: t("food.data-leaks-and-outflow"),
      lists: [
        {
          content: t("food.data-leaks-and-outflow-content"),
        },
      ],
    },
    {
      step: "03",
      title: t("food.risk-of-sop-misplacement-while-shifting-line"),
      lists: [
        {
          content: t(
            "food.risk-of-sop-misplacement-while-shifting-line-content"
          ),
        },
      ],
    },
    {
      step: "04",
      title: t("food.paper-sops-are-inconvenient"),
      lists: [
        {
          content: t("food.paper-sops-are-inconvenient-content"),
        },
      ],
    },
  ];

  return infoCards;
};

export { useInfoCardsData };
