import { ISolutionData } from "./types/Solution";
import { Box, Button, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashedDividingLine from "../Common/DashedDividingLine";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface ISolutionProps {
  solution: ISolutionData;
  active: boolean;
}

const RoleCard: React.FC<ISolutionProps> = ({ solution, active }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const goToPage = (url: string | undefined) => {
    if (!url) return;
    navigate(url);
  };

  const notActiveStyle = {
    boxShadow: "none",
    border: "none",
    opacity: 0.4,
  };

  const activeStyle = {
    boxShadow: matchesDownMd ? "none" : "0px 2px 8px rgba(0, 140, 214, 0.5)",
    border: matchesDownMd ? "1px solid #C8C8C8" : "1px solid #008CD6",
    opacity: 1,
  };

  const boxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    maxWidth: { sm: "280px", xs: "328px" },
    minHeight: { md: "240px", sm: "auto" },
    borderRadius: "4px",
    flexShrink: 0,
    transition: "all 0.2s ease-in-out",
    boxSizing: "border-box",
    padding: { sm: "20px", xs: "16px" },
    gap: { sm: "20px", xs: "16px" },
    height: { sm: "auto", xs: "auto" },
  };

  return (
    <Box
      sx={Object.assign(
        boxStyle,
        active || matchesDownMd ? activeStyle : notActiveStyle
      )}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: { sm: "column", xs: "row-reverse" },
          alignItems: "flex-start",
          boxSizing: "border-box",
          gap: { sm: "4px", xs: "16px" },
        }}
      >
        {matchesDownSm ? (
          <Box
            sx={{
              width: "100%",
              maxWidth: { md: "66.67px", sm: "50px", xs: "45px" },
              height: { sm: "auto", xs: "45px" },
              backgroundImage: `url(${solution.src})`,
              backgroundSize: "cover",
              backgroundOrigin: "center center",
            }}
          ></Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: { md: "center", sm: "flex-start" },
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: { md: "66.67px", sm: "50px", xs: "88px" },
              }}
            >
              <img
                src={solution.src}
                style={{ width: "100%" }}
                alt={solution.title}
              />
            </Box>
          </Box>
        )}
        <Box
          sx={{
            boxSizing: "border-box",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            // padding: { sm: "20px", xs: 0 },
            gap: "4px",
            height: { md: "auto", sm: "120px", xs: "100%" },
            maxHeight: { xs: "auto" },
          }}
        >
          <Typography
            variant="body1"
            fontWeight="700"
            sx={{
              fontSize: { md: "16px", sm: "16px", xs: "14px" },
              lineHeight: { md: "32px", sm: "24px", xs: "20px" },
              textAlign: { md: "center", sm: "left" },
            }}
            textAlign={"center"}
          >
            {solution.title}
          </Typography>
          <Typography
            variant="body1"
            fontWeight="400"
            color="#89898A"
            sx={{
              fontSize: { md: "12px", sm: "12px", xs: "12px" },
              width: "100%",
              "-webkit-line-clamp": "2",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              "-webkit-box-orient": "vertical",
              overflow: "hidden",
              height: { sm: "auto", xs: "76px" },
              textAlign: { md: "center", sm: "left" },
            }}
          >
            {solution.description}
          </Typography>
          {matchesDownSm ? (
            <Box
              sx={{
                width: "100%",
                maxWidth: "295px",
                display: "flex",
                alignItems: "center",
                gap: "20px",
                boxSizing: "border-box",
                opacity: active || matchesDownSm ? 1 : 0,
              }}
            >
              <Button
                sx={{
                  color: "#008CD6",
                  fontWeight: 700,
                  fontSize: "12px",
                  textTransform: "none",
                  margin: { sm: "0", xs: "14px 0 0  0" },
                  padding: { sm: "6px 8px", xs: "0" },
                }}
                disabled={!solution.url}
                onClick={() => goToPage(solution.url)}
              >
                <Typography fontWeight="700" whiteSpace="nowrap">
                  {!solution.url
                    ? t("landing-page.headers.coming-soon")
                    : t("landing-page.common.learn-more")}
                </Typography>

                <ChevronRightIcon />
              </Button>
            </Box>
          ) : undefined}
        </Box>
      </Box>
      {!matchesDownSm ? (
        <Box
          sx={{
            width: "100%",
            maxWidth: "295px",
            display: "flex",
            alignItems: "center",

            boxSizing: "border-box",
            opacity: active || matchesDownMd ? 1 : 0,
          }}
        >
          <Button
            variant="contained"
            fullWidth
            sx={{
              boxSizing: "border-box",
              color: "white",
              fontWeight: 700,
              textTransform: "none",
              height: { md: "32px", sm: "40px" },
              padding: { md: "4px 8px", sm: "6px 8px" },
              borderRadius: "4px",
              background: "#008CD6",
            }}
            onClick={() => goToPage(solution.url)}
            disabled={!solution.url}
          >
            <Typography
              fontWeight="700"
              variant="body1"
              sx={{ width: "100%", fontSize: { md: "16px", sm: "14px" } }}
            >
              {!solution.url
                ? t("landing-page.headers.coming-soon")
                : t("landing-page.common.learn-more")}
            </Typography>
          </Button>
        </Box>
      ) : undefined}
    </Box>
  );
};
export default RoleCard;
