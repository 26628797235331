import { ReactNode, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  Drawer,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { useAuth, useHeader, useService } from "../hooks";
import CrossSvg from "../images/cross.svg";
import { isAxiosError } from "axios";

export interface LoginDrawerProps {
  open: boolean;
  onClose?: () => void;
}

export const LoginDrawer = (props: LoginDrawerProps) => {
  const theme = useTheme();
  const { lock, unlock } = useHeader();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const { refetch } = useAuth();
  const { t } = useTranslation();
  const { authService } = useService();
  const [username, setUsername] = useState(
    window.localStorage.getItem("username") || ""
  );
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [rememberMe, setRememberMe] = useState<boolean>(() =>
    window.localStorage.getItem("remember") ? true : false
  );
  const [site, setSite] = useState<string>(
    window.localStorage.getItem("site") || "eu"
  );
  const loginMutation = useMutation(
    ["login"],
    () => authService.login(username, password, site),
    {
      onSuccess: (data) => {
        if (data.data.error) {
          switch (data.status) {
            case 401:
              setErrorMessage(
                t("landing-page.login.invalid-username-or-password") ||
                  "Invalid username or password"
              );
              break;
            case 423:
              setErrorMessage(
                t("landing-page.login.account-blocked") ||
                  "Account blocked temporarily, please retry after 3 minutes."
              );
              break;
            default:
              setErrorMessage(data.data.error.message);
          }
        } else if (data.data.data) {
          if (rememberMe) {
            window.localStorage.setItem("remember", "true");
            window.localStorage.setItem("username", username);
          }
          refetch();
          props.onClose?.();
          // window.location.href = apiHubUrl;
        }
      },
      onError: (err) => {
        if (isAxiosError(err)) {
          setErrorMessage(err.message);
        } else if (err instanceof Error) {
          setErrorMessage(err.message);
        }
      },
    }
  );

  useEffect(() => {
    if (matches && props.open) {
      lock();
    } else {
      unlock();
    }
  }, [lock, matches, props.open, unlock]);

  return (
    <Drawer
      {...props}
      variant={matches ? "temporary" : "persistent"}
      anchor="right"
      sx={{
        "& .MuiDrawer-paper": {
          position: "fixed",
          width: { xs: "100%", md: 360 },
          // display: "block",
          backgroundColor: {
            xs: "#FAFAFA",
            md: "rgba(255, 255, 255, 0.8)",
          },
          backdropFilter: "blur(2px)",
          zIndex: 900,
          display: "flex",
          flexFlow: "column",
        },
        zIndex: 900,
      }}
      ModalProps={{
        keepMounted: false,
      }}
    >
      <div style={{ justifyContent: "center", display: "flex" }}>
        <div
          style={{
            maxWidth: 320,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            padding: 24,
            paddingTop: "32px",
            marginTop: "73px",
            // marginBottom: "73px",
            paddingBottom: "32px",
            // overflow: "auto"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{ fontSize: 24, fontWeight: "700", flex: 1 }}
            >
              {t("landing-page.common.sign-in", "Sign in")}
            </Typography>
            <IconButton onClick={() => props.onClose?.()}>
              <img src={CrossSvg} alt="close" />
            </IconButton>
          </div>
          <div style={{ paddingTop: 44 }}>
            <Typography variant="h6" sx={{ fontSize: 16, fontWeight: "700" }}>
              {t(
                "landing-page.common.login-to-wise-iotsuite-pivot",
                "Login to WISE-IoTSuite Real-time"
              )}
            </Typography>
          </div>
          <div
            style={{
              paddingTop: 16,
              paddingBottom: 16,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              sx={{ width: "100%", paddingBottom: 2 }}
              title="username"
              placeholder={t("landing-page.common.account") || "Account"}
              type="email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              inputProps={{
                style: {
                  backgroundColor: "white",
                  borderRadius: 4,
                  height: 16,
                  padding: 12,
                },
              }}
            />
            <TextField
              sx={{ width: "100%" }}
              title={t("landing-page.common.password") || "Password"}
              placeholder={t("landing-page.common.password") || "Password"}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              inputProps={{
                style: {
                  backgroundColor: "white",
                  borderRadius: 4,
                  height: 16,
                  padding: 12,
                },
              }}
            />
          </div>
          <Select
            sx={{ backgroundColor: "white" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            placeholder="Select a site ..."
            value={site}
            variant="outlined"
            SelectDisplayProps={{ style: { padding: 10 } }}
            MenuProps={{ disableScrollLock: true }}
            onChange={(e) => {
              setSite(e.target.value);
              window.localStorage.setItem("site", e.target.value);
            }}
          >
            {/* <MenuItem value="sa">{t("landing-page.login.site.sa")}</MenuItem> */}
            <MenuItem value="eu">{t("landing-page.login.site.eu")}</MenuItem>
            <MenuItem value="na">{t("landing-page.login.site.na")}</MenuItem>
            <MenuItem value="sg">{t("landing-page.login.site.sg")}</MenuItem>
          </Select>
          <div style={{}}>
            <Typography
              // variant=""
              color={theme.palette.error.light}
              sx={{ flex: 1 }}
            >
              {errorMessage}
            </Typography>
          </div>
          <div
            style={{ paddingTop: 24, paddingBottom: 48, userSelect: "none" }}
          >
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                checked={rememberMe}
                onChange={() => setRememberMe((v) => !v)}
                label={t("landing-page.common.remember-me") || "Remember me"}
              />
            </FormGroup>
          </div>
          <LoadingButton
            sx={{
              height: 40,
              width: "100%",
              bgcolor: "#008CD6",
              textTransform: "none",
              fontSize: 16,
              fontWeight: "700",
              // marginBottom: 24,
            }}
            variant="contained"
            loadingPosition="start"
            loading={loginMutation.isLoading}
            onClick={() => {
              if (!rememberMe) {
                window.localStorage.clear();
              }
              setErrorMessage("");
              loginMutation.mutate();
            }}
          >
            {t("landing-page.common.sign-in") || "Sign in"}
          </LoadingButton>
        </div>
      </div>
    </Drawer>
  );
};
