import React, { useEffect, useRef, useState } from "react";
import { Box, BoxProps, Button, styled, Typography } from "@mui/material";

import scrollPicture from "./images/purple_scroll_btn.png";
import { InfoCard } from "../../../components/industries/InfoCard";
import { StepCard } from "../../../components/industries/StepCard";
import { PictureCard } from "../../../components/industries/PictureCard";
import { useHeader, useImageUtils } from "../../../hooks";

import { useInfoCardsData } from "./data/infoCards";
import { useStepCardsData } from "./data/stepCards";
import { usePartnersData } from "./data/partners";
import { regions } from "./data/regions";
import { BluePurpleTypography } from "../../../components";
import { useTranslation } from "react-i18next";

import bannerAVIF from "./images/img_banner_electronic.png";
import bannerWebp from "./images/img_banner_electronic.png";
import banner from "./images/img_banner_electronic.png";
import scrollTCPicture from "../../../images/scroll_btn_purple_tc.png";

import i18n from "i18next";
import { DashedDividingLine, ResourcesBlock } from "@/components/Common";
import { useResourcesData } from "./data/useResourcesData";

const ScrollBtnBox = styled(Box)<BoxProps>({
  "@keyframes scrollBtnBox": {
    from: {
      transform: "translate(0)",
    },
    to: {
      transform: "translateY(20px)",
    },
  },
  animation: "scrollBtnBox 0.78s infinite ease-in-out alternate",
});

export const ElectronicsIndustry = () => {
  const { visible } = useHeader();
  const { selectImage } = useImageUtils();
  const [isSticky, setIsSticky] = useState(false);
  const ref = useRef<HTMLElement>(null);
  const [selectedRegion, setSelectedRegion] = useState(regions[0]);
  const { t } = useTranslation();
  const infoCards = useInfoCardsData();
  const stepCards = useStepCardsData();
  const partners = usePartnersData();
  const resources = useResourcesData();

  useEffect(() => {
    const cachedRef = ref.current,
      observer = new IntersectionObserver(
        ([e]) => setIsSticky(e.intersectionRatio < 1),
        {
          threshold: [1],
          rootMargin: "-73px 0px 100px 0px",
        }
      );

    if (cachedRef) {
      observer.observe(cachedRef);
    }

    // unmount
    return function () {
      if (cachedRef) {
        observer.unobserve(cachedRef);
      }
    };
  }, []);

  const handleNavigateView = (
    e: React.MouseEvent<HTMLButtonElement>,
    elementId: string
  ) => {
    const scrolledView = document.querySelector(`#${elementId}`);
    if (!scrolledView) return;
    const scrollDown = scrolledView.getBoundingClientRect().top > 0;
    window.scrollBy({
      top: scrollDown
        ? scrolledView.getBoundingClientRect().top - 80
        : scrolledView.getBoundingClientRect().top - 150,
      behavior: "smooth",
    });
  };

  enum CursorStyle {
    default = "default",
    pointer = "pointer",
    grab = "grab",
  }

  const firstBlockRef = useRef<HTMLElement>(null);

  const handleChangeCursor = (
    event: React.MouseEvent<HTMLDivElement>,
    cursorStyle: CursorStyle
  ) => {
    (event.target as HTMLElement).style.cursor = cursorStyle;
  };

  const handleNavigateFirstBlock = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    const firstRef = firstBlockRef!.current;
    if (!firstRef) return;
    firstRef.scrollIntoView({ behavior: "smooth" });
  };

  const handleChangeRegion = (region: string) => {
    setSelectedRegion(region);
  };

  // @ts-ignore
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(182, 240, 211, 0.1) 0%, rgba(182, 240, 211, 0) 100%), radial-gradient(50% 632.1% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), url(${selectImage(
            {
              avif: bannerAVIF,
              webp: bannerWebp,
              default: banner,
            }
          )})`,
          background:
            "linear-gradient(0deg, rgba(227, 201, 247, 0.1) 0%, rgba(227, 201, 247, 0) 100%), radial-gradient(50% 632.1% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), $FFFFFF",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            maxWidth: { md: 780, sm: 532, xs: 328 },
            marginTop: { xs: "20vh" },
          }}
        >
          <BluePurpleTypography
            variant="h2"
            textAlign="center"
            fontWeight="900"
            fontSize="96"
            sx={{
              fontSize: {
                md: "48px",
                sm: "40px",
                xs: "28px",
                wordBreak: "break-word",
                whiteSpace: "break-spaces",
              },
            }}
          >
            {t("electronics.electronics-and-electrical-machinery")}
          </BluePurpleTypography>
          <Typography
            textAlign="center"
            color={"GrayText"}
            sx={{ marginTop: "20px", fontSize: { md: "20px", xs: "16px" } }}
          >
            {t(
              "electronics.stable-production-status-and-occupational-health-and-safety"
            )}
          </Typography>
        </Box>
        <ScrollBtnBox sx={{ zIndex: 50, margin: "20px", padding: "8px 4px" }}>
          <div
            onMouseEnter={(event) =>
              handleChangeCursor(event, CursorStyle.pointer)
            }
            onClick={(event) => handleNavigateFirstBlock(event)}
          >
            <img
              src={i18n.language === "zh-TW" ? scrollTCPicture : scrollPicture}
              style={{ maxWidth: "64px" }}
              alt="scroll"
            />
          </div>
        </ScrollBtnBox>
      </div>
      <Box
        ref={ref}
        sx={{
          width: "100%",
          minHeight: "73px",
          position: "sticky",
          top: visible ? 72 : -1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 100,
          transition: "all 0.1s",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            overflowY: "hidden",
            "::-webkit-scrollbar": { display: "none" },
            "-ms-overflow-style": { display: "none" },
            "scrollbar-width": "none",
            display: "flex",
            justifyContent: {
              md: "center",
              sm: "flex-start",
              sx: "flex-start",
            },
            alignItems: "center",
            flexWrap: { md: "nowrap", sm: "nowrap", xs: "nowrap" },
            flexShrink: { md: 1, sm: 0, xs: 0 },
            gap: "20px",
            backgroundColor: "#fff",
            height: "100%",
            width: { md: "100%", sm: "100%", xs: "100%" },
            top: -10,
            padding: isSticky ? "0 20px" : "",
            maxWidth: { md: "1180px", sm: "716px", xs: "100%" },
            boxShadow: "0px 2px 8px rgba(50, 50, 51, 0.5)",
            transition: "all 0.5s",
            ...(isSticky && {
              width: "100%",
              maxWidth: "100%",
              margin: 0,
              boxShadow: "0px 2px 8px -2px rgba(50, 50, 51, 0.5)",
            }),
          }}
        >
          {stepCards.map((step, index) => (
            <Button
              key={step.stepName}
              sx={{
                display: "flex",
                flexDirection: { sm: "column", xs: "row" },
                alignItems: "center",
                justifyContent: "center",
                minHeight: "73px",
                height: "100%",
                width: "100%",
                maxWidth: { sm: "160px", xs: "120px" },
                minWidth: { md: 0, sm: "120px", xs: "120px" },
                textTransform: "none",
              }}
              onClick={(e) => handleNavigateView(e, step.elementId)}
            >
              <Box
                sx={{
                  minWidth: "20px",
                  minHeight: "20px",
                  width: "20px",
                  height: "20px",
                  backgroundColor: "#89898A",
                  borderRadius: "4px",
                  color: "#fff",
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: 700,
                  lineHeight: "20px",
                  marginRight: { sm: "0px", xs: "10px" },
                }}
              >
                {index + 1}
              </Box>
              <Typography
                sx={{ textTransform: "none" }}
                textAlign="center"
                color={"GrayText"}
                fontWeight={700}
              >
                {step.stepName}
              </Typography>
            </Button>
          ))}
        </Box>
      </Box>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        ref={firstBlockRef as React.RefObject<HTMLDivElement>}
      >
        <Box
          sx={{ maxWidth: { md: 780, sm: 532, xs: 328 }, marginTop: "80px" }}
        >
          <Typography
            textAlign="center"
            variant="h3"
            fontWeight="700"
            sx={{ fontSize: { md: "48px", xs: "28px" } }}
          >
            {t(
              "electronics.digital-transformation-of-electronics-and-electrics-industry"
            )}
          </Typography>
          <Typography
            textAlign="center"
            color={"GrayText"}
            sx={{ marginTop: "20px" }}
          >
            {t("electronics.advantech-provides-large-scale-ai-solutions")}
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: { lg: 1180, md: 768, sm: 716, xs: 328 },
            marginTop: { md: "96px", xs: "20px" },
          }}
        >
          <Typography
            textAlign="center"
            variant="h6"
            fontWeight="700"
            fontSize={"2rem"}
            lineHeight={"3.25rem"}
            display={"flex"}
            justifyContent={"center"}
            position={"relative"}
          >
            <span
              style={{
                backgroundColor: "#fff",
                width: "fit-content",
                padding: "0 20px",
                fontSize: "1.5rem",
              }}
            >
              {t("electronics.pain-points")}
            </span>
            <div
              style={{
                width: "100%",
                border: "1px dashed #E1E1E1",
                position: "absolute",
                top: "50%",
                zIndex: -1,
              }}
            ></div>
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: {
                lg: "center",
                md: "flex-start",
                sm: "flex-start",
                xs: "center",
              },
              alignItems: "flex-start",
              margin: "20px 0 0",
              gap: "20px",
              flexWrap: { md: "wrap", sm: "wrap", xs: "wrap" },
              maxWidth: { lg: 1180, md: 716, sm: 716, xs: 328 },
            }}
          >
            {infoCards.map((info) => (
              <InfoCard
                cardInfo={info}
                textColor="bluePurple"
                key={info.step}
              ></InfoCard>
            ))}
          </Box>

          <Typography
            textAlign="center"
            variant="h6"
            fontWeight="700"
            fontSize={"2rem"}
            lineHeight={"3.25rem"}
            display={"flex"}
            justifyContent={"center"}
            position={"relative"}
            padding={"40px 0"}
          >
            <div
              style={{
                width: "100%",
                border: "1px dashed #E1E1E1",
                position: "absolute",
                top: "50%",
                zIndex: -1,
              }}
            ></div>
          </Typography>
        </Box>
        <Box sx={{ maxWidth: "1280px", marginTop: { sm: "60px", xs: "32px" } }}>
          <Box
            sx={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "center",
              gap: "40px",
              flexWrap: "wrap",
            }}
          >
            {stepCards.map((stepCard) => (
              <StepCard
                key={stepCard.stepName}
                stepCardInfo={stepCard}
                stepCards={stepCards}
                stepTextColor="bluePurple"
              ></StepCard>
            ))}
          </Box>
        </Box>

        {/*DSFI*/}
        <div
          style={{
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "1280px",
          }}
        >
          <Box
            sx={{ maxWidth: { md: 780, sm: 532, xs: 328 }, marginTop: "40px" }}
          >
            <Typography
              textAlign="center"
              variant="h3"
              fontWeight="700"
              sx={{ fontSize: { md: "48px", sm: "40px", xs: "28px" } }}
            >
              {t("partners.dfsi")}
            </Typography>
            <Typography
              textAlign="center"
              color={"GrayText"}
              sx={{ marginTop: "20px" }}
            >
              {t(
                "partners.industrial-iot-iiot-involves-integrating-big-data-cloud-and-ai-applications-to-embrace-this-trend-advantech-is-collaborating-with-domain-focused-system-integrators-dfsis-to-develop-industrial-apps-i-apps"
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              width: "100%",
              maxWidth: { sm: "1180px", xs: "328px" },
              margin: "20px auto 0",
              paddingBottom: "90px",
              flexWrap: "wrap",
              justifyContent: {
                lg: "flex-start",
                md: "center",
                sm: "center",
                xs: "center",
              },
            }}
          >
            {partners.map((partner) => (
              <PictureCard
                key={partner.name}
                cardInfo={{
                  title: partner.name,
                  content: partner.description,
                  src: partner.src,
                  flexDirection: "row-reverse",
                  pictureAlign: "start",
                  region: partner.region,
                }}
              />
            ))}
          </Box>
          {resources.length > 0 && (
            <>
              <Box
                sx={{
                  width: "100%",
                  height: "40px",
                  zIndex: 1,
                  marginBottom: "40px",
                }}
              >
                <DashedDividingLine />
              </Box>
              <Typography
                textAlign="center"
                variant="h3"
                fontWeight="700"
                sx={{ fontSize: { md: "48px", sm: "40px", xs: "28px" } }}
              >
                {t("intelligent-automation.resources")}
              </Typography>

              <ResourcesBlock resources={resources} />
              <Box sx={{ paddingBottom: "40px" }}></Box>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
