import { isAxiosError } from "axios";
import { BaseService } from "./base";

export class AuthService extends BaseService {
  async login(username: string, password: string, site?: string) {
    try {
      const res = await this.axios.post<{
        data?: {
          token: string;
        };
        error?: {
          message: string;
        };
        errors?: [{ name: string; message: string }];
      }>(
        "/api/v1/auth/login",
        {
          username: username,
          password: password,
          site: site,
        },
        {
          validateStatus: (n) =>
            (n >= 200 && n < 300) || n === 401 || n === 402,
        }
      );
      return res;
    } catch (e) {
      throw e;
    }
  }

  async logout() {
    try {
      await this.axios.post<{}>("/api/v1/auth/logout");
      return;
    } catch (e) {
      if (isAxiosError(e)) {
        if (e.response && e.response.status === 401) {
          return;
        }
      }
      throw e;
    }
  }
}
