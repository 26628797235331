import { useTranslation } from "react-i18next";
import ecoPicture from "../images/img_partner_ECO.png";
import expetchPicture from "../images/img_partner_Expetech.png";
import streberPicture from "../images/img_partner_Streber-Tech.png";

const partners = [
  {
    name: "ECO Automação Industrial",
    description:
      "For 13 YEARS , ECO Automação Industrial has brought a differentiated vision to the market.\n" +
      "Supported by solid knowledge of what we sell, visible in the know-how and constant training of the technical team, we are accredited distributors of the most relevant multinationals in the market.\n" +
      "With a broad portfolio of solutions and quality brands, teamwork and partnership with our suppliers, allow us to have fast deliveries and negotiation flexibility. Today with branches in SP, PR and RS, the company preserves its values ​​and business culture, connecting companies to the future!",
    region: "Brazil",
    src: ecoPicture,
  },
  {
    name: "Expetech Co., Ltd",
    description:
      "Expetech Co., Ltd is a join ventured company, established by Advantech Co., Ltd., Industrial Technology Research Institute, and dadu-iif foundation. Expetech provides turn-key solution from Automation integration to intelligent factory, monitoring the production with data streaming integration and AIOT application.",
    region: "Taiwan, Vietnam, Thailand",
    src: expetchPicture,
  },
  {
    name: "Streber-Tech Co., LTD",
    description:
      "Streber-Tech was established at 2003 and provides end-to-end smart factory solutions from equipment and machine connection (OT layer), company or factory management (IT layer) to the war room / business predicting (DT layer). At the OT layer, combining Advantech's expertise in automatically connecting hardware equipment and sensors.",
    region: "Taiwan",
    src: streberPicture,
  },
];

const usePartnersData = () => {
  const { t } = useTranslation();
  const partners = [
    {
      name: t("partners.eco-industrial"),
      description: t("partners.eco-industrial-description"),
      region: t("partners.brazil"),
      src: ecoPicture,
    },
    {
      name: t("partners.expetech"),
      description: t("partners.expetech-description"),
      region: t("partners.taiwan-vietnam-thailand"),
      src: expetchPicture,
    },
    {
      name: t("partners.streber-tech"),
      description: t("partners.streber-tech-description"),
      region: t("partners.taiwan"),
      src: streberPicture,
    },
  ];
  return partners;
};

export { partners, usePartnersData };
