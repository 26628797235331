import React, { useRef, useState } from "react";
import { Box, BoxProps, styled, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";

import { useHeader, useImageUtils } from "../../hooks";

import bannerPicture from "./images/img_banner_home.png";
import bannerAvifPicture from "./images/img_banner_home.avif";
import bannerWebPPicture from "./images/img_banner_home.webp";

import scrollPicture from "../../images/scroll.png";
import scrollTCPicture from "../../images/scroll_btn_green_tc.png";
import DashedDividingLine from "../../components/Common/DashedDividingLine";

import {
  HomeIndustries,
  HomeResources,
  HomeSolutions,
  HomeFeatures,
  HomeMain,
  HomeRoles,
} from "@/components/home";

import goToTopPicture from "../../images/go-to-top.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { I18nBlock } from "@/components/Common";

enum CursorStyle {
  default = "default",
  pointer = "pointer",
}

const ScrollBtnBox = styled(Box)<BoxProps>({
  "@keyframes scrollBtnBox": {
    from: {
      transform: "translate(0)",
    },
    to: {
      transform: "translateY(20px)",
    },
  },
  animation: "scrollBtnBox 0.78s infinite ease-in-out alternate",
});

export const Home = () => {
  const { selectImage } = useImageUtils();

  const handleChangeCursor = (
    event: React.MouseEvent<HTMLDivElement>,
    cursorStyle: CursorStyle
  ) => {
    (event.target as HTMLElement).style.cursor = cursorStyle;
  };

  const firstBlockRef = useRef<HTMLElement>(null);
  const handleNavigateFirstBlock = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    const firstRef = firstBlockRef!.current;
    if (!firstRef) return;
    firstRef.scrollIntoView({ behavior: "smooth" });
  };

  const HomeRef = useRef<HTMLElement>(null);
  const handleHomeBlock = (event: React.MouseEvent<HTMLDivElement>) => {
    const HomeRefCurrent = HomeRef!.current;
    if (!HomeRefCurrent) return;
    HomeRefCurrent.scrollIntoView({ behavior: "smooth" });
  };

  const theme = useTheme();
  const matchesDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const { visible } = useHeader();

  const [mainReadMore, setMainReadMore] = useState(false);
  const handleClickReadText = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (!matchesDownMd) return;
    setMainReadMore((oldMore) => {
      return !oldMore;
    });
  };

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        ref={HomeRef}
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: {
            md: "112px 40px 40px",
            sm: "96px 20px 40px",
            xs: "80px 16px 32px",
          },
          height: mainReadMore ? "auto" : "100vh",
          backgroundSize: "cover",
          backgroundPosition: "center",
          gap: { sm: "20px", xs: "16px" },
          width: "100%",
          backgroundImage: `url(${selectImage({
            avif: bannerAvifPicture,
            webp: bannerWebPPicture,
            default: bannerPicture,
          })})`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { md: "flex-start", xs: "center" },
            padding: 0,
            width: "100%",
            height: "100%",
            maxHeight: { md: "404px", sm: "auto", xs: "auto" },
            margin: { md: "70px 0 0", sm: "20px", xs: 0 },
            maxWidth: { md: 1180, sm: 532, xs: 328 },
            flexWrap: { md: "nowrap", xs: "wrap" },
            gap: { md: "20px", sm: "20px", xs: "16px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: "20px",
              width: "100%",
              maxWidth: "580px",
            }}
          >
            <Typography
              fontWeight="700"
              color="#323233"
              sx={{
                fontSize: { md: "48px", sm: "40px", xs: "28px" },
                textAlign: { md: "left", xs: "center" },
                lineHeight: { md: "56px", sm: "48px", xs: "36px" },
              }}
            >
              {t("home.smart-green-manufacturing")}
            </Typography>
            <Typography
              fontWeight="400"
              color="#89898A"
              sx={{
                fontSize: { md: "20px", sm: "16px", xs: "16px" },
                lineHeight: { md: "32px", sm: "24px", xs: "24px" },
                textAlign: { md: "left", sm: "center", xs: "center" },
                "-webkit-line-clamp": {
                  md: "auto",
                  sm: !mainReadMore ? "3" : "auto",
                  xs: !mainReadMore ? "6" : "auto",
                },
                textOverflow: "ellipsis",
                display: { md: "block", xs: "-webkit-box" },
                "-webkit-box-orient": "vertical",
                overflow: "hidden",
              }}
              onClick={(e) => handleClickReadText(e)}
            >
              {t("home.banner-description")}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              maxWidth: { md: "580px", sm: "348px", xs: "328px" },
              height: { md: "325.25px", sm: "196px", xs: "184.5px" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              <iframe
                allowTransparency
                title="Wistia video player"
                allowFullScreen
                frameBorder="0"
                scrolling="no"
                className="wistia_embed"
                name="wistia_embed"
                src={t("home.banner-video") as string}
                width="100%"
                height="100%"
              ></iframe>

              {/* {i18n.language === "en-US" ? (
                <iframe
                  allowTransparency
                  title="Wistia video player"
                  allowFullScreen
                  frameBorder="0"
                  scrolling="no"
                  className="wistia_embed"
                  name="wistia_embed"
                  src="https://fast.wistia.net/embed/iframe/fpegzxxuh9"
                  width="100%"
                  height="100%"
                ></iframe>
              ) : (
                <iframe
                  allowTransparency
                  title="Wistia video player"
                  allowFullScreen
                  frameBorder="0"
                  scrolling="no"
                  className="wistia_embed"
                  name="wistia_embed"
                  src="https://fast.wistia.net/embed/iframe/apeym4swpt"
                  width="100%"
                  height="100%"
                ></iframe>
              )} */}
            </Box>
          </Box>
        </Box>
        <ScrollBtnBox
          sx={{
            zIndex: 50,
            margin: { md: "20px", sm: "0", xs: "0" },
            padding: "8px 4px",
          }}
        >
          <div
            onMouseEnter={(event) =>
              handleChangeCursor(event, CursorStyle.pointer)
            }
            onClick={(event) => handleNavigateFirstBlock(event)}
          >
            <img
              src={i18n.language === "zh-TW" ? scrollTCPicture : scrollPicture}
              style={{ maxWidth: "64px" }}
              alt={"scroll button"}
            />
          </div>
        </ScrollBtnBox>
      </Box>
      <Box
        ref={firstBlockRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: { md: "40px", sm: "40px 20px", xs: "32px 16px" },
          gap: { md: "20px", sm: "20px", xs: "16px" },
        }}
      >
        <HomeMain />
        <HomeFeatures />
        <HomeSolutions />
        <Box
          sx={{
            width: "100%",
            maxWidth: { md: "1180px", sm: "716px", xs: "328px" },
            display: { md: "flex", sm: "none" },
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            height: "32px",
          }}
        >
          <DashedDividingLine />
        </Box>
      </Box>
      <HomeIndustries />

      <I18nBlock langs={["en-US", "zh-TW"]}>
        <HomeRoles />
      </I18nBlock>

      <HomeResources />

      {matchesDownMd && !visible ? (
        <Box
          sx={{
            width: "100%",
            maxWidth: "56px",
            position: "fixed",
            right: "20px",
            bottom: "20px",
          }}
          onMouseEnter={(event) =>
            handleChangeCursor(event, CursorStyle.pointer)
          }
          onClick={(event) => {
            handleHomeBlock(event);
          }}
        >
          <img
            src={goToTopPicture}
            style={{ width: "100%" }}
            alt={"go to the top"}
          />
        </Box>
      ) : undefined}
    </Box>
  );
};
