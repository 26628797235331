import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

interface FeatInfo {
  src: string;
  title: string;
  content: string;
  flexDirection?: string;
  pictureAlign?: string;
  region?: string | undefined | null;
}

interface InfoCardProps {
  cardInfo: FeatInfo;
}

export const PictureCard = ({ cardInfo }: InfoCardProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        maxWidth: "380px",
        gap: "20px",
        alignItems: cardInfo.pictureAlign || "flex-end",
        flexDirection: cardInfo.flexDirection || "row",
        margin: "20px 0 0",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        <Typography variant={"h6"} sx={{ fontSize: "16px" }}>
          {cardInfo.title || "Item name"}
        </Typography>
        <Typography
          textAlign="left"
          color={"GrayText"}
          sx={{
            fontSize: "12px",
            wordBreak: "break-word",
            maxWidth: "280px",
            maxHeight: "88px",
            // overflowX: "hidden",
            padding: "0 10px 0 0",
            color: " #89898A",
            "-webkit-line-clamp": "6",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
          }}
        >
          {cardInfo.content ||
            " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n" +
              "        magna aliqua."}
        </Typography>
        {cardInfo.region !== undefined ? (
          <Typography
            textAlign="left"
            color={"GrayText"}
            sx={{ fontWeight: 700, fontStyle: "italic", fontSize: "12px" }}
          >
            {t("landing-page.common.service-region")}: {cardInfo.region}
          </Typography>
        ) : undefined}
      </Box>
      <Box
        sx={{
          position: "relative",
          maxWidth: "80px",
          maxHeight: "80px",
          minWidth: "80px",
          borderRadius: "7px",
          overflow: "hidden",
        }}
      >
        <img
          src={cardInfo.src}
          alt={"product card"}
          style={{
            width: "100%",
            maxWidth: "80px",
            maxHeight: "80px",
            minWidth: "80px",
          }}
        />
      </Box>
    </Box>
  );
};
