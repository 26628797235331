import { useTranslation } from "react-i18next";
import churPicture from "../../food-and-beverage/images/img_partner_Chur.png";

import hitiPicture from "../../../partner/finding-a-partner/images/img_partner_HITI.png"
import huaPicture from "../../../partner/finding-a-partner/images/img_partner_Hua Cen.png"

const usePartnersData = () => {
  const { t } = useTranslation();
  const partners = [
    {
      name: t("partners.chur-co-ltd"),
      description: t("partners.chur-co-ltd-description"),
      region: t("partners.taiwan"),
      src: churPicture,
    },
    {
      name: t("partners.hiti-industrial-automation"),
      description: t(
        "partners.hiti-uphold-the-spirit-of-integrity-service-open-up-more-diversified-business-tentacles-is-committed-to-higher-quality-professional-technology-to-help-customers-improve-productivity-competitiveness-and-added-value-of-the-full-range-of-integrated-services"
      ),
      region: t("partners.taiwan-vietnam-thailand"),
      src:  hitiPicture,
    },
    {
      name: t("partners.hua-cen-automation-technology-co-ltd"),
      description: t(
        "partners.hua-cen-automation-technology-co-ltd-description"
      ),
      region: t("partners.taiwan"),
      src: huaPicture,
    },
  ];
  return partners;
};

export { usePartnersData };
