import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import i18n from "i18next";

import ecoPicture from "../images/unknownItem.png";
import servtechPicture from "../images/unknownItem.png";
import churPicture from "../images/unknownItem.png";
import pic01 from "../images/01.png";
import picTC01 from "../images/01tc.png";
import pic02 from "../images/02.png";
import picTC02 from "../images/02tc.png";
import pic03 from "../images/03.png";
import picTC03 from "../images/03tc.png";

const useResourcesData = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesMdToSm = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const isTC = i18n.language === "zh-TW";
  const resources = [
    {
      page: 1,
      infos: [
        {
          name: "Petrochemical",
          description: "The Revelation of Hsu Fu Chi's Digital Transformation",
          category: t("solutions.ai.case-study"),
          date: "2022/12/28",
          src: !isTC ? pic01 : picTC01,
          url: "https://www.advantech.com/en/resources/case-study/the-revelation-of-hsu-fu-chis-digital-transformation",
          isOuterLink: true,
        },
        {
          name: "CNC",
          description:
            "Advantech's iFactory Solution Revolutionizes the Footwear Industry",
          category: t("solutions.ai.case-study"),
          date: "2022/12/28",
          src: !isTC ? pic02 : picTC02,
          url: "https://www.advantech.com/en/resources/case-study/ifactory-solution-revolutionizes-the-footwear-industry",
          isOuterLink: true,
        },
        {
          name: "Pannel",
          description:
            "Centralized Management of Production Line in Semiconductor Manufacturing",
          category: t("solutions.ai.case-study"),
          date: "2022/09/08",
          src: !isTC ? pic03 : picTC03,
          url: "https://www.advantech.com/en/resources/case-study/centralized-management-of-production-line-in-semiconductor-manufacturing",
          isOuterLink: true,
        },
      ],
    },
  ];

  const mdToSmResourcesData = [
    {
      page: 1,
      infos: [
        {
          name: "Petrochemical",
          description: "The Revelation of Hsu Fu Chi's Digital Transformation",
          category: t("solutions.ai.case-study"),
          date: "2022/12/28",
          src: !isTC ? pic01 : picTC01,
          url: "https://www.advantech.com/en/resources/case-study/the-revelation-of-hsu-fu-chis-digital-transformation",
          isOuterLink: true,
        },
        {
          name: "CNC",
          description:
            "Advantech's iFactory Solution Revolutionizes the Footwear Industry",
          category: t("solutions.ai.case-study"),
          date: "2022/12/28",
          src: !isTC ? pic02 : picTC02,
          url: "https://www.advantech.com/en/resources/case-study/ifactory-solution-revolutionizes-the-footwear-industry",
          isOuterLink: true,
        },
      ],
    },
    {
      page: 2,
      infos: [
        {
          name: "Pannel",
          description:
            "Centralized Management of Production Line in Semiconductor Manufacturing",
          category: t("solutions.ai.case-study"),
          date: "2022/09/08",
          src: !isTC ? pic03 : picTC03,
          url: "https://www.advantech.com/en/resources/case-study/centralized-management-of-production-line-in-semiconductor-manufacturing",
          isOuterLink: true,
        },
      ],
    },
  ];

  const downSmResourcesData = [
    {
      page: 1,
      infos: [
        {
          name: "Petrochemical",
          description: "The Revelation of Hsu Fu Chi's Digital Transformation",
          category: t("solutions.ai.case-study"),
          date: "2022/12/28",
          src: !isTC ? pic01 : picTC01,
          url: "https://www.advantech.com/en/resources/case-study/the-revelation-of-hsu-fu-chis-digital-transformation",
          isOuterLink: true,
        },
        {
          name: "CNC",
          description:
            "Advantech's iFactory Solution Revolutionizes the Footwear Industry",
          category: t("solutions.ai.case-study"),
          date: "2022/12/28",
          src: !isTC ? pic02 : picTC02,
          url: "https://www.advantech.com/en/resources/case-study/ifactory-solution-revolutionizes-the-footwear-industry",
          isOuterLink: true,
        },
        {
          name: "Pannel",
          description:
            "Centralized Management of Production Line in Semiconductor Manufacturing",
          category: t("solutions.ai.case-study"),
          date: "2022/09/08",
          src: !isTC ? pic03 : picTC03,
          url: "https://www.advantech.com/en/resources/case-study/centralized-management-of-production-line-in-semiconductor-manufacturing",
          isOuterLink: true,
        },
      ],
    },
  ];

  const resourcesTC = [
    {
      page: 1,
      infos: [
        {
          name: "Petrochemical",
          description: "化身製鞋機數位轉型強力後盾 智慧設備運維管理成新關鍵",
          category: t("solutions.tpm.news"),
          date: "2021/2/18",
          src: picTC01,
          url: "https://www.advantech.com/zh-tw/resources/news/%E5%8C%96%E8%BA%AB%E8%A3%BD%E9%9E%8B%E6%A9%9F%E6%95%B8%E4%BD%8D%E8%BD%89%E5%9E%8B%E5%BC%B7%E5%8A%9B%E5%BE%8C%E7%9B%BE-%E6%99%BA%E6%85%A7%E8%A8%AD%E5%82%99%E9%81%8B%E7%B6%AD%E7%AE%A1%E7%90%86%E6%88%90%E6%96%B0%E9%97%9C%E9%8D%B5",
          isOuterLink: true,
        },
        {
          name: "CNC",
          description:
            "宏遠興業以研華WISE-PaaS工業物聯網平台，加速邁向智慧紡織製造",
          category: t("solutions.ai.case-study"),
          date: "2021/9/22",
          src: picTC02,
          url: "https://www.advantech.com/zh-tw/resources/case-study/%E5%AE%8F%E9%81%A0%E8%88%88%E6%A5%AD%E4%BB%A5%E7%A0%94%E8%8F%AFwise-paas%E5%B7%A5%E6%A5%AD%E7%89%A9%E8%81%AF%E7%B6%B2%E5%B9%B3%E5%8F%B0%EF%BC%8C%E5%8A%A0%E9%80%9F%E9%82%81%E5%90%91%E6%99%BA%E6%85%A7%E7%B4%A1%E7%B9%94%E8%A3%BD%E9%80%A0",
          isOuterLink: true,
        },
        {
          name: "Pannel",
          description:
            "研華 WISE-iFactory TPM 全面維護管理，助電子製造業數位精實生產",
          category: t("solutions.ai.case-study"),
          date: "2023/1/9",
          src: picTC03,
          url: "https://www.advantech.com/zh-tw/resources/case-study/%E7%A0%94%E8%8F%AF-wise-ifactory-tpm-%E5%8A%A9%E9%9B%BB%E5%AD%90%E8%A3%BD%E9%80%A0%E6%A5%AD%E6%95%B8%E4%BD%8D%E7%B2%BE%E5%AF%A6%E7%94%9F%E7%94%A2",
          isOuterLink: true,
        },
      ],
    },
  ];

  const mdToSmResourcesDataTC = [
    {
      page: 1,
      infos: [
        {
          name: "Petrochemical",
          description: "化身製鞋機數位轉型強力後盾 智慧設備運維管理成新關鍵",
          category: t("solutions.tpm.news"),
          date: "2021/2/18",
          src: picTC01,
          url: "https://www.advantech.com/zh-tw/resources/news/%E5%8C%96%E8%BA%AB%E8%A3%BD%E9%9E%8B%E6%A9%9F%E6%95%B8%E4%BD%8D%E8%BD%89%E5%9E%8B%E5%BC%B7%E5%8A%9B%E5%BE%8C%E7%9B%BE-%E6%99%BA%E6%85%A7%E8%A8%AD%E5%82%99%E9%81%8B%E7%B6%AD%E7%AE%A1%E7%90%86%E6%88%90%E6%96%B0%E9%97%9C%E9%8D%B5",
          isOuterLink: true,
        },
        {
          name: "CNC",
          description:
            "宏遠興業以研華WISE-PaaS工業物聯網平台，加速邁向智慧紡織製造",
          category: t("solutions.ai.case-study"),
          date: "2021/9/22",
          src: picTC02,
          url: "https://www.advantech.com/zh-tw/resources/case-study/%E5%AE%8F%E9%81%A0%E8%88%88%E6%A5%AD%E4%BB%A5%E7%A0%94%E8%8F%AFwise-paas%E5%B7%A5%E6%A5%AD%E7%89%A9%E8%81%AF%E7%B6%B2%E5%B9%B3%E5%8F%B0%EF%BC%8C%E5%8A%A0%E9%80%9F%E9%82%81%E5%90%91%E6%99%BA%E6%85%A7%E7%B4%A1%E7%B9%94%E8%A3%BD%E9%80%A0",
          isOuterLink: true,
        },
      ],
    },
    {
      page: 2,
      infos: [
        {
          name: "Pannel",
          description:
            "研華 WISE-iFactory TPM 全面維護管理，助電子製造業數位精實生產",
          category: t("solutions.ai.case-study"),
          date: "2023/1/9",
          src: picTC03,
          url: "https://www.advantech.com/zh-tw/resources/case-study/%E7%A0%94%E8%8F%AF-wise-ifactory-tpm-%E5%8A%A9%E9%9B%BB%E5%AD%90%E8%A3%BD%E9%80%A0%E6%A5%AD%E6%95%B8%E4%BD%8D%E7%B2%BE%E5%AF%A6%E7%94%9F%E7%94%A2",
          isOuterLink: true,
        },
      ],
    },
  ];

  const downSmResourcesDataTC = [
    {
      page: 1,
      infos: [
        {
          name: "Petrochemical",
          description: "化身製鞋機數位轉型強力後盾 智慧設備運維管理成新關鍵",
          category: t("solutions.tpm.news"),
          date: "2021/2/18",
          src: picTC01,
          url: "https://www.advantech.com/zh-tw/resources/news/%E5%8C%96%E8%BA%AB%E8%A3%BD%E9%9E%8B%E6%A9%9F%E6%95%B8%E4%BD%8D%E8%BD%89%E5%9E%8B%E5%BC%B7%E5%8A%9B%E5%BE%8C%E7%9B%BE-%E6%99%BA%E6%85%A7%E8%A8%AD%E5%82%99%E9%81%8B%E7%B6%AD%E7%AE%A1%E7%90%86%E6%88%90%E6%96%B0%E9%97%9C%E9%8D%B5",
          isOuterLink: true,
        },
        {
          name: "CNC",
          description:
            "宏遠興業以研華WISE-PaaS工業物聯網平台，加速邁向智慧紡織製造",
          category: t("solutions.ai.case-study"),
          date: "2021/9/22",
          src: picTC02,
          url: "https://www.advantech.com/zh-tw/resources/case-study/%E5%AE%8F%E9%81%A0%E8%88%88%E6%A5%AD%E4%BB%A5%E7%A0%94%E8%8F%AFwise-paas%E5%B7%A5%E6%A5%AD%E7%89%A9%E8%81%AF%E7%B6%B2%E5%B9%B3%E5%8F%B0%EF%BC%8C%E5%8A%A0%E9%80%9F%E9%82%81%E5%90%91%E6%99%BA%E6%85%A7%E7%B4%A1%E7%B9%94%E8%A3%BD%E9%80%A0",
          isOuterLink: true,
        },
        {
          name: "Pannel",
          description:
            "研華 WISE-iFactory TPM 全面維護管理，助電子製造業數位精實生產",
          category: t("solutions.ai.case-study"),
          date: "2023/1/9",
          src: picTC03,
          url: "https://www.advantech.com/zh-tw/resources/case-study/%E7%A0%94%E8%8F%AF-wise-ifactory-tpm-%E5%8A%A9%E9%9B%BB%E5%AD%90%E8%A3%BD%E9%80%A0%E6%A5%AD%E6%95%B8%E4%BD%8D%E7%B2%BE%E5%AF%A6%E7%94%9F%E7%94%A2",
          isOuterLink: true,
        },
      ],
    },
  ];

  if (isTC) {
    return matchesDownSm
      ? downSmResourcesDataTC
      : matchesMdToSm
      ? mdToSmResourcesDataTC
      : resourcesTC;
  } else {
    return matchesDownSm
      ? downSmResourcesData
      : matchesMdToSm
      ? mdToSmResourcesData
      : resources;
  }
};

export { useResourcesData };
