import React, {
  FC,
  memo,
  ReactNode,
  useEffect,
  useRef,
  createRef,
  useState,
} from "react";
import {
  Box,
  BoxProps,
  Button,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import scrollPicture from "./images/scroll_btn.png";
import scrollTCPicture from "./images/scrollbtn_TC_img.png";
import { ColumnInfoCard } from "../../../components/industries/ColumnInfoCard";
import { useArchitecturesData } from "./data/architectures";
import { useHeader, useImageUtils } from "../../../hooks";

import bannerAI from "./images/img_banner_IAI.jpg";
import bannerAIAVIF from "./images/img_banner_IAI.avif";
import bannerAIWebp from "./images/img_banner_IAI.webp";

import { useInfoCards } from "./data/infoCards";
import { useStepCardsData } from "./data/stepCards";
import { SolutionCard } from "../../../components";
import { useResourcesData } from "./data/resources";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import aiVideo from "./images/industries_ai_video.png";
import AnchorBar from "../../../components/Common/AnchorBar";
import { Trans, useTranslation } from "react-i18next";
import DashedDividingLine from "../../../components/Common/DashedDividingLine";
import i18n from "i18next";
import { useNavigate } from "react-router-dom";
const ScrollBtnBox = styled(Box)<BoxProps>({
  "@keyframes scrollBtnBox": {
    from: {
      transform: "translate(0)",
    },
    to: {
      transform: "translateY(20px)",
    },
  },
  animation: "scrollBtnBox 0.78s infinite ease-in-out alternate",
});

interface IProps {
  children?: ReactNode;
}

const SolutionAI: FC<IProps> = memo(() => {
  const { visible } = useHeader();
  const { selectImage } = useImageUtils();
  const { t } = useTranslation();
  const [isSticky, setIsSticky] = useState(false);
  const ref = createRef<HTMLElement>();
  const [activePage, setActivePage] = useState(0);
  const stepCards = useStepCardsData();
  const infoCards = useInfoCards();
  const architectures = useArchitecturesData();
  const resources = useResourcesData();

  const [transformIndex, setTransformIndex] = useState(0);

  const handleClickNextPage = () => {
    setActivePage((activePage) => activePage + 1);
    setTransformIndex((transformIndex) => transformIndex + 1);
  };
  const handleClickPreviousPage = () => {
    setActivePage((activePage) => activePage - 1);
    setTransformIndex((transformIndex) => transformIndex - 1);
  };

  const theme = useTheme();
  const queryUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const queryDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [readMore, setReadMore] = useState(queryUpMd ? false : false);

  useEffect(() => {
    const cachedRef = ref.current,
      observer = new IntersectionObserver(
        ([e]) => setIsSticky(e.intersectionRatio < 1),
        {
          threshold: [1],
          rootMargin: "-73px 0px 100px 0px",
        }
      );

    if (cachedRef) {
      observer.observe(cachedRef);
    }

    return function () {
      if (cachedRef) {
        observer.unobserve(cachedRef);
      }
    };
  }, []);

  enum CursorStyle {
    default = "default",
    pointer = "pointer",
  }

  const firstBlockRef = useRef<HTMLElement>(null);

  const handleChangeCursor = (
    event: React.MouseEvent<HTMLDivElement>,
    cursorStyle: CursorStyle
  ) => {
    (event.target as HTMLElement).style.cursor = cursorStyle;
  };

  const handleNavigateFirstBlock = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    const firstRef = firstBlockRef!.current;
    if (!firstRef) return;
    firstRef.scrollIntoView({ behavior: "smooth" });
  };

  const handleClickReadText = (e: React.MouseEvent<HTMLButtonElement>) => {
    setReadMore((oldMore) => {
      return !oldMore;
    });
  };

  const sliderContainerRef = useRef<HTMLElement>();

  const [sliderContainerWidth, setSliderContainerWidth] = useState(
    sliderContainerRef.current?.offsetWidth
  );
  useEffect(() => {
    if (sliderContainerRef.current) {
      setSliderContainerWidth(sliderContainerRef.current.offsetWidth);
    }
  }, [sliderContainerRef.current?.offsetWidth]);

  const navigate = useNavigate();
  const goToPage = (url: string | undefined, isOuterLink: boolean) => {
    if (!url) return;
    if (isOuterLink) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          backgroundImage: `radial-gradient(50% 50% at 50% 50%, #000000 0%, rgba(0, 0, 0, 0) 100%), url(${selectImage(
            {
              avif: bannerAIAVIF,
              webp: bannerAIWebp,
              default: bannerAI,
            }
          )})`,

          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: "84px 40px 40px",
        }}
      >
        <Box
          sx={{
            maxWidth: { md: 780, sm: 532, xs: 328 },
            marginTop: { xs: "20vh" },
          }}
        >
          <Typography
            color="white"
            variant="h2"
            textAlign="center"
            fontWeight="900"
            fontSize="96"
            sx={{ fontSize: { md: "48px", xs: "22px" } }}
          >
            {t("solutions.ai.banner-title")}
          </Typography>
          <Typography
            textAlign="center"
            color="white"
            sx={{ marginTop: "20px", fontSize: { md: "20px", xs: "16px" } }}
          >
            {t("solutions.ai.banner-description")}
          </Typography>
        </Box>
        <ScrollBtnBox sx={{ zIndex: 50, margin: "20px", padding: "8px 4px" }}>
          <div
            onMouseEnter={(event) =>
              handleChangeCursor(event, CursorStyle.pointer)
            }
            onClick={(event) => handleNavigateFirstBlock(event)}
          >
            <img
              src={i18n.language === "zh-TW" ?  scrollTCPicture : scrollPicture }
              style={{ maxWidth: "64px" }}
              alt="scroll button"
            />
          </div>
        </ScrollBtnBox>
      </Box>
      <AnchorBar
        visible={visible}
        isSticky={isSticky}
        stepCards={stepCards}
        ref={ref}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        ref={firstBlockRef as React.RefObject<HTMLDivElement>}
      >
        <Box
          id="Overview"
          sx={{
            maxWidth: { md: 780, sm: 532, xs: 328 },
            marginTop: "80px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0 0 20x",
            gap: "20px",
          }}
        >
          <Typography
            textAlign="center"
            variant="h3"
            fontWeight="700"
            sx={{ fontSize: { md: "48px", xs: "28px" } }}
          >
            {t("solutions.ai.main-title")}
          </Typography>
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography
              variant="body1"
              fontWeight="400"
              color="#89898A"
              textAlign="center"
              sx={{
                fontSize: { md: "20px", sm: "16px", xs: "16px" },
                "-webkit-line-clamp": {
                  md: !readMore ? "10" : "auto",
                  sm: !readMore ? "4" : "auto",
                  xs: !readMore ? "6" : "auto",
                },
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-box-orient": "vertical",
                overflow: "hidden",
                margin: "20px 0 0",
              }}
            >
              <Trans
                i18nKey={"solutions.ai.main-description"}
                components={{ br: <br /> }}
              />
            </Typography>

            <Button
              variant="text"
              sx={{
                textTransform: "none",
                textAlign: "center",
                width: "fix-content",
                whiteSpace: "nowrap",
                color: "#008CD6",
              }}
              onClick={(e) => handleClickReadText(e)}
            >
              {!readMore
                ? t("landing-page.common.read-more")
                : t("landing-page.common.read-less")}
            </Button>
          </Box>
          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              height: { md: "438.75px", sm: "299.83px", xs: "184.5px" },
            }}
          >
            <iframe
              allowTransparency={true}
              title="Wistia video player"
              allowFullScreen
              frameBorder="0"
              scrolling="no"
              className="wistia_embed"
              name="wistia_embed"
              src="https://fast.wistia.net/embed/iframe/20wdm862vh"
              width="100%"
              height="100%"
            ></iframe>
          </Box>
        </Box>
        <Box
          sx={{
            maxWidth: { lg: 1180, md: 768, sm: 716, xs: 328 },
            marginTop: { md: "96px", xs: "20px" },
          }}
        >
          <Typography
            textAlign="center"
            variant="h6"
            fontWeight="700"
            fontSize={"2rem"}
            lineHeight={"3.25rem"}
            display={"flex"}
            justifyContent={"center"}
            position={"relative"}
          >
            <span
              style={{
                backgroundColor: "#fff",
                width: "fit-content",
                padding: "0 20px",
                fontSize: "24px",
              }}
            >
              {t("solutions.ai.features")}
            </span>
            <div
              style={{
                width: "100%",
                border: "1px dashed #E1E1E1",
                position: "absolute",
                top: "50%",
                zIndex: -1,
              }}
            ></div>
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: {
                lg: "center",
                md: "flex-start",
                sm: "flex-start",
                xs: "center",
              },
              alignItems: "flex-start",
              margin: "20px 0 0",
              gap: "20px",
              flexWrap: { md: "wrap", sm: "wrap", xs: "wrap" },
              maxWidth: { lg: 1180, md: 716, sm: 716, xs: 328 },
            }}
          >
            {infoCards.map((info) => (
              <ColumnInfoCard
                cardInfo={info}
                listStyle={"none"}
                textColor={"green"}
              ></ColumnInfoCard>
            ))}
          </Box>

          <Typography
            textAlign="center"
            variant="h6"
            fontWeight="700"
            fontSize={"2rem"}
            lineHeight={"3.25rem"}
            display={"flex"}
            justifyContent={"center"}
            position={"relative"}
            padding={"40px 0"}
          >
            <div
              style={{
                width: "100%",
                border: "1px dashed #E1E1E1",
                position: "absolute",
                top: "50%",
                zIndex: -1,
              }}
            ></div>
          </Typography>
        </Box>

        <Box
          sx={{
            id: "architecture-block",
            width: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: { md: "75px 48px", sm: "40px 20px", xs: "32px 16px" },
            gap: { sm: "20px", xs: "16px" },
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{ maxWidth: { md: 780, sm: 532, xs: 328 }, marginTop: "80px" }}
            id="Architecture"
          >
            <Typography
              textAlign="center"
              variant="h3"
              fontWeight="700"
              sx={{ fontSize: { md: "48px", sm: "40px", xs: "28px" } }}
            >
              {t("solutions.ai.architecture")}
            </Typography>
          </Box>

          {/* <Box
            sx={{
              maxWidth: { md: "1180px", sm: "718px", xs: "328px" },
              marginTop: { md: "60px", sm: "40px", xs: "32px" },
              boxSizing: "border-box",
              width: "100%",
            }}
          > */}
          <Box
            sx={{
              maxWidth: { md: "1180px", sm: "718px", xs: "328px" },
              marginTop: { md: "60px", sm: "40px", xs: "32px" },
              display: "flex",
              // marginTop: "20px",
              justifyContent: "center",
              gap: "20px",
              flexWrap: "wrap",
              padding: "0 0 20px",
              boxSizing: "border-box",
              width: "100%",
            }}
          >
            {architectures.map((architecture) => (
              <SolutionCard architecture={architecture} />
            ))}
          </Box>
          {/* </Box> */}

          <Box
            sx={{
              width: "100%",
              maxWidth: { md: "1180px", sm: "732px", xs: "328px" },
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 0,
            }}
          >
            <DashedDividingLine />
          </Box>
        </Box>

        <Box
          id="resources-block"
          sx={{
            boxSizing: "border-box",
            width: "100%",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: { sm: "20px", xs: "16px" },
            padding: { sm: "40px", xs: "32px 16px" },
          }}
        >
          <Box
            sx={{ maxWidth: { md: 780, sm: 532, xs: 328 }, marginTop: "40px" }}
          >
            <Typography
              textAlign="center"
              variant="h3"
              fontWeight="700"
              sx={{ fontSize: { md: "48px", sm: "40px", xs: "28px" } }}
              id="Resources"
            >
              {t("solutions.ai.resources")}
            </Typography>
          </Box>
          <Box
            ref={sliderContainerRef}
            sx={{
              width: "100%",
              display: "flex",
              gap: "20px",
              maxWidth: { md: "1180px", sm: "716px", xs: "328px" },
              margin: "20px auto 0",
              flexWrap: "wrap",
              overflow: "hidden",
              boxSizing: "border-box",
              justifyContent: "flex-start",
            }}
          >
            <Box
              width="100%"
              maxWidth={`${sliderContainerWidth! * 2}px`}
              sx={{
                display: "flex",
                transition: "0.3s ease-in-out",
                boxSizing: "border-box",
                transform: `translateX(${transformIndex * -100}%)`,
              }}
            >
              {resources.map((infos) => (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: { md: "20px", sm: "20px", xs: "16px" },
                    flexShrink: 0,
                    boxSizing: "border-box",
                    flexDirection: { sm: "row", xs: "column" },
                  }}
                >
                  {infos.infos.map((info) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { sm: "column", xs: "row-reverse" },
                        alignItems: "flex-start",
                        width: "100%",
                        maxWidth: { md: 380, sm: 348, xs: 328 },
                        height: { md: "auto", sm: "auto", xs: "auto" },
                        minHeight: { sm: "auto", xs: "200px" },
                        border: "1px solid #C8C8C8",
                        boxSizing: "border-box",
                        borderRadius: "4px",
                        padding: { sm: 0, xs: "16px" },
                        gap: { sm: 0, xs: "16px" },
                      }}
                    >
                      <Box
                        sx={{
                          maxWidth: { md: "auto", sm: "100%", xs: "88px" },
                          width: "100%",
                        }}
                      >
                        {queryDownSm ? (
                          <Box
                            sx={{
                              width: "88px",
                              height: "88px",
                              background:
                                "url(" + info.src + ")" + "center no-repeat",
                              backgroundSize: "cover",
                            }}
                          ></Box>
                        ) : (
                          <img
                            src={info.src}
                            style={{
                              width: "100%",
                            }}
                            alt="resource-img"
                          />
                        )}
                      </Box>
                      <Box
                        sx={{
                          boxSizing: "border-box",
                          display: "flex",
                          gap: { sm: "20px", xs: "4px" },
                          padding: { sm: "20px", xs: "0" },
                          flexDirection: "column",
                          maxWidth: "100%",
                        }}
                      >
                        <Box>
                          <Typography
                            fontWeight="700"
                            sx={{
                              fontSize: { sm: "16px", xs: "14px" },
                              lineHeight: { sm: "24px", xs: "20px" },
                              height: { sm: "82.25px", xs: "120px" },
                              overflowX: "hidden",
                            }}
                          >
                            {info.description}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            height: { sm: "24px", xs: "auto" },
                            maxWidth: "340px",
                            gap: { md: "20px", sm: "16px", xs: "4px" },
                            flexWrap: { sm: "nowrap", xs: "wrap" },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              maxWidth: {
                                md: "210px",
                                sm: "188px",
                                xs: "188px",
                              },
                            }}
                          >
                            <Typography
                              color="#89898A"
                              fontWeight="700"
                              fontSize="12px"
                              sx={{ padding: { sm: "0 0 0 7px", xs: "0" } }}
                            >
                              {info.category} ・ {info.date}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Button
                              sx={{
                                color: "#008CD6",
                                fontWeight: 700,
                                fontSize: "16px",
                                textTransform: "none",
                                padding: "0",
                                whiteSpace: "nowrap",
                              }}
                              onClick={() =>
                                goToPage(info.url ?? "", !!info.isOuterLink)
                              }
                            >
                              <Typography color="#008CD6" fontWeight="700">
                                {t("landing-page.common.learn-more")}
                              </Typography>

                              <ChevronRightIcon style={{ color: "#008CD6" }} />
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>

            {queryDownSm ? undefined : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: { md: "100%", sm: "100%", xs: "100%" },
                }}
              >
                <Box
                  sx={{
                    bgcolor: "#fff",
                    zIndex: 1,
                  }}
                >
                  <IconButton
                    sx={{
                      transition: "0.3s ease-in",
                      backgroundColor: "white !important",
                    }}
                    onClick={handleClickPreviousPage}
                    disabled={activePage === 0}
                  >
                    <NavigateBeforeIcon />
                  </IconButton>

                  <Typography
                    textAlign="left"
                    color={"GrayText"}
                    sx={{ display: "inline-block", backgroundColor: "white" }}
                  >
                    {(activePage + 1).toString()} / {resources!.length}
                  </Typography>

                  <IconButton
                    sx={{
                      transition: "0.3s ease-in",
                      backgroundColor: "white !important",
                    }}
                    onClick={handleClickNextPage}
                    disabled={activePage === resources.length - 1}
                  >
                    <NavigateNextIcon />
                  </IconButton>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    position: "absolute",
                    maxWidth: { md: "1180px", sm: "732px", xs: "328px" },
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 0,
                  }}
                >
                  <DashedDividingLine />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </div>
    </div>
  );
});

export { SolutionAI };
