import i18n from "i18next";
import LanguageDetector, {
  DetectorOptions,
} from "i18next-browser-languagedetector";
import Backend, { FetchOptions } from "i18next-fetch-backend";
import { initReactI18next } from "react-i18next";

i18n
  // load translation using fetch -> see /public/locales
  // learn more: https://github.com/dotcore64/i18next-fetch-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to the react-i18next components.
  // Alternative use the I18nextProvider: https://react.i18next.com/latest/i18nextprovider
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // debug: true,
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
      // 如果有安裝 ifp-ui-* Component Library
      // 他的翻譯檔會放在 /locales-libs/{{lng}}/{{ns}}.json 底下
      // 所以要覆寫 i18next-fetch-backend 設定
      // 當 Namespace 是 ifp-ui- 開頭時，改從 /locales-libs/{{lng}}/{{ns}}.json 讀取翻譯檔
      fetch: function (url: string, requestOptions) {
        if (/^\/locales\/[A-Za-z-]+\/ifp-ui-\S+/.test(url)) {
          url = url.replace("locales", "locales-libs");
        }
        return fetch(url, requestOptions);
      },
    } as FetchOptions,
    detection: {
      // 順序 1 Query String
      // 順序 2 Cookie
      // 順序 3 瀏覽器語言
      order: ["querystring", "cookie", "navigator"],
      lookupQuerystring: "IFPLang",
      lookupCookie: "IFPLang",
      // 關閉 Cache
      caches: [],
    } as DetectorOptions,
    // 語言白名單
    supportedLngs: ["en-US", "zh-TW", "zh-CN", "ja", "ko"],
    // 預設語言為 en-US
    fallbackLng: "en-US",
    // 讓他只讀取 en-US，不會去讀取 en
    load: "currentOnly",
    // 如果翻譯結果是空字串的話，會 Fallback 回預設語言 en-US 的翻譯
    returnEmptyString: false,

    // 預先載入的 Namespace，需要改成專案名稱
    ns: [
      "landing-page",
      "home",
      "metal-process",
      "footwear",
      "products",
      "partners",
      "food",
      "electronics",
      "solutions",
      "pcb",
      "intelligent-automation",
      "digital-transformation",
      "ifactoryworks"
    ],
    // 分隔符從預設的 ":" 改成 "." ，為了與 VS Code Extension i18n Ally 有更好的體驗
    nsSeparator: ".",

    interpolation: {
      // not needed for react as it escapes by default
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
      // useSuspense: false,
    },
  });

i18n.on("languageChanged", function (lng) {
  document.documentElement.lang =
    (lng === "zh-TW" ? "zh-Hant-TW" : lng) || "en-US";
});

export default i18n;
