import { BaseService } from "./base";

export class DeskService extends BaseService {
  async login(username: string, password: string) {
    return await this.axios.post<{
      data?: {
        signIn: {
          user: {
            id: string;
            name: string;
          };
          ifpToken: string;
        };
      };
      errors?: [{ name: string; message: string }];
    }>("/_/api_hub/graphql", {
      query:
        "mutation ($username: String!, $password: String!) {\n  signIn(input: {username: $username, password: $password}) {\n    user {\n      id\n      name\n    }\n    ifpToken\n  }\n}\n",
      variables: {
        username: username,
        password: password,
      },
    });
  }
}
