import React, {
  FC,
  memo,
  ReactNode,
  useEffect,
  useRef,
  createRef,
  useState,
} from "react";
import {
  Box,
  BoxProps,
  Button,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useArchitecturesData } from "./data/architectures";
import { useHeader, useImageUtils } from "../../../hooks";

import banner from "./images/img_banner_Intelligent_Automation.jpg";
import bannerAVIF from "./images/img_banner_Intelligent_Automation.avif";
import bannerWebp from "./images/img_banner_Intelligent_Automation.webp";
import overviewPicture from "./images/img_overview_feature.png";

import { useAnchorStepsData } from "./data/useAnchorStepsData";
import { useFeaturesData } from "./data/features";
import { SolutionCard } from "../../../components";
import AnchorBar from "../../../components/Common/AnchorBar";
import { useTranslation } from "react-i18next";
import DashedDividingLine from "../../../components/Common/DashedDividingLine";
import i18n from "i18next";
import { useNavigate } from "react-router-dom";
import WhyDoYouNeed from "./WhyDoYouNeed";
import FeatureCard from "@/components/solutions/FeatureCard";
import styles from "@/styles/image.module.css";
import { WhiteScrollBtn } from "@/components/Button";

const ScrollBtnBox = styled(Box)<BoxProps>({
  "@keyframes scrollBtnBox": {
    from: {
      transform: "translate(0)",
    },
    to: {
      transform: "translateY(20px)",
    },
  },
  animation: "scrollBtnBox 0.78s infinite ease-in-out alternate",
});

interface IProps {
  children?: ReactNode;
}

const IntelligentAutomation: FC<IProps> = memo(() => {
  const { visible } = useHeader();
  const { selectImage } = useImageUtils();
  const { t } = useTranslation(undefined);
  const [isSticky, setIsSticky] = useState(false);
  const ref = createRef<HTMLElement>();
  const [activePage, setActivePage] = useState(0);
  const anchorSteps = useAnchorStepsData();
  const architectures = useArchitecturesData();
  const features = useFeaturesData();

  const [transformIndex, setTransformIndex] = useState(0);

  const handleClickNextPage = () => {
    setActivePage((activePage) => activePage + 1);
    setTransformIndex((transformIndex) => transformIndex + 1);
  };
  const handleClickPreviousPage = () => {
    setActivePage((activePage) => activePage - 1);
    setTransformIndex((transformIndex) => transformIndex - 1);
  };

  const theme = useTheme();
  const queryUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const queryDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [readMore, setReadMore] = useState(queryUpMd ? false : false);

  useEffect(() => {
    const cachedRef = ref.current,
      observer = new IntersectionObserver(
        ([e]) => setIsSticky(e.intersectionRatio < 1),
        {
          threshold: [1],
          rootMargin: "-73px 0px 100px 0px",
        }
      );

    if (cachedRef) {
      observer.observe(cachedRef);
    }

    return function () {
      if (cachedRef) {
        observer.unobserve(cachedRef);
      }
    };
  }, []);

  enum CursorStyle {
    default = "default",
    pointer = "pointer",
  }

  const firstBlockRef = useRef<HTMLElement>(null);

  const handleChangeCursor = (
    event: React.MouseEvent<HTMLDivElement>,
    cursorStyle: CursorStyle
  ) => {
    (event.target as HTMLElement).style.cursor = cursorStyle;
  };

  const handleNavigateFirstBlock = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    const firstRef = firstBlockRef!.current;
    if (!firstRef) return;
    firstRef.scrollIntoView({ behavior: "smooth" });
  };

  const handleClickReadText = (e: React.MouseEvent<HTMLButtonElement>) => {
    setReadMore((oldMore) => {
      return !oldMore;
    });
  };

  const sliderContainerRef = useRef<HTMLElement>();

  const [sliderContainerWidth, setSliderContainerWidth] = useState(
    sliderContainerRef.current?.offsetWidth
  );
  useEffect(() => {
    if (sliderContainerRef.current) {
      setSliderContainerWidth(sliderContainerRef.current.offsetWidth);
    }
  }, [sliderContainerRef.current?.offsetWidth]);

  const navigate = useNavigate();
  const goToPage = (url: string | undefined, isOuterLink: boolean) => {
    if (!url) return;
    if (isOuterLink) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          backgroundImage: `radial-gradient(50% 50% at 50% 50%, #000000 0%, rgba(0, 0, 0, 0) 100%), url(${selectImage(
            {
              avif: bannerAVIF,
              webp: bannerWebp,
              default: banner,
            }
          )})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: "84px 40px 40px",
        }}
      >
        <Box
          sx={{
            maxWidth: { md: 780, sm: 532, xs: 328 },
            marginTop: { xs: "20vh" },
          }}
        >
          <Typography
            color="white"
            variant="h2"
            textAlign="center"
            fontWeight="900"
            fontSize="96"
            sx={{ fontSize: { md: "48px", xs: "22px" } }}
          >
            {t("intelligent-automation.banner-title")}
          </Typography>
          <Typography
            textAlign="center"
            color="white"
            sx={{ marginTop: "20px", fontSize: { md: "20px", xs: "16px" } }}
          >
            {t("intelligent-automation.banner-content")}
          </Typography>
        </Box>
        <ScrollBtnBox
          sx={{ zIndex: 50, margin: "20px", padding: "8px 4px" }}
          onClick={(event) => handleNavigateFirstBlock(event)}
        >
          <WhiteScrollBtn text="scroll" />
        </ScrollBtnBox>
      </Box>
      <AnchorBar
        visible={visible}
        isSticky={isSticky}
        stepCards={anchorSteps}
        ref={ref}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        ref={firstBlockRef as React.RefObject<HTMLDivElement>}
      >
        <Box
          id="Overview"
          sx={{
            maxWidth: { md: 780, sm: 532, xs: 328 },
            marginTop: "80px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0 0 20x",
            gap: "20px",
          }}
        >
          <Typography
            textAlign="center"
            variant="h3"
            fontWeight="700"
            sx={{ fontSize: { md: "48px", xs: "28px" } }}
          >
            {t("intelligent-automation.overview-title")}
          </Typography>
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography
              variant="body1"
              fontWeight="400"
              color="#89898A"
              textAlign="center"
              sx={{
                fontSize: { md: "20px", sm: "16px", xs: "16px" },
                WebkitLineClamp: {
                  md: !readMore ? "10" : "auto",
                  sm: !readMore ? "4" : "auto",
                  xs: !readMore ? "6" : "auto",
                },
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                margin: "20px 0 0",
              }}
            >
              {t("intelligent-automation.overview-content")}
            </Typography>

            <Button
              variant="text"
              sx={{
                textTransform: "none",
                textAlign: "center",
                width: "fix-content",
                whiteSpace: "nowrap",
                color: "#008CD6",
              }}
              onClick={(e) => handleClickReadText(e)}
            >
              {!readMore ? "Read More..." : "Read Less..."}
              {/* {!readMore
                ? t("landing-page.common.read-more")
                : t("landing-page.common.read-less")} */}
            </Button>
          </Box>
          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              height: { md: "438.75px", sm: "299.83px", xs: "184.5px" },
            }}
          >
            <iframe
              allowTransparency={true}
              title="Wistia video player"
              allowFullScreen
              frameBorder="0"
              scrolling="no"
              className="wistia_embed"
              name="wistia_embed"
              src={
                i18n.language === "en-US"
                  ? "https://fast.wistia.net/embed/iframe/66yr2jskfj"
                  : "https://fast.wistia.net/embed/iframe/66yr2jskfj"
                // : "https://fast.wistia.net/embed/iframe/diukzbyge6"
              }
              width="100%"
              height="100%"
            ></iframe>
          </Box>
        </Box>
        <Box
          id="Features block"
          sx={{
            width: "100%",
            maxWidth: { lg: 1180, md: 768, sm: 716, xs: 328 },
            marginTop: { md: "96px", xs: "20px" },
          }}
        >
          <Typography
            textAlign="center"
            variant="h6"
            fontWeight="700"
            fontSize={"2rem"}
            lineHeight={"3.25rem"}
            display={"flex"}
            justifyContent={"center"}
            position={"relative"}
          >
            <span
              style={{
                backgroundColor: "#fff",
                width: "fit-content",
                padding: "0 20px",
                fontSize: "24px",
              }}
            >
              Features
              {/* {t("solutions.ai.features")} */}
            </span>
            <div
              style={{
                width: "100%",
                border: "1px dashed #E1E1E1",
                position: "absolute",
                top: "50%",
                zIndex: -1,
              }}
            ></div>
          </Typography>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              flexDirection: { md: "row", sm: "column", xs: "column" },
            }}
          >
            <Box
              sx={{
                maxWidth: {
                  md: "780px",
                  sm: "532px",
                  xs: "532px",
                },
                width: "100%",
                margin: "0 auto",
              }}
            >
              <img
                src={overviewPicture}
                alt="overview"
                className={styles.imageW100}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                maxWidth: { md: "380px", sm: "none", xs: "none" },
                gap: "20px",
                flexDirection: { md: "column", sm: "row", xs: "column" },
                flexWrap: "wrap",
              }}
            >
              {features.map((feature) => (
                <FeatureCard
                  key={feature.title}
                  title={feature.title}
                  content={feature.content}
                />
              ))}
            </Box>
          </Box>

          <Typography
            className={"divider"}
            textAlign="center"
            variant="h6"
            fontWeight="700"
            fontSize={"2rem"}
            lineHeight={"3.25rem"}
            display={"flex"}
            justifyContent={"center"}
            position={"relative"}
            padding={"40px 0"}
          >
            <div
              style={{
                width: "100%",
                border: "1px dashed #E1E1E1",
                position: "absolute",
                top: "50%",
                zIndex: -1,
              }}
            ></div>
          </Typography>
        </Box>

        <Box id="FAQs">
          <WhyDoYouNeed />
          <Box sx={{ width: "100%", height: "40px" }}>
            <DashedDividingLine />
          </Box>
        </Box>

        <Box
          sx={{
            id: "application-block",
            width: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: { md: " 48px 50px", sm: "40px 20px", xs: "32px 16px" },
            gap: { sm: "20px", xs: "16px" },
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{ maxWidth: { md: 780, sm: 532, xs: 328 } }}
            id="Applications"
          >
            <Typography
              textAlign="center"
              variant="h3"
              fontWeight="700"
              sx={{ fontSize: { md: "48px", sm: "40px", xs: "28px" } }}
            >
              {t("solutions.common.applications")}
            </Typography>
          </Box>

          <Box
            sx={{
              maxWidth: { md: "1180px", sm: "718px", xs: "328px" },
              marginTop: { md: "60px", sm: "40px", xs: "32px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                marginTop: "20px",
                justifyContent: "center",
                gap: "20px",
                flexWrap: "wrap",
                padding: "0 0 20px",
              }}
            >
              {architectures.map((architecture) => (
                <SolutionCard
                  key={architecture.title}
                  architecture={architecture}
                  isBtnShow={false}
                />
              ))}
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              maxWidth: { md: "1180px", sm: "732px", xs: "328px" },
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 0,
            }}
          >
            <DashedDividingLine />
          </Box>
        </Box>
      </div>
    </div>
  );
});

export { IntelligentAutomation };
