import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material";

import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Menu from "@mui/material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import HeaderLogo from "../images/logo.png";
import { LoginDrawer } from "./LoginDrawer";
import { useAuth, useHeader, useService } from "../hooks";

import type {
  Page,
  Pages,
  StaterPackagePage,
  StaterPackagePages,
} from "./Header/types/Pages";

import {
  useConfigRoutes,
  useConfigStarterPackageRoutes,
} from "./Header/data/configRoutes";

import { ColoredTypography } from "./ColoredTypography/ColoredTypography";
import LetterAvatar from "./LetterAvatar";

export const Header = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { visible } = useHeader();
  const { user, refetch, logout } = useAuth();
  const { authService, apiHubUrl } = useService();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElNavChild, setAnchorElNavChild] = useState<null | HTMLElement>(
    null
  );
  const [activeBtnName, setActiveBtnName] = useState("");
  const [loginDrawerOpen, setLoginDrawerOpen] = useState(false);
  const open = Boolean(anchorElNav);
  const [menuChildOpen, setMenuChildOpen] = useState(false);
  const [menuChild, setMenuChild] = useState<Page | null>(null);

  const pages = useConfigRoutes();
  const starterPackageRoutes = useConfigStarterPackageRoutes();

  const lang: { [key: string]: string } = {
    "en-US": t("landing-page.header.language-en"),
    "zh-CN": t("landing-page.header.language-en"),
    "zh-TW": t("landing-page.header.language-tc"),
    "ja": t("landing-page.header.language-jp"),
    "ko": t("landing-page.header.language-kr"),
  };

  const toolPages: Page[] = [
    {
      name: lang[i18n.language],
      children: [
        {
          name: t("landing-page.header.en"),
          children: [],
          onClick: () => i18n.changeLanguage("en-US"),
        },
        {
          name: t("landing-page.header.tc"),
          children: [],
          onClick: () => i18n.changeLanguage("zh-TW"),
        },
        {
          name: t("landing-page.header.jp"),
          children: [],
          onClick: () => i18n.changeLanguage("ja"),
        },
        {
          name: t("landing-page.header.kr"),
          children: [],
          onClick: () => i18n.changeLanguage("ko"),
        },
        // {
        //   name: "简体中文",
        //   children: [],
        //   onClick: () => i18n.changeLanguage("zh-CN"),
        // },
      ],
    },
    {
      name: t("landing-page.common.contact-us"),
      path: t("landing-page.common.contact-us-url"),
      children: [],
      outerLink: true,
    },
  ];

  const loginPages: Page[] = [
    {
      name: t("landing-page.common.sign-in") || "Sign in",
      children: user
        ? [
            {
              name: t(
                "landing-page.common.go-to-wise-iotsuite-pivot",
                "Go to WISE-IoTSuite Pivot"
              ),
              onClick: () => {
                window.open("/pivot", "_blank");
              },
              children: [],
            },
            {
              name: t("landing-page.common.sign-out", "Sign out"),
              onClick: () => {
                authService.logout().then(() => refetch());
                // logout();
              },
              children: [],
            },
          ]
        : [],
      onClick: () => (user ? undefined : setLoginDrawerOpen(true)),
    },
  ];

  const handleClickLogo = () => {
    navigate("/");
  };

  const handleClickChild = (
    event: React.MouseEvent<HTMLElement>,
    child: Page
  ) => {
    setAnchorElNavChild(event.currentTarget);
    setMenuChildOpen(true);
    setMenuChild(child);
  };

  const handleClickMenuItem = useCallback(
    (event: React.MouseEvent<HTMLElement>, nav: Page) => {
      if (nav.children.length === 0) {
        if (nav.onClick) {
          nav.onClick();
        } else if (nav.path && !nav.outerLink) {
          navigate(nav.path);
        } else {
          setMenuChildOpen(false);
          setAnchorElNavChild(null);
          window.open(nav.path as string, "_blank");
        }
        setAnchorElNav(null);
        setMenuChildOpen(false);
        setActiveBtnName("");
      } else {
        setAnchorElNav(event.currentTarget);
        setActiveBtnName(nav.name);
      }
    },
    [navigate]
  );

  const handleCloseMenuItem = () => {
    setAnchorElNav(null);
    setActiveBtnName("");
    setAnchorElNavChild(null);
    setMenuChildOpen(false);
  };

  const [isHoverPackagesBtn, setIsHoverPackagesBtn] = useState<boolean>(false);
  const handleEnterPackagesBtn = (
    event: React.MouseEvent<HTMLElement>,
    nav: StaterPackagePage
  ) => {
    setIsHoverPackagesBtn(true);
  };

  const handleLeavePackagesBtn = (
    event: React.MouseEvent<HTMLElement>,
    nav: StaterPackagePage
  ) => {
    setIsHoverPackagesBtn(false);
  };
  const handleClickPackageMenuItem = useCallback(
    (event: React.MouseEvent<HTMLElement>, nav: StaterPackagePage) => {
      if (nav.children.length === 0) {
        if (nav.onClick) {
          nav.onClick();
        } else if (nav.isOuterLink) {
          window.open(nav.path, "_blank");
        } else {
          if (!nav.path) return;
          navigate(nav.path);
        }
        setActiveBtnName("");
        setAnchorElNav(null);
      } else {
        setAnchorElNav(event.currentTarget);
        setActiveBtnName(nav.name!);
      }
    },
    [navigate]
  );

  const renderStarterPackageHeader = useCallback(
    (packagePages: StaterPackagePages): React.ReactNode => {
      return packagePages.map((page) => (
        <Menu
          sx={{
            width: "100%",
          }}
          anchorEl={anchorElNav}
          open={open}
          onClose={handleCloseMenuItem}
          key={page.name}
        >
          <Box sx={{ padding: "0 20px", maxWidth: "880px" }}>
            {page.children.map((menuChild, index) => (
              <Box
                key={menuChild.name}
                sx={{ width: "100%", display: "flex", flexDirection: "column" }}
              >
                <Box
                  sx={{
                    boxSizing: "border-box",
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    padding: "20px 0",
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: "280px",
                      minHeight: "88px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      padding: "6px 16px",
                      boxSizing: "border-box",
                    }}
                  >
                    <ColoredTypography
                      variant="h2"
                      textAlign="left"
                      fontWeight="700"
                      fontStyle="italic"
                      sx={{
                        fontSize: { md: "16px", xs: "12px" },
                        lineHeight: "24px",
                        padding: "0px 10px 0 0 !important",
                      }}
                    >
                      {menuChild.title}
                    </ColoredTypography>
                    <Typography
                      variant="h2"
                      textAlign="left"
                      fontWeight="400"
                      color="#89898A"
                      sx={{
                        fontSize: { md: "12px", xs: "10px" },
                      }}
                    >
                      {menuChild.content}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                    {menuChild.children.map((childItem) => (
                      <MenuItem
                        key={childItem.name}
                        sx={{ width: "280px", padding: "8px 0" }}
                        onClick={(e) => {
                          handleClickPackageMenuItem(e, childItem);
                        }}
                        onMouseEnter={(e) =>
                          handleEnterPackagesBtn(e, childItem)
                        }
                        onMouseLeave={(e) =>
                          handleLeavePackagesBtn(e, childItem)
                        }
                        disabled={!childItem.path}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: "280px",
                            width: "100%",
                            minHeight: "88px",
                          }}
                        >
                          <Typography whiteSpace={"break-spaces"}>
                            {childItem.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            textAlign="left"
                            fontWeight="400"
                            color="#89898A"
                            whiteSpace="break-spaces"
                            sx={{
                              fontSize: { md: "12px", xs: "10px" },
                            }}
                          >
                            {childItem.content}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Box>
                </Box>
                {page.children.length - 1 === index ? undefined : (
                  <div
                    style={{
                      width: "100%",
                      height: 0,
                      border: "1px dashed #E1E1E1",
                    }}
                  ></div>
                )}
              </Box>
            ))}
          </Box>
        </Menu>
      ));
    },
    [anchorElNav, handleClickPackageMenuItem, open]
  );

  const renderHeader = useCallback(
    (pages: Pages): React.ReactNode => {
      return (
        <>
          {pages.map((page) => (
            <React.Fragment key={page.name}>
              <Button
                key={page.name}
                onClick={(e) => handleClickMenuItem(e, page)}
                sx={{
                  color: activeBtnName !== page.name ? "#89898A" : "#008CD6",
                  backgroundColor:
                    page.name === t("landing-page.header.login")
                      ? "#008CD6"
                      : "#fff",
                  display: "block",
                  paddingRight: { lg: "12px", xs: 0 },
                  margin: "0",
                  textTransform: "none",
                }}
              >
                <Typography
                  textAlign="center"
                  variant="subtitle2"
                  sx={{
                    fontWeight: "700",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span>{page.name}</span>
                  {page.children.length > 0 ? (
                    activeBtnName !== page.name ? (
                      <ArrowDropDownIcon fontSize={"small"} />
                    ) : (
                      <ArrowDropUpIcon fontSize={"small"} />
                    )
                  ) : undefined}
                </Typography>
              </Button>

              {page.children.length > 0 &&
              activeBtnName === page.name &&
              activeBtnName === t("landing-page.header.starter-packages") ? (
                <Box sx={{ maxWidth: "920px" }}>
                  {renderStarterPackageHeader(starterPackageRoutes)}
                </Box>
              ) : page.children.length > 0 && activeBtnName === page.name ? (
                <Menu
                  key={`${page.name}`}
                  anchorEl={anchorElNav}
                  open={open}
                  onClose={handleCloseMenuItem}
                >
                  {page.children.map((menuChild, index) =>
                    menuChild.children.length <= 0 ? (
                      <MenuItem
                        key={menuChild.name}
                        onClick={(e) => handleClickMenuItem(e, menuChild)}
                        disabled={menuChild.path === "disabled"}
                      >
                        <Typography>{menuChild.name}</Typography>
                      </MenuItem>
                    ) : (
                      // <Typography>{menuChild.children[0]!.name}</Typography>
                      <MenuItem
                        key={menuChild.name}
                        sx={{ width: "100%", height: "100%" }}
                        onClick={(e) => handleClickChild(e, menuChild)}
                        disabled={menuChild.path === "disabled"}
                      >
                        <Typography>{menuChild.name}</Typography>
                        <Box>
                          <ArrowRightIcon
                            sx={{
                              position: "absolute",
                              right: "0px",
                              top: "calc(50% - 12px)",
                              width: "24px",
                            }}
                          />
                        </Box>
                      </MenuItem>
                    )
                  )}
                </Menu>
              ) : undefined}
            </React.Fragment>
          ))}
          {menuChild && (
            <Menu
              anchorEl={anchorElNavChild}
              open={menuChildOpen}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={handleCloseMenuItem}
            >
              {menuChild.children.map((thirdChild) => (
                <MenuItem
                  key={thirdChild.name}
                  onClick={(e) => handleClickMenuItem(e, thirdChild)}
                >
                  <Typography>{thirdChild.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          )}
        </>
      );
    },
    [
      menuChild,
      anchorElNavChild,
      menuChildOpen,
      activeBtnName,
      t,
      renderStarterPackageHeader,
      starterPackageRoutes,
      anchorElNav,
      open,
      handleClickMenuItem,
    ]
  );

  const renderLoginHeader = useCallback(
    (pages: Pages): React.ReactNode => {
      return pages.map((page, i) => (
        <React.Fragment key={i}>
          {user ? (
            <Button
              // key={page.name}
              onClick={(e) => handleClickMenuItem(e, page)}
              sx={{
                color: activeBtnName !== page.name ? "#89898A" : "#008CD6",
                display: "block",
                paddingRight: { lg: "12px", xs: 0 },
                margin: "0",
                textTransform: "none",
              }}
            >
              <LetterAvatar
                color="auto"
                size="24px"
                letter={
                  user.firstName.length > 0
                    ? user.firstName[0].toLocaleUpperCase()
                    : "U"
                }
              />
            </Button>
          ) : (
            <Button
              // key={page.name}
              onClick={(e) => handleClickMenuItem(e, page)}
              sx={{
                color:
                  activeBtnName !==
                  (t("landing-page.common.sign-in") || "Sign in")
                    ? "#89898A"
                    : "#008CD6",
                backgroundColor:
                  page.name === (t("landing-page.common.sign-in") || "Sign in")
                    ? "#008CD6"
                    : "#fff",
                display: "block",
                padding: "12px 14px 12px 14px",
                margin: "0",
                ":hover span": { color: "#008CD6" },
                textTransform: "none",
                borderRadius: "0",
                width: "120px",
              }}
            >
              <Typography
                textAlign="center"
                variant="subtitle2"
                sx={{
                  fontWeight: "700",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                }}
              >
                <span style={{ textAlign: "center" }}>{page.name}</span>
                {page.children.length > 0 ? (
                  activeBtnName !== page.name ? (
                    <ArrowDropDownIcon fontSize={"small"} />
                  ) : (
                    <ArrowDropUpIcon fontSize={"small"} />
                  )
                ) : undefined}
              </Typography>
            </Button>
          )}
          {page.children.length > 0 && activeBtnName === page.name ? (
            <Menu
              key={`${page.name}`}
              anchorEl={anchorElNav}
              open={open}
              onClose={handleCloseMenuItem}
            >
              {user && (
                <MenuItem
                  key={user.username}
                  disableRipple
                  disableTouchRipple
                  // onClick={(e) => handleClickMenuItem(e, menuChild)}
                >
                  <LetterAvatar
                    color="auto"
                    size="40px"
                    letter={
                      user.firstName.length > 0
                        ? user.firstName[0].toLocaleUpperCase()
                        : "U"
                    }
                  />
                  <div>
                    <Typography
                      sx={{ fontSize: 16 }}
                    >{`${user.firstName} ${user.lastName}`}</Typography>
                    <Typography
                      sx={{ fontSize: 12, color: theme.palette.grey["600"] }}
                      variant="caption"
                    >
                      {user.username}
                    </Typography>
                  </div>
                </MenuItem>
              )}
              {page.children.map((menuChild, index) =>
                menuChild.children.length <= 0 ? (
                  <MenuItem
                    key={menuChild.name}
                    onClick={(e) => handleClickMenuItem(e, menuChild)}
                  >
                    <Typography>{menuChild.name}</Typography>
                  </MenuItem>
                ) : (
                  // <Typography>{menuChild.children[0]!.name}</Typography>
                  <MenuItem
                    key={menuChild.name}
                    sx={{ width: "100%", height: "100%" }}
                    onClick={(e) => handleClickChild(e, menuChild)}
                  >
                    <Typography>{menuChild.name}</Typography>
                    <Box>
                      <ArrowRightIcon
                        sx={{
                          position: "absolute",
                          right: "0px",
                          top: "calc(50% - 12px)",
                          width: "24px",
                        }}
                      />
                    </Box>
                  </MenuItem>
                )
              )}
            </Menu>
          ) : undefined}
        </React.Fragment>
      ));
    },
    [activeBtnName, anchorElNav, handleClickMenuItem, open, t, user]
  );

  const [activeList, setActiveList] = useState<string[]>([]);
  const [activeChildList, setActiveChildList] = useState<string[]>([]);
  const handleClickList = (event: React.MouseEvent<HTMLElement>, nav: Page) => {
    setActiveBtnName(nav.name);
    if (nav.children.length > 0) {
      const theFindIndex = activeList.findIndex((list) => list === nav.name);
      if (theFindIndex < 0) {
        setActiveList([...activeList, nav!.name!]);
      } else {
        setActiveList([
          ...activeList.slice(0, theFindIndex),
          ...activeList.slice(theFindIndex + 1),
        ]);
      }
      return;
    } else {
      // navigate(nav.path);
      if (nav.onClick) {
        nav.onClick();
      } else if (nav.path && !nav.outerLink) {
        navigate(nav.path);
      } else {
        window.open(nav.path as string, "_blank");
      }

      setDrawerOpen(false);
      setActiveList([]);
    }
  };

  const handleClickChildList = (
    event: React.MouseEvent<HTMLElement>,
    nav: Page
  ) => {
    if (nav.children.length > 0) {
      const theFindIndex = activeChildList.findIndex(
        (list) => list === nav.name
      );
      if (theFindIndex < 0) {
        setActiveChildList([...activeChildList, nav!.name!]);
      } else {
        setActiveChildList([
          ...activeChildList.slice(0, theFindIndex),
          ...activeChildList.slice(theFindIndex + 1),
        ]);
      }
      return;
    } else {
      if (nav.onClick) {
        nav.onClick();
      } else if (nav.path && !nav.outerLink) {
        navigate(nav.path);
      } else {
        window.open(nav.path as string, "_blank");
      }

      setDrawerOpen(false);
      setActiveChildList([]);
    }
  };

  function renderPhoneHeader(pages: Pages): React.ReactNode {
    return pages.map((page) => (
      <React.Fragment key={page.name}>
        <ListItemButton
          sx={{
            padding: "10px 20px",
            backgroundColor: "#FAFAFA",
          }}
          onClick={(e) => handleClickList(e, page)}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "14px",
            }}
          >
            {page.name}
          </Typography>
          {page.children.length > 0 ? (
            activeList.findIndex((list) => list === page.name) >= 0 ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )
          ) : undefined}
          <Box
            sx={{
              position: "absolute",
              top: "100%",
              width: "100%",
              height: "1px",
              borderBottom: "1px solid #E1E1E1",
            }}
          ></Box>
        </ListItemButton>
        {page.children.length > 0 ? (
          <Collapse
            in={activeList.findIndex((list) => list === page.name) >= 0}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" sx={{ flexDirection: "column" }}>
              {page.children.map((menuChild) => {
                if (menuChild.children.length === 0) {
                  return (
                    <ListItemButton
                      key={menuChild.name}
                      sx={{ padding: "10px 32px" }}
                      onClick={(e) => handleClickList(e, menuChild)}
                      disabled={menuChild.path === "disabled"}
                    >
                      <Typography fontSize="14px">{menuChild.name}</Typography>
                      <Box
                        sx={{
                          position: "absolute",
                          top: "100%",
                          width: "100%",
                          height: "1px",
                          borderBottom: "1px solid #E1E1E1",
                        }}
                      ></Box>
                    </ListItemButton>
                  );
                } else {
                  return (
                    <>
                      <ListItemButton
                        key={menuChild.name}
                        sx={{ padding: "10px 32px" }}
                        onClick={(e) => handleClickChildList(e, menuChild)}
                      >
                        <Typography fontSize="14px" fontWeight="700">
                          {menuChild.name}
                        </Typography>
                        {menuChild.children.length > 0 ? (
                          activeChildList.findIndex(
                            (list) => list === menuChild.name
                          ) >= 0 ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )
                        ) : undefined}
                        <Box
                          sx={{
                            position: "absolute",
                            top: "100%",
                            width: "100%",
                            height: "1px",
                            borderBottom: "1px solid #E1E1E1",
                          }}
                        ></Box>
                      </ListItemButton>
                      <Collapse
                        timeout="auto"
                        unmountOnExit
                        in={
                          activeChildList.findIndex(
                            (list) => list === menuChild.name
                          ) >= 0
                        }
                      >
                        <List component="div" sx={{ flexDirection: "column" }}>
                          {menuChild.children.map((menuChildItem) => {
                            return (
                              <ListItemButton
                                sx={{
                                  padding: "10px 32px",
                                  margin: "0 0 0 16px",
                                }}
                                onClick={(e) =>
                                  handleClickChildList(e, menuChildItem)
                                }
                              >
                                <Typography fontSize="14px">
                                  {menuChildItem.name}
                                </Typography>
                                <Box
                                  sx={{
                                    position: "absolute",
                                    top: "100%",
                                    width: "100%",
                                    height: "1px",
                                    borderBottom: "1px solid #E1E1E1",
                                  }}
                                ></Box>
                              </ListItemButton>
                            );
                          })}
                        </List>
                      </Collapse>
                    </>
                  );
                }
              })}
            </List>
          </Collapse>
        ) : undefined}
      </React.Fragment>
    ));
  }

  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const drawerWidth = "100%";

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  }));
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <LoginDrawer
        open={loginDrawerOpen}
        onClose={() => setLoginDrawerOpen(false)}
      />
      <AppBar
        position="fixed"
        sx={{
          borderBottomStyle: "solid",
          borderBottomColor: "#E1E1E1",
          borderBottomWidth: 1,
          bgcolor: "white",
          boxShadow: "none",
          transition: "top 0.1s",
          whiteSpace: "nowrap",
          ...(!visible && { top: -73 }),
        }}
      >
        <Toolbar disableGutters sx={{ height: "72px", position: "relative" }}>
          <Typography
            variant="h1"
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            style={{ maxHeight: "48px", marginLeft: "24px" }}
            onClick={handleClickLogo}
          >
            <img
              src={HeaderLogo}
              height="48px"
              alt="logo"
              style={{ display: "block" }}
            />
          </Typography>

          <Box
            sx={{
              flex: 1,
              display: { xs: "none", md: "flex" },
              marginLeft: { lg: "100px", md: "20px", xs: 0 },
              height: "100%",
            }}
          >
            {renderHeader(pages)}
          </Box>

          <Box
            sx={{
              flex: 1,
              display: { md: "flex", xs: "none" },
              justifyContent: "right",
              height: "100%",
            }}
          >
            {renderHeader(toolPages)}
            {renderLoginHeader(loginPages)}
          </Box>

          <Box
            sx={{
              flex: 1,
              height: "100%",
              color: "#000",
              display: { md: "none", xs: "flex" },
              justifyContent: "flex-end",
              alignItems: "center",
              margin: "0 10px 0 0",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={drawerOpen}
      >
        <DrawerHeader
          sx={{
            display: "flex",
            justifyContent: "space-between",
            bgcolor: "#FAFAFA",
          }}
        >
          <div style={{ maxHeight: "48px", marginLeft: "12px" }}>
            <img src={HeaderLogo} height="48px" alt="logo" />
          </div>
          <div>
            {user && (
              <Button
                sx={{
                  border: "1px solid #C8C8C8",
                  borderRadius: "4px",
                  textTransform: "none",
                  color: "#008CD6",
                }}
                startIcon={
                  <LetterAvatar
                    color="auto"
                    size="24px"
                    letter={
                      user.firstName.length > 0
                        ? user.firstName[0].toLocaleUpperCase()
                        : "U"
                    }
                  />
                }
                variant="outlined"
                onClick={logout}
              >
                {t("landing-page.common.sign-out")}
              </Button>
            )}
            {!user && (
              <>
                {loginPages.map((loginPage) => {
                  return (
                    <Button
                      sx={{
                        border: "1px solid #C8C8C8",
                        borderRadius: "4px",
                        textTransform: "none",
                        color: "#008CD6",
                      }}
                      variant="outlined"
                      onClick={(e) => handleClickList(e, loginPage)}
                    >
                      {t("landing-page.common.sign-in")}
                    </Button>
                  );
                })}
              </>
            )}
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? <ChevronLeftIcon /> : <CloseIcon />}
            </IconButton>
          </div>
        </DrawerHeader>
        <Divider />

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            backgroundColor: "#FAFAFA",
          }}
        >
          <List
            sx={{
              width: "100%",
              maxWidth: "532px",
              // bgcolor: "background.paper",
              backgroundColor: "#FAFAFA",
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {renderPhoneHeader(pages)}
            {renderPhoneHeader(toolPages)}
          </List>
        </Box>
        <Divider />
      </Drawer>
    </Box>
  );
};
