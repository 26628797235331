import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import i18n from "i18next";

import picTC01 from "../images/01tc.png";
import picTC02 from "../images/02tc.png";
import picTC03 from "../images/03tc.png";

type Resource = {
  page: number;
  infos: {
    name: string;
    description: string;
    date: string;
    src: string;
    url: string;
    isOuterLink: boolean;
    category: string;
  }[];
};

const useResourcesData = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesMdToSm = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const isTC = i18n.language === "zh-TW";
  const resources = [
    // {
    //   page: 1,
    //   infos: [
    //     {
    //       name: "Petrochemical",
    //       description:
    //         "WISE-iFactory Industry Focus | 1 iFactory Electronics Manufacturing",
    //       date: "2023/04/14",
    //       src: !isTC ? pic01 : picTC01,
    //       url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-1-ifactory-electronics-en?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXdNv3estI.link",
    //       isOuterLink: true,
    //       category: t("intelligent-automation.video"),
    //     },
    //   ],
    // },
  ] as Resource[];

  const mdToSmResourcesData = [
    // {
    //   page: 1,
    //   infos: [
    //     {
    //       name: "Petrochemical",
    //       description:
    //         "WISE-iFactory Industry Focus | 1 iFactory Electronics Manufacturing",
    //       date: "2023/04/14",
    //       src: !isTC ? pic01 : picTC01,
    //       url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-1-ifactory-electronics-en?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXdNv3estI.link",
    //       isOuterLink: true,
    //       category: t("intelligent-automation.video"),
    //     },
    //   ],
    // },
  ] as Resource[];

  const downSmResourcesData = [
    // {
    //   page: 1,
    //   infos: [
    //     {
    //       name: "Petrochemical",
    //       description:
    //         "WISE-iFactory Industry Focus | 1 iFactory Electronics Manufacturing",
    //       date: "2023/04/14",
    //       src: !isTC ? pic01 : picTC01,
    //       url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-1-ifactory-electronics-en?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXdNv3estI.link",
    //       isOuterLink: true,
    //       category: t("intelligent-automation.video"),
    //     },
    //   ],
    // },
  ] as Resource[];

  const resourcesTC = [
    {
      page: 1,
      infos: [
        {
          name: "Petrochemical",
          description: "WISE-iFactory 產業特輯 | 8 製鞋產業數位轉型新能量",
          category: t("intelligent-automation.video"),
          date: "2023/04/14",
          src: picTC01,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-8-ifactory-footwear-tc?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXi5Ul4vMU.link",
          isOuterLink: true,
        },
        {
          name: "Petrochemical",
          description:
            "WISE-iFactory 產業特輯 | 9 WISE-iFactory 製鞋產業應用套餐 - 製鞋效率全面進化",
          category: t("intelligent-automation.video"),
          date: "2023/04/14",
          src: picTC02,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-9-ifactory-footwear-tc?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXjA91QakY.link",
          isOuterLink: true,
        },
        {
          name: "Petrochemical",
          description:
            "WISE-iFactory 產業特輯 | 10 WISE-iFactory x 誠睿自動化 - 製鞋產業應用案例分享",
          category: t("intelligent-automation.video"),
          date: "2023/04/14",
          src: picTC03,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-10-ifactory-footwear-tc?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXjG3XBccE.link",
          isOuterLink: true,
        },
      ],
    },
  ];

  const mdToSmResourcesDataTC = [
    {
      page: 1,
      infos: [
        {
          name: "Petrochemical",
          description: "WISE-iFactory 產業特輯 | 8 製鞋產業數位轉型新能量",
          category: t("intelligent-automation.video"),
          date: "2023/04/14",
          src: picTC01,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-8-ifactory-footwear-tc?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXi5Ul4vMU.link",
          isOuterLink: true,
        },
        {
          name: "Petrochemical",
          description:
            "WISE-iFactory 產業特輯 | 9 WISE-iFactory 製鞋產業應用套餐 - 製鞋效率全面進化",
          category: t("intelligent-automation.video"),
          date: "2023/04/14",
          src: picTC02,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-9-ifactory-footwear-tc?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXjA91QakY.link",
          isOuterLink: true,
        },
      ],
    },
    {
      page: 2,
      infos: [
        {
          name: "Petrochemical",
          description:
            "WISE-iFactory 產業特輯 | 10 WISE-iFactory x 誠睿自動化 - 製鞋產業應用案例分享",
          category: t("intelligent-automation.video"),
          date: "2023/04/14",
          src: picTC03,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-10-ifactory-footwear-tc?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXjG3XBccE.link",
          isOuterLink: true,
        },
      ],
    },
  ];

  const downSmResourcesDataTC = [
    {
      page: 1,
      infos: [
        {
          name: "Petrochemical",
          description: "WISE-iFactory 產業特輯 | 8 製鞋產業數位轉型新能量",
          category: t("intelligent-automation.video"),
          date: "2023/04/14",
          src: picTC01,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-8-ifactory-footwear-tc?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXi5Ul4vMU.link",
          isOuterLink: true,
        },
        {
          name: "Petrochemical",
          description:
            "WISE-iFactory 產業特輯 | 9 WISE-iFactory 製鞋產業應用套餐 - 製鞋效率全面進化",
          category: t("intelligent-automation.video"),
          date: "2023/04/14",
          src: picTC02,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-9-ifactory-footwear-tc?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXjA91QakY.link",
          isOuterLink: true,
        },
        {
          name: "Petrochemical",
          description:
            "WISE-iFactory 產業特輯 | 10 WISE-iFactory x 誠睿自動化 - 製鞋產業應用案例分享",
          category: t("intelligent-automation.video"),
          date: "2023/04/14",
          src: picTC03,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-10-ifactory-footwear-tc?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXjG3XBccE.link",
          isOuterLink: true,
        },
      ],
    },
  ];

  if (isTC) {
    return matchesDownSm
      ? downSmResourcesDataTC
      : matchesMdToSm
      ? mdToSmResourcesDataTC
      : resourcesTC;
  } else {
    return matchesDownSm
      ? downSmResourcesData
      : matchesMdToSm
      ? mdToSmResourcesData
      : resources;
  }
};

export { useResourcesData };
