import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  BoxProps,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  styled,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import bannerAnewTechPicture from "./images/img_banner_find_a_partner.png";
import worldmapPicture from "./images/img_partner_worldmap.png";
import scrollPicture from "./images/scrollBtn.png";
import scrollTCPicture from "./images/scrollbtn_TC_img.png";

import { useImageUtils } from "../../../hooks";

import { useInfoCardsData } from "./data/infoCards";

import { InfoCard } from "../../../components/partners/InfoCard";
import { PartnerCard } from "../../../components/partners/PartnerCard";
import { usePartnersData } from "./data/partners";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation, Trans } from "react-i18next";
import i18n from "i18next";

import "./mapStyle/ol.css";
import "./mapStyle/map.css";

import { Feature, Map, MapBrowserEvent, View } from "ol";
import { OSM } from "ol/source";
import { Tile as TileLayer } from "ol/layer";
import { Control, Attribution, defaults as defaultControls } from "ol/control";
import { Point } from "ol/geom";
import { fromLonLat, Projection, transform } from "ol/proj";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";
import location from "./images/location.png";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { defaults, Modify } from "ol/interaction";
import ImageLayer from "ol/layer/Image";
import Static from "ol/source/ImageStatic";
import { getCenter } from "ol/extent";

import northenAsiaLocationImg from "./images/northen-asia-location.png";
import aseanLocationImg from "./images/asean-location.png";
import easternLocationImg from "./images/eastern-europe-location.png";
import southAmericaImg from "./images/south-america-location.png";
import northAmericaImg from "./images/america-location.png";

const ScrollBtnBox = styled(Box)<BoxProps>({
  "@keyframes scrollBtnBox": {
    from: {
      transform: "translate(0)",
    },
    to: {
      transform: "translateY(20px)",
    },
  },
  animation: "scrollBtnBox 0.78s infinite ease-in-out alternate",
});

export const FindingPartner = () => {
  const { selectImage } = useImageUtils();

  enum CursorStyle {
    default = "default",
    pointer = "pointer",
  }

  const infoCards = useInfoCardsData();
  const partners = usePartnersData();

  const firstBlockRef = useRef<HTMLElement>(null);

  const handleChangeCursor = (
    event: React.MouseEvent<HTMLDivElement>,
    cursorStyle: CursorStyle
  ) => {
    (event.target as HTMLElement).style.cursor = cursorStyle;
  };

  const handleNavigateFirstBlock = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    const firstRef = firstBlockRef!.current;
    if (!firstRef) return;
    firstRef.scrollIntoView({ behavior: "smooth" });
  };

  const { t } = useTranslation();

  const locationSelectItems = [
    {
      key: "isNull",
      text: "選擇地區",
      value: "",
      ariaLable: "None",
      src: northenAsiaLocationImg,
      coords: [9360336.068839777, -1101577.5502285399],
    },
    {
      key: "ASEAN",
      text: t("partners.asean"),
      value: "ASEAN",
      ariaLabel: "ASEAN",
      src: aseanLocationImg,
      coords: [7963915.746917672, -4228947.961837035],
    },
    {
      key: "EasternEurope",
      text: t("partners.eastern-europe"),
      value: "EasternEurope",
      ariaLabel: "EasternEurope",
      src: easternLocationImg,
      coords: [465065.1077316776, 1399623.9679594263],
    },
    {
      key: "NorthernAsia",
      text: t("partners.northern-asia"),
      value: "NorthernAsia",
      ariaLabel: "NorthernAsia",
      src: northenAsiaLocationImg,
      coords: [9360336.068839777, -1101577.5502285399],
    },
    {
      key: "SouthAmerica",
      text: t("partners.south-america"),
      value: "SouthAmerica",
      ariaLabel: "SouthAmerica",
      src: southAmericaImg,
      coords: [-5817833.043583142, -6251376.5029935725],
    },
  ];

  const locationMapItems = [
    {
      key: "ASEAN",
      text: t("partners.asean"),
      value: "ASEAN",
      ariaLabel: "ASEAN",
      src: aseanLocationImg,
      coords: [7963915.746917672, -4228947.961837035],
    },
    {
      key: "EasternEurope",
      text: t("partners.eastern-europe"),
      value: "EasternEurope",
      ariaLabel: "EasternEurope",
      src: easternLocationImg,
      coords: [465065.1077316776, 1399623.9679594263],
    },
    {
      key: "NorthernAsia",
      text: t("partners.northern-asia"),
      value: "NorthernAsia",
      ariaLabel: "NorthernAsia",
      src: northenAsiaLocationImg,
      coords: [9360336.068839777, -1101577.5502285399],
    },
    {
      key: "SouthAmerica",
      text: t("partners.south-america"),
      value: "SouthAmerica",
      ariaLabel: "SouthAmerica",
      src: southAmericaImg,
      coords: [-5817833.043583142, -6251376.5029935725],
    },
    {
      key: "NorthAmerica",
      text: t("partners.north-america"),
      value: "",
      ariaLabel: "NorthAmerica",
      src: northAmericaImg,
      coords: [-9662554.299980117, -1040466.6005267184],
    },
  ];

  const [filteredPartners, setFilterPartners] = useState(partners);

  useEffect(() => {
    setFilterPartners(partners);
  }, [i18n.language]);

  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");

  const handleFilterRegion = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputText = e.target.value;
    setSelectedRegion(inputText);
  };

  const handleFilterLocation = (e: SelectChangeEvent<string>) => {
    const inputText = e.target.value;
    setSelectedLocation(inputText);
  };

  useEffect(() => {
    let tempPartners = partners.filter((partner) => {
      if (!selectedLocation) {
        return (
          partner.description.includes(selectedRegion) ||
          partner.name.includes(selectedRegion) ||
          partner.region.includes(selectedRegion)
        );
      } else {
        return (
          (partner.description.includes(selectedRegion) ||
            partner.name.includes(selectedRegion) ||
            partner.region.includes(selectedRegion)) &&
          partner.location === selectedLocation
        );
      }
    });

    setFilterPartners(tempPartners);
  }, [selectedRegion, selectedLocation]);

  const [map, setMap] = useState<Map>();
  const mapElement = useRef<HTMLElement>();
  const mapRef = useRef<Map>();
  // mapRef.current = map;

  // set map feature
  useEffect(() => {
    const osmLayer = new TileLayer({
      preload: Infinity,
      source: new OSM(),
    });

    const createLocationFeature = (
      location: string,
      src: string,
      coords: number[]
    ) => {
      let locationFeature = new Feature({
        geometry: new Point(transform(coords, "EPSG:4326", "EPSG:4326")),
      });
      locationFeature.setStyle(
        new Style({
          image: new Icon({
            crossOrigin: "anonymous",
            src: src,
            scale: 0.2,
            anchorOrigin: "bottom-left",
          }),
        })
      );

      locationFeature.set("selectedLocation", location);
      return locationFeature;
    };

    const locationFeatures = locationMapItems.map((feautureTextObj) =>
      createLocationFeature(
        feautureTextObj.value,
        feautureTextObj.src,
        feautureTextObj.coords
      )
    );

    const vectorSource = new VectorSource({
      features: locationFeatures,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      zIndex: 20,
    });

    const extent = [0, 0, 1280, 720];
    const pextent = [
      -17532520.742040105, -11238795.347461605, 17532520.742040105,
      11238795.347461605,
    ];

    const projection = new Projection({
      code: "xkcd-image",
      units: "pixels",
      extent: pextent,
    });

    const imageLayer = new ImageLayer({
      source: new Static({
        url: worldmapPicture,
        // projection: "EPSG:4326",
        imageExtent: pextent,
      }),
      opacity: 1,
    });

    if (map === undefined) {
      const initialMap = new Map({
        target: "map",
        layers: [vectorLayer, imageLayer],
        view: new View({
          // projection: projection,
          // center: getCenter(extent),
          center: [0, 0],
          zoom: 0,
          minZoom: 0,
          maxZoom: 10,
        }),
        controls: defaultControls({ attribution: false, zoom: false }),
        interactions: defaults({
          altShiftDragRotate: false,
          doubleClickZoom: false,
          mouseWheelZoom: false,
          keyboard: false,
          shiftDragZoom: false,
          dragPan: false,
          pinchRotate: false,
          pinchZoom: false,
        }),
      });
      setMap(initialMap);
    }
  }, []);

  // map is init
  useEffect(() => {
    if (map) {
      map.on("pointermove", function (e) {
        const pixel = map.getEventPixel(e.originalEvent);
        const hit = map.hasFeatureAtPixel(pixel);
        if (map.getTargetElement() && hit) {
          map.getTargetElement().style.cursor = hit ? "pointer" : "";
        } else {
          map.getTargetElement().style.cursor = "";
        }
      });
      map?.on("click", (event: MapBrowserEvent<any>) => {
        const locationFeature = map.forEachFeatureAtPixel(
          event.pixel,
          function (feature) {
            if (!feature) return undefined;
            return feature?.get("selectedLocation");
          }
        );
        if (locationFeature) {
          setSelectedLocation(locationFeature);
        } else {
          setSelectedLocation("");
        }
      });
    }
  }, [map]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          background: `radial-gradient(73.19% 73.19% at 50% 26.81%, #000000 0%, rgba(0, 0, 0, 0) 100%), url(${selectImage(
            { default: bannerAnewTechPicture }
          )}), #000000`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            maxWidth: { md: 780, sm: 532, xs: 328 },
            marginTop: { xs: "20vh" },
          }}
        >
          <Typography
            variant="h2"
            textAlign="center"
            fontWeight="900"
            fontSize="96"
            color="#ffffff"
            sx={{ fontSize: { md: "48px", xs: "28px" } }}
          >
            <Trans
              i18nKey="partners.banner-title"
              components={{ br: <br /> }}
            />
          </Typography>
          <Typography
            textAlign="center"
            fontWeight="400"
            color="#ffffff"
            sx={{ marginTop: "20px", fontSize: { md: "20px", xs: "16px" } }}
          >
            {t("partners.banner-description")}
          </Typography>
        </Box>
        <ScrollBtnBox sx={{ zIndex: 50, margin: "20px", padding: "8px 4px" }}>
          <div
            onMouseEnter={(event) =>
              handleChangeCursor(event, CursorStyle.pointer)
            }
            onClick={(event) => handleNavigateFirstBlock(event)}
          >
            <img
              src={i18n.language !== "zh-TW" ? scrollPicture : scrollTCPicture}
              style={{ maxWidth: "64px" }}
              alt="scroll-button"
            />
          </div>
        </ScrollBtnBox>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        ref={firstBlockRef as React.RefObject<HTMLDivElement>}
      >
        <Box
          sx={{ maxWidth: { md: 780, sm: 532, xs: 328 }, marginTop: "80px" }}
        >
          <Typography
            textAlign="center"
            variant="h3"
            fontWeight="700"
            sx={{ fontSize: { md: "48px", xs: "28px" } }}
          >
            <Trans
              i18nKey={"partners.reach-out-to-reliable-wise-ifactory-partners"}
              components={{ br: <br /> }}
            />
          </Typography>
          <Typography
            textAlign="center"
            color={"GrayText"}
            sx={{ marginTop: "20px" }}
          >
            {t("partners.main-description")}
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: { lg: 1180, md: 768, sm: 716, xs: 328 },
            marginTop: { md: "96px", xs: "20px" },
          }}
        >
          <Typography
            textAlign="center"
            variant="h6"
            fontWeight="700"
            fontSize={"2rem"}
            lineHeight={"3.25rem"}
            display={"flex"}
            justifyContent={"center"}
            position={"relative"}
          >
            <span
              style={{
                backgroundColor: "#fff",
                // width: "114px",
                padding: "0 20px",
                fontSize: "1.5rem",
              }}
            >
              {t("partners.why-work-with-wise-ifactory-domain-partners")}
            </span>
            <div
              style={{
                width: "100%",
                border: "1px dashed #E1E1E1",
                position: "absolute",
                top: "50%",
                zIndex: -1,
              }}
            ></div>
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: {
                lg: "center",
                md: "flex-start",
                sm: "center",
                xs: "center",
              },
              alignItems: "flex-start",
              marginTop: "20px",
              gap: "20px",
              flexWrap: { md: "wrap", sm: "wrap", xs: "wrap" },
              maxWidth: { lg: 1180, md: 716, sm: 716, xs: 328 },
            }}
          >
            {infoCards.map((info) => (
              <InfoCard
                cardInfo={info}
                listStyle="none"
                textColor="green"
              ></InfoCard>
            ))}
          </Box>

          <Typography
            textAlign="center"
            variant="h6"
            fontWeight="700"
            fontSize={"2rem"}
            lineHeight={"3.25rem"}
            display={"flex"}
            justifyContent={"center"}
            position={"relative"}
            padding={"40px 0"}
          >
            <div
              style={{
                width: "100%",
                border: "1px dashed #E1E1E1",
                position: "absolute",
                top: "50%",
                zIndex: -1,
              }}
            ></div>
          </Typography>
        </Box>

        <Box
          sx={{ maxWidth: { md: 780, sm: 532, xs: 328 }, marginTop: "40px" }}
        >
          <Typography
            textAlign="center"
            variant="h3"
            fontWeight="700"
            sx={{ fontSize: { md: "48px", xs: "28px" } }}
          >
            {t("partners.partner-finder")}
          </Typography>
          <Typography
            textAlign="center"
            color={"GrayText"}
            sx={{ marginTop: "20px" }}
          >
            {t("partners.wise-ifactory-ecosystem-and-business-development")}
          </Typography>
        </Box>

        {/* Map Filter */}

        <Box
          sx={{
            width: "100%",
            // height: "663px",
            maxWidth: { lg: 1180, md: 780, sm: 532, xs: 328 },
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            id="map"
            style={{ width: "100%", height: "660px" }}
            // ref={mapElement}
            className="map-container"
          ></div>
          {/* <img src={worldmapPicture} style={{ maxWidth: "100%" }} alt="map" /> */}
        </Box>

        {/* Text and Select Filter */}
        <Box
          sx={{
            width: "100%",
            maxWidth: { lg: 1180, md: 780, sm: 532, xs: 328 },
            marginTop: "40px",
            display: "flex",
            gap: "20px",
            justifyContent: "center",
          }}
        >
          <FormControl
            variant="standard"
            sx={{ width: "100%", maxWidth: { md: 280 } }}
          >
            <OutlinedInput
              id="search-region"
              sx={{ width: "100%", height: { md: 40 } }}
              value={selectedRegion}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              onChange={(e) => handleFilterRegion(e)}
            />
          </FormControl>
          <FormControl sx={{ width: "100%", maxWidth: { md: 280 } }}>
            <Select
              sx={{ width: "100%", height: { md: 40 } }}
              id="select-location"
              value={selectedLocation}
              startAdornment={
                <InputAdornment position="start">
                  <Typography variant="body1" fontWeight="700" fontSize="16px">
                    {t("partners.location")}
                  </Typography>
                </InputAdornment>
              }
              onChange={(e) => handleFilterLocation(e)}
            >
              {locationSelectItems.map((location) => (
                <MenuItem value={location.value} key={location.key}>
                  {!location.value ? <em>{location.text}</em> : location.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            width: "100%",
            maxWidth: { lg: 1180, md: 780, sm: 532, xs: 328 },
            marginTop: "20px",
            display: "flex",
            justifyContent: { md: "flex-start", xs: "center" },
            gap: "20px",
            flexWrap: "wrap",
          }}
        >
          {filteredPartners.map((partner) => {
            return <PartnerCard partner={partner} key={partner.name} />;
          })}
        </Box>
        <Box
          sx={{
            width: "100%",
            maxWidth: { lg: 1180, md: 780, sm: 532, xs: 328 },
            height: "32px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            padding: "20px 0 40px 0",
          }}
        >
          <div
            style={{
              width: "100%",
              border: "1px dashed #E1E1E1",
              top: "50%",
              zIndex: -1,
            }}
          ></div>
        </Box>
      </div>
    </div>
  );
};
