import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import i18n from "i18next";

import pic01 from "../images/01.png";
import picTC01 from "../images/01tc.png";
// import pic02 from "../images/02.png";
// import picTC02 from "../images/01tc.png";
// import pic03 from "../images/03.png";
// import picTC03 from "../images/03tc.png";

const useResourcesData = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesMdToSm = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const isTC = i18n.language === "zh-TW";
  const resources = [
    {
      page: 1,
      infos: [
        {
          name: "Petrochemical",
          description:
            "WISE-iFactory Industry Focus | 1 iFactory Electronics Manufacturing",
          date: "2023/04/14",
          src: !isTC ? pic01 : picTC01,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-1-ifactory-electronics-en?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXdNv3estI.link",
          isOuterLink: true,
          category: t("intelligent-automation.video"),
        },
      ],
    },
  ];

  const mdToSmResourcesData = [
    {
      page: 1,
      infos: [
        {
          name: "Petrochemical",
          description:
            "WISE-iFactory Industry Focus | 1 iFactory Electronics Manufacturing",
          date: "2023/04/14",
          src: !isTC ? pic01 : picTC01,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-1-ifactory-electronics-en?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXdNv3estI.link",
          isOuterLink: true,
          category: t("intelligent-automation.video"),
        },
      ],
    },
  ];

  const downSmResourcesData = [
    {
      page: 1,
      infos: [
        {
          name: "Petrochemical",
          description:
            "WISE-iFactory Industry Focus | 1 iFactory Electronics Manufacturing",
          date: "2023/04/14",
          src: !isTC ? pic01 : picTC01,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-1-ifactory-electronics-en?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXdNv3estI.link",
          isOuterLink: true,
          category: t("intelligent-automation.video"),
        },
      ],
    },
  ];

  const resourcesTC = [
    {
      page: 1,
      infos: [
        {
          name: "Petrochemical",
          description:
            "WISE-iFactory 產業特輯 | 1 iFactory 電子製造數位轉型解決方案",
          category: "影片",
          date: "2023/04/14",
          src: picTC01,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-1-ifactory-electronics-tc?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXdg4fycaA.link",
          isOuterLink: true,
        },
      ],
    },
  ];

  const mdToSmResourcesDataTC = [
    {
      page: 1,
      infos: [
        {
          name: "Petrochemical",
          description:
            "WISE-iFactory 產業特輯 | 1 iFactory 電子製造數位轉型解決方案",
          date: "2023/04/14",
          src: picTC01,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-1-ifactory-electronics-tc?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXdg4fycaA.link",
          isOuterLink: true,
          category: t("intelligent-automation.video"),
        },
      ],
    },
  ];

  const downSmResourcesDataTC = [
    {
      page: 1,
      infos: [
        {
          name: "Petrochemical",
          description:
            "WISE-iFactory 產業特輯 | 1 iFactory 電子製造數位轉型解決方案",
          date: "2023/04/14",
          src: picTC01,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-1-ifactory-electronics-tc?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXdg4fycaA.link",
          isOuterLink: true,
          category: t("intelligent-automation.video"),
        },
      ],
    },
  ];

  if (isTC) {
    return matchesDownSm
      ? downSmResourcesDataTC
      : matchesMdToSm
      ? mdToSmResourcesDataTC
      : resourcesTC;
  } else {
    return matchesDownSm
      ? downSmResourcesData
      : matchesMdToSm
      ? mdToSmResourcesData
      : resources;
  }
};

export { useResourcesData };
