import { useTranslation } from "react-i18next";
import { AnchorStep } from "../../../../types/index";

const useAnchorStepsData = () => {
  const { t } = useTranslation();
  const stepCards: AnchorStep[] = [
    {
      // stepName: t("solutions.ai.overview"),
      stepName: 'Overview',
      elementId: "Overview",
    },
    {
      // stepName: t("solutions.common.faqs"),
      stepName: 'FAQs',
      elementId: "FAQs",
    },
    {
      // stepName: t('solutions.common.applications'),
      stepName: 'Applications',
      elementId: "Applications",
    },
  ];
  return stepCards;
};

export { useAnchorStepsData };
