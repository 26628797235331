import { Box, Button, Typography } from "@mui/material";
import React, { forwardRef } from "react";
import type { AnchorStep } from "../../types/index";

export type Ref = HTMLElement;

interface IAnchorBarProps {
  visible: boolean;
  isSticky: boolean;
  stepCards: AnchorStep[];
  ref: Ref;
}

const handleNavigateView = (elementId: string) => {
  const scrolledView = document.querySelector(`#${elementId}`);
  if (!scrolledView) return;
  const scrollDown = scrolledView.getBoundingClientRect().top > 0;
  window.scrollBy({
    top: scrollDown
      ? scrolledView.getBoundingClientRect().top - 80
      : scrolledView.getBoundingClientRect().top - 150,
    behavior: "smooth",
  });
};

const AnchorBar = forwardRef<Ref, IAnchorBarProps>(
  ({ visible, isSticky, stepCards }, ref) => {
    return (
      <Box
        ref={ref}
        sx={{
          width: "100%",
          minHeight: "73px",
          position: "sticky",
          top: visible ? 72 : -1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 100,
          transition: "all 0.1s",
        }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            overflowY: "hidden",
            display: "flex",
            justifyContent: { md: "center", sm: "center", xs: "center" },
            alignItems: "center",
            flexWrap: { sm: "wrap", xs: "nowrap" },
            flexShrink: { sm: 1, xs: 0 },
            gap: "20px",
            backgroundColor: "#fff",
            height: "100%",
            width: { sm: "90%", xs: "100%" },
            top: -10,
            padding: "0 20px",
            maxWidth: "1180px",
            boxShadow: "0px 2px 8px rgba(50, 50, 51, 0.5)",
            transition: "all 0.5s",
            ...(isSticky && {
              width: "100%",
              maxWidth: "100%",
              margin: 0,
              boxShadow: "0px 2px 8px -2px rgba(50, 50, 51, 0.5)",
            }),
          }}
        >
          {stepCards.map((step, index) => (
            <Button
              sx={{
                display: "flex",
                flexDirection: { sm: "column", xs: "row" },
                alignItems: "center",
                justifyContent: "center",
                minHeight: "73px",
                height: "100%",
                width: "100%",
                maxWidth: { sm: "160px", xs: "111px" },
                minWidth: { sm: 0, xs: "111px" },
                textTransform: "none",
              }}
              onClick={() => handleNavigateView(step.elementId)}
            >
              <Box
                sx={{
                  minWidth: "20px",
                  minHeight: "20px",
                  width: "20px",
                  height: "20px",
                  backgroundColor: "#89898A",
                  borderRadius: "4px",
                  color: "#fff",
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: 700,
                  lineHeight: "20px",
                  marginRight: { sm: "0px", xs: "10px" },
                }}
              >
                {index + 1}
              </Box>
              <Typography
                sx={{ textTransform: "none" }}
                textAlign="center"
                color={"GrayText"}
                fontWeight={700}
              >
                {step.stepName}
              </Typography>
            </Button>
          ))}
        </Box>
      </Box>
    );
  }
);

export default AnchorBar;
