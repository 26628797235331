import { createContext, useContext, useEffect, useMemo, useState } from "react";

function checkAVIFFeature(callback: (result: boolean) => void) {
  var img = new Image();
  img.src =
    "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=";
  img.onload = function () {
    var result = img.width > 0 && img.height > 0;
    callback(result);
  };
  img.onerror = function () {
    callback(false);
  };
}

function checkWebpFeature(callback: (result: boolean) => void) {
  var img = new Image();
  img.onload = function () {
    var result = img.width > 0 && img.height > 0;
    callback(result);
  };
  img.onerror = function () {
    callback(false);
  };
  img.src =
    "data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==";
}

export interface ImageUtilsContextType {
  webp: boolean;
  avif: boolean;
  prefer: "avif" | "webp" | null;
  selectImage: (images: {
    avif?: string;
    webp?: string;
    default: string;
  }) => string;
}

export const ImageUtilsContext = createContext<ImageUtilsContextType>({
  webp: false,
  avif: false,
  prefer: null,
  selectImage: null!,
});

export const ImageUtilsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [avifSupported, setAVIFSupported] = useState<boolean | null>(null);
  const [webpSupported, setWebpSupported] = useState<boolean | null>(null);
  const value = useMemo<ImageUtilsContextType>(
    () => ({
      avif: avifSupported || false,
      webp: webpSupported || false,
      prefer: avifSupported ? "avif" : webpSupported ? "webp" : null,
      selectImage: (images) =>
        images.avif && avifSupported
          ? images.avif
          : images.webp && webpSupported
          ? images.webp
          : images.default,
    }),
    [avifSupported, webpSupported]
  );

  useEffect(() => {
    checkAVIFFeature((result) => setAVIFSupported(result));
    checkWebpFeature((result) => setWebpSupported(result));
  });

  if (avifSupported === null || webpSupported === null) {
    return <></>;
  }

  return (
    <ImageUtilsContext.Provider value={value}>
      {children}
    </ImageUtilsContext.Provider>
  );
};

export const useImageUtils = () => {
  return useContext(ImageUtilsContext);
};
