import { useTranslation } from "react-i18next";

const useInfoCardsData = () => {
  const { t } = useTranslation();
  const infoCards = [
    {
      step: "01",
      title: t("footwear.labor-issues"),
      lists: [
        {
          content: t("footwear.technical-knowledge-gaps"),
        },
        {
          content: t("footwear.labor-intensive-processes"),
        },
        {
          content: t("footwear.different-equipment-types"),
        },
      ],
    },
    {
      step: "02",
      title: t("footwear.difficulties-in-production"),
      lists: [
        {
          content: t("footwear.small-runs-of-diversified-production"),
        },
        {
          content: t("footwear.schedules-can-be-hard-to-integrate"),
        },
      ],
    },

    {
      step: "03",
      title: t("footwear.high-failure-rates"),
      lists: [
        {
          content: t(
            "footwear.difficulties-controlling-temperature-and-humidity"
          ),
        },
        {
          content: t("footwear.large-chemical-variabilities"),
        },
        {
          content: t(
            "footwear.difficulties-controlling-quality-because-machines-are-controlled-manually"
          ),
        },
      ],
    },
    {
      step: "04",
      title: t("footwear.high-technical-control-levels"),
      lists: [
        {
          content: t("footwear.processing-quality-is-highly-dependent"),
        },
        {
          content: t("footwear.the-knowledge-gap-between-senior-and-junior"),
        },
        {
          content: t("footwear.higher-costs-and-lower-profits"),
        },
      ],
    },
    {
      step: "05",
      title: t("footwear.barriers-to-machine-management"),
      lists: [
        {
          content: t("footwear.high-failure-rates-that-are-hard-to-detect"),
        },
        {
          content: t("footwear.lack-of-detailed-equipment-data"),
        },
        {
          content: t("footwear.difficulty-sharing-information-among-different"),
        },
      ],
    },
  ];

  return infoCards;
};

export { useInfoCardsData };
