import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React from "react";

import { Industry } from "./types/Industry";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IdustryProps {
  industry: Industry;
  index: number;
  industries: Industry[];
}

const IdustryCard: React.FC<IdustryProps> = ({
  industry,
  index,
  industries,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const goToPage = (url: string | undefined, isOuterLink: boolean) => {
    if (!url) return;
    if (isOuterLink) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };
  return (
    <Box
      className="industry-card"
      sx={{
        borderRadius: { sm: "none", xs: "4px" },
        border: { sm: "none", xs: "1px solid #C8C8C8" },
        boxSizing: "border-box",
        padding: { sm: 0, xs: "16px" },
        display: "flex",
        flexDirection: { sm: "column", xs: "row" },
        alignItems: { sm: "center", xs: "flex-start" },
        gap: { md: "20px", sm: "20px", xs: "16px" },
        width: "100%",
        maxWidth: { md: "380px", sm: "348px", xs: "328px" },
        height: { md: "auto", sm: "auto", xs: "200px" },
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          height: "146.25px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: { md: "4px", sm: "4px", xs: "4px" },
          }}
        >
          <Box
            sx={{
              width: "100%",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-center",
              padding: { sm: "0 20px", xs: "0" },
              borderLeft: {
                sm: "4px solid transparent",
                xs: "0px solid transparent",
              },
              borderImage: {
                sm: "linear-gradient(135deg, #008CD6 0%, #00CC66 100%) 4",
                xs: "none",
              },
              // height: "24px",
              maxHeight: "118.25px",
            }}
          >
            <Typography
              fontWeight="700"
              sx={{
                fontSize: "16px",
              }}
            >
              {industry.title}
            </Typography>
          </Box>
          <Typography
            sx={{
              padding: { md: "0 20px", sm: "0 20px", xs: "0" },
              color: "#89898A",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "16px",
              overflowX: "hidden",
              "-webkit-line-clamp": {
                md: industry.title.length > 34 ? "4" : "5",
                sm: industry.title.length > 30 ? "3" : "4",
                xs: industry.title.length > 30 ? "4" : "5",
              },
              textOverflow: "ellipsis",
              display: "-webkit-box",
              "-webkit-box-orient": "vertical",
              overflow: "hidden",
              height: "auto",
              minHeight: "80px",
            }}
          >
            {industry.description}
          </Typography>
        </Box>

        <Box
          sx={{
            width: "100%",
            padding: { sm: "0 20px", md: "0" },
            boxSizing: "border-box",
          }}
        >
          <Button
            // color={!industry.url ? "rgba(255, 255, 255, 0)" : "#008CD6"}
            sx={{
              color: !industry.url ? "rgba(255, 255, 255, 0)" : "#008CD6",
              fontWeight: 700,
              fontSize: { md: "16px", sm: "16px", xs: "14px" },
              textTransform: "none",
              padding: { md: "0 20px", sm: "0", xs: "0" },
              margin: { sm: 0, xs: "14px 0 0 0" },
            }}
            onClick={() => goToPage(industry.url ?? "", !!industry.isOuterLink)}
            disabled={!industry.url}
          >
            {industry.url !== "" ? (
              <>
                <Typography color="#008CD6" fontWeight="700">
                  {t("landing-page.common.learn-more")}
                </Typography>
                <ChevronRightIcon style={{ color: "#008CD6" }} />
              </>
            ) : (
              <>
                <Typography color="C8C8C8" fontWeight="700">
                  {t("landing-page.headers.coming-soon")}
                </Typography>
                <ChevronRightIcon style={{ color: "#C8C8C8" }} />
              </>
            )}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: { md: "auto", sm: "100%", xs: "88px" },
        }}
      >
        {matchesDownSm ? (
          <Box
            sx={{
              width: "100%",
              maxWidth: { sm: "295px", xs: "88px", height: "88px" },
              backgroundImage: `url(${industry.src})`,
              backgroundSize: "cover",
              backgroundOrigin: "center center",
            }}
          ></Box>
        ) : (
          <img src={industry.src} width={"100%"} alt={industry.title} />
        )}
      </Box>
    </Box>
  );
};

export default IdustryCard;
