enum infoTypes {
  packages = "packages",
  apps = "apps",
  transformative = "transformative",
}

type packages = {
  title: string | JSX.Element;
  description: string;
  src?: string;
  tag?: string;
  titleNoWrap?: boolean;
  url?: string;
  isOuterLink?: boolean;
};

type apps = {
  title: string | JSX.Element;
  description: string;
  src: string;
  tag?: string;
  titleNoWrap?: boolean;
  url?: string;
  isOuterLink?: boolean;
};

type transformative = {
  title: string | JSX.Element;
  description: string;
  src: string[];
  tag?: string;
  titleNoWrap?: boolean;
  url?: string;
  isOuterLink?: boolean;
};

type Informations<T> = T extends infoTypes.packages
  ? packages[]
  : T extends infoTypes.apps
    ? apps[]
    : T extends infoTypes.transformative
      ? transformative[]
      : never;

type Architecture = {
  title: string;
  description: string;
  types: infoTypes;
  informations: Informations<infoTypes>;
};

type Architectures = Architecture[];

export type { Architectures, Architecture, packages, apps, transformative };
export { infoTypes };
