import { useTranslation } from "react-i18next";

type Question = {
  text: string;
};

const useQuestionsData = (): Question[] => {
  const { t } = useTranslation();
  let questions = [
    {
      text: t("digital-transformation.question-one"),
    },
    {
      text: t('digital-transformation.question-two'),
    },
    {
      text: t('digital-transformation.question-three'),
    },
  ];
  return questions;
};

export default useQuestionsData;
