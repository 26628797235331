import Box from "@mui/material/Box";

interface EDMProps {
  src?: string;
}

export const EDM = (props: EDMProps) => {
  const { src } = props;
  return (
    <Box sx={{ padding: "74px 0 0" }}>
      <iframe
        style={{
          height: "calc(100% - 74px)",
          width: "100%",
          position: "absolute",
          border: "none",
        }}
        title="dm"
        src={src ?? "/static/starter-packages/edm/index.html"}
      />
    </Box>
  );
};
