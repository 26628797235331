import { Box, useTheme, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const HomeMain: React.FC = () => {
  const theme = useTheme();
  const queryUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const [readMore, setReadMore] = useState(queryUpMd ? false : false);

  const handleClickReadText = (e: React.MouseEvent<HTMLButtonElement>) => {
    setReadMore((oldMore) => {
      return !oldMore;
    });
  };

  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: { sm: "20px", xs: "16px" },
        width: "100%",
        maxWidth: { md: "780px", sm: "532px", xs: "328px" },
        padding: { md: "0 0 20px 0", sm: "0px 0px 28px 0", xs: "0 0 16px" },
      }}
    >
      <Typography
        textAlign="center"
        variant="h2"
        fontWeight="700"
        sx={{
          fontSize: { md: "48px", sm: "40px", xs: "28px" },
        }}
      >
        {t("home.main-title")}
      </Typography>
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
        }}
      >
        <Typography
          variant="body1"
          fontWeight="400"
          color="#89898A"
          textAlign="center"
          sx={{
            fontSize: { md: "20px", sm: "16px", xs: "16px" },
            "-webkit-line-clamp": {
              md: !readMore ? "3" : "auto",
              sm: !readMore ? "4" : "auto",
              xs: !readMore ? "6" : "auto",
            },
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
          }}
        >
          {t("home.main-description")}
        </Typography>
        <Button
          variant="text"
          sx={{
            textTransform: "none",
            textAlign: "center",
            width: "fix-content",
            whiteSpace: "nowrap",
            color: "#008CD6",
          }}
          onClick={(e) => handleClickReadText(e)}
        >
          {!readMore
            ? t("landing-page.common.read-more")
            : t("landing-page.common.read-less")}
        </Button>
      </Box>
    </Box>
  );
};

export default HomeMain;
