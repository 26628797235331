import { Box, Typography } from "@mui/material";
import React from "react";
import { ColoredTypography } from "../ColoredTypography/ColoredTypography";

interface featuresColoredCardProps {
  features: string[];
}

export const FeaturesColoredCard: React.FC<featuresColoredCardProps> = ({
  features,
}) => {
  return (
    <Box
      sx={{
        width: { md: "350px", xs: "100%" },
        height: {
          sm: "170px",
          xs: "auto",
        },
        background: {
          sm: "linear-gradient(white, white) padding-box, linear-gradient(135deg, #008CD6 0%, #00CC66 100%) border-box",
          xs: "linear-gradient(rgba(235, 241, 252, 1), rgba(235, 241, 252, 1)) padding-box, linear-gradient(135deg, #008CD6 0%, #00CC66 100%) border-box",
        },
        padding: "24px 20px",
        boxSizing: "border-box",
        position: "relative",
        backgroundClip: { sm: "none", xs: "none" },
        border: "4px solid transparent",
        borderRadius: "8px",
      }}
    >
      <Box
        position="absolute"
        sx={{
          width: "103px",
          height: "24px",
          top: "0px",
          left: "10px",
          transform: "translateY(-50%)",
          backgroundColor: { sm: "#fff", xs: "rgba(235, 241, 252, 1)" },
          boxSizing: "border-box",
        }}
      >
        <ColoredTypography
          fontWeight={700}
          padding={"0 20px"}
          textAlign={"center"}
        >
          Features
        </ColoredTypography>
      </Box>
      <ul
        style={{
          color: "#89898A",
          lineHeight: "20px",
          padding: "0 0 0 20px",
          margin: "0px",
          listStyleType: "disc",
        }}
      >
        {features.map((feature) => {
          return (
            <li
              key={feature}
              style={{
                color: "#89898A",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              <Typography
                sx={{
                  color: "#89898A",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {feature}
              </Typography>
            </li>
          );
        })}
      </ul>
    </Box>
  );
};
