import Container from "@mui/material/Container";
import footerLogo from "../images/footer-logo.png";
import { Link, Typography } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        minHeight: "44px",
        padding: "20px 0 0",
        backgroundColor: " #EDEDED",
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: { sm: "nowrap", xs: "wrap" },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={footerLogo}
              style={{ maxWidth: "117px", maxHeight: "24px" }}
              alt="Advantech"
            />
            <Typography
              sx={{ ml: "10pt" }}
              textAlign="center"
              color={"#89898A"}
              fontSize={"12px"}
              fontWeight={400}
              whiteSpace={"nowrap"}
            >
              {`© 1983-${new Date().getFullYear()} Advantech Co., Ltd.`}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              width: { xs: "100%" },
              height: { xs: "24px" },
              margin: { sm: 0, xs: "16px 0" },
            }}
          >
            <Box
              sx={{
                width: "1px",
                height: "24px",
                backgroundColor: "#E1E1E1",
                margin: { sm: "0 20px", xs: "0 16px 0 0" },
              }}
            ></Box>
            <Typography
              sx={{ textDecoration: "none", m: 0 }}
              textAlign="center"
              color={"#89898A"}
              fontSize={"12px"}
              fontWeight={700}
              component={Link}
              href="https://www.advantech.com/en/legal/privacy"
              target="_blank"
            >
              {t("landing-page.footer.privacy-policy")}
            </Typography>
            <Box
              sx={{
                width: "1px",
                height: "24px",
                backgroundColor: "#E1E1E1",
                margin: { sm: "0 20px", xs: "0 0 0 16px" },
              }}
            ></Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
};
