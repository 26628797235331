import { useTranslation } from "react-i18next";
import {
  imgFAQsQ1_1,
  imgFAQsQ1_2,
  imgFAQsQ1_3,
  imgFAQsQ2_1,
  imgFAQsQ2_2,
  imgFAQsQ2_3,
  imgFAQsQ3_1,
  imgFAQsQ3_2,
  imgFAQsQ3_3,
  imgFAQsQ4_1,
  imgFAQsQ4_2,
  imgFAQsQ4_3,
  imgFAQsQ5_1,
  imgFAQsQ5_2,
  imgFAQsQ5_3,
} from "../images";

export const useSolutionsData = () => {
  const { t } = useTranslation();

  let firstIntroData = {
    title: t("intelligent-automation.to-the-plug-in-dashboard"),
    content: t("intelligent-automation.collect-data-from-any-machine"),
    features: [
      t("intelligent-automation.machine-overview"),
      t("intelligent-automation.single-machine-information"),
      t("intelligent-automation.machine-overview-alarm"),
      t("intelligent-automation.single-machine-alarm"),
      t("intelligent-automation.energy-consumption"),
      t("intelligent-automation.production-lead-time"),
    ],
  };

  let firstAnswer = [
    {
      id: "1",
      title: t("intelligent-automation.identify-and-resolve-downtimes"),
      content: t(
        "intelligent-automation.view-and-manage-the-status-of-your-machine-assets"
      ),
      src: [imgFAQsQ1_1, imgFAQsQ1_2, imgFAQsQ1_3],
      reverse: false,
    },
    {
      id: "2",
      title: t("intelligent-automation.performance-visibility"),
      content: t(
        "intelligent-automation.provide-performance-analysis-of-machines-operators"
      ),
      src: [imgFAQsQ1_2, imgFAQsQ1_3],
      reverse: true,
    },
    {
      id: "3",
      title: t("intelligent-automation.real-time-machine-conditions"),
      content: t(
        "intelligent-automation.identify-opportunities-to-streamline-production-processes-reduce-waste"
      ),
      src: [imgFAQsQ1_3],
      reverse: false,
    },
  ];

  let secondIntroData = {
    title: t("intelligent-automation.smart-operations-assistant"),
    content: t(
      "intelligent-automation.using-intelligent-all-in-one-pc-on-the-shop-floor"
    ),
    features: [
      t("intelligent-automation.process-guide"),
      t("intelligent-automation.parameter-guide"),
    ],
  };

  let secondAnswer = [
    {
      id: "1",
      title: t("intelligent-automation.construct-user-knowledge-base"),
      content: t(
        "intelligent-automation.the-process-guide-allows-users-to-search-updated-instructions"
      ),
      src: [imgFAQsQ2_1, imgFAQsQ2_2, imgFAQsQ2_3],
      reverse: false,
    },
    {
      id: "2",
      title: t("intelligent-automation.accurate-machine-setting"),
      content: t(
        "intelligent-automation.the-parameter-guide-instructs-engineers-with-clear-values"
      ),
      src: [imgFAQsQ2_2],
      reverse: true,
    },
    {
      id: "3",
      title: t("intelligent-automation.instant-connection"),
      content: t(
        "intelligent-automation.latest-information-is-always-pushed-from-mes-erp-automatically"
      ),
      src: [imgFAQsQ2_3],
      reverse: false,
    },
  ];

  let thirdIntroData = {
    title: t("intelligent-automation.alarms-and-notifications"),
    content: t(
      "intelligent-automation.alerts-or-notifications-can-be-sent-to-supervisors"
    ),
    features: [
      t("intelligent-automation.plug-in-alarm-dashboard"),
      t("intelligent-automation.operator-event-reporting-interface"),
      t("intelligent-automation.mobile-notification-support"),
    ],
  };

  let thirdAnswer = [
    {
      id: "1",
      title: t("intelligent-automation.custom-alerts-and-notifications"),
      content: t(
        "intelligent-automation.user-can-self-define-the-alerts-bottoms"
      ),
      src: [imgFAQsQ3_1],
      reverse: false,
    },
    {
      id: "2",
      title: t("intelligent-automation.alarms-analysis"),
      content: t(
        "intelligent-automation.generate-alarm-reports-reducing-the-need-for-manual-reporting-and-paper-work"
      ),
      src: [imgFAQsQ3_2],
      reverse: true,
    },
    {
      id: "3",
      title: t("intelligent-automation.mobile-interface-support"),
      content: t(
        "intelligent-automation.management-can-make-more-informed-decisions"
      ),
      src: [imgFAQsQ3_3],
      reverse: false,
    },
  ];

  let fourIntroData = {
    title: t("intelligent-automation.operations-reporting"),
    content: t(
      "intelligent-automation.monitor-the-performance-of-each-production-run-with-touch-screen-tablet-interfaces-operators"
    ),
    features: [
      t("intelligent-automation.work-order"),
      t("intelligent-automation.job-reporting"),
    ],
  };

  let fourAnswer = [
    {
      id: "1",
      title: t("intelligent-automation.empowers-your-operations"),
      content: t(
        "intelligent-automation.empower-your-workforce-with-digital-transformation-driving-better-productivity-traceability-and-efficiency"
      ),
      src: [imgFAQsQ4_1],
      reverse: false,
    },
    {
      id: "2",
      title: t("intelligent-automation.account-for-100-performance"),
      content: t("intelligent-automation.collect-data-from-each-machine"),
      src: [imgFAQsQ4_2],
      reverse: true,
    },
    {
      id: "3",
      title: t("intelligent-automation.improve-precision-and-quality"),
      content: t(
        "intelligent-automation.continuously-monitoring-and-adjusting-parameters-the-system-can-ensure"
      ),
      src: [imgFAQsQ4_3],
      reverse: false,
    },
  ];

  let fiveIntroData = {
    title: t("intelligent-automation.developer-friendly-environment"),
    content: t(
      "intelligent-automation.intelligent-all-in-one-edge-supports-interface-modbus-opc-ua-edgelink-ifactorymqtt-for-machines"
    ),
    features: [
      t("intelligent-automation.customized-dashboard-tools"),
      t("intelligent-automation.quick-assets-allocation-back-end"),
      t("intelligent-automation.future-expansion-apps"),
    ],
  };

  let fiveAnswer = [
    {
      id: "1",
      title: t("intelligent-automation.customized-dashboard-tools"),
      content: t(
        "intelligent-automation.ease-of-use-iot-setting-helps-developer-quick-hands-on-machine-digitalization-enabling-plug-in-visualization-panels"
      ),
      src: [imgFAQsQ5_1],
      reverse: false,
    },
    {
      id: "2",
      title: t("intelligent-automation.quick-asset-allocation"),
      content: t(
        "intelligent-automation.setting-assets-to-operate-within-their-optimal-parameters-quick-assets-allocation-function"
      ),
      src: [imgFAQsQ5_2],
      reverse: true,
    },
    {
      id: "3",
      title: t("intelligent-automation.future-expansion-apps"),
      content: t(
        "intelligent-automation.ifactory-intelligent-all-in-one-edge-stands-as-fundation-of-factory-digitalization"
      ),
      src: [imgFAQsQ5_3],
      reverse: false,
    },
  ];

  let solutions = [
    {
      id: 1,
      ...firstIntroData,
      answerCard: firstAnswer,
    },
    {
      id: 2,
      ...secondIntroData,
      answerCard: secondAnswer,
    },
    {
      id: 3,
      ...thirdIntroData,
      answerCard: thirdAnswer,
    },
    {
      id: 4,
      ...fourIntroData,
      answerCard: fourAnswer,
    },
    {
      id: 5,
      ...fiveIntroData,
      answerCard: fiveAnswer,
    },
  ];

  return solutions;
};
