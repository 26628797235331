import stepPicture01 from "../images/img_progress1_p.png";
import stepPicture01l from "../images/img_progress1_l.png";
import stepPicture02 from "../images/img_progress2_p.png";
import stepPicture02l from "../images/img_progress2_l.png";
import stepPicture03 from "../images/img_progress3_p.png";
import stepPicture03l from "../images/img_progress3_l.png";
import stepPicture04 from "../images/img_progress4_p.png";
import stepPicture04l from "../images/img_progress4_l.png";
import smartSchedulingPic from "../../../../images/smart-scheduling.png";
import enegyIntensivePic from "../../../../images/energy-intensive-machine-optimization.png";
import PHMPic from "../../../../images/PHM.png";

import air101Pic from "../../../../images/img_device_AIR-101.png";
import vibrationPic from "../../../../images/img_device_vibration_sensor.png";

import wise2410Pic from "../../../../images/img_device_WISE-2410.png";
import wise6610Pic from "../../../../images/img_device_WISE-6610.png";
import recognitionPic from "../../../../images/activity-recognition.png";

import acp4010Pic from "../../../../images/img_device_ACP-4010.png";
import ipcamPic from "../../../../images/img_device_IPCAM.png";
import patrolPic from "../../../../images/patrol.png";
import { useTranslation } from "react-i18next";

const useStepCardsData = () => {
  const { t } = useTranslation();
  const stepCards = [
    {
      stepName: t("electronics.forecasting"),
      elementId: "Forecasting",
      src: {
        md: stepPicture01,
        sm: stepPicture01l,
      },
      flexDirection: "row",
      starterPackages: [
        {
          name: t("electronics.smart-scheduling-tab"),
          packages: [
            {
              page: 1,
              title: t("electronics.smart-scheduling"),
              content: (
                <div>
                  {t("electronics.scheduling-allocates")}
                  <ul style={{ paddingInlineStart: "30px", margin: 0 }}>
                    <li>
                      {t("electronics.improved-visibility-and-decision-making")}
                    </li>
                    <li>{t("electronics.increased-efficiency")}</li>
                    <li>{t("electronics.better-resource-utilization")}</li>
                    <li>{t("electronics.greater-than-reduced-costs")}</li>
                  </ul>
                </div>
              ),
              devices: [
                {
                  src: smartSchedulingPic,
                  title: t("products.smart-scheduling"),
                  code: t("products.smart-scheduling-code"),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      stepName: t("electronics.manufacturing"),
      elementId: "Manufacturing",
      src: {
        md: stepPicture02,
        sm: stepPicture02l,
      },
      flexDirection: "row-reverse",
      starterPackages: [
        {
          name: t("electronics.smart-maintenance-tab"),
          packages: [
            {
              page: 1,
              title: t("electronics.rotating-machinery"),
              content: (
                <div>
                  {t("electronics.with-phm-ai-solution")}
                  <ul style={{ paddingInlineStart: "30px", margin: 0 }}>
                    <li>{t("electronics.predict-machine-failure")}</li>
                    <li>{t("electronics.make-machine-status-transparent")}</li>
                  </ul>
                </div>
              ),
              devices: [
                {
                  src: PHMPic,
                  title: t("products.phm"),
                  code: t("products.phm-code"),
                },
                {
                  src: wise2410Pic,
                  title: t("products.wise-2410-bto"),
                  code: t("products.wise-2410-bto-code"),
                },
                {
                  src: wise6610Pic,
                  title: t("products.wise-6610-bto"),
                  code: t("products.wise-6610-bto-code"),
                },
              ],
            },
            {
              page: 2,
              title: t("electronics.robot-arm"),
              content: (
                <div>
                  {t("electronics.with-phm-ai-solution")}
                  <ul style={{ paddingInlineStart: "30px", margin: 0 }}>
                    <li>{t("electronics.predict-machine-failure")}</li>
                    <li>{t("electronics.make-machine-status-transparent")}</li>
                  </ul>
                </div>
              ),
              devices: [
                {
                  src: PHMPic,
                  title: t("products.phm"),
                  code: t("products.phm-code"),
                },
                {
                  src: air101Pic,
                  title: t("products.air-101"),
                  code: t("products.air-101-code"),
                },
                {
                  src: vibrationPic,
                  title: t("products.vibration-sensors-profile"),
                  code: "",
                },
              ],
            },
          ],
          url: "/starter-packages/smart-maintenance",
          isOuterLink: false,
        },
        {
          name: t("electronics.sop-smart-monitoring"),
          packages: [
            {
              page: 1,
              title: t("electronics.cleaning"),
              content: (
                <div>
                  {t("electronics.with-activity-recognition-ai-solution")}
                  <ul style={{ paddingInlineStart: "30px", margin: 0 }}>
                    <li>{t("electronics.ihygiene-certificate")}</li>
                    <li>{t("electronics.reduce-labor-for-checking")}</li>
                    <li>{t("electronics.real-time-response")}</li>
                  </ul>
                </div>
              ),
              devices: [
                {
                  src: recognitionPic,
                  title: t("products.activity-recognition"),
                  code: t("products.activity-recognition-code"),
                },
                {
                  src: acp4010Pic,
                  title: t("products.acp-4010"),
                  code: t("products.acp-4010-code"),
                },
                {
                  src: ipcamPic,
                  title: t("products.ipcam"),
                  code: "",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      stepName: t("electronics.quality-control"),
      elementId: "QualityControl",
      src: {
        md: stepPicture03,
        sm: stepPicture03l,
      },
      flexDirection: "row",
      starterPackages: [
        {
          name: t("electronics.sop-smart-monitoring"),
          packages: [
            {
              page: 1,
              title: t("electronics.poka-yoke-screw-assembly"),
              content: (
                <div>
                  {t(
                    "electronics.with-vision-ai-analytics-solution-to-monitor-manufacturing"
                  )}
                  <ul style={{ paddingInlineStart: "30px", margin: 0 }}>
                    <li>{t("electronics.screw-with-sop")}</li>
                    <li>
                      {t("electronics.reduce-labor-for-checking-quality")}
                    </li>
                    <li>{t("electronics.real-time-response-quality")}</li>
                    <li>{t("electronics.traceability")}</li>
                  </ul>
                </div>
              ),
              devices: [
                {
                  src: recognitionPic,
                  title: t("products.activity-recognition"),
                  code: t("products.activity-recognition-code"),
                },
                {
                  src: acp4010Pic,
                  title: t("products.acp-4010"),
                  code: t("products.acp-4010-code"),
                },
                {
                  src: ipcamPic,
                  title: t("products.ipcam"),
                  code: "",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      stepName: t("electronics.facility-management"),
      elementId: "FacilityManagement",
      src: {
        md: stepPicture04,
        sm: stepPicture04l,
      },
      flexDirection: "row-reverse",
      starterPackages: [
        {
          name: t("electronics.ppe-smart-detection"),
          packages: [
            {
              page: 1,
              title: t("electronics.personal-protective-equipment"),
              content: (
                <div>
                  {t(
                    "electronics.with-activity-recognition-ai-solution-to-detect-no-personal"
                  )}
                  <ul style={{ paddingInlineStart: "30px", margin: 0 }}>
                    <li>{t("electronics.ensure-safety")}</li>
                    <li>{t("electronics.reduce-cost")}</li>
                    <li>{t("electronics.real-time-response")}</li>
                  </ul>
                </div>
              ),
              devices: [
                {
                  src: recognitionPic,
                  title: t("products.activity-recognition"),
                  code: t("products.activity-recognition-code"),
                },
                {
                  src: patrolPic,
                  title: t("electronics.patrol-inspection"),
                  code: t("electronics.patrol-inspection-code"),
                },
                {
                  src: acp4010Pic,
                  title: t("products.acp-4010"),
                  code: t("products.acp-4010-code"),
                },
                {
                  src: ipcamPic,
                  title: t("products.ipcam"),
                  code: "",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  return stepCards;
};

export { useStepCardsData };
