import Box from "@mui/material/Box";

export const BecomingPartner = () => {
  return (
    <Box sx={{ padding: "74px 0 0" }}>
      <iframe
        style={{
          height: "calc(100% - 74px)",
          width: "100%",
          position: "absolute",
          border: "none",
          transform: "translateY(-81px)",
        }}
        title="becoming-partner"
        src="https://campaign.advantech.online/en/iFactory_Partner_Program/"
      />
    </Box>
  );
};
