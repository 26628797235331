import {Box} from "@mui/material";
import FeatureCard from "./FeatureCard";
import React from "react";
import useFeatureCardData from "../../routes/Home/data/FeatureCardData";

const HomeFeatures: React.FC = () => {
  const featuresData = useFeatureCardData();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        width: "100%",
        maxWidth: {md: "880px", sm: "716px", xs: "328px"},
        padding: {sm: "0 0 20px", xs: "0 0 16px"},
        gap: {sm: "20px", xs: "16px"},
        height: {md: "196px", sm: "216px", xs: "376px"},
        flexWrap: {sm: "nowrap", xs: "wrap"}
      }}
    >
      {featuresData.map((feature) => (
        <FeatureCard feature={feature} />
      ))}
    </Box>
  )
}

export default HomeFeatures
