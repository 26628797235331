import { Box, Typography } from "@mui/material";
import React from "react";

interface FeatureCardProps {
  title: string;
  content: string;
  children?: React.ReactNode;
  sx?: any;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ title, content, sx }) => {
  return (
    <Box
      sx={{
        width: {
          md: "380px",
          sm: "348px",
          xs: "328px",
        },
        ...sx,
      }}
    >
      <Typography
        fontWeight="700"
        sx={{
          padding: "0 0 0 20px",
          fontSize: { md: 16, sm: 16, xs: 14 },
        }}
        position={"relative"}
      >
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "4px",
            height: "100%",
            background: "linear-gradient(180deg, #008CD6 0%, #00CC66 100%)",
          }}
        ></Box>
        {title ?? "Feature Title"}
      </Typography>
      <Typography
        fontWeight={400}
        color="#89898A"
        sx={{
          padding: "0 0 0 20px",
          fontSize: { md: 12, sm: 12, xs: 12 },
        }}
      >
        {content ?? "Feature Content"}
      </Typography>
    </Box>
  );
};

export default FeatureCard;
