import { BaseService } from "./base";

export interface User {
  username: string;
  firstName: string;
  lastName: string;
}

export class UserService extends BaseService {
  async getUserMe() {
    return await this.axios.get<{
      data?: User;
      errors?: [{ name: string; message: string }];
    }>("/api/v1/users/me");
  }
}
