import { useTranslation } from "react-i18next";

const useInfoCardsData = () => {
  const { t } = useTranslation();
  const infoCards = [
    {
      step: "01",
      title: t("partners.domain-expertise"),
      lists: [
        {
          content: t(
            "partners.familiar-with-manufacturing-partners-can-provide-the-right-solution-by-discussing-requirements-with-you-to-understanding-your-demands"
          ),
        },
      ],
    },
    {
      step: "02",
      title: t("partners.localized-support"),
      lists: [
        {
          content: t("partners.localized-support-content"),
        },
      ],
    },
    {
      step: "03",
      title: t("partners.starter-package"),
      lists: [
        {
          content: t("partners.starter-package-content"),
        },
      ],
    },
    {
      step: "04",
      title: t("partners.certified-and-qualified"),
      lists: [
        {
          content: t("partners.certified-and-qualified-content"),
        },
      ],
    },
  ];

  return infoCards;
};

export { useInfoCardsData };
