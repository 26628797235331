import { useTranslation } from "react-i18next";

const useInfoCardsData = () => {
  const { t } = useTranslation();
  const infoCards = [
    {
      step: "01",
      title: t("electronics.rapidly-changing-technology"),
      lists: [
        {
          content: t("electronics.changing-consumer-preferences"),
        },
        {
          content: t("electronics.avoid-inventory-getting-stuck"),
        },
      ],
    },
    {
      step: "02",
      title: t("electronics.short-product-life-cycle"),
      lists: [
        {
          content: t("electronics.products-be-replaced-with-newer-models"),
        },
        {
          content: t("electronics.requires-efficient-lean-capabilities"),
        },
      ],
    },
    {
      step: "03",
      title: t("electronics.increasing-competition"),
      lists: [
        {
          content: t("electronics.compete-for-market-share"),
        },
        {
          content: t(
            "electronics.new-entrants-are-constantly-entering-the-market"
          ),
        },
      ],
    },
    {
      step: "04",
      title: t("electronics.prices-pressure"),
      lists: [
        {
          content: t("electronics.under-pressure"),
        },
        {
          content: t("electronics.product-recalls"),
        },
      ],
    },
    {
      step: "05",
      title: t("electronics.service-and-warranty-management"),
      lists: [
        {
          content: t("electronics.post-production-service-hours"),
        },
        {
          content: t("electronics.traceability-system"),
        },
      ],
    },
  ];
  return infoCards;
};

export { useInfoCardsData };
