import { Box } from "@mui/material";
import { ColoredTypography } from "@/components/ColoredTypography/ColoredTypography";
import { BlackTitleTypography } from "@/components/ColoredTypography/BlackTitleTypography";
import { GreyContentTypography } from "@/components/ColoredTypography/GreyContentTypography";

import styles from "@/styles/image.module.css";

type answerCardType = {
  id: string;
  title: string;
  content: string;
  src: string[];
  reverse: boolean;
};

interface featuresIntroductionCardProps {
  answerCard: answerCardType[];
}

export const FeaturesIntroductionCard: React.FC<
  featuresIntroductionCardProps
> = ({ answerCard }) => {
  return (
    <>
      {answerCard &&
        answerCard.map((answer) => {
          return (
            <Box
              sx={{
                boxSizing: "border-box",
                width: "100%",
                display: "flex",
                gap: { md: "0px", sm: "20px", xs: "16px" },
                // padding: "0 40px 0 0",
                alignItems: "center",
                flexDirection: {
                  md: answer.reverse ? "row-reverse" : "row",
                  sm: "column-reverse",
                  xs: "column-reverse",
                },
                margin: { sm: "20px 0 0", xs: "16px 0 0" },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  maxWidth: {
                    sm: "430px",
                    xs: "304px",
                  },
                }}
              >
                <img
                  src={answer.src[0]}
                  alt="FAQsQ1_1"
                  className={styles.imageW100}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: { md: "430px", sm: "100%", xs: "100%" },
                  // minWidth: { md: "430px", sm: "100%", xs: "100%" },
                  maxWidth: {
                    sm: "430px",
                    xs: "304px",
                  },
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: { md: "180px", sm: "auto" },
                    display: "flex",
                    flexDirection: { md: "column", sm: "row", xs: "row" },
                    justifyContent: {
                      md: "center",
                      sm: "flex-start",
                      xs: "flex-start",
                    },
                    gap: { md: "0px", sm: "20px", xs: "16px" },
                    alignItems: {
                      md: "center",
                      sm: "flex-start",
                      xs: "flex-start",
                    },
                  }}
                >
                  <Box
                    sx={{
                      boxSizing: "border-box",
                      padding: { md: "20px" },
                      width: "100%",
                      maxWidth: "350px",
                      display: "flex",
                      flexDirection: { md: "column", sm: "row", xs: "row" },
                      gap: { md: "0px", xs: "10px" },
                    }}
                  >
                    <ColoredTypography
                      fontWeight={700}
                      sx={{
                        fontSize: {
                          md: "48px",
                          sm: "40px",
                          xs: "28px",
                        },
                        fontStyle: "italic",
                        minWidth: {
                          md: "unset",
                          sm: "72px",
                          xs: "70px",
                        },
                        textAlign: {
                          md: "left",
                          sm: "right",
                          xs: "right",
                        },
                      }}
                      boxSizing={"border-box"}
                    >
                      {answer.id}
                    </ColoredTypography>
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: {
                          md: "430px",
                          sm: "350px",
                          xs: "218px",
                        },
                      }}
                    >
                      <BlackTitleTypography
                        sx={{
                          fontSize: {
                            md: "16px",
                            xs: "14px",
                          },
                        }}
                      >
                        {answer.title}
                      </BlackTitleTypography>
                      <GreyContentTypography
                        sx={{
                          fontSize: "14px",
                        }}
                      >
                        {answer.content}
                      </GreyContentTypography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
    </>
  );
};
