import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import magazinePic1 from "../../routes/Home/images/img_magaztion_Smart Manufacturing Ecosystem.png";
import magazineAvifPic1 from "../../routes/Home/images/img_magaztion_Smart Manufacturing Ecosystem.avif";
import magazineWebPPic1 from "../../routes/Home/images/img_magaztion_Smart Manufacturing Ecosystem.webp";
import magazinePic2 from "../../routes/Home/images/img_magazine_Reshaping Manufacturing Business Operations with Industrial IoT.png";
import magazineAvifPic2 from "../../routes/Home/images/img_magazine_Reshaping Manufacturing Business Operations with Industrial IoT.avif";
import magazineWebPPic2 from "../../routes/Home/images/img_magazine_Reshaping Manufacturing Business Operations with Industrial IoT.webp";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import DashedDividingLine from "../Common/DashedDividingLine";
import { useImageUtils } from "../../hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const HomeResources: React.FC = () => {
  const theme = useTheme();
  const { selectImage } = useImageUtils();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const navigate = useNavigate();

  const resources = [
    {
      title: t("home.smart-manufacturing-ecosystem"),
      src: {
        avif: magazineAvifPic1,
        webp: magazineWebPPic1,
        default: magazinePic1,
      },
      category: t("home.magazine"),
      date: "2023/01/01",
      url: t("home.smart-url"),
      isOuterLink: true,
    },
    {
      title: t(
        "home.reshaping-manufacturing-business-operations-with-industrial-iot"
      ),
      src: {
        avif: magazineAvifPic2,
        webp: magazineWebPPic2,
        default: magazinePic2,
      },
      category: t("home.magazine"),
      date: "2023/01/01",
      url: t("home.reshaping-url"),
      isOuterLink: true,
    },
  ];

  const goToPage = (url: string | undefined, isOuterLink: boolean) => {
    if (!url) return;
    if (isOuterLink) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  const WebResources = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: { md: "20px", sm: "40px 20px", xs: "32px 16px" },
          gap: { md: "20px", sm: "20px", xs: "16px" },
        }}
      >
        <Typography
          textAlign="center"
          variant="h2"
          fontWeight="700"
          sx={{
            fontSize: { md: "48px", sm: "40px", xs: "28px" },
            maxWidth: { md: "780px" },
          }}
        >
          {t("home.resources")}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "20px",
            maxWidth: { md: "1180px" },
            height: "380px",
          }}
        >
          {resources.map((resource) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
                maxWidth: { md: 380 },
                border: "1px solid #C8C8C8",
                boxSizing: "border-box",
                borderRadius: "4px",
                padding: "20px",
                gap: "20px",
              }}
            >
              <Box sx={{ width: "100%", maxWidth: "136px" }}>
                <img
                  src={selectImage(resource.src)}
                  style={{
                    width: "100%",
                  }}
                  alt={resource.title}
                />
              </Box>
              <Box>
                <Typography
                  fontWeight="700"
                  sx={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    height: "82.25px",
                    overflowX: "hidden",
                  }}
                >
                  {resource.title}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "24px",
                  maxWidth: "380px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    maxWidth: "210px",
                    padding: "0",
                  }}
                >
                  <Typography
                    color="#89898A"
                    fontWeight="700"
                    fontSize="12px"
                    sx={{ padding: "0 0 0 7px" }}
                  >
                    {resource.category} ・ {resource.date}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Button
                    sx={{
                      color: "#008CD6",
                      fontWeight: 700,
                      fontSize: "12px",
                      textTransform: "none",
                      padding: "0",
                    }}
                    onClick={() =>
                      goToPage(resource.url ?? "", resource.isOuterLink)
                    }
                  >
                    <Typography
                      color="#008CD6"
                      fontWeight="700"
                      whiteSpace="nowrap"
                    >
                      {t("landing-page.common.learn-more")}
                    </Typography>

                    <ChevronRightIcon style={{ color: "#008CD6" }} />
                  </Button>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            width: "100%",
            maxWidth: { md: "1180px", sm: "732px", xs: "328px" },
            height: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DashedDividingLine />
        </Box>
      </Box>
    );
  };

  const MobileResource = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          gap: { sm: "20px", xs: "16px" },
        }}
      >
        <Typography
          textAlign="center"
          variant="h2"
          fontWeight="700"
          sx={{
            fontSize: { md: "48px", sm: "40px", xs: "28px" },
            maxWidth: { md: "780px" },
            padding: { sm: "0px", xs: "0 0 16px 0" },
          }}
        >
          Resources
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            maxWidth: { md: "1180px", sm: "716px", xs: "328px" },
            height: { sm: "380px", xs: "auto" },
            flexWrap: "wrap",
            gap: { md: "20px", sm: "20px", xs: "16px" },
          }}
        >
          {resources.map((resource) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "flex-start",
                width: "100%",
                maxWidth: { md: 380 },
                border: "1px solid #C8C8C8",
                boxSizing: "border-box",
                borderRadius: "4px",
                padding: "20px",
                gap: { md: "20px", sm: "20px", xs: "16px" },
                height: "200px",
              }}
            >
              <Box
                sx={{ width: "100%", maxWidth: { sm: "136px", xs: "88px" } }}
              >
                <img
                  src={selectImage(resource.src)}
                  style={{
                    width: "100%",
                  }}
                  alt={resource.title}
                />
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <Box sx={{ height: "128px" }}>
                  <Box>
                    <Typography
                      fontWeight="700"
                      sx={{
                        fontSize: { sm: "16px", xs: "14px" },
                        lineHeight: "24px",
                        overflowX: "hidden",
                      }}
                    >
                      {resource.title}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "24px",
                      maxWidth: "380px",
                      flexWrap: "wrap",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        maxWidth: "210px",
                        padding: "0",
                      }}
                    >
                      <Typography
                        color="#89898A"
                        fontWeight="700"
                        fontSize="12px"
                        sx={{ padding: { sm: "0 0 0 7px", xs: "0" } }}
                      >
                        {resource.category} ・ {resource.date}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Button
                    sx={{
                      color: "#008CD6",
                      fontWeight: 700,
                      fontSize: "12px",
                      textTransform: "none",
                      padding: "0",
                    }}
                    onClick={() =>
                      goToPage(resource.url ?? "", resource.isOuterLink)
                    }
                  >
                    <Typography
                      color="#008CD6"
                      fontWeight="700"
                      whiteSpace="nowrap"
                    >
                      {t("landing-page.common.learn-more")}
                    </Typography>

                    <ChevronRightIcon style={{ color: "#008CD6" }} />
                  </Button>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            width: "100%",
            maxWidth: { md: "1180px", sm: "732px", xs: "328px" },
            height: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DashedDividingLine />
        </Box>
      </Box>
    );
  };

  return !matchesDownSm ? WebResources() : MobileResource();
};

export default HomeResources;
