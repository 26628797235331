import { ColoredTypography } from "../ColoredTypography/ColoredTypography";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BlueColoredTypography } from "../ColoredTypography/BlueColoredTypography";
import { GreenYellowTypography } from "../ColoredTypography/GreenYellowTypography";
import { BluePurpleTypography } from "../ColoredTypography/BluePurpleTypography";
import { BlueCyanTypography } from "../ColoredTypography/BlueCyanTypography";

type list = {
  content: string;
};

interface CardInfo {
  step?: string;
  title?: string;
  content?: string;
  lists?: list[];
}

interface InfoCardProps {
  cardInfo: CardInfo;
  textColor?: string;
  listStyle?: string;
}

const renderColoredTypography = (
  step: string,
  textColor: string | undefined
) => {
  const typographyStyle = {
    fontWeight: "900",
    fontStyle: "italic",
    paddingRight: "20px",
    textAlign: "right",
    fontSize: { sm: "48px", xs: "28px" },
  };

  switch (textColor) {
    case "blue":
      return (
        <BlueColoredTypography sx={typographyStyle}>
          {step || "01"}
        </BlueColoredTypography>
      );
    case "greenYellow":
      return (
        <GreenYellowTypography sx={typographyStyle}>
          {step || "01"}
        </GreenYellowTypography>
      );
    case "bluePurple":
      return (
        <BluePurpleTypography sx={typographyStyle}>
          {step || "01"}
        </BluePurpleTypography>
      );
    case "blueCyan":
      return (
        <BlueCyanTypography sx={typographyStyle}>
          {step || "01"}
        </BlueCyanTypography>
      );
    default:
      return (
        <ColoredTypography sx={typographyStyle}>
          {step || "01"}
        </ColoredTypography>
      );
  }
};

export const InfoCard = ({ cardInfo, textColor, listStyle }: InfoCardProps) => (
  <Box
    sx={{
      display: "flex",
      maxWidth: { lg: 380, md: 348, sm: 348, xs: 328 },
      width: "100%",
      gap: { md: "20px", xs: "10px" },
    }}
  >
    <Box>{renderColoredTypography(cardInfo.step!, textColor)}</Box>
    <Box>
      <Typography
        variant={"h6"}
        sx={{ fontSize: { md: "16px", sm: "14px", xs: "14px" } }}
      >
        {cardInfo.title || "Item name"}
      </Typography>
      <Typography textAlign="left" color={"GrayText"}>
        <ul
          style={
            listStyle === "none"
              ? {
                  marginBlockStart: 0,
                  marginBlockEnd: 0,
                  paddingInlineStart: 0,
                }
              : {
                  marginBlockStart: 0,
                  marginBlockEnd: 0,
                  paddingInlineStart: "20px",
                }
          }
        >
          {cardInfo.lists?.map((list) => (
            <li
              style={
                listStyle === "none"
                  ? { listStyle: "none", paddingInlineStart: 0, padding: 0 }
                  : {}
              }
            >
              <Typography
                sx={{ fontSize: { md: "12px", sm: "10px", xs: "10px" } }}
              >
                {" "}
                {list.content}
              </Typography>
            </li>
          ))}
        </ul>
      </Typography>
    </Box>
  </Box>
);
