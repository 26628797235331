import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import IndustryCard from "./IndustryCard";
import useIndustriesCardData from "../../routes/Home/data/IndustriesCardData";
import useMediaQuery from "@mui/material/useMediaQuery";
import DashedDividingLine from "../Common/DashedDividingLine";
import { useTranslation } from "react-i18next";

const HomeIndustries: React.FC = () => {
  const industriesData = useIndustriesCardData();
  const [activePage, setActivePage] = useState(0);
  const [transformIndex, setTransformIndex] = useState(0);

  const handleClickNextPage = () => {
    setActivePage((activePage) => activePage + 1);
    setTransformIndex((transformIndex) => transformIndex + 1);
  };
  const handleClickPreviousPage = () => {
    setActivePage((activePage) => activePage - 1);
    setTransformIndex((transformIndex) => transformIndex - 1);
  };

  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const { t } = useTranslation();

  const WebIndustries = () => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: { md: "20px", sm: "40px 20px", xs: "32px 16px" },
          gap: { md: "20px", sm: "20px", xs: "16px" },
          boxSizing: "border-box",
        }}
      >
        <Typography
          textAlign="center"
          variant="h2"
          fontWeight="700"
          sx={{
            fontSize: { md: "48px", sm: "40px", xs: "28px" },
            maxWidth: { md: "780px", sm: "532px", xs: "328px" },
          }}
        >
          {t("home.industries")}
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "20px",
            padding: "0",
            maxWidth: { md: "1180px", sm: "716px", xs: "328px" },
            height: "430px",
            overflow: "hidden",
            boxSizing: "border-box",
          }}
        >
          <Box
            width={`${100 * industriesData!.length}%`}
            height="380px"
            sx={{
              display: "flex",
              transition: "0.3s ease-in-out",
              boxSizing: "border-box",
              transform: `translateX(${transformIndex * -100}%)`,
            }}
          >
            {industriesData.map((industryData) => (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  gap: { md: "20px", sm: "20px", xs: "16px" },
                  flexShrink: 0,
                  boxSizing: "border-box",
                }}
              >
                {industryData.industries.map(
                  (industry, index, industriesArray) => (
                    <IndustryCard
                      industry={industry}
                      index={index}
                      industries={industriesArray}
                    />
                  )
                )}
              </Box>
            ))}
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: { sm: "auto", xs: "100%" },
          }}
        >
          <IconButton
            sx={{
              transition: "0.3s ease-in",
              backgroundColor: "white !important",
            }}
            onClick={handleClickPreviousPage}
            disabled={activePage === 0}
          >
            <NavigateBeforeIcon />
          </IconButton>

          <Typography
            textAlign="left"
            color={"GrayText"}
            sx={{ display: "inline-block", backgroundColor: "white" }}
          >
            {(activePage + 1).toString()} / {industriesData!.length}
          </Typography>

          <IconButton
            sx={{
              transition: "0.3s ease-in",
              backgroundColor: "white !important",
            }}
            onClick={handleClickNextPage}
            disabled={activePage === industriesData.length - 1}
          >
            <NavigateNextIcon />
          </IconButton>
          <Box
            sx={{
              width: "100%",
              position: "absolute",
              maxWidth: { md: "1180px", sm: "732px", xs: "328px" },
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: -1,
            }}
          >
            <DashedDividingLine />
          </Box>
        </Box>
      </Box>
    );
  };

  const MobileIndustries = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          gap: { sm: "20px", xs: "16px" },
        }}
      >
        <Typography
          textAlign="center"
          variant="h2"
          fontWeight="700"
          sx={{
            fontSize: { md: "48px", sm: "40px", xs: "28px" },
            maxWidth: { md: "780px", sm: "532px", xs: "328px" },
            padding: { sm: "0px", xs: "0 0 16px 0" },
          }}
        >
          {t("home.industries")}
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: { sm: "flex-start", xs: "center" },
            alignItems: "center",
            gap: "20px",
            padding: "0",
            maxWidth: { md: "1180px", sm: "716px" },
            height: { sm: "380px", md: "auto" },
            overflow: "hidden",
            flexWrap: "wrap",
          }}
        >
          {industriesData.map((industryData) => (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                gap: "20px",
                flexWrap: "wrap",
                justifyContent: { sm: "flex-start", xs: "center" },
              }}
            >
              {industryData.industries.map(
                (industry, index, industriesArray) => (
                  <IndustryCard
                    industry={industry}
                    index={index}
                    industries={industriesArray}
                  />
                )
              )}
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            width: "100%",
            maxWidth: { md: "1180px", sm: "716px", xs: "328px" },
            display: { md: "none", sm: "none", xs: "flex" },
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            height: "32px",
          }}
        >
          <DashedDividingLine />
        </Box>
      </Box>
    );
  };
  return !matchesDownSm ? WebIndustries() : MobileIndustries();
};

export default HomeIndustries;
