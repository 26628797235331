import { styled, Typography, TypographyProps } from "@mui/material";

interface BluePurpleTypographyProps {
  children: React.ReactNode;
}

export const BluePurpleTypography = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    background: "linear-gradient(135deg, #008CD6 0%, #F7567C 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    padding: "0 10px !important",
    whiteSpace: "nowrap",
  })
);
