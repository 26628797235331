import { styled, Typography, TypographyProps } from "@mui/material";

export const GreenYellowTypography = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    background: "linear-gradient(135deg, #008CD6 0%, #FFA400 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    padding: "0 10px !important",
    whiteSpace: "nowrap",
  })
);
