import React, { ReactNode, useState } from "react";
import { render } from "react-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { PackageDeviceCard } from "./PackageDeviceCard";
import { useTranslation } from "react-i18next";
import DashedDividingLine from "../Common/DashedDividingLine";
import { Drawer } from "@mui/material";
import { useNavigate } from "react-router-dom";

type DeviceInfo = {
  src: string;
  title: string | JSX.Element;
  content?: string;
};

interface FeatInfo {
  page: number;
  title: string;
  content: string | ReactNode;
  devices: DeviceInfo[];
}

interface StarterPackage {
  name: string;
  packages: FeatInfo[];
  url?: string;
  isOuterLink?: boolean;
}

interface InfoCardProps {
  starterPackage?: StarterPackage;
}

const StarterPackageCard = ({ starterPackage }: InfoCardProps) => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState(0);
  const navigate = useNavigate();
  const handleClickNextPage = () => {
    setActivePage((activePage) => activePage + 1);
  };
  const handleClickPreviousPage = () => {
    setActivePage((activePage) => activePage - 1);
  };

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const goToContactUsPage = () => {
    const url = t("landing-page.common.contact-us-url");
    window.open(url, "_blank");
    // setDrawerOpen(true)
  };

  const goToPage = (url: string | undefined, isOuterLink: boolean) => {
    if (!url) return;
    if (isOuterLink) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: { lg: "flex", xs: "flex" },
          flexDirection: "column",
          width: "100%",
          minWidth: "328px",
          margin: 0,
        }}
      >
        {starterPackage?.packages[activePage]?.title !== "" ? (
          <Typography
            variant="h2"
            sx={{
              margin: { md: "10px 0 0", sm: "20px 0 0", xs: "16px 0 0" },
              fontSize: {
                md: "48px",
                sm: "40px",
                xs:
                  starterPackage!.packages[activePage]!.title!.length > 20
                    ? "25px"
                    : "28px",
              },
              fontWeight: 700,
              textAlign: { lg: "left", xs: "center" },
            }}
          >
            {starterPackage?.packages[activePage]?.title ||
              " Footwear Oven Live Progress"}
          </Typography>
        ) : undefined}

        <Box
          sx={{
            display: "flex",
            gap: "20px",
            height: "100%",
            flexWrap: { lg: "nowrap", xs: "wrap" },
            justifyContent: { lg: "flex-start", xs: "center" },
            margin: { lg: "10px 0 0", sm: "10px 0 0", xs: "16px 0 0" },
            minHeight: { md: "330px", sm: "auto" },
          }}
        >
          <Box
            sx={{
              maxWidth: { md: "380px", xs: "716px" },
              minWidth: { md: "380px", xs: "auto" },
            }}
          >
            <Typography
              sx={{
                textAlign: { md: "left", xs: "center" },
              }}
              color="#89898A"
            >
              {starterPackage?.packages[activePage].content ||
                " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n" +
                  "        magna aliqua."}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              gap: { sm: "20px", md: "16px", xs: "16px" },
              justifyContent: { md: "flex-start", xs: "center" },
              margin: "0",
              minWidth: { md: "380px", xs: "auto" },
            }}
          >
            {starterPackage?.packages[activePage].devices.map(
              (deviceIntroduction) => (
                <PackageDeviceCard cardInfo={deviceIntroduction} />
              )
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: { md: "20px", xs: "40px" },
            justifyContent: {
              md: "space-between",
              sm: "space-around",
              xs: "center",
            },
            margin: { md: "20px 0 0", xs: "40px 0 0" },
            flexDirection: { sm: "row", xs: "column-reverse" },
            flexWrap: { sm: "nowrap", xs: "wrap" },
            maxWidth: { lg: "780px", md: "716px", sm: "600px", xs: "328px" },
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            sx={{
              width: { sm: "100%", xs: "100%" },
              position: "relative",
              justifyContent: { sm: "flex-start", xs: "center" },
            }}
          >
            <Box
              sx={{
                minWidth: { md: "120px", sm: "170px", xs: "auto" },
                bgcolor: { md: "transparent", xs: "#fff" },
              }}
            >
              <IconButton
                sx={{ transition: "0.3s ease-in" }}
                onClick={handleClickPreviousPage}
                disabled={activePage === 0}
              >
                <NavigateBeforeIcon />
              </IconButton>

              <Typography
                textAlign="left"
                color={"GrayText"}
                sx={{ display: "inline-block", whiteSpace: "nowrap" }}
              >
                {(activePage + 1).toString()} /{" "}
                {starterPackage?.packages!.length}
              </Typography>

              <IconButton
                sx={{ transition: "0.3s ease-in" }}
                onClick={handleClickNextPage}
                disabled={activePage === starterPackage!.packages!.length - 1}
              >
                <NavigateNextIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                position: { md: "relative", xs: "absolute" },
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  position: "absolute",
                  top: "50%",
                }}
              >
                <DashedDividingLine />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "flex" },
              justifyContent: { xs: "center" },
            }}
          >
            <Button
              variant={"contained"}
              sx={{
                marginRight: "20px",
                textTransform: "none",
                maxWidth: { sm: "180px", xs: "142px" },
                width: "100%",
                padding: { sm: "6px 12px", xs: "0px 12px" },
                minHeight: "40px",
                whiteSpace: "nowrap",
                "&.MuiButton-containedPrimary": {
                  backgroundColor: "#008CD6",
                },
              }}
              onClick={() =>
                goToPage(
                  starterPackage!.url ?? "",
                  !!starterPackage!.isOuterLink
                )
              }
            >
              {t("landing-page.common.learn-more")}
            </Button>
            <Button
              variant={"outlined"}
              sx={{
                textTransform: "none",
                maxWidth: { sm: "180px", xs: "142px" },
                width: "100%",
                padding: { sm: "6px 16px", xs: "0px 12px" },
                minHeight: "40px",
                whiteSpace: "nowrap",
                "&.MuiButton-outlinedPrimary": {
                  color: "#008CD6",
                  border: "1px solid #008CD6",
                },
              }}
              onClick={() => goToContactUsPage()}
            >
              {t("landing-page.common.contact-us")}
            </Button>
          </Box>
        </Box>
      </Box>

      {/* <Drawer anchor="right" open={drawerOpen}>
        <iframe src={t("landing-page.common.contact-us-url") as string} />
      </Drawer> */}
    </>
  );
};

export { StarterPackageCard };
