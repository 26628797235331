import { ReactNode, useEffect, useState } from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import { StarterPackageCard } from "./StaterPackageCard";
import { ColoredTypography } from "../ColoredTypography/ColoredTypography";
import { BlueColoredTypography } from "../ColoredTypography/BlueColoredTypography";
import { GreenYellowTypography } from "../ColoredTypography/GreenYellowTypography";
import { BluePurpleTypography } from "../ColoredTypography/BluePurpleTypography";
import { BlueCyanTypography } from "../ColoredTypography/BlueCyanTypography";
import DashedDividingLine from "../Common/DashedDividingLine";
import i18n from "i18next";

type DeviceInfo = {
  src: string;
  title: string | JSX.Element;
  content?: string;
};

interface FeatInfo {
  page: number;
  title: string;
  content: ReactNode;
  devices: DeviceInfo[];
}

interface StarterPackage {
  name: string;
  title?: string;
  packages: FeatInfo[];
}

interface StepCardInfo {
  stepName?: string;
  elementId: string;
  src?: {
    md?: string;
    sm?: string;
    xs?: string;
  };
  flexDirection?: string;
  starterPackages?: StarterPackage[];
}

interface InfoCardProps {
  stepCardInfo?: StepCardInfo;
  stepCards?: StepCardInfo[];
  stepTextColor?: string;
}

export const StepCard = ({
  stepCardInfo,
  stepCards,
  stepTextColor,
}: InfoCardProps) => {
  const { starterPackages, elementId, flexDirection, src, stepName } =
    stepCardInfo!;

  const theme = useTheme();
  const matcheMD = useMediaQuery(theme.breakpoints.up("md"));
  const downMD = useMediaQuery(theme.breakpoints.down("md"));

  const [activeTabName, setActiveTabName] = useState(
    starterPackages?.[0]?.name
  );

  useEffect(() => {
    setActiveTabName(starterPackages?.[0]?.name);
  }, [i18n.language]);

  const [transformIndex, setTransformIndex] = useState(0);

  const handleClickStaterPackageBtn = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    starterPackage: StarterPackage
  ) => {
    setActiveTabName(starterPackage.name);
    const index = starterPackages!.findIndex(
      (starter, index) => starter.name === starterPackage.name
    );
    setTransformIndex(index);
  };

  const renderMobileColoredTypography = (
    stepName: string,
    textColor: string | undefined
  ) => {
    const typographyStyle = {
      variant: "h1",
      fontWeight: "900",
      fontStyle: "italic",
      textTransform: "uppercase",
      display: { md: "none", xs: "block" },
      fontSize: { md: "56px", xs: stepName.length > 12 ? "32px" : "48px" },
      whiteSpace: { md: "nowrap", sm: "normal", xs: "normal" },
      justifyContent: "center",
      textAlign: "center",
    };

    switch (textColor) {
      case "blue":
        return (
          <BlueColoredTypography sx={typographyStyle}>
            {stepName}
          </BlueColoredTypography>
        );
      case "greenYellow":
        return (
          <GreenYellowTypography sx={typographyStyle}>
            {stepName}
          </GreenYellowTypography>
        );
      case "bluePurple":
        return (
          <BluePurpleTypography sx={typographyStyle}>
            {stepName}
          </BluePurpleTypography>
        );
      case "blueCyan":
        return (
          <BlueCyanTypography sx={typographyStyle}>
            {stepName}
          </BlueCyanTypography>
        );
      default:
        return (
          <ColoredTypography sx={typographyStyle}>{stepName}</ColoredTypography>
        );
    }
  };

  const renderColoredTypography = (
    stepName: string,
    textColor: string | undefined
  ) => {
    const typographyStyle = {
      variant: "h1",
      fontWeight: "900",
      fontStyle: "italic",
      fontSize: "90px",
      textTransform: "uppercase",
      padding: { md: "10px 100px 10px 0", xs: "0 100px" },
      display: { md: "-webkit-box", xs: "none" },
      whiteSpace: "nowrap",
    };

    switch (textColor) {
      case "blue":
        return (
          <BlueColoredTypography sx={typographyStyle}>
            {/* {stepName} */}
            {(!downMD && stepName!.length <= 15
              ? stepName
              : stepName!.split(" ").length > 1
              ? stepName!.split(" ")[0] + "..."
              : stepName) || "Example Test"}
          </BlueColoredTypography>
        );
      case "greenYellow":
        return (
          <GreenYellowTypography sx={typographyStyle}>
            {/* {stepName} */}
            {(stepName!.split(" ").length > 1
              ? stepName!.split(" ")[0] + "..."
              : stepName) || "Example Test"}
          </GreenYellowTypography>
        );
      case "bluePurple":
        return (
          <BluePurpleTypography sx={typographyStyle}>
            {/* {stepName} */}
            {(stepName!.split(" ").length > 1
              ? stepName!.split(" ")[0] + "..."
              : stepName) || "Example Test"}
          </BluePurpleTypography>
        );
      default:
        return (
          <ColoredTypography sx={typographyStyle}>
            {/* {stepName} */}
            {(!downMD && stepName!.length <= 15
              ? stepName
              : stepName!.split(" ").length > 1
              ? stepName!.split(" ")[0] + "..."
              : stepName) || "Example Test"}
          </ColoredTypography>
        );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        maxWidth: { lg: "1180px", md: "716px", sm: "600px", xs: "328px" },
        boxSizing: "border-box",
      }}
      id={elementId}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          maxWidth: { lg: "1180px", md: "716px", sm: "600px", xs: "328px" },
          gap: "20px",
          flexDirection: flexDirection!,
          flexWrap: { lg: "nowrap", xs: "wrap" },
          justifyContent: "center",
          flex: 1,
          position: "relative",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            display: { xs: "none", lg: "flex" },
            position: "absolute",
            width: "100%",
            height: "100%",
            maxWidth: "640px",
            maxHeight: "640px",
            left: "240px",
            top: "360px",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: flexDirection!,
            zIndex: -1,
            overflow: "hidden",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              width: "70px",
              height: "250%",
              maxHeight: `${Math.pow(720 * 720 + 720 * 720, 1 / 2)}px`,
              backgroundColor: "rgb(230, 242, 251)",
              transform: `rotate(${flexDirection === "row" ? -45 : 45}deg)`,
              display: `${
                stepCards?.findIndex(
                  (stepCard) => stepCard!.stepName === stepCardInfo?.stepName
                ) ===
                stepCards!.length - 1
                  ? "none"
                  : "false"
              }`,
            }}
          ></div>
        </Box>
        <Box
          sx={{
            width: "100%",
            maxWidth: { lg: "380px", md: "716px", sm: "600px", xs: "328px" },
            display: { md: "flex" },
          }}
        >
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Box
              sx={{
                width: "fit-content",
                height: "100%",
                backgroundColor: "#fff",
                margin: "0 auto",
              }}
            >
              {renderMobileColoredTypography(stepName!, stepTextColor)}
            </Box>
            <Box
              sx={{
                width: "100%",
                position: "absolute",
                top: 0,
                height: "100%",
              }}
            >
              <DashedDividingLine />
            </Box>
          </Box>

          <Box
            sx={{
              padding: { sm: "20px 0 0", md: "0" },
              maxWidth: { md: "380px", sm: "640px", xs: "328px" },
            }}
          >
            {matcheMD ? (
              <img src={src!.md} style={{ maxWidth: "380px" }} alt={stepName} />
            ) : (
              <img
                src={src!.sm}
                style={{ maxWidth: "640px", width: "100%" }}
                alt={stepName}
              />
            )}
          </Box>
        </Box>

        <Box
          sx={{
            maxWidth: { lg: "780px", md: "716px", sm: "600px", xs: "328px" },
            display: "flex",
            flexDirection: "column",
            alignItems: { md: "flex-start", xs: "center" },
          }}
        >
          {renderColoredTypography(stepName!, stepTextColor)}
          <ButtonGroup
            variant="outlined"
            aria-label="Starter Packages"
            fullWidth
            sx={{
              margin: "0",
              width: { xs: "100%", md: "100%" },
            }}
          >
            {starterPackages?.map((startPackage) => (
              <Button
                sx={{
                  padding: "8px 0",
                  maxWidth: { sm: "100%", xs: "100%", textTransform: "none" },
                  "&.MuiButton-containedPrimary": {
                    backgroundColor: "#008CD6",
                  },
                  "&.MuiButton-outlinedPrimary": {
                    color: "#008CD6",
                    border: "1px solid #008CD6",
                  },
                }}
                variant={
                  activeTabName === startPackage.name ? "contained" : "outlined"
                }
                onClick={(e) => handleClickStaterPackageBtn(e, startPackage)}
              >
                {startPackage.name}
              </Button>
            ))}
          </ButtonGroup>
          <Box width="100%" overflow="hidden">
            <Box
              display="flex"
              margin="0"
              width={`${starterPackages!.length * 100}%`}
              sx={{
                transition: "0.3s ease-in-out",
                transform: `translateX(${
                  transformIndex * (-100 / starterPackages!.length)
                }%)`,
              }}
            >
              {starterPackages?.map((starterPackage) => (
                <StarterPackageCard starterPackage={starterPackage} />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
