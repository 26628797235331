import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";

interface Package {
  info: {
    title: string;
    description: string;
    tag: string;
    titleNoWrap?: boolean;
    isOuterLink?: boolean;
    url: string;
    maxWidth?: { md: string; sm: string; xs: string };
    alignItems?: { md: string; sm: string; xs: string };
  };
}

export default function PackageCard(props: Package) {
  const { info } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToPage = (url: string | undefined, isOuterLink: boolean) => {
    if (!url) return;
    if (isOuterLink) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };
  return (
    <Box
      sx={{
        boxSizing: "border-box",
        width: "100%",
        maxWidth: info.maxWidth ?? { md: "280px", sm: "100%", xs: "100%" },
        minHeight: { md: "200px", xs: "160px" },
        height: { md: "200px", xs: "160px" },
        padding: "20px",
        border: "1px solid #C8C8C8",
        display: "flex",
        flexDirection: "column",
        alignItems: { sm: "flex-end", xs: "flex-start" },
        gap: "4px",
        backgroundColor: { sm: "transparent", xs: "#fff" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: "4px",
          width: "100%",
          maxWidth: { md: "240px", sm: "100%", xs: "358px" },
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            alignItems: "flex-start",
            width: "100%",
            maxWidth: { md: "240px", sm: "100%", xs: "358px" },
            minHeight: { md: "132px", xs: "100px" },
            boxSizing: "border-box",
          }}
        >
          <Typography
            sx={{
              width: "100%",
              maxWidth: { md: "240px", sm: "100%", xs: "358px" },
              fontWeight: "700",
              fontSize: { sm: "16px", xs: "14px" },
              lineHeight: "24px",
              alignItems: "center",
              whiteSpace: info.titleNoWrap ? "nowrap" : "normal",
              "-webkit-line-clamp": {
                xs: "2",
              },
              textOverflow: "ellipsis",
              display: "-webkit-box",
              "-webkit-box-orient": "vertical",
              overflow: "hidden",
            }}
          >
            {info.title}
          </Typography>
          <Typography
            sx={{
              width: "100%",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "16px",
              color: "#89898A",
              overflowX: "hidden",
              "-webkit-line-clamp": {
                xs: "6",
              },
              textOverflow: "ellipsis",
              display: "-webkit-box",
              "-webkit-box-orient": "vertical",
              overflow: "hidden",
            }}
          >
            {info.description}
          </Typography>
        </Box>
        {info.tag ? (
          <Box
            sx={{
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0 4px",
              width: "22px",
              height: "16px",
              background: "#00CC66",
              borderRadius: "4px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "12px",
                lineHeight: "16px",
                color: "white",
              }}
            >
              {info!.tag}
            </Typography>
          </Box>
        ) : undefined}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          sx={{
            color: "#008CD6",
            fontWeight: 700,
            fontSize: "16px",
            textTransform: "none",
            padding: "0",
          }}
          onClick={() => {
            goToPage(info.url ?? "", !!info.isOuterLink);
          }}
        >
          <Typography color="#008CD6" fontWeight="700">
            {t("landing-page.common.learn-more")}
          </Typography>

          <ChevronRightIcon style={{ color: "#008CD6" }} />
        </Button>
      </Box>
    </Box>
  );
}
