import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import i18n from "i18next";

import picTC01 from "../images/01tc.png";
import picTC02 from "../images/02tc.png";
import picTC03 from "../images/03tc.png";

type Resource = {
  page: number;
  infos: {
    name: string;
    description: string;
    date: string;
    src: string;
    url: string;
    isOuterLink: boolean;
    category: string;
  }[];
};

const useResourcesData = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesMdToSm = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const isTC = i18n.language === "zh-TW";
  const resources = [
    // {
    //   page: 1,
    //   infos: [
    //     {
    //       name: "Petrochemical",
    //       description:
    //         "WISE-iFactory Industry Focus | 1 iFactory Electronics Manufacturing",
    //       date: "2023/04/14",
    //       src: !isTC ? pic01 : picTC01,
    //       url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-1-ifactory-electronics-en?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXdNv3estI.link",
    //       isOuterLink: true,
    //       category: t("intelligent-automation.video"),
    //     },
    //   ],
    // },
  ] as Resource[];

  const mdToSmResourcesData = [
    // {
    //   page: 1,
    //   infos: [
    //     {
    //       name: "Petrochemical",
    //       description:
    //         "WISE-iFactory Industry Focus | 1 iFactory Electronics Manufacturing",
    //       date: "2023/04/14",
    //       src: !isTC ? pic01 : picTC01,
    //       url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-1-ifactory-electronics-en?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXdNv3estI.link",
    //       isOuterLink: true,
    //       category: t("intelligent-automation.video"),
    //     },
    //   ],
    // },
  ] as Resource[];

  const downSmResourcesData = [
    // {
    //   page: 1,
    //   infos: [
    //     {
    //       name: "Petrochemical",
    //       description:
    //         "WISE-iFactory Industry Focus | 1 iFactory Electronics Manufacturing",
    //       date: "2023/04/14",
    //       src: !isTC ? pic01 : picTC01,
    //       url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-1-ifactory-electronics-en?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXdNv3estI.link",
    //       isOuterLink: true,
    //       category: t("intelligent-automation.video"),
    //     },
    //   ],
    // },
  ] as Resource[];

  const resourcesTC = [
    {
      page: 1,
      infos: [
        {
          name: "Petrochemical",
          description:
            "WISE-iFactory 產業特輯 | 2 雲端共創 - 智慧製造服務平台 助力金屬加工產業升級",
          category: t("intelligent-automation.video"),
          date: "2023/04/14",
          src: picTC01,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-2-ifactory-metal-tc?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXgniDeuBI.link",
          isOuterLink: true,
        },
        {
          name: "Petrochemical",
          description:
            "WISE-iFactory 產業特輯 | 3 大缺工時代 - 模具加工智慧化，從自動化到無人化的過程",
          category: t("intelligent-automation.video"),
          date: "2023/04/14",
          src: picTC02,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-3-ifactory-metal-tc?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXgt-Vfuxs.link",
          isOuterLink: true,
        },
        {
          name: "Petrochemical",
          description:
            "WISE-iFactory 產業特輯 | 4 扣件業轉骨轉型 - 製造數據輔助決策實務分享",
          category: t("intelligent-automation.video"),
          date: "2023/04/14",
          src: picTC03,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-4-ifactory-metal-tc?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXg0ku1wTw.link",
          isOuterLink: true,
        },
      ],
    },
  ];

  const mdToSmResourcesDataTC = [
    {
      page: 1,
      infos: [
        {
          name: "Petrochemical",
          description:
            "WISE-iFactory 產業特輯 | 2 雲端共創 - 智慧製造服務平台 助力金屬加工產業升級",
          category: t("intelligent-automation.video"),
          date: "2023/04/14",
          src: picTC01,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-2-ifactory-metal-tc?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXgniDeuBI.link",
          isOuterLink: true,
        },
        {
          name: "Petrochemical",
          description:
            "WISE-iFactory 產業特輯 | 3 大缺工時代 - 模具加工智慧化，從自動化到無人化的過程",
          category: t("intelligent-automation.video"),
          date: "2023/04/14",
          src: picTC02,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-3-ifactory-metal-tc?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXgt-Vfuxs.link",
          isOuterLink: true,
        },
      ],
    },
    {
      page: 2,
      infos: [
        {
          name: "Petrochemical",
          description:
            "WISE-iFactory 產業特輯 | 4 扣件業轉骨轉型 - 製造數據輔助決策實務分享",
          category: t("intelligent-automation.video"),
          date: "2023/04/14",
          src: picTC03,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-4-ifactory-metal-tc?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXg0ku1wTw.link",
          isOuterLink: true,
        },
      ],
    },
  ];

  const downSmResourcesDataTC = [
    {
      page: 1,
      infos: [
        {
          name: "Petrochemical",
          description:
            "WISE-iFactory 產業特輯 | 2 雲端共創 - 智慧製造服務平台 助力金屬加工產業升級",
          category: t("intelligent-automation.video"),
          date: "2023/04/14",
          src: picTC01,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-2-ifactory-metal-tc?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXgniDeuBI.link",
          isOuterLink: true,
        },
        {
          name: "Petrochemical",
          description:
            "WISE-iFactory 產業特輯 | 3 大缺工時代 - 模具加工智慧化，從自動化到無人化的過程",
          category: t("intelligent-automation.video"),
          date: "2023/04/14",
          src: picTC02,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-3-ifactory-metal-tc?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXgt-Vfuxs.link",
          isOuterLink: true,
        },
        {
          name: "Petrochemical",
          description:
            "WISE-iFactory 產業特輯 | 4 扣件業轉骨轉型 - 製造數據輔助決策實務分享",
          category: t("intelligent-automation.video"),
          date: "2023/04/14",
          src: picTC03,
          url: "https://connect.advantech.com/videos/details/wise-ifactory-focus-industry-4-ifactory-metal-tc?shareby=d73d9e27-8a4f-49c1-8b21-3763e0949462#.ZGXg0ku1wTw.link",
          isOuterLink: true,
        },
      ],
    },
  ];

  if (isTC) {
    return matchesDownSm
      ? downSmResourcesDataTC
      : matchesMdToSm
      ? mdToSmResourcesDataTC
      : resourcesTC;
  } else {
    return matchesDownSm
      ? downSmResourcesData
      : matchesMdToSm
      ? mdToSmResourcesData
      : resources;
  }
};

export { useResourcesData };
