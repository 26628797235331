import { useTranslation } from "react-i18next"

const useFeaturesData = () => {
  const {t} = useTranslation()
  const features = [
    {
      title: t('intelligent-automation.feature-title-one'),
      content:
        t('intelligent-automation.feature-content-one'),
    },
    {
      title: t('intelligent-automation.feature-title-two'),
      content:
        t('intelligent-automation.feature-content-two'),
    },
    {
      title: t('intelligent-automation.feature-title-three'),
      content:
        t('intelligent-automation.feature-content-three'),
    },
    {
      title: t('intelligent-automation.feature-title-four'),
      content:
        t('intelligent-automation.feature-content-four'),
    },
  ]
  return features
}

export { useFeaturesData }
