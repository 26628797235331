import React from "react";
import { Box } from "@mui/material";

const DashedDividingLine = () => {
  return (
    <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
      <div
        style={{
          width: "100%",
          border: "1px dashed #E1E1E1",
          position: "absolute",
          top: "50%",
          zIndex: -1,
        }}
      ></div>
    </Box>
  );
};

export default DashedDividingLine;
