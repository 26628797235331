import { Outlet } from "react-router-dom";
import { Footer, Header } from "../components";
import { HeaderProvider } from "../hooks";
import ScrollToTop from "./Home/ScrollTop";

export const Root = () => {
  return (
    <HeaderProvider>
      <div>
        <Header />
        <main>
          <ScrollToTop/>
          <Outlet />
        </main>
        <Footer />
      </div>
    </HeaderProvider>
  );
};
