import { useTranslation } from "react-i18next";

import lowCodePicture from "../images/lowcode.png";
import edgeToCloudPicture from "../images/edge-cloud.png";
import marketplacePicture from "../images/marketplace-scalability.png";

interface IFeatureData {
  title: string;
  description: string;
  src: string;
}

type IFeaturesData = IFeatureData[];

const useFeatureCardData = (): IFeaturesData => {
  const { t } = useTranslation();
  const features: IFeaturesData = [
    {
      title: t("home.low-code"),
      description: t("home.low-code-description"),
      src: lowCodePicture,
    },
    {
      title: t("home.edge-to-cloud"),
      description: t("home.edge-to-cloud-description"),
      src: edgeToCloudPicture,
    },
    {
      title: t("home.marketplace-scalability"),
      description: t("home.marketplace-scalability-description"),
      src: marketplacePicture,
    },
  ];

  return features;
};

export default useFeatureCardData;
