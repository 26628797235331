import { useTranslation } from "react-i18next";
import {
  imgFAQsQ1_1,
  imgFAQsQ1_2,
  imgFAQsQ2_1,
  imgFAQsQ3_1,
  imgFAQsQ3_2,
  imgFAQsQ3_3,
  imgFAQsQ3_4,
  imgFAQsQ3_5,
  imgFAQsQ3_6,
  imgFAQsQ3_7,
} from "../images";

export const useSolutionsData = () => {
  const { t } = useTranslation();
  let firstIntroData = {
    title: "",
    content: (
      <>
        <div>
          {t(
            "digital-transformation.digital-transformation-helps-organizations-use-digital-technologies"
          )}
        </div>
        <br />
        <div>
          {t(
            "digital-transformation.overall-kpi-dashboards-are-critical-for-companies-to-monitor"
          )}
        </div>
      </>
    ),
    videoSrc: t("digital-transformation.solution-one-video-url"),
  };

  let firstAnswer = [
    {
      id: "1",
      features: [
        {
          title: t("digital-transformation.provides-visibility"),
          content: t(
            "digital-transformation.a-kpi-dashboard-provides-a-quick-and-easy-way-to-visualize-the-performance-of-an-organization-or-project"
          ),
        },
        {
          title: t("digital-transformation.identifies-trends"),
          content: t(
            "digital-transformation.allowing-stakeholders-to-better-understand-the-impact-of-changes"
          ),
        },
        {
          title: t("digital-transformation.encourages-accountability"),
          content: t(
            "digital-transformation.tracking-kpis-and-sharing-results-fosters-accountability-motivation"
          ),
        },
        {
          title: t("digital-transformation.facilitates-communication"),
          content: t(
            "digital-transformation.ensure-everyone-is-on-the-same-page-and-working-towards-the-same-goals"
          ),
        },
      ],
      src: [imgFAQsQ1_1, imgFAQsQ1_2],
      reverse: false,
    },
  ];

  let secondIntroData = {
    title: "",
    content: (
      <>
        <div>
          {t(
            "digital-transformation.creating-a-dashboard-for-factory-operations-involves-identifying-relevant-kpis-collecting"
          )}
        </div>
        <br />
        <div>
          {t(
            "digital-transformation.data-collection-and-analysis-can-be-time-consuming-particularly-for-large-volumes-of-data"
          )}
        </div>
      </>
    ),
    videoSrc: t("digital-transformation.solution-two-video-url"),
  };

  let secondAnswer = [
    {
      id: "1",
      features: [
        {
          title: t("digital-transformation.data-preparation"),
          content: t(
            "digital-transformation.data-often-needs-to-be-collected-from-different-sources"
          ),
        },
        {
          title: t("digital-transformation.design-and-development"),
          content: t(
            "digital-transformation.users-needs-must-be-considered-and-appropriate-charts-and-visualization-tools-need-to-be-selected"
          ),
        },
        {
          title: t("digital-transformation.testing-and-validation"),
          content: t(
            "digital-transformation.ensures-the-accuracy-and-reliability-of-the-dashboard-with-repeated-testing"
          ),
        },
      ],
      src: [imgFAQsQ2_1],
      reverse: false,
    },
  ];

  let thirdIntroData = {
    title: "",
    content: (
      <>
        <div>
          {t(
            "digital-transformation.the-kpis-that-manufacturers-should-consider-for-digital-transformation-will-depend-on-their-specific-goals-and-objectives"
          )}
        </div>
        <ul>
          {[
            t("digital-transformation.overall-equipment-effectiveness-oee"),
            t("digital-transformation.cost-of-quality"),
            t("digital-transformation.lead-time-reduction"),
            t("digital-transformation.inventory-turnover"),
            t("digital-transformation.employee-productivity"),
          ].map((li) => {
            return (
              <li key={li} style={{ padding: "0 0 0 20px", margin: "0" }}>
                {li}
              </li>
            );
          })}
        </ul>
      </>
    ),
    videoSrc: t("digital-transformation.solution-three-video-url"),
  };

  let thirdAnswer = [
    {
      id: "1",
      features: [
        {
          title: t("digital-transformation.oee"),
          content: t(
            "digital-transformation.oee-measures-manufacturing-productivity-and-efficiency-based-on-availability-performance-and-quality"
          ),
        },
        {
          title: t("digital-transformation.cost-of-quality-coq"),
          content: t(
            "digital-transformation.coq-measures-costs-associated-with-meeting-customer-requirements"
          ),
        },
        {
          title: t("digital-transformation.lead-time-reduction"),
          content: t(
            "digital-transformation.lead-time-reduction-kpi-measures-time-from-order-placement"
          ),
        },
        {
          title: t("digital-transformation.inventory-turnover"),
          content: t(
            "digital-transformation.inventory-turnover-measures-how-efficiently-a-business-manages-inventory-and-avoids-overstocking-or-understocking-improving-profitability"
          ),
        },
      ],
      src: [
        imgFAQsQ3_1,
        imgFAQsQ3_2,
        imgFAQsQ3_3,
        imgFAQsQ3_3,
        imgFAQsQ3_4,
        imgFAQsQ3_5,
        imgFAQsQ3_6,
        imgFAQsQ3_7,
      ],
      reverse: false,
    },
  ];

  let solutions = [
    {
      id: 1,
      ...firstIntroData,
      answerCard: firstAnswer,
    },
    {
      id: 2,
      ...secondIntroData,
      answerCard: secondAnswer,
    },
    {
      id: 3,
      ...thirdIntroData,
      answerCard: thirdAnswer,
    },
  ];

  return solutions;
};
