import React, { useEffect, useRef, useState, createRef } from "react";
import { Box, BoxProps, Button, styled, Typography } from "@mui/material";

import { useHeader, useImageUtils } from "../../../hooks";
import { useTranslation } from "react-i18next";

import bannerFootwear from "./images/img_banner_footwear.png";
import bannerAVIFFootwear from "./images/img_banner_footwear.avif";
import bannerWebpFootwear from "./images/img_banner_footwear.webp";
import HITIPic from "./images/img_partner_HITI.png";
import eaiPic from "../../partner/finding-a-partner/images/img_partner_eAI.png";
import scrollPicture from "../../../images/scroll.png";
import scrollTCPicture from "../../../images/scroll_btn_green_tc.png";

import { ColoredTypography } from "../../../components/ColoredTypography/ColoredTypography";
import { InfoCard } from "../../../components/industries/InfoCard";
import { StepCard } from "../../../components/industries/StepCard";
import { PictureCard } from "../../../components/industries/PictureCard";

import { useStepCardsData } from "./data/stepCards";
import { useInfoCardsData } from "./data/infoCards";
import i18n from "i18next";
import AnchorBar from "../../../components/Common/AnchorBar";

import { DashedDividingLine, ResourcesBlock } from "@/components/Common";
import { useResourcesData } from "./data/useResourcesData";

const ScrollBtnBox = styled(Box)<BoxProps>({
  "@keyframes scrollBtnBox": {
    from: {
      transform: "translate(0)",
    },
    to: {
      transform: "translateY(20px)",
    },
  },
  animation: "scrollBtnBox 0.78s infinite ease-in-out alternate",
});

export const FootwearIndustry = () => {
  const { t } = useTranslation();
  const { visible } = useHeader();
  const { selectImage } = useImageUtils();
  const [isSticky, setIsSticky] = useState(false);
  const ref = createRef<HTMLElement>();
  const infoCards = useInfoCardsData();
  const stepCards = useStepCardsData();
  const resources = useResourcesData();

  useEffect(() => {
    const cachedRef = ref.current,
      observer = new IntersectionObserver(
        ([e]) => setIsSticky(e.intersectionRatio < 1),
        {
          threshold: [1],
          rootMargin: "-73px 0px 100px 0px",
        }
      );

    if (cachedRef) {
      observer.observe(cachedRef);
    }

    // unmount
    return function () {
      if (cachedRef) {
        observer.unobserve(cachedRef);
      }
    };
  }, []);

  const handleNavigateView = (
    e: React.MouseEvent<HTMLButtonElement>,
    elementId: string
  ) => {
    const scrolledView = document.querySelector(`#${elementId}`);
    if (!scrolledView) return;
    const scrollDown = scrolledView.getBoundingClientRect().top > 0;
    window.scrollBy({
      top: scrollDown
        ? scrolledView.getBoundingClientRect().top - 80
        : scrolledView.getBoundingClientRect().top - 150,
      behavior: "smooth",
    });
  };

  enum CursorStyle {
    default = "default",
    pointer = "pointer",
  }

  const firstBlockRef = useRef<HTMLElement>(null);

  const handleChangeCursor = (
    event: React.MouseEvent<HTMLDivElement>,
    cursorStyle: CursorStyle
  ) => {
    (event.target as HTMLElement).style.cursor = cursorStyle;
  };

  const handleNavigateFirstBlock = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    const firstRef = firstBlockRef!.current;
    if (!firstRef) return;
    firstRef.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(182, 240, 211, 0) 0%, rgba(182, 240, 211, 0.1) 100%), radial-gradient(73.19% 73.19% at 50% 26.81%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), url(${selectImage(
            {
              avif: bannerAVIFFootwear,
              webp: bannerWebpFootwear,
              default: bannerFootwear,
            }
          )})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            maxWidth: { md: 780, sm: 532, xs: 328 },
            marginTop: { xs: "20vh" },
          }}
        >
          <ColoredTypography
            variant="h2"
            textAlign="center"
            fontWeight="900"
            sx={{
              fontSize: {
                md: "48px",
                sm: "40px",
                xs: "28px",
                wordBreak: "break-word",
                whiteSpace: "break-spaces",
              },
            }}
          >
            {t("footwear.title")}
          </ColoredTypography>
          <Typography
            textAlign="center"
            color="#89898A"
            sx={{ marginTop: "20px", fontSize: { md: "20px", xs: "16px" } }}
          >
            {t("footwear.sub-title")}
          </Typography>
        </Box>
        <ScrollBtnBox sx={{ zIndex: 50, margin: "20px", padding: "8px 4px" }}>
          <div
            onMouseEnter={(event) =>
              handleChangeCursor(event, CursorStyle.pointer)
            }
            onClick={(event) => handleNavigateFirstBlock(event)}
          >
            <img
              alt="scroll-button"
              src={i18n.language === "zh-TW" ? scrollTCPicture : scrollPicture}
              style={{ maxWidth: "64px" }}
            />
          </div>
        </ScrollBtnBox>
      </div>
      <AnchorBar
        visible={visible}
        isSticky={isSticky}
        stepCards={stepCards}
        ref={ref}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        ref={firstBlockRef as React.RefObject<HTMLDivElement>}
      >
        <Box
          sx={{ maxWidth: { md: 780, sm: 532, xs: 328 }, marginTop: "80px" }}
        >
          <Typography
            textAlign="center"
            variant="h3"
            fontWeight="700"
            sx={{ fontSize: { md: "48px", xs: "28px" } }}
          >
            {t("footwear.main-title")}
          </Typography>
          <Typography
            textAlign="center"
            color={"GrayText"}
            sx={{ marginTop: "20px" }}
          >
            {t("footwear.main-content")}
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: { lg: 1180, md: 768, sm: 716, xs: 328 },
            marginTop: { md: "96px", xs: "20px" },
          }}
        >
          <Typography
            textAlign="center"
            variant="h6"
            fontWeight="700"
            fontSize={"2rem"}
            lineHeight={"3.25rem"}
            display={"flex"}
            justifyContent={"center"}
            position={"relative"}
          >
            <span
              style={{
                backgroundColor: "#fff",
                width: "auto",
                padding: "0 20px",
                fontSize: "1.5rem",
              }}
            >
              {t("footwear.pain-points")}
            </span>
            <div
              style={{
                width: "100%",
                border: "1px dashed #E1E1E1",
                position: "absolute",
                top: "50%",
                zIndex: -1,
              }}
            ></div>
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: {
                lg: "center",
                md: "flex-start",
                sm: "flex-start",
                xs: "center",
              },
              alignItems: "flex-start",
              marginTop: "20px",
              gap: "20px",
              flexWrap: { md: "wrap", sm: "wrap", xs: "wrap" },
              maxWidth: { lg: 1180, md: 716, sm: 716, xs: 328 },
            }}
          >
            {infoCards.map((info) => (
              <InfoCard cardInfo={info}></InfoCard>
            ))}
          </Box>

          <Typography
            textAlign="center"
            variant="h6"
            fontWeight="700"
            fontSize={"2rem"}
            lineHeight={"3.25rem"}
            display={"flex"}
            justifyContent={"center"}
            position={"relative"}
            padding={"40px 0"}
          >
            <div
              style={{
                width: "100%",
                border: "1px dashed #E1E1E1",
                position: "absolute",
                top: "50%",
                zIndex: -1,
              }}
            ></div>
          </Typography>
        </Box>
        <Box sx={{ maxWidth: "1280px", marginTop: { sm: "60px", xs: "32px" } }}>
          <Box
            sx={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "center",
              gap: "40px",
              flexWrap: "wrap",
            }}
          >
            {stepCards.map((stepCard) => (
              <StepCard
                stepCardInfo={stepCard}
                stepCards={stepCards}
              ></StepCard>
            ))}
          </Box>
        </Box>

        <div
          style={{
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "1280px",
          }}
        >
          <Box
            sx={{ maxWidth: { md: 780, sm: 532, xs: 328 }, marginTop: "40px" }}
          >
            <Typography
              textAlign="center"
              variant="h3"
              fontWeight="700"
              sx={{ fontSize: { md: "48px", sm: "40px", xs: "28px" } }}
            >
              {t("partners.dfsi")}
            </Typography>
            <Typography
              textAlign="center"
              color={"GrayText"}
              sx={{ marginTop: "20px" }}
            >
              {t(
                "partners.industrial-iot-iiot-involves-integrating-big-data-cloud-and-ai-applications-to-embrace-this-trend-advantech-is-collaborating-with-domain-focused-system-integrators-dfsis-to-develop-industrial-apps-i-apps"
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              width: "100%",
              maxWidth: { sm: "1180px", xs: "328px" },
              margin: "20px auto 0",
              paddingBottom: "90px",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <PictureCard
              cardInfo={{
                title: t("partners.hiti-industrial-automation"),
                content: t("partners.footwear-hiti-description"),
                src: HITIPic,
                flexDirection: "row-reverse",
                pictureAlign: "start",
                region: t("partners.taiwan-vietnam"),
              }}
            />
            <PictureCard
              cardInfo={{
                title: t("partners.eai-technologies-inc"),
                content: t("partners.eai-technologies-inc-description"),
                src: eaiPic,
                flexDirection: "row-reverse",
                pictureAlign: "start",
                region: t("partners.taiwan"),
              }}
            />
          </Box>
          {resources.length > 0 && (
            <>
              <Box
                sx={{
                  width: "100%",
                  height: "40px",
                  zIndex: 1,
                  marginBottom: "40px",
                }}
              >
                <DashedDividingLine />
              </Box>
              <Typography
                textAlign="center"
                variant="h3"
                fontWeight="700"
                sx={{ fontSize: { md: "48px", sm: "40px", xs: "28px" } }}
              >
                {t("intelligent-automation.resources")}
              </Typography>

              <ResourcesBlock resources={resources} />
              <Box sx={{ paddingBottom: "40px" }}></Box>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
