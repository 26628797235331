import { useTranslation } from "react-i18next";

const useInfoCardsData = () => {
  const { t } = useTranslation();
  const infoCards = [
    {
      step: "01",
      title: t("pcb.strictly-controlled-environment"),
      lists: [
        {
          content: t(
            "pcb.high-tech-production-lines-need-to-strictly-control-temperatures-humidity-and-dustfall-amounts-for-better-product-yield-rates-and-quality-control"
          ),
        },
      ],
    },
    {
      step: "02",
      title: t("pcb.voc-emissions"),
      lists: [
        {
          content: t(
            "pcb.know-when-volatile-organic-compounds-from-processes-using-hazardous-chemicals-exceed-limits"
          ),
        },
      ],
    },
    {
      step: "03",
      title: t("pcb.device-energy-consumption"),
      lists: [
        {
          content: t(
            "pcb.need-to-know-high-energy-consumption-processes-and-equipment-across-the-whole-plant"
          ),
        },
      ],
    },
    {
      step: "04",
      title: t("pcb.performance-tracking-difficulties"),
      lists: [
        {
          content: t(
            "pcb.kpis-are-difficult-to-track-so-the-performance-of-certain-devices-or-departments-in-large-scale-plants-is-unknown"
          ),
        },
      ],
    },
    {
      step: "05",
      title: t("pcb.complex-ghg-emission-sources"),
      lists: [
        {
          content: t(
            "pcb.complex-emission-source-categories-in-the-value-chain-make-it-hard-for-manufactures-to-list-specific-emitting-items"
          ),
        },
      ],
    },
    {
      step: "06",
      title: t("pcb.complicated-data-collection"),
      lists: [
        {
          content: t(
            "pcb.calculation-parameters-and-data-sources-make-data-management-complicated"
          ),
        },
      ],
    },
  ];
  return infoCards;
};

export { useInfoCardsData };
