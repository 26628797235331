import * as React from "react";
import GlobalStyles from "@mui/material/GlobalStyles";

const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      // "html[lang=zh-Hant-TW], body": {
      //   fontFamily:
      //     '"Roboto","微軟正黑體", "Microsoft JhengHei", "Arial", "Helvetica", "sans-serif"',
      // },
      // "html[lang=en-US], body": {
      //   fontFamily:
      //     '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif',
      // },
      // "html[lang=ja], body": {
      //   fontFamily:
      //    '"Arial",-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif',
      // },
    }}
  />
);

export default function GlobalCssOverride() {
  return <React.Fragment>{inputGlobalStyles}</React.Fragment>;
}
