import stepPicture02 from "../../../../images/img_progress2_p.png";
import stepPciture02l from "../../../../images/img_progress2_l.png";
import stepPicture03 from "../../../../images/img_progress3_p.png";
import stepPicture03l from "../../../../images/img_progress3_l.png";
import stepPicture06 from "../../../../images/img_progress6_p.png";
import stepPicture06l from "../../../../images/img_progress6_l.png";

import ShopfloorPic from "../../../../images/ShopFloor.png";
import mic770Pic from "../../../../images/img_device_MIC-770-V2.png";
import eAIGatewayPic from "../../../../images/img_device_eAI_Lora_Gateway.png";
import eAINLS105Pic from "../../../../images/img_device_eAI_LoRa_IoT_Box.png";
import BarCodePic from "../../../../images/img_device_scanner.png";
import OEEPic from "../../../../images/OEE.png";
import AddOnPic from "../../../../images/AddOn.png";

import ECU1051Pic from "../../../../images/img_device_ECU-1051.png";
import ADAM4017Pic from "../../../../images/img_device_ADAM-4017.png";
import ADAM4018Pic from "../../../../images/img_device_ADAM-4118.png";
import meterCounterPic from "../../../../images/img_device_meter_counter.png";
import temperatureSensorPic from "../../../../images/img_device_temperature_sensor.png";

import itemPic from "../../../../images/item.png";
import { Box } from "@mui/material";
import { useTranslation, Trans } from "react-i18next";

const useStepCardsData = () => {
  const { t } = useTranslation();
  const stepCards = [
    {
      stepName: t("footwear.sewing"),
      elementId: "Sewing",
      src: {
        md: stepPicture02,
        sm: stepPciture02l,
      },
      flexDirection: "row",
      starterPackages: [
        {
          name: t("footwear.real-time-progress"),
          packages: [
            {
              page: 1,
              title: t("footwear.stitching-garment"),
              content: t("footwear.making-shoe-uppers-and-using-stitching"),
              devices: [
                {
                  src: ShopfloorPic,
                  title: t("products.shop-floor"),
                  code: "9804EBSFC00",
                },
                {
                  src: mic770Pic,
                  title: t("products.mic770v2-i5-edge"),
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: eAIGatewayPic,
                  title: t("products.eai-lora-gateway-slg1211"),
                },
                {
                  src: eAINLS105Pic,
                  title: t("products.eai-lora-iot-box-nls105"),
                },
                {
                  src: BarCodePic,
                  title: (
                    <Trans
                      i18nKey="products.barcode-scanner-rs233"
                      components={{ br: <br /> }}
                    />
                  ),
                },
              ],
            },
            {
              page: 2,
              title: t("footwear.knitting-machine"),
              content: t("footwear.making-shoe-uppers-and-using-stitching"),
              devices: [
                {
                  src: ShopfloorPic,
                  title: t("products.shop-floor"),
                  code: "9804EBSFC00",
                },
                {
                  src: mic770Pic,
                  title: t("products.mic770v2-i5-edge"),
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: eAIGatewayPic,
                  title: t("products.eai-lora-gateway-slg1211"),
                },
                {
                  src: eAINLS105Pic,
                  title: t("products.eai-lora-iot-box-nls105"),
                },
              ],
            },
          ],
        },
        {
          name: t("footwear.quick-response"),
          packages: [
            {
              page: 1,
              title: t("footwear.stitching-garment-knitting"),
              content: t("footwear.making-shoe-uppers-and-using-stitching"),
              devices: [
                {
                  src: OEEPic,
                  title: "OEE",
                  code: "9804EBOEE00",
                },
                {
                  src: AddOnPic,
                  title: t("products.andon"),
                  code: "9804EBADN00",
                },
                {
                  src: mic770Pic,
                  title: t("products.mic770v2-i5-edge"),
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: eAIGatewayPic,
                  title: t("products.eai-lora-gateway-slg1211"),
                },
                {
                  src: eAINLS105Pic,
                  title: t("products.eai-lora-iot-box-nls105"),
                },
              ],
            },
          ],
          url: "/starter-packages/quick-response",
        },
      ],
    },
    {
      stepName: t("footwear.assembly"),
      elementId: "Assembly",
      src: {
        md: stepPicture03,
        sm: stepPicture03l,
      },
      flexDirection: "row-reverse",
      starterPackages: [
        {
          name: t("footwear.quick-response"),
          packages: [
            {
              page: 1,
              title: t("footwear.oven"),
              content: (
                <Box>
                  <Box>
                    {t(
                      "footwear.the-control-of-oven-temperature-is-of-particular-importance-for-quality-and-affects-the-tightness-of-the-shoe"
                    )}
                  </Box>
                  <Box sx={{ marginTop: "20px" }}>
                    {t(
                      "footwear.in-order-to-improve-overall-efficiency-and-output-quality-accurate-and-effective-data-collection-and-reliable-information-analysis-are-important-in-the-shoe-assembly-process"
                    )}
                  </Box>
                </Box>
              ),
              devices: [
                {
                  src: OEEPic,
                  title: "OEE",
                  code: "9804EBOEE00",
                },
                {
                  src: AddOnPic,
                  title: t("products.andon"),
                  code: "9804EBADN00",
                },
                {
                  src: mic770Pic,
                  title: t("products.mic770v2-i5-edge"),
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: meterCounterPic,
                  title: t("products.meter-counter"),
                },
                {
                  src: temperatureSensorPic,
                  title: t("products.temperature-sensor"),
                },
              ],
            },
            {
              page: 2,
              title: t("footwear.cooling"),
              content: (
                <Box>
                  <Box>
                    {t(
                      "footwear.the-temperature-of-the-cooling-machine-and-the-speed-of-the-conveyor-belt-are-important-parameters-for-controlling-the-finished-shoe-shape"
                    )}
                  </Box>
                  <Box sx={{ marginTop: "20px" }}>
                    {t(
                      "footwear.in-order-to-improve-overall-efficiency-and-output-quality-accurate-and-efficient-data-collection-and-reliable-information-analysis-are-important-in-the-shoe-assembly-process"
                    )}
                  </Box>
                </Box>
              ),
              devices: [
                {
                  src: OEEPic,
                  title: t("products.oee"),
                  code: "9804EBOEE00",
                },
                {
                  src: AddOnPic,
                  title: t("products.andon"),
                  code: "9804EBADN00",
                },
                {
                  src: mic770Pic,
                  title: t("products.mic770v2-i5-edge"),
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: meterCounterPic,
                  title: t("products.meter-counter"),
                },
                {
                  src: temperatureSensorPic,
                  title: t("products.temperature-sensor"),
                },
              ],
            },
          ],
          url: "/starter-packages/quick-response",
        },
      ],
    },
    {
      stepName: t("footwear.sole-making"),
      elementId: "SoleMaking",
      src: {
        md: stepPicture06,
        sm: stepPicture06l,
      },
      flexDirection: "row",
      starterPackages: [
        {
          name: t("footwear.quick-response"),
          packages: [
            {
              page: 1,
              title: t("footwear.eva-ip-modeling-machine"),
              content: (
                <Box>
                  <Box>
                    {t(
                      "footwear.whether-it-is-injection-molding-or-hot-press-molding-temperature-pressure-and-recipe-management-are-always-the-key-to-quality-in-the-sole-manufacturing-process"
                    )}
                  </Box>
                  <Box sx={{ marginTop: "20px" }}>
                    {t(
                      "footwear.the-lack-of-systematic-data-collection-of-traditional-equipment-is-the-main-reason-why-the-quantity-of-input-materials-and-output-yield-are-not-consistent"
                    )}
                  </Box>
                </Box>
              ),
              devices: [
                {
                  src: OEEPic,
                  title: t("products.oee"),
                  code: "9804EBOEE00",
                },
                {
                  src: AddOnPic,
                  title: t("products.andon"),
                  code: "9804EBADN00",
                },
                {
                  src: mic770Pic,
                  title: t("products.mic770v2-i5-edge"),
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: ECU1051Pic,
                  title: t("products.ecu-1051"),
                  code: "SRP-IFS420-E12TAEA",
                },
                {
                  src: ADAM4017Pic,
                  title: t("products.adam-4017"),
                  code: t("products.adam-4017-code"),
                },
                {
                  src: ADAM4018Pic,
                  title: t("products.adam-4118"),
                  code: t("products.adam-4118-code"),
                },
              ],
            },
          ],
          url: "/starter-packages/quick-response",
        },
      ],
    },
  ];

  return stepCards;
};

export { useStepCardsData };
