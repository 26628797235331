import { createContext, useContext, useEffect, useMemo, useState } from "react";

export interface HeaderContextType {
  visible: boolean;
  lock: () => void;
  unlock: () => void;
}

export const HeaderContext = createContext<HeaderContextType>({
  visible: true,
  lock: () => {},
  unlock: () => {},
});

export const HeaderProvider = ({ children }: { children: React.ReactNode }) => {
  const [locked, setLocked] = useState(false);
  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const value = useMemo<HeaderContextType>(
    () => ({
      visible: visible || locked,
      lock: () => {
        setLocked(true);
        setVisible(true);
      },
      unlock: () => setLocked(false),
    }),
    [locked, visible]
  );

  useEffect(() => {
    const handleScroll = () => {
      const moving = window.scrollY;
      if (Math.abs(position - moving) > 8 || moving <= 10) {
        setVisible(position > moving || moving <= 10);
      }
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
};

export const useHeader = () => {
  return useContext(HeaderContext);
};
