import { Box, Button, Typography } from "@mui/material";
import { ColoredTypography } from "../ColoredTypography/ColoredTypography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React from "react";
import type { Architecture } from "../../routes/solutions/solution-AI/types/architecture";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface SolutionCardProps {
  architecture: Architecture;
  isBtnShow?: boolean;
}

export const SolutionCard = ({
  architecture,
  isBtnShow = true,
}: SolutionCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToPage = (url: string | undefined, isOuterLink: boolean) => {
    if (!url) return;
    if (isOuterLink) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        gap: "20px",
        minHeight: { md: "440px", xs: "auto" },
        padding: { md: "0 0 20px" },
        flexDirection: { md: "row", xs: "column" },
        boxSizing: "border-box",
        justifyContent: "center",
        borderRadius: "4px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: { md: "280px", sm: "100%", xs: "100%" },
          display: "flex",
          flexDirection: { md: "column", xs: "row" },
          alignItems: "flex-start",
          padding: { md: "0 10px 0 0", sm: "0 20px 0", xs: "0 20px 0" },
          gap: "4px",
          borderRight: { md: "4px solid", xs: 0 },
          borderLeft: { md: 0, xs: "4px solid" },
          borderBottom: 0,
          borderTop: 0,
          borderImage: "linear-gradient(135deg, #008CD6 0%, #00CC66 100%) 1",
          boxSizing: "border-box",
        }}
      >
        <ColoredTypography
          fontSize="48px"
          fontStyle="italic"
          fontWeight="700"
          lineHeight="56px"
          padding="0 10px 0 0 !important"
        >
          {architecture.informations.length}
        </ColoredTypography>
        <Box sx={{ width: { md: "auto", xs: "100%" } }}>
          <ColoredTypography
            fontSize="32px"
            fontStyle="italic"
            fontWeight="700"
            lineHeight="38px"
            padding="0 10px 0 0 !important"
            sx={{
              whiteSpace: "break-spaces !important",
            }}
          >
            {architecture.title}
          </ColoredTypography>
          <Typography fontSize="14px" fontWeight="400" color="#89898A">
            {architecture.description}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: "880px",
          gap: "20px",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {architecture.types === "packages"
          ? architecture.informations.map((info) => (
              <Box
                sx={{
                  boxSizing: "border-box",
                  width: "100%",
                  maxWidth: { md: "280px", xs: "348px" },
                  minHeight: "200px",
                  height: "200px",
                  padding: "20px",
                  border: "1px solid #C8C8C8",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { md: "flex-end", xs: "flex-start" },
                  gap: "4px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "4px",
                    width: "100%",
                    maxWidth: "240px",
                    height: "auto",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      alignItems: "flex-start",
                      width: "100%",
                      maxWidth: "240px",
                      minHeight: "132px",
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography
                      sx={{
                        width: "100%",
                        maxWidth: "240px",
                        fontWeight: "700",
                        fontSize: "16px",
                        lineHeight: "24px",
                        alignItems: "center",
                        whiteSpace: info.titleNoWrap ? "nowrap" : "normal",
                        "-webkit-line-clamp": {
                          xs: "2",
                        },
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        "-webkit-box-orient": "vertical",
                        overflow: "hidden",
                      }}
                    >
                      {info.title}
                    </Typography>
                    <Typography
                      sx={{
                        width: "100%",
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#89898A",
                        overflowX: "hidden",
                        "-webkit-line-clamp": {
                          xs: "6",
                        },
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        "-webkit-box-orient": "vertical",
                        overflow: "hidden",
                      }}
                    >
                      {info.description}
                    </Typography>
                  </Box>
                  {info.tag ? (
                    <Box
                      sx={{
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0 4px",
                        width: "22px",
                        height: "16px",
                        background: "#00CC66",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "12px",
                          lineHeight: "16px",
                          color: "white",
                        }}
                      >
                        {info!.tag}
                      </Typography>
                    </Box>
                  ) : undefined}
                </Box>
                {isBtnShow && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      sx={{
                        color: "#008CD6",
                        fontWeight: 700,
                        fontSize: "16px",
                        textTransform: "none",
                        padding: "0",
                      }}
                      onClick={() => {
                        goToPage(info.url ?? "", !!info.isOuterLink);
                      }}
                    >
                      <Typography color="#008CD6" fontWeight="700">
                        {t("landing-page.common.learn-more")}
                      </Typography>

                      <ChevronRightIcon style={{ color: "#008CD6" }} />
                    </Button>
                  </Box>
                )}
              </Box>
            ))
          : architecture.types === "apps"
          ? architecture.informations.map((info) => (
              <Box
                sx={{
                  boxSizing: "border-box",
                  width: "100%",
                  maxWidth: { md: "280px", xs: "348px" },
                  minHeight: "200px",
                  height: "200px",
                  padding: "20px",
                  border: "1px solid #C8C8C8",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { md: "flex-end", xs: "flex-start" },
                  gap: "4px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: " flex-start",
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: "60px",
                      maxHeight: "60px",
                      borderRadius: "6px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={info.src as string}
                      style={{ width: "100%" }}
                      alt={info.title as string}
                    />
                  </Box>
                  <Box
                    sx={{
                      boxSizing: "border-box",
                      width: "100%",
                      maxWidth: "160px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      padding: "4px 0",
                      alignItems: "center",
                      height: "132px",
                    }}
                  >
                    <Typography
                      sx={{
                        width: "100%",
                        fontWeight: "700",
                        fontSize: "16px",
                        lineHeight: "24px",
                        alignItems: "center",
                        color: "#323233",
                        "-webkit-line-clamp": {
                          xs: "2",
                        },
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        "-webkit-box-orient": "vertical",
                        overflow: "hidden",
                      }}
                    >
                      {info.title}
                    </Typography>

                    <Typography
                      sx={{
                        width: "100%",
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#89898A",
                        maxHeight: "88px",
                        overflowX: "hidden",
                        "-webkit-line-clamp": {
                          md: "4",
                          xs: "6",
                        },
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        "-webkit-box-orient": "vertical",
                        overflow: "hidden",
                      }}
                    >
                      {info.description}
                    </Typography>
                  </Box>
                </Box>

                {isBtnShow && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      sx={{
                        color: "#008CD6",
                        fontWeight: 700,
                        fontSize: "16px",
                        textTransform: "none",
                        padding: "0",
                      }}
                      onClick={() => {
                        goToPage(info.url ?? "", !!info.isOuterLink);
                      }}
                    >
                      <Typography color="#008CD6" fontWeight="700">
                        {t("landing-page.common.learn-more")}
                      </Typography>

                      <ChevronRightIcon style={{ color: "#008CD6" }} />
                    </Button>
                  </Box>
                )}
              </Box>
            ))
          : architecture.informations.map((info) => (
              <Box
                sx={{
                  boxSizing: "border-box",
                  width: "100%",
                  maxWidth: { md: "280px", xs: "348px" },
                  minHeight: "200px",
                  height: "200px",
                  padding: "20px",
                  border: "1px solid #C8C8C8",
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "4px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        width: "100%",
                        maxWidth: "214px",
                        fontWeight: "700",
                        fontSize: "16px",
                        lineHeight: "24px",
                        alignItems: "center",
                        "-webkit-line-clamp": {
                          xs: "2",
                        },
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        "-webkit-box-orient": "vertical",
                        overflow: "hidden",
                      }}
                    >
                      {info.title}
                    </Typography>
                    <Box
                      sx={{
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0 4px",
                        width: "22px",
                        height: "16px",
                        background: "#00CC66",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "12px",
                          lineHeight: "16px",
                          color: "white",
                        }}
                      >
                        {info!.tag}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      width: "100%",
                      fontWeight: "400",
                      fontSize: "12px",
                      lineHeight: "16px",
                      color: "#89898A",
                      height: "96px",
                      "-webkit-line-clamp": {
                        xs: "6",
                      },
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      "-webkit-box-orient": "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {info.description}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {(info.src as string[]).map((src) => (
                    <Box sx={{ width: "100%", maxWidth: "24px" }}>
                      <img src={src} style={{ width: "100%" }} alt={src} />
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
      </Box>
    </Box>
  );
};
