import { useTranslation } from "react-i18next";
import type { Pages, StaterPackagePages } from "../types/Pages";

const useConfigRoutes = () => {
  const { t } = useTranslation();
  const devRoutes: Pages = [
    {
      name: t("landing-page.header.industries"),
      path: "/industries",
      children: [
        {
          name: t("landing-page.header.footwear"),
          path: "/industries/footwear",
          children: [],
        },
        {
          name: t("landing-page.header.metal-process"),
          path: "/industries/metal-processing",
          children: [],
        },
        {
          name: t("landing-page.header.electronics"),
          path: "/industries/electronics-and-electrics",
          children: [],
        },
        {
          name: t("landing-page.header.food-and-beverage"),
          path: "/industries/food-and-beverage",
          children: [],
        },
        {
          name: t("landing-page.header.pcb-industry"),
          path: "/industries/pcb",
          children: [],
        },
      ],
    },
    {
      name: t("landing-page.header.starter-packages"),
      path: "/starter-package",
      children: [
        {
          name: t("landing-page.header.connected-factory-worker") as string,
          path: "",
          children: [
            {
              name: t("landing-page.header.work-mentor"),
              path: "/starter-packages/work-mentor",
              children: [],
            },
          ],
        },
        {
          name: t("landing-page.header.digitize-core-process") as string,
          path: "",
          children: [
            {
              name: t("landing-page.header.mobile-operational-inspection"),
              path: t('landing-page.header.mobile-operational-inspection-path'),
              children: [],
            },
            {
              name:  t("landing-page.header.real-time-progress"),
              path: t('landing-page.header.real-time-progress-path'),
              children: [],
            },
          ],
        },
        {
          name: t("landing-page.header.equipment-performance"),
          path: "",
          children: [
            {
              name: t(
                "landing-page.header.high-energy-equipment-monitoring"
              ),
              path: t('landing-page.header.high-energy-equipment-monitoring-path'),
              children: [],
            },
            {
              name: t('landing-page.header.machine-inspector'),
              path: t('landing-page.header.starter-packages-machine-inspector'),
              children:[]
            },
            {
              name: t("landing-page.header.quick-response"),
              path: t('landing-page.header.quick-response-path'),
              children: [],
            },
            {
              name: t("landing-page.header.smart-maintenance"),
              path: t('landing-page.header.smart-maintenance-path'),
              children: [],
            }
          ],
        },
        
      ],
    },
    {
      name: t("landing-page.header.solutions"),
      path: "/solutions",
      children: [

        {
          name: t("landing-page.header.intelligent-automation"),
          path: "/solutions/intelligent-automation",
          children: [],
        },
        {
          name: t('ifactoryworks.ifactoryworks'),
          path: "/solutions/ifactoryworks",
          children: [],
        },
        {
          name: t("landing-page.header.digital-transformation"),
          path: "/solutions/digital-transformation",
          children: [],
        },
      ],
    },
    {
      name: t("landing-page.header.partner"),
      path: "/partner",
      children: [
        {
          name: t("landing-page.header.finding-a-partner"),
          path: "/partner/finding-partners",
          children: [],
        },
        {
          name: t("landing-page.header.becoming-a-partner"),
          path: "/partner/becoming-partners",
          children: [],
        },
      ],
    },
    {
      name: t("landing-page.header.resources"),
      path: "",
      children: [
        {
          name: t("landing-page.header.online-forum-vod"),
          path: t("landing-page.header.subscription-service-url"),
          children: [],
          outerLink: true,
        },
        {
          name: t("landing-page.header.video"),
          path: t(
            "landing-page.header.accelerator-for-smart-manufacturing-digital-transformation-url"
          ),
          children: [],
          outerLink: true,
        },
        {
          name: t("landing-page.header.magazine"),
          path: "",
          children: [
            {
              name: t("landing-page.header.smart-manufacturing-ecosystem"),
              path: t("landing-page.header.smart-manufacturing-ecosystem-url"),
              children: [],
              outerLink: true,
            },
            {
              name: t(
                "landing-page.header.reshaping-manufacturing-business-operations-with-industrial-iot"
              ),
              path: t(
                "landing-page.header.reshaping-manufacturing-business-operations-with-industrial-iot-url"
              ),
              children: [],
              outerLink: true,
            },
          ],
        },
        {
          name: t("landing-page.header.case-study"),
          path: t("landing-page.header.case-study-url"),
          children: [],
          outerLink: true,
        },
        {
          name: t("landing-page.header.iiot-articles"),
          path: t("landing-page.header.iiot-articles-url"),
          children: [],
          outerLink: true,
        },
        {
          name: t("landing-page.header.technical-documentation"),
          path: t("landing-page.header.wise-ifactory-solutions-url"),
          children: [],
          outerLink: true,
        },
        {
          name: t("landing-page.header.iot-academy"),
          path: t("landing-page.header.ifactory-url"),
          children: [],
          outerLink: true,
        },
      ],
    },
  ];

  const productionRoutes = [
    {
      name: t("landing-page.header.industries"),
      path: "/industries",
      children: [
        {
          name: t("landing-page.header.footwear"),
          path: "/industries/footwear",
          children: [],
        },
        {
          name: t("landing-page.header.metal-process"),
          path: "/industries/metal-processing",
          children: [],
        },
        {
          name: t("landing-page.header.electronics"),
          path: "/industries/electronics-and-electrics",
          children: [],
        },
        {
          name: t("landing-page.header.food-and-beverage"),
          path: "/industries/food-and-beverage",
          children: [],
        },
        {
          name: t("landing-page.header.pcb-industry"),
          path: "/industries/pcb",
          children: [],
        },
      ],
    },
    {
      name: t("landing-page.header.starter-packages"),
      path: "/starter-package",
      children: [
        {
          name: t("landing-page.header.connected-factory-worker") as string,
          path: "",
          children: [
            {
              name: t("landing-page.header.work-mentor"),
              path: "/starter-packages/work-mentor",
              children: [],
            },
          ],
        },
        {
          name: t("landing-page.header.digitize-core-process") as string,
          path: "",
          children: [
            {
              name: t("landing-page.header.mobile-operational-inspection"),
              path: t('landing-page.header.mobile-operational-inspection-path'),
              children: [],
            },
            {
              name:  t("landing-page.header.real-time-progress"),
              path: t('landing-page.header.real-time-progress-path'),
              children: [],
            },
          ],
        },
        {
          name: t("landing-page.header.equipment-performance"),
          path: "",
          children: [
            {
              name: t(
                "landing-page.header.high-energy-equipment-monitoring"
              ),
              path: t('landing-page.header.high-energy-equipment-monitoring-path'),
              children: [],
            },
            {
              name: t('landing-page.header.machine-inspector'),
              path: t('landing-page.header.starter-packages-machine-inspector'),
              children:[]
            },
            {
              name: t("landing-page.header.quick-response"),
              path: t('landing-page.header.quick-response-path'),
              children: [],
            },
            {
              name: t("landing-page.header.smart-maintenance"),
              path: t('landing-page.header.smart-maintenance-path'),
              children: [],
            },
          
          ],
        },
        
      ],
    },
    {
      name: t("landing-page.header.solutions"),
      path: "/solutions",
      children: [
        {
          name: t("landing-page.header.intelligent-automation"),
          path: "/solutions/intelligent-automation",
          children: [],
        },
        {
          name: t('ifactoryworks.ifactoryworks'),
          path: "/solutions/ifactoryworks",
          children: [],
        },
        {
          name: t("landing-page.header.digital-transformation"),
          path: "/solutions/digital-transformation",
          children: [],
        },
      ],
    },
    {
      name: t("landing-page.header.partner"),
      path: "/partner",
      children: [
        {
          name: t("landing-page.header.finding-a-partner"),
          path: "/partner/finding-partners",
          children: [],
        },
        {
          name: t("landing-page.header.becoming-a-partner"),
          path: "/partner/becoming-partners",
          children: [],
        },
      ],
    },
    {
      name: t("landing-page.header.resources"),
      path: "",
      children: [
        {
          name: t("landing-page.header.online-forum-vod"),
          path: t("landing-page.header.subscription-service-url"),
          children: [],
          outerLink: true,
        },
        {
          name: t("landing-page.header.video"),
          path: t(
            "landing-page.header.accelerator-for-smart-manufacturing-digital-transformation-url"
          ),
          children: [],
          outerLink: true,
        },
        {
          name: t("landing-page.header.magazine"),
          path: "",
          children: [
            {
              name: t("landing-page.header.smart-manufacturing-ecosystem"),
              path: t("landing-page.header.smart-manufacturing-ecosystem-url"),
              children: [],
              outerLink: true,
            },
            {
              name: t(
                "landing-page.header.reshaping-manufacturing-business-operations-with-industrial-iot"
              ),
              path: t(
                "landing-page.header.reshaping-manufacturing-business-operations-with-industrial-iot-url"
              ),
              children: [],
              outerLink: true,
            },
          ],
        },
        {
          name: t("landing-page.header.case-study"),
          path: t("landing-page.header.case-study-url"),
          children: [],
          outerLink: true,
        },
        {
          name: t("landing-page.header.iiot-articles"),
          path: t("landing-page.header.iiot-articles-url"),
          children: [],
          outerLink: true,
        },
        {
          name: t("landing-page.header.technical-documentation"),
          path: t("landing-page.header.wise-ifactory-solutions-url"),
          children: [],
          outerLink: true,
        },
        {
          name: t("landing-page.header.iot-academy"),
          path: t("landing-page.header.ifactory-url"),
          children: [],
          outerLink: true,
        },
      ],
    },
  ];

  return process.env.REACT_APP_DEBUG ? devRoutes : productionRoutes;
};

const useConfigStarterPackageRoutes = () => {
  const { t } = useTranslation();
  const developmentStarterPackageRoutes: StaterPackagePages = [
    {
      name: "Starter Packages",
      children: [
        {
          name: "Connected Factory Worker",
          title: t("landing-page.header.connected-factory-worker") as string,
          content: t(
            "landing-page.header.lower-human-error-and-bias-labor-productivity-and-consistency"
          ) as string,
          children: [
            {
              name: "Work Mentor",
              title: t("landing-page.header.work-mentor"),
              path: "/starter-packages/work-mentor",
              content: t(
                "landing-page.header.record-recipes-formulas-and-work-sops-to-guide-on-site-operators"
              ),
              children: [],
              isOuterLink: false,
            },
          ],
        },
        {
          name: "Digitize Core Process",
          title: t("landing-page.header.digitize-core-process") as string,
          content: t(
            "landing-page.header.improved-data-quality-eliminate-non-value-ass-manual-activities"
          ) as string,
          children: [
            {
              name: "Mobile Operational Inspection",
              title: t("landing-page.header.mobile-operational-inspection"),
              path: "/starter-packages/mobile-operational-inspection",
              content: t(
                "landing-page.header.mobile-operational-inspection-content"
              ),
              children: [],
              isOuterLink: false,
            },
            {
              name: "Real-time Progress",
              title: t("landing-page.header.real-time-progress"),
              path: "/starter-packages/real-time-progress",
              content: t(
                "landing-page.header.notifications-for-supervisors-and-managers-when-equipment-productivity-drops"
              ),
              children: [],
              isOuterLink: false,
            },
          ],
        },
        {
          name: "Equipment Performance",
          title: t("landing-page.header.equipment-performance") as string,
          content: t(
            "landing-page.header.less-downtime-with-unplanned-event-handling-energy-management"
          ) as string,
          children: [
            {
              name: "High-energy Equipment Monitoring",
              title: t(
                "landing-page.header.high-energy-equipment-monitoring"
              ) as string,
              path: "/starter-packages/high-energy-equipment-monitoring",
              content: t(
                "landing-page.header.high-energy-equipment-monitoring-content"
              ) as string,
              children: [],
            },
            {
              name: "Machine Inspector",
              title: t("landing-page.header.machine-inspector"),
              path: "/starter-packages/machine-inspector",
              content: t(
                "landing-page.header.zero-downtime-machine-doctor-focuses-on-detecting-specific-parameters-of-machines-and-gives-an-appropriate-early-diagnosis-evaluation-to-avoid-unplanned-issues"
              ),
              children: [],
            },
            {
              name: "Quick Response",
              title: t("landing-page.header.quick-response"),
              path: "/starter-packages/quick-response",
              content: t(
                "landing-page.header.warnings-and-reports-to-the-relevant-staff-in-case-of-equipment-abnormalities"
              ),
              children: [],
            },
            {
              name: "Smart Maintenance",
              title: t("landing-page.header.smart-maintenance") as string,
              path: "/starter-packages/smart-maintenance",
              content: t(
                "landing-page.header.with-big-data-analysis-solution-to-pre-diagnose-machine-early-stage-anomalies-to-achieve-near-zero-downtime-and-increase-factory-productivity"
              ) as string,
              children: [],
            },
          ],
        },
        {
          name: "Visual Quality",
          title: t("landing-page.header.visual-quality") as string,
          content: t(
            "landing-page.header.add-compliance-reduce-scrap-rework-capture-images-at-source"
          ) as string,
          children: [
            // {
            //   name: "Defect Smart Detection",
            //   title: t("landing-page.header.defect-smart-detection"),
            //   path: "",
            //   content: t(
            //     "landing-page.header.real-time-defect-detection-and-classification-to-reduce-the-cost-of-material-waste-and-manual-inspection"
            //   ),
            //   children: [],
            //   isOuterLink: true,
            // },
            {
              name: "PPE Smart Detection",

              title: t("landing-page.header.ppe-smart-detection"),
              path: "",
              content: t(
                "landing-page.header.detects-lack-of-personal-protective-equipment-or-if-incorrect-worn-through-video-ai"
              ),
              children: [],
              isOuterLink: true,
            },
            {
              name: "SOP Smart Monitoring",
              title: t("landing-page.header.sop-smart-monitoring"),
              path: "",
              content: t(
                "landing-page.header.real-time-streaming-video-ai-analysis-to-monitor-if-employees-are-following-cleaning-sop"
              ),
              children: [],
              isOuterLink: true,
            },
          ],
        },
      ],
    },
  ];

  const productionStarterPackageRoutes = [
    {
      name: "Starter Packages",
      children: [
        {
          name: "Connected Factory Worker",
          title: t("landing-page.header.connected-factory-worker") as string,
          content: t(
            "landing-page.header.lower-human-error-and-bias-labor-productivity-and-consistency"
          ) as string,
          children: [
            {
              name: "Work Mentor",
              title: t("landing-page.header.work-mentor"),
              path: "/starter-packages/work-mentor",
              content: t(
                "landing-page.header.record-recipes-formulas-and-work-sops-to-guide-on-site-operators"
              ),
              children: [],
              isOuterLink: false,
            },
          ],
        },
        {
          name: "Digitize Core Process",
          title: t("landing-page.header.digitize-core-process") as string,
          content: t(
            "landing-page.header.improved-data-quality-eliminate-non-value-ass-manual-activities"
          ) as string,
          children: [
            {
              name: "Mobile Operational Inspection",
              title: t("landing-page.header.mobile-operational-inspection"),
              path: "/starter-packages/mobile-operational-inspection",
              content: t(
                "landing-page.header.mobile-operational-inspection-content"
              ),
              children: [],
              isOuterLink: false,
            },
            {
              name: "Real-time Progress",
              title: t("landing-page.header.real-time-progress"),
              path: "/starter-packages/real-time-progress",
              content: t(
                "landing-page.header.notifications-for-supervisors-and-managers-when-equipment-productivity-drops"
              ),
              children: [],
              isOuterLink: false,
            },
          ],
        },
        {
          name: "Equipment Performance",
          title: t("landing-page.header.equipment-performance") as string,
          content: t(
            "landing-page.header.less-downtime-with-unplanned-event-handling-energy-management"
          ) as string,
          children: [
            {
              name: "High-energy Equipment Monitoring",
              title: t(
                "landing-page.header.high-energy-equipment-monitoring"
              ) as string,
              path: "/starter-packages/high-energy-equipment-monitoring",
              content: t(
                "landing-page.header.high-energy-equipment-monitoring-content"
              ) as string,
              children: [],
            },
            {
              name: "Machine Inspector",
              title: t("landing-page.header.machine-inspector"),
              path: "/starter-packages/machine-inspector",
              content: t(
                "landing-page.header.zero-downtime-machine-doctor-focuses-on-detecting-specific-parameters-of-machines-and-gives-an-appropriate-early-diagnosis-evaluation-to-avoid-unplanned-issues"
              ),
              children: [],
            },
            {
              name: "Quick Response",
              title: t("landing-page.header.quick-response"),
              path: "/starter-packages/quick-response",
              content: t(
                "landing-page.header.warnings-and-reports-to-the-relevant-staff-in-case-of-equipment-abnormalities"
              ),
              children: [],
            },
            {
              name: "Smart Maintenance",
              title: t("landing-page.header.smart-maintenance") as string,
              path: "/starter-packages/smart-maintenance",
              content: t(
                "landing-page.header.with-big-data-analysis-solution-to-pre-diagnose-machine-early-stage-anomalies-to-achieve-near-zero-downtime-and-increase-factory-productivity"
              ) as string,
              children: [],
            },
          ],
        },
        {
          name: "Visual Quality",
          title: t("landing-page.header.visual-quality") as string,
          content: t(
            "landing-page.header.add-compliance-reduce-scrap-rework-capture-images-at-source"
          ) as string,
          children: [
            // {
            //   name: "Defect Smart Detection",
            //   title: t("landing-page.header.defect-smart-detection"),
            //   path: "",
            //   content: t(
            //     "landing-page.header.real-time-defect-detection-and-classification-to-reduce-the-cost-of-material-waste-and-manual-inspection"
            //   ),
            //   children: [],
            //   isOuterLink: true,
            // },
            {
              name: "PPE Smart Detection",

              title: t("landing-page.header.ppe-smart-detection"),
              path: "",
              content: t(
                "landing-page.header.detects-lack-of-personal-protective-equipment-or-if-incorrect-worn-through-video-ai"
              ),
              children: [],
              isOuterLink: true,
            },
            {
              name: "SOP Smart Monitoring",
              title: t("landing-page.header.sop-smart-monitoring"),
              path: "",
              content: t(
                "landing-page.header.real-time-streaming-video-ai-analysis-to-monitor-if-employees-are-following-cleaning-sop"
              ),
              children: [],
              isOuterLink: true,
            },
          ],
        },
      ],
    },
  ];

  return process.env.REACT_APP_DEBUG
    ? developmentStarterPackageRoutes
    : productionStarterPackageRoutes;
};

export { useConfigRoutes, useConfigStarterPackageRoutes };
