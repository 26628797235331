import type {
  apps,
  Architectures,
  packages,
  transformative,
} from "../types/architecture";
import { infoTypes } from "../types/architecture";
import fakePicture from "../images/src/unknownItem.png";
import activityRecognitionPic from "../images/src/activity_recognition_img.png";
import energyIntensivePic from "../images/src/energy_intense_optimization_img.png";
import inspectionAIPic from "../images/src/inspection_ai_img.png";
import phmPic from "../images/src/phm_img.png";
import smartSchedulingPic from "../images/src/smart_scheduling_img.png";
import spcPic from "../images/src/spc_img.png";
import { useTranslation, Trans } from "react-i18next";
import addOnPic from "../../../../images/AddOn.png";
import eManualPic from "../../../../images/eManual.png";
import maintenancePic from "../../../../images/img_service_maintenance.png";
import oeePic from "../../../../images/OEE.png";
import shopfloorPic from "../../../../images/ShopFloor.png";
import trackerPic from "../../../../images/img_service_sc-tracker.png";

const useArchitecturesData = () => {
  const { t } = useTranslation();
  // let starterPackageInfo: packages[] = [
  //   {
  //     title: t("solutions.tpm.machine-inspector"),
  //     description: t(
  //       "solutions.tpm.zero-downtime-machine-doctor-focuses-on-detecting-specific-parameters-of-machines-and-gives-an-appropriate-early-diagnosis-evaluation-to-avoid-unplanned"
  //     ),
  //     src: fakePicture,
  //     tag: "4b",
  //   },
  //   {
  //     title: t("solutions.tpm.quick-response"),
  //     description: t(
  //       "solutions.tpm.warn-and-report-to-the-relevant-personnel-in-case-of-equipment-abnormalities"
  //     ),
  //     src: fakePicture,
  //     tag: "4c",
  //     url: "/starter-packages/quick-response",
  //   },
  //   {
  //     title: t("solutions.tpm.real-time-progress"),
  //     description: t(
  //       "solutions.tpm.notify-the-supervisor-and-manager-immediately-when-equipment-productivity-drops"
  //     ),
  //     src: fakePicture,
  //     tag: "3b",
  //   },
  //   {
  //     title: t("solutions.tpm.work-mentor"),
  //     description: t(
  //       "solutions.tpm.record-recipe-formula-and-work-sop-guiding-on-site-operator-and-providing-standard-formula-and-recipes"
  //     ),
  //     src: fakePicture,
  //     tag: "2b",
  //   },
  // ];
  //
  // let appInfo: apps[] = [
  //   {
  //     title: t("solutions.tpm.andon"),
  //     description: t(
  //       "solutions.tpm.manage-abonormal-event-and-report-to-quickly-return-to-normal-production"
  //     ),
  //     src: addOnPic,
  //     url: t("products.addon-url") as string,
  //     isOuterLink: true,
  //   },
  //   {
  //     title: t("solutions.tpm.emanual"),
  //     description: t(
  //       "solutions.tpm.digital-sop-provides-accurate-product-information-and-fast-updates-which-shortens-the-learning-curve-for-operators"
  //     ),
  //     src: eManualPic,
  //     url: t("products.emanual-url") as string,
  //     isOuterLink: true,
  //   },
  //   {
  //     title: t("solutions.tpm.maintenance"),
  //     description: t(
  //       "solutions.tpm.machine-maintenance-and-repair-strategy-planner-for-production-field"
  //     ),
  //     src: maintenancePic,
  //     url: t("products.maintenance-url") as string,
  //     isOuterLink: true,
  //   },
  //   {
  //     title: t("solutions.tpm.oee"),
  //     description: t(
  //       "solutions.tpm.monitor-and-analysis-machine-and-production-kpi-oee-in-real-time"
  //     ),
  //     src: oeePic,
  //     url: t("products.oee-url") as string,
  //     isOuterLink: true,
  //   },
  //   {
  //     title: (
  //       <Trans i18nKey="solutions.tpm.sc-tracker" components={{ br: <br /> }} />
  //     ),
  //     description: t(
  //       "solutions.tpm.synchronize-work-progress-across-suppliers-for-timely-delivery"
  //     ),
  //     src: trackerPic,
  //     url: "",
  //     isOuterLink: true,
  //   },
  //   {
  //     title: t("solutions.tpm.shop-floor"),
  //     description: t(
  //       "solutions.tpm.production-tracking-scheduling-and-status-reporting"
  //     ),
  //     src: shopfloorPic,
  //     url: t("products.shop-floor-url") as string,
  //     isOuterLink: true,
  //   },
  // ];

  let transformativeInfo: transformative[] = [
    {
      title: t("solutions.tpm.automated-insights", { lng: "en-US" }),
      description: t(
        "solutions.tpm.support-for-user-driven-combination-of-data-from-different-sources-and-the-creation-of-analytic-models-such-as-user-defined-measures-sets-groups-and-hierarchies-and-a-core-attribute-of-augmented-analytics-ability-to-apply-machine-learning-ml-techniques-to-automatically-generate-insights-for-end-users",
        { lng: "en-US" }
      ),
      src: [fakePicture, fakePicture],
    },
    {
      title: t("solutions.tpm.cloud-enabled-analytics", { lng: "en-US" }),
      description: t(
        "solutions.tpm.the-ability-to-build-deploy-and-manage-analytics-and-analytic-applications-in-the-cloud-based-on-data-both-in-the-cloud-and-on-premises-and-across-multi-cloud-deployments",
        { lng: "en-US" }
      ),
      src: [fakePicture, fakePicture],
    },
    {
      title: t("solutions.tpm.data-storytelling", { lng: "en-US" }),
      description: t(
        "solutions.tpm.to-generate-news-style-data-stories-communities-of-professionals-it-ot-et-alignment-and-convergence-improved-knowledge-creation-and-management-combining-headlines-narrative-text-data-visualizations-and-audiovisual-content-based-on-the-ongoing-monitoring-of-findings",

        { lng: "en-US" }
      ),
      src: [fakePicture, fakePicture],
    },
    {
      title: t("solutions.tpm.data-visualization", { lng: "en-US" }),
      description: t(
        "solutions.tpm.support-for-highly-interactive-dashboards-and-the-exploration-of-data-through-the-manipulation-of-chart-images-this-includes-an-array-of-visualization-options-that-go-beyond-those-of-pie-bar-and-line-charts-such-as-heat-and-tree-maps-geographic-maps-scatter-plots-and-other-special-purpose-visuals",

        { lng: "en-US" }
      ),
      src: [fakePicture, fakePicture],
      titleNoWrap: true,
    },
    {
      title: t("solutions.tpm.digital-source-connectivity", { lng: "en-US" }),
      description: t(
        "solutions.tpm.capabilities-that-enable-users-to-connect-to-and-ingest-data-contained-in-various-types-of-storage-platforms-both-on-premises-and-in-the-cloud-ifs-code-make-it-easy-to-display-content-to-make-it-easy-to-find-and-consume-the-catalog-is-searchable-and-makes-recommendations-to-users",
        { lng: "en-US" }
      ),
      src: [fakePicture, fakePicture],
    },
  ];
  const architectures: Architectures = [
    // {
    //   title: t("solutions.ai.starter_packages"),
    //   description: t(
    //     "solutions.ai.improve_daily_work_by_baselining_performance"
    //   ),
    //   types: infoTypes.packages,
    //   informations: starterPackageInfo,
    // },
    // {
    //   title: t("solutions.ai.optimization_iapps"),
    //   description: t(
    //     "solutions.ai.a_combination_of_datadriven_applications_data"
    //   ),
    //   types: infoTypes.apps,
    //   informations: appInfo,
    // },
    {
      title: t("solutions.ai.knowledge_transformative", { lng: "en-US" }),
      description: t(
        "solutions.ai.improved_decision_support_customer_centric",
        { lng: "en-US" }
      ),
      types: infoTypes.packages,
      informations: transformativeInfo,
    },
  ];

  return architectures;
};
export { useArchitecturesData };
