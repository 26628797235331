import {styled, Typography, TypographyProps} from "@mui/material";

interface BlueColoredTypographyProps {
  children: React.ReactNode;
}

export const BlueColoredTypography =  styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    background: "linear-gradient(135deg, #008CD6 0%, #0B4CD9 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    padding: "0 10px !important",
    whiteSpace: "nowrap",
  })
);
