import { Typography } from "@mui/material";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  if (isRouteErrorResponse(error)) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h3">{error.statusText}</Typography>
        <Typography variant="h3">{error.status}</Typography>
        {/* <Typography variant="h3">{error.error?.name}</Typography> */}
        <Typography variant="h5">{error.statusText}</Typography>
      </div>
    );
  } else if (error instanceof Error) {
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h3">{error.name}</Typography>
      <Typography variant="h3">{error.message}</Typography>
    </div>;
  }

  return <div>Error</div>;
};
