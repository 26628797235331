import { useTranslation } from "react-i18next";

type Feature = {
  title: string;
  content: string;
}

const useFeaturesData = (): Feature[] => {
  let { t } = useTranslation();
  const features: Feature[] = [
    {
      title: t('digital-transformation.dashboard-and-situation-center'),
      content:
        t('digital-transformation.dashboard-and-situation-centers-take-data-sources'),
    },
    {
      title: t('digital-transformation.kpi-engine'),
      content:
        t('digital-transformation.a-kpi-engine-can-create-your-companys-own-management-performance-formula'),
    },
    {
      title: t('digital-transformation.dashboard-templates'),
      content:
        t('digital-transformation.various-dashboard-templates-are-pre-designed'),
    },
  ];
  return features;
};

export { useFeaturesData };
