import { Box, useTheme } from "@mui/material";
import SolutionCard from "./SolutionCard";
import React, { useState } from "react";
import useSolutionCardData from "../../routes/Home/data/SolutionCardData";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import useMediaQuery from "@mui/material/useMediaQuery";
import DashedDividingLine from "../Common/DashedDividingLine";

const HomeSolutions: React.FC = () => {
  const solutionData = useSolutionCardData();

  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesBetweenSmToMd = useMediaQuery(
    theme.breakpoints.between("sm", "md")
  );

  const [activeSolutionTitle, setActiveSolutionTitle] = useState(
    solutionData[0].solutions[0].title
  );

  const handleEnterSolutionCard = (title: string) => {
    setActiveSolutionTitle(title);
  };

  const [activePage, setActivePage] = useState(0);
  const [transformIndex, setTransformIndex] = useState(0);

  const handleClickNextPage = () => {
    setActivePage((activePage) => activePage + 1);
    setTransformIndex((transformIndex) => transformIndex + 1);
  };
  const handleClickPreviousPage = () => {
    setActivePage((activePage) => activePage - 1);
    setTransformIndex((transformIndex) => transformIndex - 1);
  };

  const WebSolutions = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            maxWidth: { md: "1180px", sm: "716px", xs: "328px" },
            height: { md: "auto", sm: "408px", xs: "auto" },
            boxSizing: "border-box",
            overflow: "hidden",
          }}
        >
          <Box
            width={`${100 * solutionData!.length}%`}
            height="auto"
            sx={{
              display: "flex",
              transition: "0.3s ease-in-out",
              transform: `translateX(${transformIndex * -100}%)`,
            }}
          >
            {solutionData.map((solutions) => (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexShrink: 0,
                  justifyContent: { md: "flex-start", sm: "center" },
                  gap: { md: 0, sm: "20px" },
                }}
              >
                {solutions.solutions.map((solution) => (
                  <Box
                    onMouseEnter={() => handleEnterSolutionCard(solution.title)}
                    sx={{
                      height: { sm: "auto", xs: "200px" },
                    }}
                  >
                    <SolutionCard
                      solution={solution}
                      active={solution.title === activeSolutionTitle}
                    />
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        </Box>
        {matchesBetweenSmToMd ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: { sm: "auto", xs: "100%" },
            }}
          >
            <IconButton
              sx={{
                transition: "0.3s ease-in",
                backgroundColor: "white !important",
              }}
              onClick={handleClickPreviousPage}
              disabled={activePage === 0}
            >
              <NavigateBeforeIcon sx={{ backgroundColor: "white" }} />
            </IconButton>

            <Typography
              textAlign="left"
              color={"GrayText"}
              sx={{ display: "inline-block", backgroundColor: "white" }}
            >
              {(activePage + 1).toString()} / {solutionData!.length}
            </Typography>

            <IconButton
              sx={{
                transition: "0.3s ease-in",
                backgroundColor: "white !important",
              }}
              onClick={handleClickNextPage}
              disabled={activePage === solutionData.length - 1}
            >
              <NavigateNextIcon />
            </IconButton>
            <Box
              sx={{
                width: "100%",
                position: "absolute",
                maxWidth: { sm: "732px", xs: "328px" },
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: -1,
              }}
            >
              <DashedDividingLine />
            </Box>
          </Box>
        ) : undefined}
      </Box>
    );
  };

  const MobileSolutions = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            maxWidth: { md: "1180px", sm: "716px", xs: "328px" },
            flexWrap: "wrap",
            height: { xs: "auto" },
            boxSizing: "border-box",
            overflow: "hidden",
          }}
        >
          {solutionData.map((solutions) => (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: { md: "flex-start", sm: "center" },
                flexWrap: "wrap",
                gap: "16px",
              }}
            >
              {solutions.solutions.map((solution) => (
                <Box
                  onMouseEnter={() => handleEnterSolutionCard(solution.title)}
                >
                  <SolutionCard
                    solution={solution}
                    active={solution.title === activeSolutionTitle}
                  />
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  return <>{!matchesDownSm ? WebSolutions() : MobileSolutions()}</>;
};

export default HomeSolutions;
