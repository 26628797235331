import { useTranslation } from "react-i18next";

import footwearPicture from "../images/img_industry_footwear.png";
import footwearAvifPicture from "../images/img_industry_footwear.avif";
import footwearWebpPicture from "../images/img_industry_footwear.webp";
import metalPicture from "../images/img_industry_metal.png";
import metalAvifPicture from "../images/img_industry_metal.avif";
import metalWebpPicture from "../images/img_industry_metal.webp";
import electronicPicture from "../images/img_industry_electronics.png";
import electronicAvifPicture from "../images/img_industry_electronics.avif";
import electronicWebpPicture from "../images/img_industry_electronics.webp";
import pcbPicture from "../images/img_industry_PCB.png";
import pcbAvifPicture from "../images/img_industry_PCB.avif";
import pcbWebpPicture from "../images/img_industry_PCB.webp";
import foodPicture from "../images/img_industry_food.png";
import foodAvifPicture from "../images/img_industry_food.avif";
import foodWebPPicture from "../images/img_industry_food.webp";
import pulpPicture from "../images/img_industry_paper.png";
import pulpAvifPicture from "../images/img_industry_paper.avif";
import pulpWebpPicture from "../images/img_industry_paper.webp";
import {
  IndustryData,
  Industry,
} from "../../../components/home/types/Industry";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { useImageUtils } from "../../../hooks";

const useIndustriesCardData = (): IndustryData => {
  const { t } = useTranslation();
  const { selectImage } = useImageUtils();
  const theme = useTheme();
  const matchesMdToSm = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const industryData: IndustryData = [
    {
      page: 1,
      industries: [
        {
          title: t("home.footwear"),
          description: t("home.footwear-description"),
          src: selectImage({
            avif: footwearAvifPicture,
            webp: footwearWebpPicture,
            default: footwearPicture,
          }),
          url: "/industries/footwear",
        },
        {
          title: t("home.metal-processing"),
          description: t("home.metal-processing-description"),
          src: selectImage({
            avif: metalAvifPicture,
            webp: metalWebpPicture,
            default: metalPicture,
          }),
          url: "/industries/metal-processing",
        },
        {
          title: t("home.electronics-and-electrical-machinery"),
          description: t(
            "home.electronics-and-electrical-machinery-description"
          ),
          src: selectImage({
            avif: electronicAvifPicture,
            webp: electronicWebpPicture,
            default: electronicPicture,
          }),
          url: "/industries/electronics-and-electrics",
        },
      ],
    },
    {
      page: 2,
      industries: [
        {
          title: t("home.pcb"),
          description: t("home.pcb-description"),
          src: selectImage({
            avif: pcbAvifPicture,
            webp: pcbWebpPicture,
            default: pcbPicture,
          }),
          url: "/industries/pcb",
        },
        {
          title: t("home.food-and-beverage"),
          description: t("home.food-and-beverage-description"),
          src: selectImage({
            avif: foodAvifPicture,
            webp: foodWebPPicture,
            default: foodPicture,
          }),
          url: "/industries/food-and-beverage",
        },
        {
          title: t("home.pulp-and-paper"),
          description: t("home.pulp-and-paper-description"),
          src: selectImage({
            avif: pulpAvifPicture,
            webp: pulpWebpPicture,
            default: pulpPicture,
          }),
          url: t("home.pulp-url") as string,
          isOuterLink: true,
        },
      ],
    },
  ];

  const mdToSmIndustryData: IndustryData = [
    {
      page: 1,
      industries: [
        {
          title: t("home.footwear"),
          description: t("home.footwear-description"),
          src: selectImage({
            avif: footwearAvifPicture,
            webp: footwearWebpPicture,
            default: footwearPicture,
          }),
          url: "/industries/footwear",
        },
        {
          title: t("home.metal-processing"),
          description: t("home.metal-processing-description"),
          src: selectImage({
            avif: metalAvifPicture,
            webp: metalWebpPicture,
            default: metalPicture,
          }),
          url: "/industries/metal-processing",
        },
      ],
    },
    {
      page: 2,
      industries: [
        {
          title: t("home.electronics-and-electrical-machinery"),
          description: t(
            "home.electronics-and-electrical-machinery-description"
          ),
          src: selectImage({
            avif: electronicAvifPicture,
            webp: electronicWebpPicture,
            default: electronicPicture,
          }),
          url: "/industries/electronics-and-electrics",
        },
        {
          title: t("home.pcb"),
          description: t("home.pcb-description"),
          src: selectImage({
            avif: pcbAvifPicture,
            webp: pcbWebpPicture,
            default: pcbPicture,
          }),
          url: "/industries/pcb",
        },
      ],
    },
    {
      page: 3,
      industries: [
        {
          title: t("home.food-and-beverage"),
          description: t("home.food-and-beverage-description"),
          src: selectImage({
            avif: foodAvifPicture,
            webp: foodWebPPicture,
            default: foodPicture,
          }),
          url: "/industries/food-and-beverage",
        },
        {
          title: t("home.pulp-and-paper"),
          description: t("home.pulp-and-paper-description"),
          src: selectImage({
            avif: pulpAvifPicture,
            webp: pulpWebpPicture,
            default: pulpPicture,
          }),
          url: t("home.pulp-url") as string,
          isOuterLink: true,
        },
      ],
    },
  ];
  return matchesMdToSm ? mdToSmIndustryData : industryData;
};

export default useIndustriesCardData;
