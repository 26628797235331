import stepPicture01 from "../images/metal_progress1_p.png";
import stepPicture01l from "../images/metal_progress1_l.png";
import stepPicture02 from "../images/metal_progress2_p.png";
import stepPicture02l from "../images/metal_progress2_l.png";
import stepPicture03 from "../images/metal_progress3_p.png";
import stepPicture03l from "../images/metal_progress3_l.png";
import stepPicture04 from "../images/metal_progress4_p.png";
import stepPicture04l from "../images/metal_progress4_l.png";
import stepPicture05 from "../images/metal_progress5_p.png";
import stepPicture05l from "../images/metal_progress5_l.png";

import uno137Pic from "../../../../images/img_device_UNO-137.png";
import shopfloorPic from "../../../../images/ShopFloor.png";
import maintenancePic from "../../../../images/img_service_maintenance.png";
import ecu1051Pic from "../../../../images/img_device_ECU-1051.png";
import ecu1251Pic from "../../../../images/img_device_ECU-1051.png";
import Mic7702Pic from "../../../../images/img_device_MIC-770-V2.png";
import tpc107wPic from "../../../../images/img_device_TPC-107W.png";
import barcodeScannerPic from "../../../../images/img_device_scanner.png";
import OEEPic from "../../../../images/OEE.png";
import AddOnPic from "../../../../images/AddOn.png";
import emanualPic from "../../../../images/eManual.png";
import aim75sPic from "../../../../images/img_device_AIM-75S.png";

import itemPic from "../images/unknownItem.png";
import { useTranslation, Trans } from "react-i18next";

const useStepCardsData = () => {
  const { t } = useTranslation();
  const stepCards = [
    {
      stepName: t("metal-process.Molding"),
      elementId: "Molding",
      src: {
        md: stepPicture01,
        sm: stepPicture01l,
      },
      flexDirection: "row",
      starterPackages: [
        {
          name: t("metal-process.machine-inspector"),
          packages: [
            {
              page: 1,
              title: t("metal-process.injection-molding"),
              content: t("metal-process.injection-molding-content"),
              devices: [
                {
                  src: maintenancePic,
                  title: t("products.maintenance"),
                  code: "9803IAMNT00",
                },
                {
                  src: uno137Pic,
                  title: t("products.uno-137"),
                  code: "ESRP-1IMM-UNO137",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      stepName: t("metal-process.forming"),
      elementId: t("metal-process.forming"),
      src: {
        md: stepPicture02,
        sm: stepPicture02l,
      },
      flexDirection: "row-reverse",
      starterPackages: [
        {
          name: t("metal-process.machine-inspector"),
          packages: [
            {
              page: 1,
              title: t("metal-process.stamping"),
              content: t("metal-process.stamping-content"),
              devices: [
                {
                  src: maintenancePic,
                  title: t("products.maintenance"),
                  code: "9803IAMNT00",
                },
                {
                  src: uno137Pic,
                  title: t("products.uno-137"),
                  code: "IFS-RTM-UNO137",
                },
                {
                  src: ecu1051Pic,
                  title: t("products.ecu-1051"),
                  code: "SRP-IFS420-E12TAE",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      stepName: t("metal-process.machining"),
      elementId: "Machining",
      src: {
        md: stepPicture03,
        sm: stepPicture03l,
      },
      flexDirection: "row",
      starterPackages: [
        {
          name: t("metal-process.machine-inspector"),
          packages: [
            {
              page: 1,
              title: t("metal-process.milling"),
              content: t("metal-process.milling-content"),
              devices: [
                {
                  src: maintenancePic,
                  title: t("products.maintenance"),
                  code: "9803IAMNT00",
                },
                {
                  src: uno137Pic,
                  title: t("products.uno-137"),
                  code: "IFS-RTM-UNO137",
                },
              ],
            },
            {
              page: 2,
              title: t("metal-process.turning"),
              content: t("metal-process.turning-content"),
              devices: [
                {
                  src: maintenancePic,
                  title: t("products.maintenance"),
                  code: "9803IAMNT00",
                },
                {
                  src: uno137Pic,
                  title: t("products.uno-137"),
                  code: "IFS-RTM-UNO137",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      stepName: t("metal-process.surface"),
      elementId: "SurfaceTreatment",
      src: {
        md: stepPicture04,
        sm: stepPicture04l,
      },
      flexDirection: "row-reverse",
      starterPackages: [
        {
          name: t("metal-process.quick-response"),
          packages: [
            {
              page: 1,
              title: t("metal-process.coating"),
              content: t("metal-process.coating-content"),
              devices: [
                {
                  src: OEEPic,
                  title: t("products.oee"),
                  code: "",
                },
                {
                  src: AddOnPic,
                  title: t("products.addon"),
                  code: "",
                },
                {
                  src: Mic7702Pic,
                  title: t("products.mic770v2-i5-edge"),
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: tpc107wPic,
                  title: t("products.tpc-100w"),
                  code: "TPC-100W Series",
                },
                {
                  src: ecu1251Pic,
                  title: t("products.ecu-1251"),
                  code: "SRP-IFS420-E14TAE",
                },
                {
                  src: barcodeScannerPic,
                  title: (
                    <Trans
                      i18nKey="products.barcode-scanner-rs233"
                      components={{ br: <br /> }}
                    />
                  ),
                },
              ],
            },
          ],
          url: "/starter-packages/quick-response",
        },
        {
          name: t("metal-process.machine-inspector"),
          packages: [
            {
              page: 1,
              title: t("metal-process.plating"),
              content: t("metal-process.plating-content"),
              devices: [
                {
                  src: maintenancePic,
                  title: t("products.maintenance"),
                  code: "9803IAMNT00",
                },
                {
                  src: uno137Pic,
                  title: t("products.uno-137"),
                  code: "IFS-RTM-UNO137",
                },
                {
                  src: ecu1051Pic,
                  title: t("products.ecu-1051"),
                  code: "SRP-IFS420-E12TAE",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      stepName: t("metal-process.qa-and-packaging"),
      elementId: "QualityAssurancePackaging",
      src: {
        md: stepPicture05,
        sm: stepPicture05l,
      },
      flexDirection: "row",
      starterPackages: [
        {
          name: t("metal-process.real-time-progress"),
          packages: [
            {
              page: 1,
              title: t("metal-process.quality-assurance"),
              content: t("metal-process.big-data-big-question-content"),
              devices: [
                {
                  src: shopfloorPic,
                  title: "Shop Floor",
                  code: "",
                },
                {
                  src: Mic7702Pic,
                  title: "MIC770V2 i5 Edge",
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: barcodeScannerPic,
                  title: (
                    <Trans
                      i18nKey="products.barcode-scanner-rs233"
                      components={{ br: <br /> }}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          name: t("metal-process.work-mentor"),
          packages: [
            {
              page: 1,
              title: t("metal-process.packaging"),
              content: t("metal-process.packaging-content"),
              devices: [
                {
                  src: emanualPic,
                  title: "eManual",
                  code: "9804EIEMN00",
                },
                {
                  src: Mic7702Pic,
                  title: "MIC770V2 i5 Edge",
                  code: "",
                },
                {
                  src: tpc107wPic,
                  title: "TPC-100W",
                  code: "TPC-100W",
                },
                {
                  src: aim75sPic,
                  title: "AIM-75S",
                  code: t("products.ifs-aim75s-imb-code"),
                },
                {
                  src: barcodeScannerPic,
                  title: (
                    <Trans
                      i18nKey="products.barcode-scanner-rs233"
                      components={{ br: <br /> }}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  return stepCards;
};

export { useStepCardsData };
