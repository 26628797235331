import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface DeviceInfo {
  src: string;
  title: string | JSX.Element;
  content?: string;
  code?: string;
}

interface InfoCardProps {
  cardInfo: DeviceInfo;
}

export const PackageDeviceCard = ({ cardInfo }: InfoCardProps) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        maxWidth: { sm: "113.33px", xs: "96.67px" },
        borderRadius: "7px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        minHeight: { md: "auto", sm: "116px" },
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: { sm: "80px", xs: "70px" },
          maxHeight: { sm: "80px", xs: "70px" },
          overflow: "hidden",
          borderRadius: "8px",
        }}
      >
        <img
          src={cardInfo.src}
          alt="product introduction"
          style={{
            width: "100%",
          }}
        />
      </Box>
      <Typography
        variant="h6"
        textAlign="center"
        sx={{
          fontSize: { sm: "16px", xs: "14px" },
          maxWidth: "113.33px",
          display: "-webkit-box",
          textOverflow: "ellipsis",
          overflow: "hidden",
          "-webkit-line-clamp": cardInfo.code ? "1" : "2",
          "-webkit-box-orient": "vertical",
        }}
      >
        {cardInfo.title}
      </Typography>
      {cardInfo.code ? (
        <Typography
          variant="h6"
          textAlign="center"
          color="#89898A"
          sx={{
            fontSize: "12px",
            maxWidth: "113.33px",
            display: "-webkit-box",
            textOverflow: "ellipsis",
            overflow: "hidden",
            "-webkit-line-clamp": cardInfo.code ? "1" : "2",
            "-webkit-box-orient": "vertical",
          }}
        >
          {cardInfo.code}
        </Typography>
      ) : undefined}
    </Box>
  );
};
