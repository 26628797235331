import ecoPicture from "../images/img_partner_ECO.png";
import servtechPicture from "../images/img_partner_Servtech.png";
import churPicture from "../images/img_partner_Chur.png";
import { useTranslation } from "react-i18next";

const usePartnersData = () => {
  const { t } = useTranslation();
  const partners = [
    {
      name: t("partners.chur-co-ltd"),
      description: t("partners.chur-co-ltd-description"),
      region: t("partners.taiwan"),
      src: churPicture,
    },
    {
      name: t("partners.eco-industrial"),
      description: t("partners.eco-industrial-description"),
      region: t("partners.brazil"),
      src: ecoPicture,
    },
    {
      name: t("partners.servtech-co-ltd"),
      description: t("partners.servtech-description"),
      region: t("partners.taiwan"),
      src: servtechPicture,
    },
  ];
  return partners;
};

export { usePartnersData };
