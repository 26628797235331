import { useTranslation } from "react-i18next";

const useInfoCardsData = () => {
  const { t } = useTranslation();
  const infoCards = [
    {
      step: "01",
      title: t("metal-process.exist-paper-work"),
      lists: [
        {
          content: t("metal-process.exist-paper-work-content"),
        },
      ],
    },
    {
      step: "02",
      title: t("metal-process.profession-training-cost"),
      lists: [
        {
          content: t("metal-process.profession-training-cost-content"),
        },
      ],
    },
    {
      step: "03",
      title: t("metal-process.customer-audit"),
      lists: [
        {
          content: t("metal-process.customer-audit-content"),
        },
      ],
    },
    {
      step: "04",
      title: t("metal-process.information-silo"),
      lists: [
        {
          content: t("metal-process.information-silo-content"),
        },
      ],
    },
    {
      step: "05",
      title: t("metal-process.big-data-big-question"),
      lists: [
        {
          content: t("metal-process.big-data-big-question-content"),
        },
      ],
    },
  ];
  return infoCards;
};

export { useInfoCardsData };
