import {
  Box,
  List,
  ListItem,
  Typography,
  ListItemButton,
  useTheme,
  useMediaQuery,
  Collapse,
} from "@mui/material";
import { Fragment, useState } from "react";
import DashedDividingLine from "../../../components/Common/DashedDividingLine";
import AppCard from "../../../components/solutions/AppCard";
import PackageCard from "../../../components/solutions/PackageCard";
import { useQuestionsData } from "./data/questions";
import { useTranslation } from "react-i18next";

export default function SolutionTPMNeed() {
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const theme = useTheme();
  const queryUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const queryUpXs = useMediaQuery(theme.breakpoints.up("xs"));
  const queryDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const questionsData = useQuestionsData();
  const { t } = useTranslation();
  let questionCard = [
    {
      text: t("solutions.tpm.question-1"),
    },
    {
      text: t("solutions.tpm.question-2"),
    },
    {
      text: t("solutions.tpm.question-3"),
    },
    {
      text: t("solutions.tpm.question-4"),
    },
    {
      text: t("solutions.tpm.question-5"),
    },
    {
      text: t("solutions.tpm.question-6"),
    },
  ];

  enum showContentPart {
    none = 0,
    intro = 1,
    related = 2,
  }

  const [collapsePart, setCollapsePart] = useState(showContentPart.related);
  const handleChangeActiveQuestionCard = (index: number) => {
    setActiveCardIndex(index);
    setCollapsePart(showContentPart.related);
  };

  const handleReadLess = (
    e:
      | React.MouseEvent<HTMLLIElement, MouseEvent>
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (collapsePart >= 0) {
      setCollapsePart((value) => {
        return value - 1;
      });
    }
  };

  const handleReadMore = (
    e:
      | React.MouseEvent<HTMLLIElement, MouseEvent>
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    setCollapsePart(showContentPart.related);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        maxWidth: { lg: 1180, md: 768, sm: 716, xs: 328 },
        marginTop: { md: "96px", xs: "20px" },
      }}
    >
      <Typography
        textAlign="center"
        variant="h6"
        fontWeight="700"
        fontSize={{ md: "48px", sm: "40px", xs: "28px" }}
        display={"flex"}
        justifyContent={"center"}
        position={"relative"}
        width="100%"
      >
        {t('ifactoryworks.why-do-you-need-ifactoryworks')}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", sm: "row", xs: "column" },
          gap: { sm: "20px", xs: "0" },
          justifyContent: "center",
          alignItems: { sm: "flex-start", xs: "center" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: { md: "280px", sm: "256px", xs: "328px" },
          }}
        >
          <List sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <ListItem
              disablePadding
              sx={{
                width: { md: "280px", sm: "256px", xs: "328px" },
                display: "flex",
                flexDirection: "column",
                gap: queryDownSm ? "16px" : "20px",
              }}
            >
              {questionCard.map((question, index) => {
                return (
                  <Fragment key={question.text}>
                    {activeCardIndex === index ? (
                      <Box
                        sx={{
                          background:
                            "linear-gradient(315deg, rgba(11, 76, 217, 0.1) 0%, rgba(0, 140, 214, 0.1) 100%), #FFFFFF",
                          display: "flex",
                          gap: "16px",
                          flexDirection: "column",
                          padding: { sm: 0, xs: "0 0 16px 0" },
                        }}
                      >
                        <ListItemButton
                          sx={{
                            width: "100%",
                            padding: "8px 0px",
                            boxSizing: "border-box",
                            flexWrap: "wrap",
                            borderLeft: "4px #008CD6 solid",
                          }}
                          onClick={() => handleChangeActiveQuestionCard(index)}
                        >
                          <Box
                            sx={{
                              boxSizing: "border-box",
                              width: "100%",
                              height: "100%",
                              padding: { sm: "0px 24px", xs: "0 12px" },
                              display: "flex",
                              flexDirection: { sm: "column", xs: "row" },
                              alignItems: "flex-start",
                              gap: { sm: "4px", xs: "10px" },
                            }}
                          >
                            <Box sx={{ width: "24px", height: "24px" }}>
                              <Box
                                sx={{
                                  width: { sm: "24px", xs: "20px" },
                                  height: { sm: "24px", xs: "20px" },
                                  borderRadius: "4px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "#008CD6",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#fff",
                                    fontWeight: "bold",
                                    width: "20px",
                                    height: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: {
                                      md: "16px",
                                      sm: "14px",
                                      xs: "14px",
                                    },
                                  }}
                                >
                                  {index + 1}
                                </Typography>
                              </Box>
                            </Box>
                            <Typography
                              component={"span"}
                              color={"#323233"}
                              fontWeight="400"
                              sx={{
                                fontSize: {
                                  md: "16px",
                                  sm: "14px",
                                  xs: "14px",
                                },
                                color: "#008CD6",
                                fontWeight: "700",
                                letterSpacing: "-0.003em",
                              }}
                            >
                              {question.text}
                            </Typography>
                          </Box>
                        </ListItemButton>
                        {queryDownSm ? (
                          <>
                            {collapsePart >= 0 ? (
                              <ListItem
                                sx={{
                                  flexWrap: "wrap",
                                  padding: "8px 0 0 40px",
                                  zIndex: 50,
                                  borderLeft: "4px #008CD6 solid",
                                  margin: "-20px 0 0 0",
                                }}
                                onClick={(e) => handleReadLess(e)}
                              >
                                <Typography
                                  sx={{
                                    color: "#008CD6",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                  }}
                                >
                                  {t("pcb.read-less")}
                                </Typography>
                              </ListItem>
                            ) : (
                              <ListItem
                                sx={{
                                  flexWrap: "wrap",
                                  padding: "8px 0 0 40px",
                                  zIndex: 50,
                                  borderLeft: "4px #008CD6 solid",
                                  margin: "-20px 0 0 0",
                                }}
                                onClick={(e) => handleReadMore(e)}
                              >
                                <Typography
                                  sx={{
                                    color: "#008CD6",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                  }}
                                >
                                  {t("pcb.read-more")}
                                </Typography>
                              </ListItem>
                            )}
                          </>
                        ) : undefined}

                        <Collapse
                          in={activeCardIndex === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          {queryDownSm && collapsePart >= 0 ? (
                            <>
                              {questionsData
                                .filter(
                                  (question, index) => index === activeCardIndex
                                )
                                .map((question) => {
                                  return (
                                    <Box
                                      key={question.text}
                                      sx={{
                                        width: "100%",
                                        maxWidth: {
                                          md: "880px",
                                          sm: "440px",
                                          xs: "328px",
                                        },
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          padding: "0px 12px",
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: "16px",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItem: "center",
                                            gap: "20px",
                                          }}
                                        >
                                          <Typography
                                            component={"span"}
                                            height="100%"
                                            color="#323233"
                                            fontWeight="700"
                                            sx={{
                                              fontSize: {
                                                sm: "24px",
                                                xs: "20px",
                                              },
                                              whiteSpace: "nowrap",
                                            }}
                                            lineHeight="40px"
                                            maxWidth="51px"
                                          >
                                            {t("solutions.tpm.intro")}
                                          </Typography>
                                          <Box
                                            sx={{ width: "100%", zIndex: 1 }}
                                          >
                                            <DashedDividingLine />
                                          </Box>
                                        </Box>
                                        <Box
                                          id="video-block"
                                          sx={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItem: "flex-start",
                                            gap: "20px",
                                            flexDirection: {
                                              lg: "row",
                                              xs: "column",
                                            },
                                            minHeight: "270px",
                                          }}
                                        >
                                          <Box
                                            id="video-description"
                                            sx={{
                                              width: "100%",
                                              maxWidth: {
                                                lg: "380px",
                                                xs: "auto",
                                              },
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: {
                                                  md: "14px",
                                                  sm: "12px",
                                                  xs: "12px",
                                                },
                                                color: "#89898A",
                                              }}
                                            >
                                              {question.introContent}
                                            </Typography>
                                          </Box>
                                          <Box
                                            id="video"
                                            sx={{
                                              width: "100%",
                                              maxWidth: "480px",
                                              minWidth: {
                                                md: "480px",
                                                xs: "auto",
                                              },
                                            }}
                                          >
                                            <iframe
                                              allowTransparency={true}
                                              title="Wistia video player"
                                              allowFullScreen
                                              frameBorder="0"
                                              scrolling="no"
                                              className="wistia_embed"
                                              name="wistia_embed"
                                              src={question.videoSrc}
                                              width="100%"
                                              height="100%"
                                            ></iframe>
                                          </Box>
                                        </Box>

                                        {collapsePart >= 1 ? (
                                          <Box
                                            id="feature-intro"
                                            width={"100%"}
                                            sx={{
                                              display: "flex",
                                              gap: "20px",
                                              flexWrap: "wrap",
                                            }}
                                          >
                                            {question.featureIntros?.map(
                                              (featureIntro) => {
                                                return (
                                                  <Box
                                                    key={featureIntro.title}
                                                    id="feature-intro-content"
                                                    sx={{
                                                      width: "100%",
                                                      maxWidth: {
                                                        sm: "205px",
                                                        xs: "100%",
                                                      },
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      gap: "4px",
                                                      maxHeight: "100px",
                                                    }}
                                                  >
                                                    <Box
                                                      id="feature-intro-content__title"
                                                      sx={{
                                                        padding: "0 0 0 20px",
                                                        borderLeft: "4px solid",
                                                        borderImage:
                                                          "linear-gradient(135deg, #008CD6 0%, #00CC66 100%) 1",
                                                      }}
                                                    >
                                                      <Typography
                                                        sx={{
                                                          color: "#323233",
                                                          fontWeight: "700",
                                                          fontSize: {
                                                            sm: "16px",
                                                            xs: "14px",
                                                          },
                                                          "-webkit-line-clamp":
                                                            "2",
                                                          textOverflow:
                                                            "ellipsis",
                                                          display:
                                                            "-webkit-box",
                                                          "-webkit-box-orient":
                                                            "vertical",
                                                          overflow: "hidden",
                                                        }}
                                                      >
                                                        {featureIntro.title}
                                                      </Typography>
                                                    </Box>
                                                    <Box
                                                      id="feature-intro-content__description"
                                                      sx={{
                                                        padding: "0 0 0 20px",
                                                        fontSize: "12px",
                                                        fontWeight: "400",
                                                        color: "#89898A",
                                                      }}
                                                    >
                                                      {featureIntro.text}
                                                    </Box>
                                                  </Box>
                                                );
                                              }
                                            )}
                                          </Box>
                                        ) : undefined}

                                        {collapsePart >= 2 ? (
                                          <>
                                            <Box
                                              sx={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItem: "center",
                                                gap: "20px",
                                              }}
                                            >
                                              <Typography
                                                component={"span"}
                                                height="100%"
                                                color="#323233"
                                                fontWeight="700"
                                                sx={{
                                                  fontSize: {
                                                    sm: "24px",
                                                    xs: "20px",
                                                  },
                                                }}
                                                lineHeight="40px"
                                                maxWidth="51px"
                                                whiteSpace="nowrap"
                                              >
                                                {t("solutions.tpm.related")}
                                              </Typography>
                                              <Box
                                                sx={{
                                                  width: "100%",
                                                  zIndex: 1,
                                                }}
                                              >
                                                <DashedDividingLine />
                                              </Box>
                                            </Box>
                                            <Box
                                              sx={{
                                                width: "100%",
                                                display: "flex",
                                                alignItem: "center",
                                                gap: "20px",
                                                flexDirection: {
                                                  md: "row",
                                                  xs: "column",
                                                },
                                              }}
                                            >
                                              <Box
                                                sx={{
                                                  width: "100%",
                                                  maxWidth: "480px",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  flexWrap: "wrap",
                                                }}
                                              >
                                                <Box
                                                  sx={{
                                                    width: "100%",
                                                    maxWidth: "410px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      question.relatedPicture
                                                    }
                                                    title="Question one"
                                                    style={{ width: "100%" }}
                                                  />
                                                </Box>
                                              </Box>
                                              <Box
                                                sx={{
                                                  width: "100%",
                                                  maxWidth: {
                                                    md: "380px",
                                                    sm: "auto",
                                                  },
                                                }}
                                              >
                                                <ol
                                                  style={{
                                                    color: "#89898A",
                                                    fontSize: queryUpMd
                                                      ? "14px"
                                                      : "12px",
                                                    lineHeight: "20px",
                                                    padding: queryUpXs
                                                      ? "0 0 0 15px"
                                                      : 0,
                                                  }}
                                                >
                                                  {" "}
                                                  {question.relatedContents?.map(
                                                    (content) => {
                                                      return (
                                                        <Fragment
                                                          key={
                                                            content.shortDescription
                                                          }
                                                        >
                                                          {content?.shortDescription ??
                                                            ""}
                                                          {content?.texts.map(
                                                            (texts) => (
                                                              <li
                                                                key={texts.text}
                                                              >
                                                                {texts.text}
                                                              </li>
                                                            )
                                                          )}
                                                        </Fragment>
                                                      );
                                                    }
                                                  )}
                                                </ol>
                                              </Box>
                                            </Box>
                                            <Box
                                              id="products-block"
                                              sx={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                width: "100%",
                                                maxWidth: "880px",
                                                gap: "20px",
                                              }}
                                            >
                                              {question.packages?.map(
                                                (packageData) => {
                                                  return (
                                                    <PackageCard
                                                      key={packageData.title}
                                                      info={packageData}
                                                    />
                                                  );
                                                }
                                              )}

                                              {question.apps?.map((app) => {
                                                return (
                                                  <AppCard
                                                    key={app.title}
                                                    total={question.apps.length}
                                                    info={app}
                                                  />
                                                );
                                              })}
                                            </Box>
                                          </>
                                        ) : undefined}

                                        <Box
                                          sx={{
                                            width: "100%",
                                            height: "40px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItem: "center",
                                            position: "relative",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              width: "100%",
                                              zIndex: 1,
                                            }}
                                          >
                                            <DashedDividingLine />
                                          </Box>
                                          <Typography
                                            sx={{
                                              color: "#008CD6",
                                              fontSize: "14px",
                                              fontWeight: 700,
                                              zIndex: 2,
                                              textAlign: "center",
                                              width: "100%",
                                              lineHeight: "40px",
                                            }}
                                            onClick={(e) => handleReadLess(e)}
                                          >
                                            {t("pcb.read-less")}
                                          </Typography>
                                          <Box
                                            sx={{
                                              width: "100%",
                                              zIndex: 1,
                                            }}
                                          >
                                            <DashedDividingLine />
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  );
                                })}
                            </>
                          ) : undefined}
                        </Collapse>
                      </Box>
                    ) : (
                      <ListItemButton
                        sx={{
                          width: "100%",
                          padding: "8px 12px",
                          boxSizing: "border-box",
                          display: "flex",
                          flexDirection: { sm: "row", xs: "column" },
                          alignItems: "flex-start",
                        }}
                        onClick={() => handleChangeActiveQuestionCard(index)}
                      >
                        <Box
                          sx={{
                            boxSizing: "border-box",
                            width: "100%",
                            height: "100%",
                            padding: { sm: "0px 24px", xs: "0 0px" },
                            display: "flex",
                            flexDirection: { sm: "column", xs: "row" },
                            alignItems: "flex-start",
                            gap: { sm: "4px", xs: "10px" },
                          }}
                        >
                          <Box sx={{ width: "24px", height: "24px" }}>
                            <Box
                              sx={{
                                width: { sm: "24px", xs: "20px" },
                                height: { sm: "24px", xs: "20px" },
                                borderRadius: "4px",
                                backgroundColor: "#89898A",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontWeight: "bold",
                                  width: "20px",
                                  height: "20px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: {
                                    md: "16px",
                                    sm: "14px",
                                    xs: "14px",
                                  },
                                }}
                              >
                                {index + 1}
                              </Typography>
                            </Box>
                          </Box>
                          <Typography
                            component={"span"}
                            color={"#323233"}
                            sx={{
                              fontSize: { md: "16px", sm: "14px", xs: "14px" },
                              fontWeight: { sm: 400, xs: 700 },
                            }}
                          >
                            {question.text}
                          </Typography>
                        </Box>
                        {queryDownSm ? (
                          <Box
                            sx={{
                              flexWrap: "wrap",
                              zIndex: 50,
                            }}
                            onClick={(e) => handleReadMore(e)}
                          >
                            <Typography
                              sx={{
                                color: "#008CD6",
                                fontSize: "14px",
                                fontWeight: 700,
                                margin: "0 0 0 30px",
                              }}
                            >
                              {t("pcb.read-more")}
                            </Typography>
                          </Box>
                        ) : undefined}
                      </ListItemButton>
                    )}
                  </Fragment>
                );
              })}
            </ListItem>
          </List>
        </Box>
        {!queryDownSm &&
          questionsData
            .filter((question, index) => index === activeCardIndex)
            .map((question) => {
              return (
                <Box
                  key={question.text}
                  sx={{
                    width: "100%",
                    maxWidth: { md: "880px", sm: "440px", xs: "328px" },
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItem: "center",
                      gap: "20px",
                    }}
                  >
                    <Typography
                      component={"span"}
                      height="100%"
                      color="#323233"
                      fontWeight="700"
                      sx={{
                        fontSize: { sm: "24px", xs: "20px" },
                        whiteSpace: "nowrap",
                      }}
                      lineHeight="40px"
                      maxWidth="51px"
                    >
                      {t("solutions.tpm.intro")}
                    </Typography>
                    <Box sx={{ width: "100%" }}>
                      <DashedDividingLine />
                    </Box>
                  </Box>
                  <Box
                    id="video-block"
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItem: "flex-start",
                      gap: "20px",
                      flexDirection: { lg: "row", xs: "column" },
                      minHeight: "270px",
                    }}
                  >
                    <Box
                      id="video-description"
                      sx={{
                        width: "100%",
                        maxWidth: { lg: "380px", xs: "auto" },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { md: "14px", sm: "12px", xs: "12px" },
                          color: "#89898A",
                        }}
                      >
                        {question.introContent}
                      </Typography>
                    </Box>
                    <Box
                      id="video"
                      sx={{
                        width: "100%",
                        maxWidth: "480px",
                        minWidth: { md: "480px", xs: "auto" },
                      }}
                    >
                      <iframe
                        allowTransparency={true}
                        title="Wistia video player"
                        allowFullScreen
                        frameBorder="0"
                        scrolling="no"
                        className="wistia_embed"
                        name="wistia_embed"
                        src={question.videoSrc}
                        width="100%"
                        height="100%"
                      ></iframe>
                    </Box>
                  </Box>
                  <Box
                    id="feature-intro"
                    width={"100%"}
                    sx={{
                      display: "flex",
                      gap: "20px",
                      flexWrap: "wrap",
                    }}
                  >
                    {question.featureIntros?.map((featureIntro) => {
                      return (
                        <Box
                          key={featureIntro.title}
                          id="feature-intro-content"
                          sx={{
                            width: "100%",
                            maxWidth: { sm: "205px", xs: "100%" },
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                            maxHeight: "100px",
                          }}
                        >
                          <Box
                            id="feature-intro-content__title"
                            sx={{
                              padding: "0 0 0 20px",
                              borderLeft: "4px solid",
                              borderImage:
                                "linear-gradient(135deg, #008CD6 0%, #00CC66 100%) 1",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#323233",
                                fontWeight: "700",
                                fontSize: "16px",
                                "-webkit-line-clamp": "2",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                "-webkit-box-orient": "vertical",
                                overflow: "hidden",
                              }}
                            >
                              {featureIntro.title}
                            </Typography>
                          </Box>
                          <Box
                            id="feature-intro-content__description"
                            sx={{
                              padding: "0 0 0 20px",
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "#89898A",
                            }}
                          >
                            {featureIntro.text}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItem: "center",
                      gap: "20px",
                    }}
                  >
                    <Typography
                      component={"span"}
                      height="100%"
                      color="#323233"
                      fontWeight="700"
                      sx={{ fontSize: "24px" }}
                      lineHeight="40px"
                      maxWidth="51px"
                      whiteSpace="nowrap"
                    >
                      {t("solutions.tpm.related")}
                    </Typography>
                    <Box sx={{ width: "100%" }}>
                      <DashedDividingLine />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItem: "center",
                      gap: "20px",
                      flexDirection: {
                        md: "row",
                        xs: "column",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: "480px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          maxWidth: "410px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={question.relatedPicture}
                          title="Question one"
                          style={{ width: "100%" }}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: { md: "380px", sm: "auto" },
                      }}
                    >
                      <ol
                        style={{
                          color: "#89898A",
                          fontSize: queryUpMd ? "14px" : "12px",
                          lineHeight: "20px",
                          padding: queryUpXs ? "0 0 0 15px" : 0,
                        }}
                      >
                        {question.relatedContents?.map((content) => {
                          return (
                            <Fragment key={content.shortDescription}>
                              {content?.shortDescription ?? ""}
                              {content?.texts.map((texts) => (
                                <li key={texts.text}>{texts.text}</li>
                              ))}
                            </Fragment>
                          );
                        })}
                      </ol>
                    </Box>
                  </Box>
                  <Box
                    id="products-block"
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: "100%",
                      maxWidth: "880px",
                      gap: "20px",
                    }}
                  >
                    {question.packages?.map((packageData) => {
                      return (
                        <PackageCard
                          key={packageData.title}
                          info={packageData}
                        />
                      );
                    })}

                    <Box sx={{ display: "flex", gap: "20px" }}>
                      {question.apps?.map((app) => {
                        return (
                          <AppCard
                            key={app.title}
                            total={question.apps.length}
                            info={app}
                          />
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              );
            })}
      </Box>
    </Box>
  );
}
