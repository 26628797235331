import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { IFeatureData } from "./types/Feature";
import useMediaQuery from "@mui/material/useMediaQuery";

interface FeatureCardProps {
  feature: IFeatureData;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ feature }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { sm: "column", xs: "row" },
        alignItems: { sm: "center", xs: "flex-start" },
        gap: { sm: "4px", xs: "16px" },
        width: "100%",
        maxWidth: { sm: "280px", xs: "328px" },
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: { sm: "80px", xs: "40px" },
        }}
      >
        <img style={{ width: "100%", height: "100%" }} src={feature.src} />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: { sm: "center", xs: "flex-start" },
          gap: "4px",
        }}
      >
        <Typography
          variant="body1"
          fontWeight="700"
          sx={{
            fontSize: { md: "16px", sm: "14px", xs: "14px" },
            textAlign: { sm: "center", xs: "left" },
          }}
        >
          {feature.title}
        </Typography>
        <Typography
          variant="body1"
          fontWeight="400"
          color="#89898A"
          sx={{
            fontSize: { md: "12px", sm: "12px", xs: "12px" },
            textAlign: { sm: "center", xs: "left" },
          }}
        >
          {feature.description}
        </Typography>
      </Box>
    </Box>
  );
};

export default FeatureCard;
