// import stepPicture01 from "../images/img_progress1_p.png";
// import stepPicture01l from "../images/img_progress1_l.png";
// import stepPicture02 from "../images/img_progress2_p.png";
// import stepPicture02l from "../images/img_progress2_l.png";
// import stepPicture03 from "../images/img_progress3_p.png";
// import stepPicture03l from "../images/img_progress3_l.png";
// import stepPicture04 from "../images/img_progress4_p.png";
// import stepPicture04l from "../images/img_progress4_l.png";
// import stepPicture05 from "../images/img_progress5_p.png";
// import stepPicture05l from "../images/img_progress5_l.png";
// import stepPicture06 from "../images/img_progress6_p.png";
// import stepPicture06l from "../images/img_progress6_l.png";
// import stepPicture07 from "../images/img_progress7_p.png";
// import stepPicture07l from "../images/img_progress7_l.png";

import ShopfloorPic from "../../../../images/ShopFloor.png";
import OEEPic from "../../../../images/OEE.png";
import AddOnPic from "../../../../images/AddOn.png";
import Mic7702Pic from "../../../../images/img_device_MIC-770-V2.png";
import BarcodePic from "../../../../images/barcode-scanner-RS233.png";
import Amax558Pic from "../../../../images/img_device_AMAX-558.png";
import Tpc1251Pic from "../../../../images/img_device_TPC-1251T.png";
import eAIGatewayPic from "../../../../images/gateway-slg1211.png";
import eAINLS105Pic from "../../../../images/iotbox-NLS105.png";
import eManualPic from "../../../../images/eManual.png";
import AIM75SPic from "../../../../images/AIM-75s.png";
import tpc100wPic from "../../../../images/img_device_TPC-107W.png";
import ecu1251Pic from "../../../../images/img_device_ECU-1251.png";
import { useTranslation } from "react-i18next";

const useAnchorStepsData = () => {
  const { t } = useTranslation();
  const stepCards = [
    {
      stepName: t("solutions.ai.overview"),
      elementId: "Overview",
      // src: {
      //   md: stepPicture01,
      //   sm: stepPicture01l,
      // },
      flexDirection: "row",
      starterPackages: [
        {
          name: "Real-time Progress",
          packages: [
            {
              page: 1,
              title: "Route Control during Progressing",
              content:
                "During the production progress, raw materials transport between stations, managers have to make the production plan and create work orders for all station. Real-time Progress can help create, management and trace the word orders on site, Real-time display of work progress and production information, improve productivity and shorten production cycle",
              devices: [
                {
                  src: ShopfloorPic,
                  title: "Shop Floor",
                  code: "9804EBSFC00",
                },
                {
                  src: Mic7702Pic,
                  title: "MIC770V2 i5 Edge",
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: eAIGatewayPic,
                  title: "eAI LoRa Gateway SLG1211",
                },
                {
                  src: BarcodePic,
                  title: "Barcode Scanner RS-233",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      stepName: t("solutions.common.faqs"),
      elementId: "FAQs",
      // src: {
      //   md: stepPicture01,
      //   sm: stepPicture01l,
      // },
      flexDirection: "row",
      starterPackages: [
        {
          name: "Real-time Progress",
          packages: [
            {
              page: 1,
              title: "Route Control during Progressing",
              content:
                "During the production progress, raw materials transport between stations, managers have to make the production plan and create work orders for all station. Real-time Progress can help create, management and trace the word orders on site, Real-time display of work progress and production information, improve productivity and shorten production cycle",
              devices: [
                {
                  src: ShopfloorPic,
                  title: "Shop Floor",
                  code: "9804EBSFC00",
                },
                {
                  src: Mic7702Pic,
                  title: "MIC770V2 i5 Edge",
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: eAIGatewayPic,
                  title: "eAI LoRa Gateway SLG1211",
                },
                {
                  src: BarcodePic,
                  title: "Barcode Scanner RS-233",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      stepName: t("solutions.ai.architecture"),
      elementId: "Architecture",
      // src: {
      //   md: stepPicture02,
      //   sm: stepPicture02l,
      // },
      flexDirection: "row-reverse",
      starterPackages: [
        {
          name: "Real-time Progress",
          packages: [
            {
              page: 1,
              title: "Route Control during Progressing",
              content:
                "During the production progress, raw materials transport between stations, managers have to make the production plan and create work orders for all station. Real-time Progress can help create, management and trace the word orders on site, Real-time display of work progress and production information, improve productivity and shorten production cycle",
              devices: [
                {
                  src: ShopfloorPic,
                  title: "Shop Floor",
                  code: "9804EBSFC00",
                },
                {
                  src: Mic7702Pic,
                  title: "MIC770V2 i5 Edge",
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: eAIGatewayPic,
                  title: "eAI LoRa Gateway SLG1211",
                },
              ],
            },
          ],
        },
        {
          name: "Quick Response",
          packages: [
            {
              page: 1,
              title: "Steam sterilization Machine",
              content:
                "Corresponding to various food sterilization methods, create the most suitable sterilization environment, monitor environmental parameters in real time, and regularly and automatically sample the bacteria count indicators in the sterilization environment.",
              devices: [
                {
                  src: OEEPic,
                  title: "OEE",
                  code: "9804EBOEE00",
                },
                {
                  src: AddOnPic,
                  title: "Andon",
                  code: "9804EBADN00",
                },
                {
                  src: Mic7702Pic,
                  title: "MIC770V2 i5 Edge",
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: tpc100wPic,
                  title: "TPC-100W",
                  code: "TPC-100W Series",
                },
                {
                  src: ecu1251Pic,
                  title: "ECU-1251",
                  code: "SRP-IFS420-E14TAE",
                },
                {
                  src: BarcodePic,
                  title: "Barcode Scanner RS-233",
                },
              ],
            },
          ],
        },
        {
          name: "Work Mentor",
          packages: [
            {
              page: 1,
              title: "Steam sterilization Machine",
              content:
                "Invoke the product SOP through WorkMentor, and automatically put in suitable machine parameters for sterilization. Real-time monitoring parameters of the machine to ensure machine status.",
              devices: [
                {
                  src: eManualPic,
                  title: "eManual",
                  code: "Model Name",
                },
                {
                  src: Mic7702Pic,
                  title: "MIC770V2 i5 Edge",
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: tpc100wPic,
                  title: "TPC-100W",
                  code: "TPC-100W Series",
                },
                {
                  src: AIM75SPic,
                  title: "AIM-75S",
                  code: "Model Name",
                },
                {
                  src: BarcodePic,
                  title: "Barcode Scanner RS-233",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      stepName: t("solutions.ai.resources"),
      elementId: "Resources",
      // src: {
      //   md: stepPicture03,
      //   sm: stepPicture03l,
      // },
      flexDirection: "row",
      starterPackages: [
        {
          name: "Real-time Progress",
          packages: [
            {
              page: 1,
              title: "Section Title",
              content:
                "During the production progress, raw materials transport between stations, managers have to make the production plan and create work orders for all station. Real-time Progress can help create, management and trace the word orders on site, Real-time display of work progress and production information, improve productivity and shorten production cycle",
              devices: [
                {
                  src: ShopfloorPic,
                  title: "Shop Floor",
                  code: "9804EBSFC00",
                },
                {
                  src: Mic7702Pic,
                  title: "MIC770V2 i5 Edge",
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: eAIGatewayPic,
                  title: "eAI LoRa Gateway SLG1211",
                },
              ],
            },
          ],
        },
        {
          name: "Quick Response",
          packages: [
            {
              page: 1,
              title: "Boiling & Steaming Machine (Blancher)",
              content:
                "According to the characteristics of processed food and the condition of raw materials, continuously control the cooking time and cooking temperature to ensure that the ingredients are sufficient and accurately complete the cooking process. Also monitor the status of the device through sensors, and will automatically trigger the exception event handler if there is an abnormal event.",
              devices: [
                {
                  src: OEEPic,
                  title: "OEE",
                  code: "9804EBOEE00",
                },
                {
                  src: AddOnPic,
                  title: "Andon",
                  code: "9804EBADN00",
                },
                {
                  src: Mic7702Pic,
                  title: "MIC770V2 i5 Edge",
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: tpc100wPic,
                  title: "TPC-100W",
                  code: "TPC-100W Series",
                },
                {
                  src: ecu1251Pic,
                  title: "ECU-1251",
                  code: "SRP-IFS420-E14TAE",
                },
                {
                  src: BarcodePic,
                  title: "Barcode Scanner RS-233",
                },
              ],
            },
          ],
        },
        {
          name: "Work Mentor",
          packages: [
            {
              page: 1,
              title: "Boiling & Steaming Machine (Blancher)",
              content:
                "Get product SOP through WorkMentor, and automatically put in the appropriate machine parameters for boiling, by detecting environmental indicators and adjusting machine parameter to ensure the production quality.",
              devices: [
                {
                  src: eManualPic,
                  title: "eManual",
                  code: "Model Name",
                },
                {
                  src: Mic7702Pic,
                  title: "MIC770V2 i5 Edge",
                  code: "IFS-RTM-M770W4A",
                },

                {
                  src: tpc100wPic,
                  title: "TPC-100W",
                  code: "TPC-100W Series",
                },
                {
                  src: AIM75SPic,
                  title: "AIM-75S",
                  code: "Model Name",
                },
                {
                  src: BarcodePic,
                  title: "Barcode Scanner RS-233",
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  return stepCards;
};

export { useAnchorStepsData };
