import creatorPicture from "@/routes/Home/images/creator.png";
import factoryPicture from "@/routes/Home/images/factory.png";
import factoriesPicture from "@/routes/Home/images/factories.png";
import { useTranslation } from "react-i18next";

export const useRolesData = () => {
  const { t } = useTranslation();
  const roles = [
    {
      title: t("home.i-am-an-automation-creator"),
      description: t(
        "home.i-want-to-provide-custom-automation-and-equipment-solutions-and-services-to-manufacturers"
      ),
      src: creatorPicture,
      url: "/solutions/ifactoryworks",
    },
    {
      title: t("home.i-own-a-factory"),
      description: t(
        "home.i-have-a-factory-and-would-like-to-understand-my-systems-better"
      ),
      src: factoryPicture,
      url: "/solutions/intelligent-automation",
    },
    {
      title: t("home.i-own-many-businesses"),
      description: t(
        "home.i-want-to-centralize-management-and-understand-how-they-are-all-doing"
      ),
      src: factoriesPicture,
      url: "/solutions/digital-transformation",
    },
  ];

  return roles;
};
