import { ColoredTypography } from "../ColoredTypography/ColoredTypography";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BlueColoredTypography } from "../ColoredTypography/BlueColoredTypography";
import { GreenYellowTypography } from "../ColoredTypography/GreenYellowTypography";

type list = {
  content: string | JSX.Element;
};

interface CardInfo {
  step?: string;
  title?: string;
  content?: string | JSX.Element;
  lists?: list[];
}

interface InfoCardProps {
  cardInfo: CardInfo;
  textColor?: string;
  listStyle?: string;
}

const renderColoredTypography = (
  step: string,
  textColor: string | undefined
) => {
  const typographyStyle = {
    fontWeight: "900",
    fontStyle: "italic",
    paddingRight: "20px",
    width: "fit-content",
    fontSize: { sm: "48px", xs: "28px" },
  };

  switch (textColor) {
    case "blue":
      return (
        <BlueColoredTypography sx={typographyStyle}>
          {step || "01"}
        </BlueColoredTypography>
      );
    case "greenYellow":
      return (
        <GreenYellowTypography sx={typographyStyle}>
          {step || "01"}
        </GreenYellowTypography>
      );
    default:
      return (
        <ColoredTypography sx={typographyStyle}>
          {step || "01"}
        </ColoredTypography>
      );
  }
};

export const ColumnInfoCard = ({
  cardInfo,
  textColor,
  listStyle,
}: InfoCardProps) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      maxWidth: { lg: 380, md: 348, sm: 348, xs: 328 },
      width: "100%",
    }}
  >
    {renderColoredTypography(cardInfo.step!, textColor)}

    <Box>
      <Typography
        variant={"h6"}
        sx={{ fontSize: { md: "16px", sm: "14px", xs: "14px" } }}
      >
        {cardInfo.title || "Item name"}
      </Typography>
      <Typography textAlign="left" color={"GrayText"}>
        <ul
          style={
            listStyle === "none"
              ? {
                  marginBlockStart: 0,
                  marginBlockEnd: 0,
                  paddingInlineStart: 0,
                }
              : {
                  marginBlockStart: 0,
                  marginBlockEnd: 0,
                  paddingInlineStart: "20px",
                }
          }
        >
          {cardInfo.lists?.map((list) => (
            <li
              style={
                listStyle === "none"
                  ? { listStyle: "none", paddingInlineStart: 0, padding: 0 }
                  : {}
              }
            >
              <Typography
                sx={{
                  fontSize: { md: "12px", sm: "10px", xs: "10px" },
                  "-webkit-line-clamp": {
                    xs: "4",
                  },
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-box-orient": "vertical",
                  overflow: "hidden",
                }}
              >
                {list.content}
              </Typography>
            </li>
          ))}
        </ul>
      </Typography>
    </Box>
  </Box>
);
