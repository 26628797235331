import { createContext, useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { AuthService, DeskService, InfoService } from "../services";
import { useQuery } from "react-query";

export interface ServiceContextType {
  apiHubApiUrl: string;
  apiHubUrl: string;
  authService: AuthService;
  deskService: DeskService;
}

export const ServiceContext = createContext<ServiceContextType>({
  apiHubApiUrl: "/_/api_hub/",
  apiHubUrl: "/_/api_hub/",
  authService: new AuthService(axios.create({ url: "/_/api_hub/" })),
  deskService: new DeskService(axios.create({ url: "/_/api_hub/" })),
});

export const ServiceProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [apiHubUrl, setApiHubUrl] = useState("");
  const value = useMemo<ServiceContextType>(
    () => ({
      apiHubUrl: apiHubUrl,
      apiHubApiUrl: "/_/api_hub/",
      authService: new AuthService(axios.create()),
      deskService: new DeskService(axios.create({ url: "/_/api_hub/" })),
    }),
    [apiHubUrl]
  );

  const query = useQuery(
    ["info"],
    () => new InfoService(axios.create()).get(),
    { retry: true }
  );

  useEffect(() => {
    if (query.data) {
      setApiHubUrl(query.data?.data.service.ifp.url);
    }
  }, [query.data]);

  return (
    <ServiceContext.Provider value={value}>{children}</ServiceContext.Provider>
  );
};

export const useService = () => {
  return useContext(ServiceContext);
};
