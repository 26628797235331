import axios, { isAxiosError } from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { AuthService, User, UserService } from "../services";

export interface AuthContextType {
  isAuth: boolean;
  user?: User | null;
  logout: () => void;
  refetch: () => void;
}

export const AuthContext = createContext<AuthContextType>({
  isAuth: true,
  refetch: () => {},
  logout: () => {},
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const queryClient = useQueryClient();
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const query = useQuery(
    ["user", "me"],
    () => new UserService(axios.create()).getUserMe(),
    { retry: false, refetchOnWindowFocus: false, staleTime: 0 }
  );

  const value = useMemo<AuthContextType>(
    () => ({
      isAuth: isAuth,
      user: user,
      refetch: () => query.refetch(),
      logout: () => {
        // auth
        query.remove();
        new AuthService(axios.create()).logout().then(() => {
          query.refetch();
          setUser(null);
          setIsAuth(false);
        });
      },
    }),
    [isAuth, query, user]
  );

  useEffect(() => {
    if (query.data?.data.data && !query.isRefetchError && query.isStale) {
      setUser(query.data?.data.data);
      setIsAuth(true);
    } else if (query.data?.status === 401) {
      setUser(null);
      setIsAuth(false);
    } else if (
      query.isError &&
      isAxiosError(query.error) &&
      (query.error.response?.status === 401 ||
        query.error.response?.status === 403 ||
        query.error.response?.status === 404 ||
        query.error.response?.status === 504)
    ) {
      setUser(null);
      setIsAuth(false);
    } else {
      // console.log(query.isError)
      query.refetch();
    }
  }, [query, query.data?.data.data]);

  useEffect(() => {
    if (query.isFetchedAfterMount) {
      setIsFirstLoading(false);
    }
  }, [query.isFetchedAfterMount]);

  if (query.isLoading && !isFirstLoading) {
    return <></>;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
