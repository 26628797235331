import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ":hover": {
            background:
              "linear-gradient(315deg, rgba(11, 76, 217, 0.05) 0%, rgba(11, 76, 217, 0.05) 0%, rgba(0, 140, 214, 0.05) 100%)",
            color: "#008CD6",
            fontWeight: "700",
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          border: "1px solid #008CD6",
          boxShadow: "0px 2px 8px rgba(0, 140, 214, 0.5)",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "&:lang(ja)": {
            fontFamily:
              '"Roboto","Arial",-apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif',
          },
          "&:lang(zh-Hant-TW), &:lang(zh-TW)": {
            fontFamily:
              '"Roboto","微軟正黑體", "Microsoft JhengHei", "Arial", "Helvetica", "sans-serif" ',
          },
          "&:lang(en-US)": {
            fontFamily:
              '-apple-system, "Roboto", BlinkMacSystemFont, "Segoe UI", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif !important',
          }
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#008CD6",
          ":hover, :focus": {
            color: "#006BAA",
          },
          ":active": {
            color: "#008CD6",
          },
        },
      },
    },
  },
});
