import React from "react";
import i18next from "i18next";

interface I18nBlockProps {
  children: React.ReactNode;
  lang?: string;
  langs?: string[];
}

const I18nBlock: React.FC<I18nBlockProps> = ({ children, lang, langs }) => {
  return (
    <>
      {(lang === i18next.language ||
        (langs && langs?.findIndex((l) => l === i18next.language) > -1)) &&
        children}
    </>
  );
};

export default I18nBlock;
