import { useTranslation } from "react-i18next";

const useInfoCards = () => {
  const { t } = useTranslation();
  const infoCards = [
    {
      step: "01",
      title: t("solutions.tpm.real-time-equipment-monitoring"),
      lists: [
        {
          content: t(
            "solutions.tpm.track-machine-statuses-and-efficiency-in-real-time-to-maximize"
          ),
        },
      ],
    },
    {
      step: "02",
      title: t("solutions.tpm.event-reporting"),
      lists: [
        {
          content: t(
            "solutions.tpm.one-click-reporting-for-employees-to-request-assistance-for-anomalies"
          ),
        },
      ],
    },
    {
      step: "03",
      title: t("solutions.tpm.transparent-progress"),
      lists: [
        {
          content: t(
            "solutions.tpm.update-work-order-progress-in-real-time-from-equipment-data"
          ),
        },
      ],
    },
    {
      step: "04",
      title: t("solutions.tpm.digital-work-instructions"),
      lists: [
        {
          content: t(
            "solutions.tpm.guide-employees-with-interactive-instructions-to-reduce-training-time"
          ),
        },
      ],
    },
    {
      step: "05",
      title: t("solutions.tpm.master-data"),
      lists: [
        {
          content: t(
            "solutions.tpm.setup-shared-definitions-of-manufacturing-resources"
          ),
        },
      ],
    },
    {
      step: "06",
      title: t("solutions.tpm.visualized-insights"),
      lists: [
        {
          content: t(
            "solutions.tpm.ready-to-use-dashboard-templates-to-enable-data-driven-decisions"
          ),
        },
      ],
    },
  ];
  return infoCards;
};

export { useInfoCards };
