import { Box, useMediaQuery, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DashedDividingLine from "@/components/Common/DashedDividingLine";
import React from "react";

interface PaginationProps {
  activePage: number;
  dataLength: number;
  handleClickNextPage: () => void;
  handleClickPreviousPage: () => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  activePage,
  dataLength,
  handleClickNextPage,
  handleClickPreviousPage,
}) => {
  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: { sm: "auto", xs: "100%" },
      }}
    >
      <IconButton
        sx={{
          transition: "0.3s ease-in",
          backgroundColor: matchesDownSm ? "transparent" : "white !important",
        }}
        onClick={handleClickPreviousPage}
        disabled={activePage === 0}
      >
        <NavigateBeforeIcon />
      </IconButton>

      <Typography
        textAlign="left"
        color={"GrayText"}
        sx={{
          display: "inline-block",
          backgroundColor: matchesDownSm ? "transparent" : "white !important",
        }}
      >
        {(activePage + 1).toString()} / {dataLength.toString()}
      </Typography>

      <IconButton
        sx={{
          transition: "0.3s ease-in",
          backgroundColor: matchesDownSm ? "transparent" : "white !important",
        }}
        onClick={handleClickNextPage}
        disabled={activePage === dataLength - 1}
      >
        <NavigateNextIcon />
      </IconButton>
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          maxWidth: { md: "1180px", sm: "732px", xs: "328px" },
          height: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: -1,
        }}
      >
        <DashedDividingLine />
      </Box>
    </Box>
  );
};
