import { useTranslation } from "react-i18next";

import lowCodePicture from "../images/lowcode.png";
import tpmPicture from "../images/img_solution_TPM.png";
import ehsPicture from "../images/img_solution_EHS.png";
import biPicture from "../images/img_solution_dashboard.png";
import dataAIPicture from "../images/img_solution_IAI.png";

import {
  ISolutionData,
  Solutions,
} from "../../../components/home/types/Solution";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const useSolutionCardData = (): Solutions => {
  const { t } = useTranslation();

  const theme = useTheme();
  const matchesMdToSm = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const solutionsData = [
    {
      page: 1,
      solutions: [
        {
          title: t('ifactoryworks.ifactoryworks'),
          description: t("home.tpm-description"),
          src: tpmPicture,
          url: "/solutions/ifactoryworks",
        },
        {
          title: t("home.ehs"),
          description: t("home.ehs-description"),
          src: ehsPicture,
          // url: "/solutions/solution-EMS-and-EHS",
          url: "",
        },
        {
          title: t("home.bi"),
          description: t("home.bi-description"),
          src: biPicture,
          url: "",
        },
        {
          title: t("home.data-ai-and-vision-ai"),
          description: t("home.data-ai-and-vision-ai-description"),
          src: dataAIPicture,
          // url: "/solutions/solution-AI",
          url: "",
        },
      ],
    },
  ];

  const smToMdSolutionsData = [
    {
      page: 1,
      solutions: [
        {
          title: t("home.tpm"),
          description: t("home.tpm-description"),
          src: tpmPicture,
          url: "/solutions/ifactoryworks",
        },
        {
          title: t("home.ehs"),
          description: t("home.ehs-description"),
          src: ehsPicture,
        },
      ],
    },
    {
      page: 2,
      solutions: [
        {
          title: t("home.bi"),
          description: t("home.bi-description"),
          src: biPicture,
        },
        {
          title: t("home.data-ai-and-vision-ai"),
          description: t("home.data-ai-and-vision-ai-description"),
          src: dataAIPicture,
        },
      ],
    },
  ];

  return matchesMdToSm ? smToMdSolutionsData : solutionsData;
};

export default useSolutionCardData;
