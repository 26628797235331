import { Box, Button, Typography } from "@mui/material";
import worldmapPicture from "../../routes/partner/finding-a-partner/images/img_partner_worldmap.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React from "react";
import type { Partner } from "./types/Partners";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface PartnerCardProps {
  partner: Partner;
}

export const PartnerCard = ({ partner }: PartnerCardProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const goToPage = (url: string | undefined) => {
    if (!url) return;
    window.open(url, "_blank");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: { md: 380 },
        maxHeight: { md: 240 },
        minHeight: "240px",
        border: "1px solid #C8C8C8",
        boxSizing: "border-box",
        borderRadius: "4px",
        gap: "4px",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          height: "auto",
          minHeight: "172px",
        }}
      >
        <img
          src={partner.src}
          style={{
            maxWidth: "60px",
            width: "100%",
            maxHeight: "60px",
          }}
          alt={partner.name}
        />
        <Box>
          <Typography fontWeight="700" sx={{ fontSize: "16px" }}>
            {partner.name}
          </Typography>
          <Typography
            fontWeight="400"
            sx={{
              fontSize: "12px",
              paddingTop: "4px",
              height: "104px",
              color: " #89898A",
              "-webkit-line-clamp": "6",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              "-webkit-box-orient": "vertical",
              overflow: "hidden",
            }}
          >
            {partner.description}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "24px",
          maxWidth: "340px",
          padding: { md: "0 0 0 80px", sm: "0 0 0 76px" },
          gap: { md: "20px", sm: "16px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            maxWidth: "130px",
          }}
        >
          <LocationOnIcon
            sx={{
              color: "#89898A",
              maxWidth: "16px",
              maxHeight: "16px",
            }}
          />
          <Typography
            color="#89898A"
            fontWeight="700"
            fontSize="12px"
            sx={{ padding: "0 0 0 7px" }}
          >
            {partner.region}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {!partner.url ? undefined : (
            <Button
              sx={{
                color: "#008CD6",
                fontWeight: 700,
                fontSize: "16px",
                textTransform: "none",
                padding: "0",
              }}
              onClick={() => goToPage(partner.url ?? "")}
            >
              <Typography color="#008CD6" fontWeight="700">
                {t("landing-page.common.learn-more")}
              </Typography>

              <ChevronRightIcon style={{ color: "#008CD6" }} />
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
