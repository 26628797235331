import { useRef, useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { IResources } from "../home/types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { DashedDividingLine } from "./";

interface IResourcesBlockProps {
  resources: IResources[];
}
const ResourcesBlock: React.FC<IResourcesBlockProps> = ({ resources }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const queryDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  // create SliderContainerRef to get width and update width when resize
  const sliderContainerRef = useRef<HTMLElement>();
  const [sliderContainerWidth, setSliderContainerWidth] = useState(
    sliderContainerRef.current?.offsetWidth
  );
  useEffect(() => {
    if (sliderContainerRef.current) {
      setSliderContainerWidth(sliderContainerRef.current.offsetWidth);
    }
  }, [sliderContainerRef.current?.offsetWidth]);

  // set active page
  const [transformIndex, setTransformIndex] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const handleClickNextPage = () => {
    setActivePage((activePage) => activePage + 1);
    setTransformIndex((transformIndex) => transformIndex + 1);
  };
  const handleClickPreviousPage = () => {
    setActivePage((activePage) => activePage - 1);
    setTransformIndex((transformIndex) => transformIndex - 1);
  };

  // go to page
  const navigate = useNavigate();
  const goToPage = (url: string | undefined, isOuterLink: boolean) => {
    if (!url) return;
    if (isOuterLink) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  return (
    <Box
      ref={sliderContainerRef}
      sx={{
        width: "100%",
        display: "flex",
        gap: "20px",
        maxWidth: { md: "1180px", sm: "716px", xs: "328px" },
        margin: "20px auto 0",
        flexWrap: "wrap",
        overflow: "hidden",
        boxSizing: "border-box",
        justifyContent: "flex-start",
      }}
    >
      <Box
        width="100%"
        maxWidth={`${sliderContainerWidth! * 2}px`}
        sx={{
          display: "flex",
          transition: "0.3s ease-in-out",
          boxSizing: "border-box",
          transform: `translateX(${transformIndex * -100}%)`,
        }}
      >
        {resources.map((infos) => (
          <Box
            key={infos.infos[0].name}
            sx={{
              display: "flex",
              width: "100%",
              gap: { md: "20px", sm: "20px", xs: "16px" },
              flexShrink: 0,
              boxSizing: "border-box",
              flexDirection: { sm: "row", xs: "column" },
            }}
          >
            {infos.infos.map((info) => (
              <Box
                key={info.name}
                sx={{
                  display: "flex",
                  flexDirection: { sm: "column", xs: "row-reverse" },
                  alignItems: "flex-start",
                  width: "100%",
                  maxWidth: { md: 380, sm: 348, xs: 328 },
                  height: { md: "auto", sm: "auto", xs: "auto" },
                  minHeight: { sm: "auto", xs: "200px" },
                  border: "1px solid #C8C8C8",
                  boxSizing: "border-box",
                  borderRadius: "4px",
                  padding: { sm: 0, xs: "16px" },
                  gap: { sm: 0, xs: "16px" },
                }}
              >
                <Box
                  sx={{
                    maxWidth: { md: "auto", sm: "100%", xs: "88px" },
                    width: "100%",
                  }}
                >
                  {queryDownSm ? (
                    <Box
                      sx={{
                        width: "88px",
                        height: "88px",
                        background:
                          "url(" + info.src + ")" + "center no-repeat",
                        backgroundSize: "cover",
                      }}
                    ></Box>
                  ) : (
                    <img
                      src={info.src}
                      style={{
                        width: "100%",
                      }}
                      alt="resource-img"
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    boxSizing: "border-box",
                    display: "flex",
                    gap: { sm: "20px", xs: "4px" },
                    padding: { sm: "20px", xs: "0" },
                    flexDirection: "column",
                    maxWidth: "100%",
                  }}
                >
                  <Box>
                    <Typography
                      fontWeight="700"
                      sx={{
                        fontSize: { sm: "16px", xs: "14px" },
                        lineHeight: { sm: "24px", xs: "20px" },
                        height: { sm: "82.25px", xs: "120px" },
                        overflowX: "hidden",
                      }}
                    >
                      {info.description}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: { sm: "24px", xs: "auto" },
                      maxWidth: "340px",
                      gap: { md: "20px", sm: "16px", xs: "4px" },
                      flexWrap: { sm: "nowrap", xs: "wrap" },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        maxWidth: {
                          md: "210px",
                          sm: "188px",
                          xs: "188px",
                        },
                      }}
                    >
                      <Typography
                        color="#89898A"
                        fontWeight="700"
                        fontSize="12px"
                        sx={{ padding: { sm: "0 0 0 7px", xs: "0" } }}
                      >
                        {info.category} ・ {info.date}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Button
                        sx={{
                          color: "#008CD6",
                          fontWeight: 700,
                          fontSize: "16px",
                          textTransform: "none",
                          padding: "0",
                          whiteSpace: "nowrap",
                        }}
                        onClick={() =>
                          goToPage(info.url ?? "", !!info.isOuterLink)
                        }
                      >
                        <Typography color="#008CD6" fontWeight="700">
                          {t("landing-page.common.learn-more")}
                        </Typography>

                        <ChevronRightIcon style={{ color: "#008CD6" }} />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        ))}
      </Box>

      {queryDownSm ? undefined : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: { md: "100%", sm: "100%", xs: "100%" },
          }}
        >
          <Box
            sx={{
              bgcolor: "#fff",
              zIndex: 1,
            }}
          >
            <IconButton
              sx={{
                transition: "0.3s ease-in",
                backgroundColor: "white !important",
              }}
              onClick={handleClickPreviousPage}
              disabled={activePage === 0}
            >
              <NavigateBeforeIcon />
            </IconButton>

            <Typography
              textAlign="left"
              color={"GrayText"}
              sx={{ display: "inline-block", backgroundColor: "white" }}
            >
              {(activePage + 1).toString()} / {resources!.length}
            </Typography>

            <IconButton
              sx={{
                transition: "0.3s ease-in",
                backgroundColor: "white !important",
              }}
              onClick={handleClickNextPage}
              disabled={activePage === resources.length - 1}
            >
              <NavigateNextIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              width: "100%",
              position: "absolute",
              maxWidth: { md: "1180px", sm: "732px", xs: "328px" },
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 0,
            }}
          >
            <DashedDividingLine />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ResourcesBlock;
