import Q1Img from "../images/img_Why_need_TPM_Q1.png";
import Q2Img from "../images/img_Why_need_TPM_Q2.png";
import Q3Img from "../images/img_Why_need_TPM_Q3.png";
import Q4Img from "../images/img_Why_need_TPM_Q4.png";
import Q5Img from "../images/img_Why_need_TPM_Q5.png";
import Q6Img from "../images/img_Why_need_TPM_Q6.png";
import Q7Img from "../images/img_Why_need_TPM_Q7.png";
import OEEImg from "../../../../images/OEE.png";
import AddonImg from "../../../../images/AddOn.png";
import maintenanceImg from "../../../../images/img_service_maintenance.png";
import shopfloorImg from "../../../../images/ShopFloor.png";
import eManualImg from "../../../../images/eManual.png";
import statisticalImg from "../images/spc_img.png";
import { useTranslation } from "react-i18next";

const useQuestionsData = () => {
  const { t } = useTranslation();
  let questions = [
    {
      key: 1,
      text: "How can we easily understand the OEE of our factory? Where do we start?",
      introContent: t("solutions.tpm.question-one-intro-content"),
      featureIntros: [
        {
          title: t("solutions.tpm.oee"),
          text: t("solutions.tpm.oee-text"),
        },
        {
          title: t("solutions.tpm.availability"),
          text: t("solutions.tpm.availability-text"),
        },
        {
          title: t("solutions.tpm.performance"),
          text: t("solutions.tpm.performance-text"),
        },
        {
          title: t("solutions.tpm.quality"),
          text: t("solutions.tpm.quality-text"),
        },
      ],
      relatedPicture: Q1Img,
      relatedContents: [
        {
          shortDescription: "",
          texts: [
            {
              text: t("solutions.tpm.q1-content-text-1"),
            },
            {
              text: t("solutions.tpm.q1-content-text-2"),
            },
            {
              text: t("solutions.tpm.q1-content-text-3"),
            },
            {
              text: t("solutions.tpm.q1-content-text-4"),
            },
            {
              text: t("solutions.tpm.q1-content-text-5"),
            },
          ],
        },
      ],
      packages: [
        {
          title: t("solutions.tpm.quick-response"),
          description: t(
            "landing-page.header.warnings-and-reports-to-the-relevant-staff-in-case-of-equipment-abnormalities"
          ),
          tag: "4c",
          url: "/starter-packages/quick-response",
          isOuterLink: true,
          maxWidth: { md: "280px", sm: "100%", xs: "100%" },
        },
      ],
      apps: [
        {
          title: t("solutions.tpm.oee"),
          description: t("solutions.tpm.oee-description"),
          src: OEEImg,
          url: t("products.oee-url"),
          isOuterLink: true,
        },
      ],
      videoSrc: "https://fast.wistia.net/embed/iframe/hzxbn4r7wc"
    },
    {
      key: 2,
      text: "Equipment and production efficiency at my plant is low. How can we improve it?",
      introContent: t("solutions.tpm.question-two-intro-content"),
      featureIntros: [
        {
          title: t("solutions.tpm.visual-management"),
          text: t("solutions.tpm.visual-managemen-text"),
        },
        {
          title: t("solutions.tpm.abnormal-tracking"),
          text: t("solutions.tpm.abnormal-tracking-text"),
        },
        {
          title: t("solutions.tpm.faster-turnaround-time"),
          text: t("solutions.tpm.faster-turnaround-time-text"),
        },
        {
          title: t("solutions.tpm.less-recurring"),
          text: t("solutions.tpm.less-recurring-text"),
        },
      ],
      relatedPicture: Q2Img,
      relatedContents: [
        {
          shortDescription: t("solutions.tpm.q2-short-description"),
          texts: [
            {
              text: t("solutions.tpm.q2-content-text-1"),
            },
            {
              text: t("solutions.tpm.q2-content-text-2"),
            },
            {
              text: t("solutions.tpm.q2-content-text-3"),
            },
          ],
        },
      ],
      packages: [
        {
          title: t("solutions.tpm.quick-response"),
          description: t(
            "landing-page.header.warnings-and-reports-to-the-relevant-staff-in-case-of-equipment-abnormalities"
          ),
          tag: "4c",
          url: "/starter-packages/quick-response",
          isOuterLink: true,
          maxWidth: { md: "280px", sm: "100%", xs: "100%" },
        },
      ],
      apps: [
        {
          title: t("solutions.tpm.oee"),
          description: t("solutions.tpm.oee-description"),
          src: OEEImg,
          url: t("products.oee-url"),
          isOuterLink: true,
        },
        {
          title: t("solutions.tpm.andon"),
          description: t("solutions.tpm.addon-description"),
          src: AddonImg,
          url: t("products.addon-url"),
          isOuterLink: true,
        },
      ],
      videoSrc: "https://fast.wistia.net/embed/iframe/c0kvn4j6ie"
    },
    {
      key: 3,
      text: "What check-ups and maintenance can be scheduled to help prevent unexpected machine downtime?",
      introContent: t("solutions.tpm.question-three-intro-content"),
      featureIntros: [
        {
          title: t("solutions.tpm.condition-based"),
          text: t("solutions.tpm.condition-based-text"),
        },
        {
          title: t("solutions.tpm.proactive-planning"),
          text: t("solutions.tpm.proactive-planning-text"),
        },
        {
          title: t("solutions.tpm.improve-maintenance-result"),
          text: t("solutions.tpm.improve-maintenance-result-text"),
        },
        {
          title: t("solutions.tpm.historical-data-analysis"),
          text: t("solutions.tpm.historical-data-analysis-text"),
        },
      ],
      relatedPicture: Q3Img,
      relatedContents: [
        {
          shortDescription: t("solutions.tpm.q3-short-description"),
          texts: [
            {
              text: t("solutions.tpm.q3-content-text-1"),
            },
            {
              text: t("solutions.tpm.q3-content-text-2"),
            },
          ],
        },
      ],
      packages: [
        {
          title: t("solutions.tpm.machine-inspector"),
          description: t(
            "landing-page.header.zero-downtime-machine-doctor-focuses-on-detecting-specific-parameters-of-machines-and-gives-an-appropriate-early-diagnosis-evaluation-to-avoid-unplanned-issues"
          ),
          tag: "4b",
          url: "",
          isOuterLink: true,
          maxWidth: { md: "280px", sm: "100%", xs: "100%" },
        },
      ],
      apps: [
        {
          title: t("solutions.tpm.maintenance"),
          description: t("solutions.tpm.maintenance-description"),
          src: maintenanceImg,
          url: t("products.maintenance-url"),
          isOuterLink: true,
        },
        {
          title: t("solutions.tpm.oee"),
          description: t("solutions.tpm.oee-description"),
          src: OEEImg,
          url: t("products.oee-url"),
          isOuterLink: true,
        },
      ],
      videoSrc: "https://fast.wistia.net/embed/iframe/qkn2hvg1ka"
    },
    {
      key: 4,
      text: "How can we further improve production output and performance after automation investments?",
      introContent: t("solutions.tpm.question-four-intro-content"),
      featureIntros: [
        {
          title: t("solutions.tpm.optimized-cycle-time"),
          text: t("solutions.tpm.optimized-cycle-time-text"),
        },
        {
          title: t("solutions.tpm.reduce-low-speed-production"),
          text: t("solutions.tpm.reduce-low-speed-production-text"),
        },
        {
          title: t("solutions.tpm.track-performance-loss"),
          text: t("solutions.tpm.track-performance-loss-text"),
        },
        {
          title: t("solutions.tpm.routing-arrangement"),
          text: t("solutions.tpm.routing-arrangement-text"),
        },
      ],
      relatedPicture: Q4Img,
      relatedContents: [
        {
          shortDescription: t("solutions.tpm.q4-short-description"),
          texts: [
            {
              text: t("solutions.tpm.q4-content-text-1"),
            },
            {
              text: t("solutions.tpm.q4-content-text-2"),
            },
          ],
        },
      ],
      packages: [
        {
          title: t("solutions.tpm.real-time-progress"),
          description: t(
            "landing-page.header.notifications-for-supervisors-and-managers-when-equipment-productivity-drops"
          ),
          tag: "3b",
          url: "",
          isOuterLink: true,
          maxWidth: { md: "280px", sm: "100%", xs: "100%" },
        },
      ],
      apps: [
        {
          title: t("products.shop-floor"),
          description: t(
            "solutions.tpm.production-tracking-scheduling-and-status-reporting"
          ),
          src: shopfloorImg,
          url: t("products.shop-floor-url"),
          isOuterLink: true,
        },
      ],
      videoSrc: "https://fast.wistia.net/embed/iframe/3mrhm0t019"
    },
    {
      key: 5,
      text: "How can we empower employees with information, and secure productivity with good quality outcomes?",
      introContent: t("solutions.tpm.question-five-intro-content"),
      featureIntros: [
        {
          title: t("solutions.tpm.easy-accessibility"),
          text: t("solutions.tpm.easy-accessibility-text"),
        },
        {
          title: t("solutions.tpm.consistency-and-up-to-date"),
          text: t("solutions.tpm.consistency-and-up-to-date-text"),
        },
        {
          title: t("solutions.tpm.customization"),
          text: t("solutions.tpm.customization-text"),
        },
        {
          title: t("solutions.tpm.tracking-and-monitoring"),
          text: t("solutions.tpm.tracking-and-monitoring-text"),
        },
      ],
      relatedPicture: Q5Img,
      relatedContents: [
        {
          shortDescription: t("solutions.tpm.q5-short-description"),
          texts: [
            {
              text: t("solutions.tpm.q5-content-text-1"),
            },
            {
              text: t("solutions.tpm.q5-content-text-2"),
            },
            {
              text: t("solutions.tpm.q5-content-text-3"),
            },
            {
              text: t("solutions.tpm.q5-content-text-4"),
            },
          ],
        },
      ],
      packages: [
        {
          title: t("solutions.tpm.work-mentor"),
          description: t(
            "landing-page.header.record-recipes-formulas-and-work-sops-to-guide-on-site-operators"
          ),
          tag: "2b",
          url: "",
          isOuterLink: true,
          maxWidth: { md: "280px", sm: "100%", xs: "100%" },
        },
      ],
      apps: [
        {
          title: t("solutions.tpm.emanual"),
          description: t(
            "solutions.tpm.digital-sop-provides-accurate-product-information-and-fast-updates-which-shortens-the-learning-curve-for-operators"
          ),
          src: eManualImg,
          url: t("products.emanual-url"),
          isOuterLink: true,
        },
        {
          title: t("solutions.tpm.shop-floor"),
          description: t(
            "solutions.tpm.production-tracking-scheduling-and-status-reporting"
          ),
          src: shopfloorImg,
          url: t("products.shop-floor-url"),
          isOuterLink: true,
        },
      ],
      videoSrc: "https://fast.wistia.net/embed/iframe/mn1643oo3q"
    },
    {
      key: 6,
      text: "How can we manage and reduce variations in production quality to ensure stable quality?",
      introContent: t("solutions.tpm.question-six-intro-content"),
      featureIntros: [
        {
          title: t("solutions.tpm.early-detection"),
          text: t("solutions.tpm.early-detection-text"),
        },
        {
          title: t("solutions.tpm.data-driven"),
          text: t("solutions.tpm.data-driven-text"),
        },
        {
          title: t("solutions.tpm.continuous-improvement"),
          text: t("solutions.tpm.continuous-improvement-text"),
        },
        {
          title: t("solutions.tpm.flexible-and-adaptable"),
          text: t("solutions.tpm.flexible-and-adaptable-text"),
        },
      ],
      relatedPicture: Q6Img,
      relatedContents: [
        {
          shortDescription: t("solutions.tpm.q6-short-description"),
          texts: [
            {
              text: t("solutions.tpm.q6-content-text-1"),
            },
            {
              text: t("solutions.tpm.q6-content-text-2"),
            },
            {
              text: t("solutions.tpm.q6-content-text-3"),
            },
          ],
        },
      ],
      packages: [],
      apps: [
        {
          title: t("solutions.ai.spc"),
          description: t(
            "solutions.ai.safeguard-plant-and-keep-fire-fighting-equipment-in-good-condition-with-ease-and-efficiency"
          ),
          src: statisticalImg,
          url: t("products.spc-url"),
          isOuterLink: true,
        },
        {
          title: t("solutions.tpm.oee"),
          description: t("solutions.tpm.oee-description"),
          src: OEEImg,
          url: t("products.oee-url"),
          isOuterLink: true,
        },
      ],
      videoSrc: "https://fast.wistia.net/embed/iframe/51t6d2y3r1"
    },
  ];

  return questions;
};

export { useQuestionsData };
