import { useTranslation } from "react-i18next";

export const useQuestionsData = () => {
  const {t} = useTranslation();
  let questions = [
    {
      text: t('intelligent-automation.question-one'),
    },
    {
      text: t('intelligent-automation.question-two'),
    },
    {
      text: t('intelligent-automation.question-three'),
    },
    {
      text: t('intelligent-automation.question-four'),
    },
    {
      text: t('intelligent-automation.question-five'),
    },
  ];

  return questions;
};
