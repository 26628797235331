import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";
import GlobalCss from "./components/Common/GlobalCssOverride";
import reportWebVitals from "./reportWebVitals";
import {
  ElectronicsIndustry,
  ErrorPage,
  FindingPartner,
  FoodAndBeverageIndustry,
  FootwearIndustry,
  Home,
  MetalProcessingIndustry,
  PCBIndustry,
  Root,
  BecomingPartner,
} from "./routes";

import { EDM } from "./routes/starter-kits/EDM";
import { theme } from "./theme";
import { AuthProvider, ImageUtilsProvider, ServiceProvider } from "./hooks";
import "./i18n";

import "./index.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { SolutionTPM } from "./routes/solutions/solution-TPM/SolutionTPM";
import { SolutionEMS } from "./routes/solutions/solution-EMS/SolutionEMS";
import { IntelligentAutomation } from "./routes/solutions/IntelligentAutomation/IntelligentAutomation";
import { useTranslation } from "react-i18next";
import { DigitalTransformation } from "@/routes/solutions/DigitalTransformation/DigitalTransformation";

const queryClient = new QueryClient();

const useRouter = () => {
  const { t } = useTranslation();
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          // index: true,
          path: "",
          element: <Home />,
          children: [],
        },
        {
          path: "starter-packages",
          // element: <></>,
          children: [
            {
              path: "smart-maintenance",
              element: <EDM />,
            },
            {
              path: "high-energy-equipment-monitoring",
              element: (
                <EDM
                  src={t(
                    "landing-page.header.energy-efficiency-accelerator-url" ??
                      ""
                  )}
                />
              ),
            },
            {
              path: "machine-inspector",
              element: (
                <EDM
                  src={t('home.machine-inspector-url')}
                />
              ),
            },
            {
              path: "quick-response",
              element: (
                <EDM
                  src={"/static/starter-packages/quick-response/index.html"}
                />
              ),
            },
            {
              path: "mobile-operational-inspection",
              element: (
                <EDM
                  src={
                    "https://campaign.advantech.online/en/iFactory_Smart_Patrol/"
                  }
                />
              ),
            },
            {
              path: "real-time-progress",
              element: (
                <EDM src={"/static/starter-packages/real-time/index.html"} />
              ),
            },
            {
              path: "work-mentor",
              element: <EDM src={t("home.work-mentor-edm-url")} />,
            },
          ],
        },
        {
          path: "industries",
          // element: <></>,
          children: [
            {
              path: "footwear",
              element: <FootwearIndustry />,
            },
            {
              path: "metal-processing",
              element: <MetalProcessingIndustry />,
            },
            {
              path: "electronics-and-electrics",
              element: <ElectronicsIndustry />,
            },
            {
              path: "pcb",
              element: <PCBIndustry />,
            },
            {
              path: "food-and-beverage",
              element: <FoodAndBeverageIndustry />,
            },
          ],
        },
        {
          path: "solutions",
          children: [
            {
              path: "ifactoryworks",
              element: <SolutionTPM />,
            },
            {
              path: "intelligent-automation",
              element: <IntelligentAutomation />,
            },
            {
              path: "digital-transformation",
              element: <DigitalTransformation />,
            },
          ],
        },
        {
          path: "resources",
          element: <></>,
        },
        {
          path: "partner",
          children: [
            {
              path: "finding-partners",
              element: <FindingPartner />,
            },
            {
              path: "becoming-partners",
              element: <BecomingPartner />,
            },
          ],
        },
      ],
    },
  ]);

  return router;
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const RootContent = () => {
  return (
    // <React.StrictMode>
    <ImageUtilsProvider>
      <QueryClientProvider client={queryClient}>
        <ServiceProvider>
          <AuthProvider>
            <ThemeProvider theme={theme}>
              <GlobalCss />
              <RouterProvider router={useRouter()} />
            </ThemeProvider>
          </AuthProvider>
        </ServiceProvider>
      </QueryClientProvider>
    </ImageUtilsProvider>
    // </React.StrictMode>
  );
};
root.render(<RootContent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
