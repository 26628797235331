import {
  Box,
  List,
  ListItem,
  Typography,
  ListItemButton,
  useTheme,
  useMediaQuery,
  Collapse,
  Slide,
} from "@mui/material";
import { Fragment, useState } from "react";
import DashedDividingLine from "../../../components/Common/DashedDividingLine";
import { useTranslation, Trans } from "react-i18next";
import { FeaturesColoredCard } from "@/components/solutions/FeaturesColoredCard";
import { FeaturesIntroductionCard } from "@/components/solutions/FeaturesIntroductionCard";
import { useSolutionsData } from "./data/useSolutionsData";
import { useQuestionsData } from "./data/useQuestionsData";

export default function WhyDoYouNeed() {
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const theme = useTheme();
  const queryDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const solutions = useSolutionsData();
  const questions = useQuestionsData();

  enum showContentPart {
    none = 0,
    features = 1,
    introduction = 2,
  }

  const [collapsePart, setCollapsePart] = useState(
    showContentPart.introduction
  );
  const handleChangeActiveQuestionCard = (index: number) => {
    setActiveCardIndex(index);
    setCollapsePart(showContentPart.introduction);
  };

  const handleReadLess = (
    e:
      | React.MouseEvent<HTMLLIElement, MouseEvent>
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (collapsePart >= 0) {
      setCollapsePart((value) => {
        return value - 1;
      });
    }
  };

  const handleReadMore = (
    e:
      | React.MouseEvent<HTMLLIElement, MouseEvent>
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    setCollapsePart(showContentPart.introduction);
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        maxWidth: { lg: 1180, md: 768, sm: 716, xs: 328 },
        flexDirection: "column",
        padding: { md: "40px 0", xs: "20px 0" },
        gap: "20px",
      }}
    >
      <Typography
        textAlign="center"
        variant="h6"
        fontWeight="700"
        fontSize={{ md: "48px", sm: "40px", xs: "28px" }}
        display={"flex"}
        justifyContent={"center"}
        position={"relative"}
        width="100%"
      >
        <Trans
          i18nKey="intelligent-automation.questions-title"
          components={{ br: <br /> }}
        />
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", sm: "row", xs: "column" },
          gap: { sm: "20px", xs: "0" },
          justifyContent: "center",
          alignItems: { sm: "flex-start", xs: "center" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: { md: "280px", sm: "256px", xs: "328px" },
          }}
        >
          <List sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <ListItem
              disablePadding
              sx={{
                width: { md: "280px", sm: "256px", xs: "328px" },
                display: "flex",
                flexDirection: "column",
                gap: queryDownSm ? "16px" : "20px",
              }}
            >
              {questions.map((question, index) => {
                return (
                  <Fragment key={question.text}>
                    {activeCardIndex === index ? (
                      <Box
                        sx={{
                          background:
                            "linear-gradient(315deg, rgba(11, 76, 217, 0.1) 0%, rgba(0, 140, 214, 0.1) 100%), #FFFFFF",
                          display: "flex",
                          flexDirection: "column",
                          padding: { md: "8px 0", sm: 0, xs: "8px 0" },
                        }}
                      >
                        <ListItemButton
                          sx={{
                            width: "100%",
                            padding: "0px",
                            flexWrap: "wrap",
                            borderLeft: "4px #008CD6 solid",
                            boxSizing: "border-box !important",
                          }}
                          onClick={() => handleChangeActiveQuestionCard(index)}
                        >
                          <Box
                            sx={{
                              boxSizing: "border-box !important",
                              width: "100%",
                              height: "100%",
                              padding: { sm: "0px 0px 0px 20px", xs: "0 12px" },
                              display: "flex",
                              flexDirection: { sm: "column", xs: "row" },
                              alignItems: "flex-start",
                              gap: { sm: "4px", xs: "10px" },
                            }}
                          >
                            <Box sx={{ width: "24px", height: "24px" }}>
                              <Box
                                sx={{
                                  width: { sm: "24px", xs: "20px" },
                                  height: { sm: "24px", xs: "20px" },
                                  borderRadius: "4px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "#008CD6",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#fff",
                                    fontWeight: "bold",
                                    width: "20px",
                                    height: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: {
                                      md: "16px",
                                      sm: "14px",
                                      xs: "14px",
                                    },
                                  }}
                                >
                                  {index + 1}
                                </Typography>
                              </Box>
                            </Box>
                            <Typography
                              component={"span"}
                              color={"#323233"}
                              fontWeight="400"
                              sx={{
                                fontSize: {
                                  md: "16px",
                                  sm: "14px",
                                  xs: "14px",
                                },
                                color: "#008CD6",
                                fontWeight: "700",
                                letterSpacing: "0.003em",
                              }}
                            >
                              {question.text}
                            </Typography>
                          </Box>
                        </ListItemButton>

                        {/*Mobile*/}
                        {queryDownSm ? (
                          <>
                            {collapsePart > 0 ? (
                              <ListItem
                                sx={{
                                  flexWrap: "wrap",
                                  padding: "0 0 0 40px",
                                  zIndex: 50,
                                  borderLeft: "4px #008CD6 solid",
                                  margin: "0",
                                }}
                                onClick={(e) => handleReadLess(e)}
                              >
                                <Typography
                                  sx={{
                                    color: "#008CD6",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Read less...
                                  {/*//Todo {t("pcb.read-less")} */}
                                  {t("pcb.read-less")}
                                </Typography>
                              </ListItem>
                            ) : (
                              <ListItem
                                sx={{
                                  flexWrap: "wrap",
                                  padding: "0px 0 0 40px",
                                  zIndex: 50,
                                  borderLeft: "4px #008CD6 solid",
                                  margin: "0 0 0 0",
                                }}
                                onClick={(e) => handleReadMore(e)}
                              >
                                <Typography
                                  sx={{
                                    color: "#008CD6",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Read more...
                                  {/*//Todo {t("pcb.read-more")} */}
                                </Typography>
                              </ListItem>
                            )}
                          </>
                        ) : undefined}

                        <Collapse
                          in={activeCardIndex === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          {queryDownSm &&
                            collapsePart > 0 &&
                            solutions
                              .filter(
                                (solution) =>
                                  activeCardIndex + 1 === solution.id
                              )
                              .map((solution) => {
                                return (
                                  <Box
                                    key={solution.id}
                                    sx={{
                                      width: "100%",
                                      maxWidth: 328,
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "16px",
                                      padding: "0 12px",
                                      boxSizing: "border-box",
                                      margin: "20px 0 0",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItem: "center",
                                        gap: "16px",
                                        maxWidth: 304,
                                      }}
                                    >
                                      <Typography
                                        component={"span"}
                                        height="100%"
                                        color="#323233"
                                        fontWeight="700"
                                        fontSize="20px"
                                        whiteSpace="nowrap"
                                        lineHeight="40px"
                                        maxWidth="51px"
                                      >
                                        Intro
                                        {/* //Todo {t("solutions.tpm.intro")} */}
                                      </Typography>
                                      <Box sx={{ width: "100%", zIndex: 0 }}>
                                        <DashedDividingLine />
                                      </Box>
                                    </Box>
                                    <Box
                                      sx={{
                                        width: "100%",
                                        display: "flex",
                                        gap: "16px",

                                        flexDirection: "column",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          width: "100%",
                                          maxWidth: 304,
                                          flexDirection: "column",
                                          gap: "16px",
                                        }}
                                      >
                                        <Typography
                                          sx={{ fontSize: 14 }}
                                          fontWeight={700}
                                        >
                                          {solution.title}
                                        </Typography>
                                        <Typography
                                          sx={{ fontSize: 12 }}
                                          fontWeight={400}
                                          color="#89898A"
                                        >
                                          {solution.content}
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          width: "100%",
                                          maxWidth: 304,
                                          height: {
                                            sm: 154,
                                            xs: "auto",
                                          },
                                        }}
                                      >
                                        <FeaturesColoredCard
                                          features={solution.features}
                                        />
                                      </Box>
                                    </Box>
                                    {collapsePart > 1 ? (
                                      <FeaturesIntroductionCard
                                        answerCard={solution.answerCard}
                                      />
                                    ) : undefined}
                                    <Box
                                      sx={{
                                        width: "100%",
                                        height: "40px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItem: "center",
                                        position: "relative",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          width: "100%",
                                          zIndex: 1,
                                        }}
                                      >
                                        <DashedDividingLine />
                                      </Box>
                                      <Typography
                                        sx={{
                                          color: "#008CD6",
                                          fontSize: "14px",
                                          fontWeight: 700,
                                          zIndex: 2,
                                          textAlign: "center",
                                          width: "100%",
                                          lineHeight: "40px",
                                        }}
                                        onClick={(e) => handleReadLess(e)}
                                      >
                                        Read less...
                                        {/*//Todo {t("pcb.read-less")} */}
                                      </Typography>
                                      <Box
                                        sx={{
                                          width: "100%",
                                          zIndex: 1,
                                        }}
                                      >
                                        <DashedDividingLine />
                                      </Box>
                                    </Box>
                                  </Box>
                                );
                              })}
                        </Collapse>
                      </Box>
                    ) : (
                      <ListItemButton
                        sx={{
                          width: "100%",
                          padding: { md: "8px 0", sm: 0, xs: "8px 0" },
                          boxSizing: "border-box !important",
                          display: "flex",
                          flexDirection: { sm: "row", xs: "column" },
                          alignItems: "flex-start",
                        }}
                        onClick={() => handleChangeActiveQuestionCard(index)}
                      >
                        <Box
                          sx={{
                            boxSizing: "border-box !important",
                            width: "100%",
                            height: "100%",
                            padding: { sm: "0px 0px 0px 24px", xs: "0 0px" },
                            display: "flex",
                            flexDirection: { sm: "column", xs: "row" },
                            alignItems: "flex-start",
                            gap: { sm: "4px", xs: "10px" },
                          }}
                        >
                          <Box sx={{ width: "24px", height: "24px" }}>
                            <Box
                              sx={{
                                width: { sm: "24px", xs: "20px" },
                                height: { sm: "24px", xs: "20px" },
                                borderRadius: "4px",
                                backgroundColor: "#89898A",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontWeight: "bold",
                                  width: "20px",
                                  height: "20px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: {
                                    md: "16px",
                                    sm: "14px",
                                    xs: "14px",
                                  },
                                }}
                              >
                                {index + 1}
                              </Typography>
                            </Box>
                          </Box>
                          <Typography
                            component={"span"}
                            color={"#323233"}
                            sx={{
                              fontSize: { md: "16px", sm: "14px", xs: "14px" },
                              fontWeight: { sm: 400, xs: 700 },
                            }}
                          >
                            {question.text}
                          </Typography>
                        </Box>
                        {queryDownSm ? (
                          <Box
                            sx={{
                              flexWrap: "wrap",
                              zIndex: 50,
                            }}
                            onClick={(e) => handleReadMore(e)}
                          >
                            <Typography
                              sx={{
                                color: "#008CD6",
                                fontSize: "14px",
                                fontWeight: 700,
                                margin: "0 0 0 30px",
                              }}
                            >
                              Read more...
                              {/*Todo {t("pcb.read-more")} */}
                            </Typography>
                          </Box>
                        ) : undefined}
                      </ListItemButton>
                    )}
                  </Fragment>
                );
              })}
            </ListItem>
          </List>
        </Box>
        {!queryDownSm &&
          solutions
            .filter((solution) => activeCardIndex + 1 === solution.id)
            .map((solution) => {
              return (
                <Box
                  key={solution.id}
                  sx={{
                    width: "100%",
                    height: "100%",
                    minHeight: "1000px",
                    maxWidth: { md: "880px", sm: "440px", xs: "328px" },
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <Slide in={true} direction="left" timeout={600}>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItem: "center",
                          gap: "20px",
                        }}
                      >
                        <Typography
                          component={"span"}
                          height="100%"
                          color="#323233"
                          fontWeight="700"
                          sx={{
                            fontSize: { sm: "24px", xs: "20px" },
                            whiteSpace: "nowrap",
                          }}
                          lineHeight="40px"
                          maxWidth="51px"
                        >
                          Intro
                          {/*//Todo {t("solutions.tpm.intro")} */}
                        </Typography>
                        <Box sx={{ width: "100%" }}>
                          <DashedDividingLine />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          boxSizing: "border-box",
                          width: "100%",
                          display: "flex",
                          gap: { md: "60px", sm: "20px", xs: "20px" },
                          // padding: "0 40px 0 0",
                          flexDirection: {
                            md: "row",
                            sm: "column",
                            xs: "column",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "430px",
                            flexDirection: "column",
                            gap: "10px",
                            height: { md: "180px", xs: "auto" },
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "16px" }}
                            fontWeight={700}
                          >
                            {solution.title}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "14px" }}
                            fontWeight={400}
                            color="#89898A"
                          >
                            {solution.content}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            width: "430px",
                            height: "180px",
                          }}
                        >
                          <FeaturesColoredCard features={solution.features} />
                        </Box>
                      </Box>
                      <FeaturesIntroductionCard
                        answerCard={solution.answerCard}
                      />
                    </Box>
                  </Slide>
                </Box>
              );
            })}
      </Box>
    </Box>
  );
}
