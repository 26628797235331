import stepPicture01 from "../images/img_progress1_p.png";
import stepPicture01l from "../images/img_progress1_l.png";
import stepPicture02 from "../images/img_progress2_p.png";
import stepPicture02l from "../images/img_progress2_l.png";
import stepPicture03 from "../images/img_progress3_p.png";
import stepPicture03l from "../images/img_progress3_l.png";
import stepPicture04 from "../images/img_progress4_p.png";
import stepPicture04l from "../images/img_progress4_l.png";
import stepPicture05 from "../images/img_progress5_p.png";
import stepPicture05l from "../images/img_progress5_l.png";
import stepPicture06 from "../images/img_progress6_p.png";
import stepPicture06l from "../images/img_progress6_l.png";
import stepPicture07 from "../images/img_progress7_p.png";
import stepPicture07l from "../images/img_progress7_l.png";

import ShopfloorPic from "../../../../images/ShopFloor.png";
import OEEPic from "../../../../images/OEE.png";
import AddOnPic from "../../../../images/AddOn.png";
import Mic7702Pic from "../../../../images/img_device_MIC-770-V2.png";
import BarcodePic from "../../../../images/img_device_scanner.png";
import eAIGatewayPic from "../../../../images/gateway-slg1211.png";
import eManualPic from "../../../../images/eManual.png";
import AIM75SPic from "../../../../images/img_device_AIM-75S.png";
import tpc100wPic from "../../../../images/img_device_TPC-107W.png";
import ecu1251Pic from "../../../../images/img_device_ECU-1251.png";
import { useTranslation, Trans } from "react-i18next";

const useStepCardsData = () => {
  const { t } = useTranslation();

  const stepCards = [
    {
      stepName: t("food.cleaning"),
      elementId: "Cleaning",
      src: {
        md: stepPicture01,
        sm: stepPicture01l,
      },
      flexDirection: "row",
      starterPackages: [
        {
          name: t("food.real-time-progress"),
          packages: [
            {
              page: 1,
              title: t("food.route-control-during-processing"),
              content: t("food.during-the-production-process"),
              devices: [
                {
                  src: ShopfloorPic,
                  title: t("products.shop-floor"),
                  code: "9804EBSFC00",
                },
                {
                  src: Mic7702Pic,
                  title: t("products.mic770v2-i5-edge"),
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: BarcodePic,
                  title: (
                    <Trans
                      i18nKey="products.barcode-scanner-rs233"
                      components={{ br: <br /> }}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      stepName: t("food.sterilizing"),
      elementId: "Sterilizing",
      src: {
        md: stepPicture02,
        sm: stepPicture02l,
      },
      flexDirection: "row-reverse",
      starterPackages: [
        {
          name: t("food.real-time-progress"),
          packages: [
            {
              page: 1,
              title: t("food.route-control-during-processing"),
              content: t("food.during-the-production-process"),
              devices: [
                {
                  src: ShopfloorPic,
                  title: t("products.shop-floor"),
                  code: "9804EBSFC00",
                },
                {
                  src: Mic7702Pic,
                  title: t("products.mic770v2-i5-edge"),
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: BarcodePic,
                  title: (
                    <Trans
                      i18nKey="products.barcode-scanner-rs233"
                      components={{ br: <br /> }}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          name: t("food.quick-response"),
          packages: [
            {
              page: 1,
              title: t("food.steam-sterilization-machine"),
              content: t(
                "food.in-accordance-with-various-food-sterilization-methods"
              ),
              devices: [
                {
                  src: OEEPic,
                  title: t("products.oee"),
                  code: "9804EBOEE00",
                },
                {
                  src: AddOnPic,
                  title: t("products.andon"),
                  code: "9804EBADN00",
                },
                {
                  src: Mic7702Pic,
                  title: t("products.mic770v2-i5-edge"),
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: tpc100wPic,
                  title: t("products.tpc-100w"),
                  code: "TPC-100W Series",
                },
                {
                  src: ecu1251Pic,
                  title: t("products.ecu-1251"),
                  code: "SRP-IFS420-E14TAE",
                },
                {
                  src: BarcodePic,
                  title: (
                    <Trans
                      i18nKey="products.barcode-scanner-rs233"
                      components={{ br: <br /> }}
                    />
                  ),
                },
              ],
            },
          ],
          url: "/starter-packages/quick-response",
        },
        {
          name: t("food.work-mentor"),
          packages: [
            {
              page: 1,
              title: t("food.steam-sterilization-machine"),
              content: t("food.sterilization-work-mentor-content"),
              devices: [
                {
                  src: eManualPic,
                  title: t("products.emanual"),
                  code: "9804EIEMN00",
                },
                {
                  src: Mic7702Pic,
                  title: t("products.mic770v2-i5-edge"),
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: tpc100wPic,
                  title: t("products.tpc-100w"),
                  code: "TPC-100W Series",
                },
                {
                  src: AIM75SPic,
                  title: t("products.aim-75s"),
                  code: t("products.ifs-aim75s-imb-code"),
                },
                {
                  src: BarcodePic,
                  title: (
                    <Trans
                      i18nKey="products.barcode-scanner-rs233"
                      components={{ br: <br /> }}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      stepName: t("food.boiling"),
      elementId: "Boiling",
      src: {
        md: stepPicture03,
        sm: stepPicture03l,
      },
      flexDirection: "row",
      starterPackages: [
        {
          name: t("food.real-time-progress"),
          packages: [
            {
              page: 1,
              title: t("food.route-control-during-processing"),
              content: t("food.during-the-production-process"),
              devices: [
                {
                  src: ShopfloorPic,
                  title: t("products.shop-floor"),
                  code: "9804EBSFC00",
                },
                {
                  src: Mic7702Pic,
                  title: t("products.mic770v2-i5-edge"),
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: BarcodePic,
                  title: (
                    <Trans
                      i18nKey="products.barcode-scanner-rs233"
                      components={{ br: <br /> }}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          name: t("food.quick-response"),
          packages: [
            {
              page: 1,
              title: t("food.steam-sterilization-machine"),
              content: t("food.according-to-the-characteristics"),
              devices: [
                {
                  src: OEEPic,
                  title: t("products.oee"),
                  code: "9804EBOEE00",
                },
                {
                  src: AddOnPic,
                  title: t("products.andon"),
                  code: "9804EBADN00",
                },
                {
                  src: Mic7702Pic,
                  title: t("products.mic770v2-i5-edge"),
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: tpc100wPic,
                  title: t("products.tpc-100w"),
                  code: "TPC-100W Series",
                },
                {
                  src: ecu1251Pic,
                  title: t("products.ecu-1251"),
                  code: "SRP-IFS420-E14TAE",
                },
                {
                  src: BarcodePic,
                  title: (
                    <Trans
                      i18nKey="products.barcode-scanner-rs233"
                      components={{ br: <br /> }}
                    />
                  ),
                },
              ],
            },
          ],
          url: "/starter-packages/quick-response",
        },
        {
          name: t("food.work-mentor"),
          packages: [
            {
              page: 1,
              title: t("food.boiling-and-steaming-machine-blancher"),
              content: t(
                "food.the-product-sop-is-invoked-through-work-mentor-and-the-appropriate"
              ),
              devices: [
                {
                  src: eManualPic,
                  title: t("products.emanual"),
                  code: t("products.emanual-code"),
                },
                {
                  src: Mic7702Pic,
                  title: t("products.mic770v2-i5-edge"),
                  code: "IFS-RTM-M770W4A",
                },

                {
                  src: tpc100wPic,
                  title: t("products.tpc-100w"),
                  code: "TPC-100W Series",
                },
                {
                  src: AIM75SPic,
                  title: t("products.aim-75s"),
                  code: t("products.ifs-aim75s-imb-code"),
                },
                {
                  src: BarcodePic,
                  title: (
                    <Trans
                      i18nKey="products.barcode-scanner-rs233"
                      components={{ br: <br /> }}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      stepName: t("food.concentration"),
      elementId: "Concentration",
      src: {
        md: stepPicture04,
        sm: stepPicture04l,
      },
      flexDirection: "row-reverse",
      starterPackages: [
        {
          name: t("food.real-time-progress"),
          packages: [
            {
              page: 1,
              title: t("food.route-control-during-processing"),
              content: t("food.during-the-production-process-raw-materials"),
              devices: [
                {
                  src: ShopfloorPic,
                  title: t("products.shop-floor"),
                  code: "9804EBSFC00",
                },
                {
                  src: Mic7702Pic,
                  title: t("products.mic770v2-i5-edge"),
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: BarcodePic,
                  title: (
                    <Trans
                      i18nKey="products.barcode-scanner-rs233"
                      components={{ br: <br /> }}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          name: t("food.quick-response"),
          packages: [
            {
              page: 1,
              title: t("food.steam-sterilization-machine"),
              content: t(
                "food.it-is-important-to-ensure-the-quality-of-concentrated"
              ),
              devices: [
                {
                  src: OEEPic,
                  title: t("products.oee"),
                  code: "9804EBOEE00",
                },
                {
                  src: AddOnPic,
                  title: t("products.andon"),
                  code: "9804EBADN00",
                },
                {
                  src: Mic7702Pic,
                  title: t("products.mic770v2-i5-edge"),
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: tpc100wPic,
                  title: t("products.tpc-100w"),
                  code: "TPC-100W Series",
                },
                {
                  src: ecu1251Pic,
                  title: t("products.ecu-1251"),
                  code: "SRP-IFS420-E14TAE",
                },
                {
                  src: BarcodePic,
                  title: (
                    <Trans
                      i18nKey="products.barcode-scanner-rs233"
                      components={{ br: <br /> }}
                    />
                  ),
                },
              ],
            },
          ],
          url: "/starter-packages/quick-response",
        },
        {
          name: t("food.work-mentor"),
          packages: [
            {
              page: 1,
              title: t("food.concentration-equipment"),
              content: t("food.through-workmentor"),
              devices: [
                {
                  src: eManualPic,
                  title: t("products.emanual"),
                  code: t("products.emanual-code"),
                },
                {
                  src: Mic7702Pic,
                  title: t("products.mic770v2-i5-edge"),
                  code: "IFS-RTM-M770W4A",
                },

                {
                  src: tpc100wPic,
                  title: t("products.tpc-100w"),
                  code: "TPC-100W Series",
                },
                {
                  src: AIM75SPic,
                  title: t("products.aim-75s"),
                  code: t("products.ifs-aim75s-imb-code"),
                },
                {
                  src: BarcodePic,
                  title: (
                    <Trans
                      i18nKey="products.barcode-scanner-rs233"
                      components={{ br: <br /> }}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      stepName: t("food.packaging"),
      elementId: "Packaging",
      src: {
        md: stepPicture05,
        sm: stepPicture05l,
      },
      flexDirection: "row",
      starterPackages: [
        {
          name: t("food.real-time-progress"),
          packages: [
            {
              page: 1,
              title: t("food.route-control-during-processing"),
              content: t("food.during-the-production-process-raw-materials"),
              devices: [
                {
                  src: ShopfloorPic,
                  title: t("products.shop-floor"),
                  code: "9804EBSFC00",
                },
                {
                  src: Mic7702Pic,
                  title: t("products.mic770v2-i5-edge"),
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: BarcodePic,
                  title: (
                    <Trans
                      i18nKey="products.barcode-scanner-rs233"
                      components={{ br: <br /> }}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      stepName: t("food.qa"),
      elementId: "QA",
      src: {
        md: stepPicture06,
        sm: stepPicture06l,
      },
      flexDirection: "row-reverse",
      starterPackages: [
        {
          name: t("food.real-time-progress"),
          packages: [
            {
              page: 1,
              title: t("food.route-control-during-processing"),
              content: t("food.during-the-production-process-raw-materials"),
              devices: [
                {
                  src: ShopfloorPic,
                  title: t("products.shop-floor"),
                  code: "9804EBSFC00",
                },
                {
                  src: Mic7702Pic,
                  title: t("products.mic770v2-i5-edge"),
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: BarcodePic,
                  title: (
                    <Trans
                      i18nKey="products.barcode-scanner-rs233"
                      components={{ br: <br /> }}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      stepName: t("food.shipping"),
      elementId: t("food.shipping"),
      src: {
        md: stepPicture07,
        sm: stepPicture07l,
      },
      flexDirection: "row",
      starterPackages: [
        {
          name: t("food.real-time-progress"),
          packages: [
            {
              page: 1,
              title: t("food.route-control-during-processing"),
              content: t("food.during-the-production-process-raw-materials"),
              devices: [
                {
                  src: ShopfloorPic,
                  title: t("products.shop-floor"),
                  code: "9804EBSFC00",
                },
                {
                  src: Mic7702Pic,
                  title: t("products.mic770v2-i5-edge"),
                  code: "IFS-RTM-M770W4A",
                },
                {
                  src: BarcodePic,
                  title: (
                    <Trans
                      i18nKey="products.barcode-scanner-rs233"
                      components={{ br: <br /> }}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  return stepCards;
};

export { useStepCardsData };
