import {
  Box,
  List,
  ListItem,
  Typography,
  ListItemButton,
  useTheme,
  useMediaQuery,
  Collapse,
  Slide,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import DashedDividingLine from "../../../components/Common/DashedDividingLine";
import { useTranslation } from "react-i18next";

import styles from "@/styles/image.module.css";
import { useSolutionsData } from "./data/useSolutionsData";
import FeatureCard from "@/components/solutions/FeatureCard";
import { Pagination } from "@/components/Common/Pagination";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import useQuestionsData from "./data/useQuestionsData";

export default function WhyDoYouNeed() {
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const theme = useTheme();
  const queryUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const queryUpXs = useMediaQuery(theme.breakpoints.up("xs"));
  const queryDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const { t } = useTranslation();
  const solutions = useSolutionsData();
  const questions = useQuestionsData();

  let questionCard = [
    {
      text: "Why do companies use KPI dashboards? How can a dashboard help my company improve?",
    },
    {
      text: "I have performance indicators that I want to see, does it take a lot of time to make a dashboard every time? is there a simpler solution?",
    },
    {
      text: "What are some of the KPIs that manufacturers should consider for digital transformation?",
    },
  ];

  enum showContentPart {
    none = 0,
    features = 1,
    introduction = 2,
  }

  const [collapsePart, setCollapsePart] = useState(
    showContentPart.introduction
  );
  const [activePage, setActivePage] = useState(0);

  const handleChangeActiveQuestionCard = (index: number) => {
    setActiveCardIndex(index);
    setCollapsePart(showContentPart.introduction);
    setActivePage(0);
  };

  const handleReadLess = (
    e:
      | React.MouseEvent<HTMLLIElement, MouseEvent>
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (collapsePart >= 0) {
      setCollapsePart((value) => {
        return value - 1;
      });
    }
  };

  const handleReadMore = (
    e:
      | React.MouseEvent<HTMLLIElement, MouseEvent>
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    setCollapsePart(showContentPart.introduction);
  };

  const handleClickNextPage = () => {
    setActivePage((value) => value + 1);
  };
  const handleClickPreviousPage = () => {
    setActivePage((value) => value - 1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        maxWidth: { lg: 1180, md: 768, sm: 716, xs: 328 },
        flexDirection: "column",
        padding: { md: "40px 0", xs: "20px 0" },
        gap: "20px",
      }}
    >
      <Typography
        textAlign="center"
        variant="h6"
        fontWeight="700"
        fontSize={{ md: "48px", sm: "40px", xs: "28px" }}
        display={"flex"}
        justifyContent={"center"}
        position={"relative"}
        width="100%"
      >
        Why Do you need
        <br />
        Digital Transformation?
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", sm: "row", xs: "column" },
          gap: { sm: "20px", xs: "0" },
          justifyContent: "center",
          alignItems: { sm: "flex-start", xs: "center" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: { md: "280px", sm: "256px", xs: "328px" },
          }}
        >
          <List sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <ListItem
              disablePadding
              sx={{
                width: { md: "280px", sm: "256px", xs: "328px" },
                display: "flex",
                flexDirection: "column",
                gap: queryDownSm ? "16px" : "20px",
              }}
            >
              {questions.map((question, index) => {
                return (
                  <Fragment key={question.text}>
                    {activeCardIndex === index ? (
                      <Box
                        sx={{
                          background:
                            "linear-gradient(315deg, rgba(11, 76, 217, 0.1) 0%, rgba(0, 140, 214, 0.1) 100%), #FFFFFF",
                          display: "flex",
                          flexDirection: "column",
                          padding: { md: "8px 0", sm: 0, xs: "8px 0" },
                        }}
                      >
                        <ListItemButton
                          sx={{
                            width: "100%",
                            padding: "0px 0px",
                            boxSizing: "border-box !important",
                            flexWrap: "wrap",
                            borderLeft: "4px #008CD6 solid",
                          }}
                          onClick={() => handleChangeActiveQuestionCard(index)}
                        >
                          <Box
                            sx={{
                              boxSizing: "border-box !important",
                              width: "100%",
                              height: "100%",
                              padding: { sm: "0px 0px 0px 20px", xs: "0 12px" },
                              display: "flex",
                              flexDirection: { sm: "column", xs: "row" },
                              alignItems: "flex-start",
                              gap: { sm: "4px", xs: "10px" },
                            }}
                          >
                            <Box sx={{ width: "24px", height: "24px" }}>
                              <Box
                                sx={{
                                  width: { sm: "24px", xs: "20px" },
                                  height: { sm: "24px", xs: "20px" },
                                  borderRadius: "4px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "#008CD6",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#fff",
                                    fontWeight: "bold",
                                    width: "20px",
                                    height: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: {
                                      md: "16px",
                                      sm: "14px",
                                      xs: "14px",
                                    },
                                  }}
                                >
                                  {index + 1}
                                </Typography>
                              </Box>
                            </Box>
                            <Typography
                              component={"span"}
                              color={"#323233"}
                              fontWeight="400"
                              sx={{
                                fontSize: {
                                  md: "16px",
                                  sm: "14px",
                                  xs: "14px",
                                },
                                color: "#008CD6",
                                fontWeight: "700",
                                letterSpacing: "-0.003em",
                              }}
                            >
                              {question.text}
                            </Typography>
                          </Box>
                        </ListItemButton>
                        {queryDownSm ? (
                          <>
                            {collapsePart > 0 ? (
                              <ListItem
                                sx={{
                                  flexWrap: "wrap",
                                  padding: "0 0 0 40px",
                                  zIndex: 50,
                                  borderLeft: "4px #008CD6 solid",
                                  margin: "0 0 0 0",
                                }}
                                onClick={(e) => handleReadLess(e)}
                              >
                                <Typography
                                  sx={{
                                    color: "#008CD6",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Read less...
                                  {/*//Todo {t("pcb.read-less")} */}
                                </Typography>
                              </ListItem>
                            ) : (
                              <ListItem
                                sx={{
                                  flexWrap: "wrap",
                                  padding: "0px 0 0 40px",
                                  zIndex: 50,
                                  borderLeft: "4px #008CD6 solid",
                                  margin: "0 0 0 0",
                                }}
                                onClick={(e) => handleReadMore(e)}
                              >
                                <Typography
                                  sx={{
                                    color: "#008CD6",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Read more...
                                  {/*//Todo {t("pcb.read-more")} */}
                                </Typography>
                              </ListItem>
                            )}
                          </>
                        ) : undefined}

                        <Collapse
                          in={activeCardIndex === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          {queryDownSm &&
                            collapsePart > 0 &&
                            solutions
                              .filter(
                                (solution) =>
                                  activeCardIndex + 1 === solution.id
                              )
                              .map((solution) => {
                                return (
                                  <Box
                                    key={solution.id}
                                    sx={{
                                      width: "100%",
                                      height: "100%",
                                      minHeight: "623.75px",
                                      maxWidth: {
                                        md: "880px",
                                        sm: "440px",
                                        xs: "328px",
                                      },
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "20px",
                                    }}
                                  >
                                    <Slide
                                      in={true}
                                      direction="left"
                                      timeout={600}
                                    >
                                      <Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItem: "center",
                                            gap: "20px",
                                          }}
                                        >
                                          <Typography
                                            component={"span"}
                                            height="100%"
                                            color="#323233"
                                            fontWeight="700"
                                            sx={{
                                              fontSize: {
                                                sm: "24px",
                                                xs: "20px",
                                              },
                                              whiteSpace: "nowrap",
                                            }}
                                            lineHeight="40px"
                                            maxWidth="51px"
                                          >
                                            Intro
                                            {/* //Todo {t("solutions.tpm.intro")} */}
                                          </Typography>
                                          <Box sx={{ width: "100%" }}>
                                            <DashedDividingLine />
                                          </Box>
                                        </Box>
                                        <Box
                                          sx={{
                                            boxSizing: "border-box",
                                            width: "100%",
                                            display: "flex",
                                            gap: {
                                              md: "20px",
                                              sm: "20px",
                                              xs: "20px",
                                            },
                                            flexDirection: {
                                              md: "row",
                                              sm: "column",
                                              xs: "column",
                                            },
                                            alignItems: {
                                              sm: "flex-start",
                                              xs: "center",
                                            },
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: {
                                                sm: "430px",
                                                xs: "304px",
                                              },
                                              flexDirection: "column",
                                              gap: "10px",
                                              height: {
                                                md: "auto",
                                                xs: "auto",
                                              },
                                            }}
                                          >
                                            <Typography
                                              sx={{ fontSize: "16px" }}
                                              fontWeight={700}
                                            >
                                              {solution.title}
                                            </Typography>
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                              fontWeight={400}
                                              color="#89898A"
                                            >
                                              {solution.content}
                                            </Typography>
                                          </Box>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: {
                                                sm: "430px",
                                                xs: "304px",
                                              },
                                            }}
                                          >
                                            <iframe
                                              allowTransparency={true}
                                              title="Wistia video player"
                                              allowFullScreen
                                              frameBorder="0"
                                              scrolling="no"
                                              className="wistia_embed"
                                              name="wistia_embed"
                                              src={
                                                solution.videoSrc ||
                                                "https://fast.wistia.net/embed/iframe/gnhk4c27df"
                                              }
                                              width="100%"
                                              height="100%"
                                            ></iframe>
                                          </Box>
                                        </Box>
                                        <Box
                                          sx={{
                                            width: "100%",
                                            display: "flex",
                                            gap: {
                                              md: "20px",
                                              sm: "20px",
                                              xs: "16px",
                                            },
                                            padding: "0 40px 0 0",
                                            alignItems: "center",
                                            flexDirection: {
                                              md: "row",
                                              sm: "column-reverse",
                                              xs: "column-reverse",
                                            },
                                            margin: {
                                              sm: "20px 0 0",
                                              xs: "16px 0 0",
                                            },
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              width: "100%",
                                              maxWidth: {
                                                sm: "430px",
                                                xs: "304px",
                                              },
                                            }}
                                          >
                                            {solution.answerCard[0].src.map(
                                              (src, index) => {
                                                return (
                                                  <Collapse
                                                    appear={
                                                      activePage === index
                                                    }
                                                    in={activePage === index}
                                                    timeout={{
                                                      appear: 0,
                                                      enter: 600,
                                                      exit: 600,
                                                    }}
                                                    key={src}
                                                  >
                                                    <Box
                                                      sx={{
                                                        height: "100%",
                                                        maxWidth: {
                                                          sm: "430px",
                                                          xs: "304px",
                                                        },
                                                        minHeight: {
                                                          md: "241px",
                                                          sm: "241px",
                                                          xs: "auto",
                                                        },
                                                      }}
                                                    >
                                                      <ImageList
                                                        cols={1}
                                                        rowHeight={
                                                          queryDownSm
                                                            ? "auto"
                                                            : 241
                                                        }
                                                      >
                                                        <ImageListItem
                                                          key={src}
                                                        >
                                                          <img
                                                            loading="lazy"
                                                            src={src}
                                                            alt="FAQsQ1_1"
                                                            className={
                                                              styles.imageW100
                                                            }
                                                            style={{
                                                              height: "100%",
                                                            }}
                                                          />
                                                        </ImageListItem>
                                                      </ImageList>
                                                    </Box>
                                                  </Collapse>
                                                );
                                              }
                                            )}

                                            <Box
                                              sx={{
                                                position: "relative",
                                                margin: "20px 0 0",
                                              }}
                                            >
                                              <Pagination
                                                activePage={activePage}
                                                dataLength={
                                                  solution.answerCard[0].src
                                                    .length
                                                }
                                                handleClickNextPage={
                                                  handleClickNextPage
                                                }
                                                handleClickPreviousPage={
                                                  handleClickPreviousPage
                                                }
                                              />
                                            </Box>
                                          </Box>
                                          {collapsePart > 1 ? (
                                            <Box
                                              sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: {
                                                  md: "430px",
                                                  sm: "100%",
                                                  xs: "100%",
                                                },
                                                // minWidth: { md: "430px", sm: "100%", xs: "100%" },
                                                // maxWidth: {
                                                //   sm: "430px",
                                                //   xs: "304px",
                                                // },
                                                gap: "20px",
                                                justifyContent: "center",
                                              }}
                                            >
                                              {solution.answerCard.map(
                                                (answer) => {
                                                  return (
                                                    <>
                                                      {answer?.features?.map(
                                                        (feature) => {
                                                          return (
                                                            <FeatureCard
                                                              key={
                                                                feature.title
                                                              }
                                                              title={
                                                                feature.title
                                                              }
                                                              content={
                                                                feature.content
                                                              }
                                                              sx={{
                                                                width: {
                                                                  md: "100%",
                                                                  sm: "348px",
                                                                  xs: "328px",
                                                                },
                                                              }}
                                                            />
                                                          );
                                                        }
                                                      )}
                                                    </>
                                                  );
                                                }
                                              )}
                                            </Box>
                                          ) : undefined}
                                        </Box>
                                        <Box
                                          sx={{
                                            width: "100%",
                                            height: "40px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItem: "center",
                                            position: "relative",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              width: "100%",
                                              zIndex: 1,
                                            }}
                                          >
                                            <DashedDividingLine />
                                          </Box>
                                          <Typography
                                            sx={{
                                              color: "#008CD6",
                                              fontSize: "14px",
                                              fontWeight: 700,
                                              zIndex: 2,
                                              textAlign: "center",
                                              width: "100%",
                                              lineHeight: "40px",
                                            }}
                                            onClick={(e) => handleReadLess(e)}
                                          >
                                            Read less...
                                            {/*Todo {t("pcb.read-less")} */}
                                          </Typography>
                                          <Box
                                            sx={{
                                              width: "100%",
                                              zIndex: 1,
                                            }}
                                          >
                                            <DashedDividingLine />
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Slide>
                                  </Box>
                                );
                              })}
                        </Collapse>
                      </Box>
                    ) : (
                      <ListItemButton
                        sx={{
                          width: "100%",
                          boxSizing: "border-box",
                          display: "flex",
                          flexDirection: { sm: "row", xs: "column" },
                          alignItems: "flex-start",
                          padding: { md: "8px 0", sm: 0, xs: "8px 0" },
                        }}
                        onClick={() => handleChangeActiveQuestionCard(index)}
                      >
                        <Box
                          sx={{
                            boxSizing: "border-box",
                            width: "100%",
                            height: "100%",
                            padding: { sm: "0px 0px 0px 24px", xs: "0 0px" },
                            display: "flex",
                            flexDirection: { sm: "column", xs: "row" },
                            alignItems: "flex-start",
                            gap: { sm: "4px", xs: "10px" },
                          }}
                        >
                          <Box sx={{ width: "24px", height: "24px" }}>
                            <Box
                              sx={{
                                width: { sm: "24px", xs: "20px" },
                                height: { sm: "24px", xs: "20px" },
                                borderRadius: "4px",
                                backgroundColor: "#89898A",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontWeight: "bold",
                                  width: "20px",
                                  height: "20px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: {
                                    md: "16px",
                                    sm: "14px",
                                    xs: "14px",
                                  },
                                }}
                              >
                                {index + 1}
                              </Typography>
                            </Box>
                          </Box>
                          <Typography
                            component={"span"}
                            color={"#323233"}
                            sx={{
                              fontSize: { md: "16px", sm: "14px", xs: "14px" },
                              fontWeight: { sm: 400, xs: 700 },
                            }}
                          >
                            {question.text}
                          </Typography>
                        </Box>
                        {queryDownSm ? (
                          <Box
                            sx={{
                              flexWrap: "wrap",
                              zIndex: 50,
                            }}
                            onClick={(e) => handleReadMore(e)}
                          >
                            <Typography
                              sx={{
                                color: "#008CD6",
                                fontSize: "14px",
                                fontWeight: 700,
                                margin: "0 0 0 30px",
                              }}
                            >
                              Read more...
                              {/*//Todo {t("pcb.read-more")} */}
                            </Typography>
                          </Box>
                        ) : undefined}
                      </ListItemButton>
                    )}
                  </Fragment>
                );
              })}
            </ListItem>
          </List>
        </Box>
        {!queryDownSm &&
          solutions
            .filter((solution) => activeCardIndex + 1 === solution.id)
            .map((solution) => {
              return (
                <Box
                  key={solution.id}
                  sx={{
                    width: "100%",
                    height: "100%",
                    minHeight: "623.75px",
                    maxWidth: { md: "880px", sm: "440px", xs: "328px" },
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <Slide in={true} direction="left" timeout={600}>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItem: "center",
                          gap: "20px",
                        }}
                      >
                        <Typography
                          component={"span"}
                          height="100%"
                          color="#323233"
                          fontWeight="700"
                          sx={{
                            fontSize: { sm: "24px", xs: "20px" },
                            whiteSpace: "nowrap",
                          }}
                          lineHeight="40px"
                          maxWidth="51px"
                        >
                          Intro
                          {/*//Todo {t("solutions.tpm.intro")} */}
                        </Typography>
                        <Box sx={{ width: "100%" }}>
                          <DashedDividingLine />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          boxSizing: "border-box",
                          width: "100%",
                          display: "flex",
                          gap: { md: "20px", sm: "20px", xs: "20px" },
                          flexDirection: {
                            md: "row",
                            sm: "column",
                            xs: "column",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "430px",
                            flexDirection: "column",
                            gap: "10px",
                            height: { md: "auto", xs: "auto" },
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "16px" }}
                            fontWeight={700}
                          >
                            {solution.title}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "14px" }}
                            fontWeight={400}
                            color="#89898A"
                          >
                            {solution.content}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            width: "430px",
                          }}
                        >
                          <iframe
                            allowTransparency={true}
                            title="Wistia video player"
                            allowFullScreen
                            frameBorder="0"
                            scrolling="no"
                            className="wistia_embed"
                            name="wistia_embed"
                            src={
                              solution.videoSrc ||
                              "https://fast.wistia.net/embed/iframe/gnhk4c27df"
                            }
                            width="100%"
                            height="100%"
                          ></iframe>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          gap: { md: "20px", sm: "20px", xs: "16px" },
                          padding: "0 40px 0 0",
                          alignItems: "center",
                          flexDirection: {
                            md: "row",
                            sm: "column-reverse",
                            xs: "column-reverse",
                          },
                          margin: { sm: "20px 0 0", xs: "16px 0 0" },
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            maxWidth: {
                              sm: "430px",
                              xs: "304px",
                            },
                          }}
                        >
                          {solution.answerCard[0].src.map((src, index) => {
                            return (
                              <Collapse
                                appear={activePage === index}
                                in={activePage === index}
                                timeout={{
                                  appear: 0,
                                  enter: 600,
                                  exit: 600,
                                }}
                                key={src}
                              >
                                <Box
                                  sx={{
                                    height: "100%",
                                    maxWidth: {
                                      sm: "430px",
                                      xs: "304px",
                                    },
                                    minHeight: {
                                      md: "241px",
                                    },
                                  }}
                                >
                                  <ImageList cols={1} rowHeight={241}>
                                    <ImageListItem key={src}>
                                      <img
                                        loading="lazy"
                                        src={src}
                                        alt="FAQsQ1_1"
                                        className={styles.imageW100}
                                        style={{
                                          height: "100%",
                                        }}
                                      />
                                    </ImageListItem>
                                  </ImageList>
                                </Box>
                              </Collapse>
                            );
                          })}

                          <Box
                            sx={{ position: "relative", margin: "20px 0 0" }}
                          >
                            <Pagination
                              activePage={activePage}
                              dataLength={solution.answerCard[0].src.length}
                              handleClickNextPage={handleClickNextPage}
                              handleClickPreviousPage={handleClickPreviousPage}
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: { md: "430px", sm: "100%", xs: "100%" },
                            // minWidth: { md: "430px", sm: "100%", xs: "100%" },
                            // maxWidth: {
                            //   sm: "430px",
                            //   xs: "304px",
                            // },
                            gap: "20px",
                            justifyContent: "center",
                          }}
                        >
                          {solution.answerCard.map((answer) => {
                            return (
                              <>
                                {answer?.features?.map((feature) => {
                                  return (
                                    <FeatureCard
                                      key={feature.title}
                                      title={feature.title}
                                      content={feature.content}
                                      sx={{
                                        width: {
                                          md: "100%",
                                          sm: "348px",
                                          xs: "328px",
                                        },
                                      }}
                                    />
                                  );
                                })}
                              </>
                            );
                          })}
                        </Box>
                      </Box>
                    </Box>
                  </Slide>
                </Box>
              );
            })}
      </Box>
      <Box sx={{ width: "100%", height: "40px" }}>
        <DashedDividingLine />
      </Box>
    </Box>
  );
}
