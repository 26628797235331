import stepPicture01 from "../images/img_progress1_p.png";
import stepPicture01l from "../images/img_progress1_l.png";
import stepPicture02 from "../images/img_progress2_p.png";
import stepPicture02l from "../images/img_progress2_l.png";

import uno137Pic from "../../../../images/img_device_UNO-137.png";
import maintenancePic from "../../../../images/img_service_maintenance.png";
import ecu1051Pic from "../../../../images/img_device_ECU-1051.png";
import onePPowerImg from "../../../../images/img_device_1-P_power_meter.png";
import threePPowerImg from "../../../../images/img_device_3-P_power_meter.png";
import flowMeterImg from "../../../../images/img_device_flow-meter.png";
import directGHGImg from "../../../../images/directGHG.png";
import indirectEnergyGHGImg from "../../../../images/indirectGHGPurchasedEnergy.png";
import indirectGHGUpImg from "../../../../images/indirectGHGUp.png";
import uno137Img from "../../../../images/img_device_UNO-137.png";
import ecu1051Img from "../../../../images/img_device_ECU-1051.png";
import workEnvImg from "../../../../images/workspace-env-monitoring.png";
import mic770Img from "../../../../images/img_device_MIC-770-V2.png";
import threeInOneSensorImg from "../../../../images/img_device_AQI_sensor.png";

import itemPic from "../images/unknownItem.png";
import { useTranslation, Trans } from "react-i18next";

const useStepCardsData = () => {
  const { t } = useTranslation();
  const stepCards = [
    {
      stepName: t("pcb.building"),
      elementId: "Building",
      src: {
        md: stepPicture01,
        sm: stepPicture01l,
      },
      flexDirection: "row",
      starterPackages: [
        {
          name: t("solutions.ems-and-ehs.energy-efficiency-accelerator"),
          packages: [
            {
              page: 1,
              title: t("pcb.improve-energy-efficiency"),
              content: t("pcb.improve-energy-efficiency-content"),
              devices: [
                {
                  src: maintenancePic,
                  title: "Facility Management & Sustainability",
                  code: t("products.fms-code"),
                },
                {
                  src: uno137Pic,
                  title: t("products.ecu-1051"),
                  code: t("products.ecu-1051-code"),
                },
                {
                  src: onePPowerImg,
                  title: t("products.1-p-power-meter"),
                  code: "",
                },
                {
                  src: threePPowerImg,
                  title: t("products.3-p-power-meter"),
                  code: "",
                },
                {
                  src: flowMeterImg,
                  title: t("products.flow-meter"),
                  code: "",
                },
              ],
            },
          ],
          url: "/starter-packages/high-energy-equipment-monitoring",
        },
        {
          name: t("solutions.ems-and-ehs.ghg-emission-analyzer"),
          packages: [
            {
              page: 1,
              title: t("pcb.reduce-ghg-emissions"),
              content: t("pcb.reduce-ghg-emissions-content"),
              devices: [
                {
                  src: directGHGImg,
                  title: t("solutions.ems-and-ehs.direct-ghg"),
                  code: t("solutions.direct-ghg-code"),
                },
                {
                  src: indirectEnergyGHGImg,
                  title: t(
                    "solutions.ems-and-ehs.indirect-ghg-purchased-energy"
                  ),
                  code: t("products.indirect-ghg-purchased-energy-code"),
                },
                {
                  src: indirectGHGUpImg,
                  title: t(
                    "products.indirect-ghg-up-and-down-stream-activities"
                  ),
                  code: t(
                    "products.indirect-ghg-up-and-down-stream-activities-code"
                  ),
                },
                {
                  src: uno137Img,
                  title: t("products.uno-137"),
                  code: t("products.uno-137-code"),
                },
                {
                  src: ecu1051Img,
                  title: t("products.ecu-1051"),
                  code: t("products.ecu-1051-code"),
                },
                {
                  src: onePPowerImg,
                  title: t("products.1-p-power-meter"),
                  code: "",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      stepName: t("pcb.production-line"),
      elementId: "ProductionLine",
      src: {
        md: stepPicture02,
        sm: stepPicture02l,
      },
      flexDirection: "row-reverse",
      starterPackages: [
        {
          name: t("solutions.tpm.workplace-env-monitoring"),
          packages: [
            {
              page: 1,
              title: t("pcb.indoor-pollutants-monitoring"),
              content: t("pcb.indoor-pollutants-monitoring-content"),
              devices: [
                {
                  src: workEnvImg,
                  title: "Workplace Env. Monitoring",
                  code: t("solutions.ems-and-ehs.wpem-code"),
                },
                {
                  src: mic770Img,
                  title: t("products.mic-770-v2"),
                  code: t("products.mic-770-v2-code"),
                },
                {
                  src: uno137Pic,
                  title: t("products.uno-137"),
                  code: t("products.uno-137-code"),
                },
                {
                  src: ecu1051Pic,
                  title: t("products.ecu-1051"),
                  code: t("products.ecu-1051-code"),
                },
                {
                  src: threeInOneSensorImg,
                  title: t("products.3-in-1-aqi-sensor"),
                  code: "",
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  return stepCards;
};

export { useStepCardsData };
